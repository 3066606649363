import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { PFLoadEntities } from '@pf/shared-common';
import { LoadScheduleSummaryRead } from '../../entities/load-schedule-summary.dto';

@Injectable()
export class LoadScheduleSummaryStore extends AbstractEntityStore<LoadScheduleSummaryRead> {
    constructor() {
        super(PFLoadEntities.LoadScheduleSummary);
    }
}
