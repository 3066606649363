import { IQueryParams } from '@pf/shared-common';

export class QueryParamsChangeDetector {
    private previousJsonParams = '';

    /**
     * Updates the query parameters and checks if there has been any change.
     * @param params - The current query parameters.
     * @returns {boolean} - Returns true if the parameters have changed, otherwise false.
     */
    update(params: IQueryParams): boolean {
        if (!params) {
            throw new Error('Params should not be null or undefined');
        }

        const jsonParams = JSON.stringify({
            ...params,
            filters: {
                ...params.filters,
            },
            pageIndex: undefined,
            pageSize: undefined,
        });

        const changed = jsonParams !== this.previousJsonParams;
        this.previousJsonParams = jsonParams;
        return changed;
    }
}
