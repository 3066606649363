import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { LaneRead } from '../../entities/lane.dto';
import { PFLoadEntities } from '@pf/shared-common';

@Injectable()
export class LaneStore extends AbstractEntityStore<LaneRead> {
    constructor() {
        super(PFLoadEntities.Lane);
    }
}
