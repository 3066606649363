import {
    Inject,
    Injectable,
    InjectionToken,
    Injector,
    Optional,
    runInInjectionContext,
} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpResponseBase,
} from '@angular/common/http';
import { catchError, Observable, retry, tap, throwError, timer } from 'rxjs';
import { LoggerService } from '@pf/shared-services';
import { ResultMap, ResultType } from '@pf/shared-ui';
import { SafeAny } from '@pf/shared-common';

export type RequestFailureHandler = (
    request: HttpRequest<unknown>,
    errorMessage: string,
    error: HttpErrorResponse
) => void;
export const REQUEST_FAILURE_HANDLER =
    new InjectionToken<RequestFailureHandler>('API_FAILURE_HANDLER');

@Injectable()
export class ApiLoggingInterceptor implements HttpInterceptor {
    constructor(
        private loggerService: LoggerService,
        private injector: Injector,
        @Optional()
        @Inject(REQUEST_FAILURE_HANDLER)
        private apiFailureHandler?: RequestFailureHandler
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const startTime = +new Date();

        function createLogMessage(message: string, response: HttpResponseBase) {
            return `${request.method} ${request.url}\n${message} with status: ${
                response.status
            } - ${response.statusText}.\nRequest completed in ${
                +new Date() - startTime
            }ms.`;
        }

        return next.handle(request).pipe(
            retry({
                count: 2,
                delay: (error: HttpErrorResponse) => {
                    if (error.status === 0 || error.status >= 500) {
                        return timer(1000);
                    }

                    return throwError(() => error);
                },
            }),
            catchError((err: ErrorEvent | HttpErrorResponse) => {
                let message = '';
                if (err.error instanceof ErrorEvent) {
                    message = (err.error as ErrorEvent).error;
                } else {
                    message = this.processServerError(err);
                }
                if (err instanceof HttpErrorResponse) {
                    this.handleRequestFailure(request, message, err);
                }
                return throwError(err);
            }),
            tap({
                next: event => {
                    if (event instanceof HttpResponse) {
                        this.loggerService.trace(
                            createLogMessage(`Succeeded`, event)
                        );
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.loggerService.trace(
                        createLogMessage(error.error, error)
                    );
                },
            })
        );
    }

    processServerError(err: SafeAny): string {
        const errorMessage = err?.error;
        if (errorMessage?.title) {
            if (
                errorMessage.title.indexOf(
                    'One or more validation errors occurred'
                ) > -1
            ) {
                let message = '';
                for (const key in errorMessage.errors) {
                    message += errorMessage.errors[key] + '\n';
                }
                return message;
            }
            return errorMessage.title;
        } else if (errorMessage?.message) {
            return errorMessage.message;
        }
        const resultTypes = [...ResultMap.values()];
        const status = err.status?.toString();
        const resultType = resultTypes.find(r => r.statusCode === status);
        return (
            resultType?.subTitle ||
            ResultMap.get(ResultType.Error)?.subTitle ||
            ''
        );
    }

    private handleRequestFailure(
        request: HttpRequest<unknown>,
        message: string,
        error: HttpErrorResponse
    ) {
        if (this.apiFailureHandler) {
            runInInjectionContext(this.injector, () =>
                this.apiFailureHandler!(request, message, error)
            );
        }
    }
}
