import {
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import {
    buildCorePermissionPath,
    buildPermissionPath,
    ContactFacade,
    IContact,
} from '@pf/shared/util-platform';
import {
    PermissionTypes,
    PFCoreEntities,
    PFEntities,
    PlatformModules,
    TableAction,
    TableColumn,
} from '@pf/shared-common';
import { TableColumnBuilder } from '@pf/shared-ui';
import { ContactSummaryComponent } from '../templates/contact.component';
import { contactCustomFieldColumn } from '@pf/platform-core/domain';

@Component({
    selector: 'platform-associated-contact-table',
    templateUrl: './associated-contact-table.component.html',
    styleUrls: ['./associated-contact-table.component.css'],
})
export class AssociatedContactTableComponent implements OnInit {
    contactFacade = inject(ContactFacade);

    @Input()
    contacts$: Observable<IContact[]> | undefined;
    @Input()
    customFieldKey: string | undefined;
    @Input() customFieldTitle?: string;
    @Input() tableActions: TableAction<IContact>[] = [];
    @Input() canAdd = true;
    @Input() baseEntityPlatform!: PlatformModules;
    @Input() baseEntityType!: PFEntities;
    @Output() addAction = new EventEmitter<void>();
    @Output() tableReset = new EventEmitter<void>();

    contactColumns: TableColumn<IContact>[] = [];
    permissions: string[] = [];

    ngOnInit() {
        this.setupColumns();
        this.setupPermissions();
        this.loadInitialData();
    }

    private loadInitialData() {
        this.contactFacade
            .search$({
                pageIndex: 1,
                pageSize: 10,
                sortField: 'modifiedDate',
                sortOrder: 'descend',
            })
            .subscribe(data => (this.contacts$ = of(data.data as IContact[])));
    }

    private setupColumns() {
        const contactColumns = new TableColumnBuilder<IContact>()
            .withNameColumn('fullName')
            .withColumn({
                headerName: 'Email',
                field: 'email',
            })
            .withColumn({
                headerName: 'Type',
                field: ['contactType', 'name'],
            });

        if (this.customFieldKey) {
            contactColumns.with(
                contactCustomFieldColumn,
                this.customFieldKey,
                this.customFieldTitle,
                ContactSummaryComponent
            );
        }

        this.contactColumns = contactColumns.columns;
    }

    private setupPermissions() {
        this.permissions.push(
            buildCorePermissionPath(
                PermissionTypes.Modify,
                PFCoreEntities.Contact
            ),
            buildPermissionPath(
                this.baseEntityPlatform,
                PermissionTypes.Modify,
                this.baseEntityType
            )
        );
    }
}
