import { Injectable } from '@angular/core';
import { IWorkflowDefinitionModelProvider } from '@pf/shared/ui-platform';
import { PFLoadEntities } from '@pf/shared-common';
import { Observable, of } from 'rxjs';
import {
    createBranchedStepModel,
    createBranchesValueModel,
    createChoiceValueModel,
    createDefinitionModel,
    createNullableAnyVariableValueModel,
    createRootModel,
    createStepModel,
    createStringDictionaryValueModel,
    createStringValueModel,
    createVariableDefinitionsValueModel,
    DefinitionModel,
} from 'sequential-workflow-editor-model';
import { ComponentTypesMap } from '../types/load-workflow.types';
import { StepType } from '@control-tower/platform-loads';

@Injectable()
export class LoadWorkflowDefinitionModelProvider
    implements IWorkflowDefinitionModelProvider
{
    name = PFLoadEntities.LoadWorkflow;

    getDefinitionModel$(): Observable<DefinitionModel> {
        const rootModel = createRootModel(root => {
            root.property('name').value(createStringValueModel({}));
            root.property('description').value(
                createStringValueModel({ multiline: true })
            );
            root.property('globals').value(
                createVariableDefinitionsValueModel({})
            );
        });

        const switchModel = createBranchedStepModel(
            'map-uom',
            ComponentTypesMap[StepType.Switch],
            step => {
                step.category('Controls');
                step.property('paramRef').value(
                    createNullableAnyVariableValueModel({
                        isRequired: true,
                        valueTypes: ['string'],
                    })
                );
                step.branches().value(
                    createBranchesValueModel({
                        branches: { GT: [], NT: [], EA: [], LB: [] },
                        dynamic: true,
                    })
                );
            }
        );

        const ifModel = createBranchedStepModel(
            'If',
            ComponentTypesMap[StepType.If],
            step => {
                step.category('Controls');
                step.property('paramRef')
                    .value(
                        createNullableAnyVariableValueModel({
                            isRequired: true,
                            valueTypes: ['number', 'string'],
                        })
                    )
                    .label('Parameter');
                step.property('operator').value(
                    createChoiceValueModel({
                        choices: [
                            'Equals',
                            'NotEquals',
                            'In',
                            'NotIn',
                            'GreaterThan',
                            'GreaterThanOrEquals',
                            'LessThan',
                            'LessThanOrEquals',
                        ],
                    })
                );
                step.property('value')
                    .value(createStringValueModel({}))
                    .label('Value');
                step.branches().value(
                    createBranchesValueModel({
                        branches: { true: [], false: [] },
                        dynamic: false,
                    })
                );
            }
        );

        const evaluateFormulaModel = createStepModel(
            'EvaluateFormula',
            ComponentTypesMap[StepType.Action],
            step => {
                step.category('Actions');
                step.property('name')
                    .value(createStringValueModel({}))
                    .label('Formula Name');
                step.property('expression').value(
                    createStringValueModel({ multiline: true })
                );
                step.property('description').value(
                    createStringValueModel({ multiline: true })
                );
                step.property('variables').value(
                    createStringDictionaryValueModel({ uniqueKeys: true })
                );
            }
        );

        const definitionModel = createDefinitionModel(model => {
            model.valueTypes(['string', 'boolean', 'number', 'object', 'date']);
            model.root(rootModel);
            model.steps([ifModel, switchModel, evaluateFormulaModel]);
        });

        return of(definitionModel);
    }
}
