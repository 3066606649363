import {
    ItemChargeSearchRequestParams,
    ItemChargeViewModelBody,
    ItemChargeViewModelRead,
} from '@control-tower/platform-loads';
import { IEntity, ITypeEntityFragment } from '@pf/shared-common';
import { Currency } from '@control-tower/platform-loads/model/currency';
import { ResourceAuth } from '@control-tower/platform-loads/model/resourceAuth';
import { AccountType } from '@control-tower/platform-loads/model/accountType';
import { CustomFieldViewModel } from '@control-tower/platform-loads/model/customFieldViewModel';
import { ItemDto } from '@pf/platform-item-catalog/domain';

export type ItemChargeRead = ItemChargeViewModelRead & IEntity;
export type ItemChargeCreate = ItemChargeViewModelBody;
export type ItemChargeSearchParams = ItemChargeSearchRequestParams;

export class ItemChargeDto implements IEntity {
    constructor(data: Partial<ItemChargeDto>) {
        Object.assign(this, data);
        this.rateValue = this.rateValue || { amount: 0 };
        this.name = `${this.chargeType?.name} Charge - ${this.accountType} per ${this.unitOfMeasure}`;
    }

    readonly name: string;
    rateId?: string;
    rateValue!: Currency;
    resourceAuth?: ResourceAuth;
    perQuantity?: number | null;
    unitOfMeasure?: string | null;
    accountType?: AccountType;
    item: ItemDto | null = null;
    /**
     * The date the Charge was created.
     */
    createdDate?: string;
    /**
     * The date the Charge was modified.
     */
    modifiedDate?: string;
    /**
     * The Rate Charge\'s Id
     */
    id!: string;
    /**
     * True if the Charge is deleted; otherwise, false.
     */
    isDeleted?: boolean;
    customFields?: Array<CustomFieldViewModel> | null;
    chargeType?: ITypeEntityFragment | null;
}
