import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { ItemChargeRead } from '../../entities/item-charge.dto';
import { PFLoadEntities } from '@pf/shared-common';

@Injectable()
export class ItemChargeStore extends AbstractEntityStore<ItemChargeRead> {
    constructor() {
        super(PFLoadEntities.ItemCharge);
    }
}
