import {
    Component,
    ElementRef,
    HostBinding,
    Injector,
    Input,
    OnInit,
    TemplateRef,
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    FormControl,
    FormGroup,
} from '@angular/forms';
import { PfFormControl } from './form-control';

export type PfStyle =
    | 'default'
    | 'block'
    | 'simple'
    | 'compact'
    | 'stacked'
    | 'wide';

@Component({
    selector: 'pf-base-control',
    template: ``,
    styles: [],
})
export class BaseControlComponent implements OnInit, PfFormControl {
    private _initialDisabledState: boolean | null = null;

    @Input() tooltip?: string | TemplateRef<void>;
    @Input() validating?: boolean;
    private _readonly = false;

    @HostBinding('class.readonly')
    @Input()
    set pfReadonly(readonly: boolean) {
        this.setDisabled(this._initialDisabledState || readonly);
        this._readonly = readonly;
    }

    get pfReadonly() {
        return this._readonly;
    }

    @HostBinding('attr.data-pf-style')
    @Input()
    pfStyle: PfStyle = 'default';
    /**
     * The `label` input is used for displaying a label for the
     * created input. It will be utilized by the `form-item` component.
     * It can be displayed on the left or on the top of the input
     * component.
     */
    @HostBinding('attr.label')
    @Input()
    label = '';
    /**
     * The `loading` field will determine to show/hide a loading animation
     * on the created input.
     */
    @Input() loading = false;
    /**
     * The `control` or the `controlName` fields are for binding the control
     * itself....
     */
    @Input() control?: FormControl;
    @Input() controlName?: string | number;

    /**
     * The `pfErrorTip` input is used by form to show below validation messages regarding the
     * input type.
     */
    @Input() pfErrorTip?: string;

    @Input() set pfDisabled(disabled: boolean) {
        this._initialDisabledState = disabled;
        this.setDisabled(disabled);
    }

    get pfDisabled() {
        return this.formControl?.disabled;
    }

    private setDisabled(disabled: boolean) {
        if (!this.formControl) {
            return;
        }
        if (disabled) {
            this.formControl.disable();
        } else {
            this.formControl.enable();
        }
    }

    get pfRequired() {
        if (!this.control?.validator) {
            return false;
        }
        const validator = this.control.validator({} as AbstractControl);
        return validator && validator['required'];
    }

    get formControl() {
        return this.control as FormControl;
    }

    protected focusElementSelector = '.pf-input';

    constructor(
        private _injector: Injector,
        protected elementRef: ElementRef
    ) {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    protected formControlConfig(control: FormControl) {}

    /**
     * The focus function will allow you to focus on the created input.
     */
    focus() {
        const element = this.elementRef.nativeElement.querySelector(
            this.focusElementSelector
        ) as HTMLElement;

        if (!element?.focus) {
            console.error('Unable to locate input with class .pf-input');
            return;
        }

        element.focus();

        if ((element as HTMLInputElement).select) {
            (element as HTMLInputElement).select();
        }

        if (element.localName === 'nz-date-picker') {
            element.querySelector('input')?.focus();
        }
    }

    ngOnInit(): void {
        this.setupFormControl();
    }

    private setupFormControl() {
        if (!this.control && !this.controlName) {
            throw new Error('Input has not been provided a form control');
        }

        if (!this.control && this.controlName) {
            const formGroup = this._injector.get(ControlContainer, null)
                ?.control as FormGroup;

            if (!formGroup) {
                throw new Error(
                    'ControlName was provided but formGroup not found'
                );
            }

            if (!formGroup?.controls[this.controlName]) {
                throw new Error(
                    `Unable to find form control in form with the name ${this.controlName}`
                );
            }

            this.control = formGroup.get(
                this.controlName as string
            ) as FormControl;
        }

        if (!this.control) {
            throw new Error(`Unable to find form control`);
        }

        this.setDisabled(this._initialDisabledState || this._readonly);

        this.formControlConfig?.(this.control);
    }
}
