import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    Definition,
    Designer,
    RootEditorProvider,
    StepEditorProvider,
    StepsConfiguration,
    ToolboxConfiguration,
    Uid,
    ValidatorConfiguration,
} from 'sequential-workflow-designer';
import { EditorProvider } from 'sequential-workflow-editor';
import { DefinitionModel } from 'sequential-workflow-editor-model';
import { WorkflowDefinitionsService } from './workflow-definitions.service';
import { first, tap } from 'rxjs';
import { PlatformWorkflowDefinition } from '@pf/shared-common';

@Component({
    selector: 'platform-workflow-designer',
    templateUrl: './workflow-designer.component.html',
    styleUrls: ['./workflow-designer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowDesignerComponent implements OnInit {
    private readonly _cdr = inject(ChangeDetectorRef);
    private readonly _definitionProviderService = inject(
        WorkflowDefinitionsService
    );

    @Input({ required: true }) definitionModel!: string;
    @Input({ required: true }) definition!: PlatformWorkflowDefinition;
    @Input() isDuplicable = false;

    @Output() definitionChanged =
        new EventEmitter<PlatformWorkflowDefinition>();

    /**
     * @internal
     */
    stepsConfiguration: StepsConfiguration = {};

    /**
     * @internal
     */
    toolboxConfiguration?: ToolboxConfiguration;

    /**
     * @internal
     */
    validatorConfiguration?: ValidatorConfiguration;

    /**
     * @internal
     */
    stepEditorProvider?: StepEditorProvider;

    /**
     * @internal
     */
    rootEditorProvider?: RootEditorProvider;

    loading = true;

    public ngOnInit() {
        if (!this.definitionModel) {
            throw new Error('Definition model is required');
        }

        const provider =
            this._definitionProviderService.getDefinitionModelProvider(
                this.definitionModel
            );

        if (!provider) {
            throw new Error(
                `Definition model provider for ${this.definitionModel} not found`
            );
        }

        provider
            .getDefinitionModel$()
            .pipe(
                first(),
                tap(model => this.onDefinitionModelLoaded(model)),
                tap(() => {
                    this.loading = false;
                    this._cdr.detectChanges();
                })
            )
            .subscribe();
    }

    public onDesignerReady(_: Designer) {
        // Not currently needed
    }

    public onDefinitionChanged(definition: Definition) {
        this.definition = definition;
        this.definitionChanged.emit(definition);
    }

    private onDefinitionModelLoaded(model: DefinitionModel) {
        this.stepsConfiguration = {
            isDuplicable: () => {
                return this.isDuplicable;
            },
        };
        const editorProvider = EditorProvider.create(model, {
            uidGenerator: Uid.next,
        });
        this.stepEditorProvider = editorProvider.createStepEditorProvider();
        this.rootEditorProvider = editorProvider.createRootEditorProvider();
        this.validatorConfiguration = {
            root: editorProvider.createRootValidator(),
            step: editorProvider.createStepValidator(),
        };
        this.toolboxConfiguration = {
            groups: editorProvider.getToolboxGroups(),
        };
    }
}
