import {
    LoadCreate,
    LoadDto,
    LoadRead,
    LoadSearchParams,
} from '../../entities/load.dto';
import {
    AbstractManageEntityFacade,
    UserAccountType,
    ValueFormatterService,
} from '@pf/shared-services';
import {
    BuildChangeTrackingFacadeFactory,
    CustomFieldConfig,
    TypeConfig,
} from '@pf/shared/util-platform';
import { inject, Injectable } from '@angular/core';
import {
    BulkUpdateResult,
    LoadDataService,
} from '../../infrastructure/loads/load.data.service';
import { LoadMapper } from '../../infrastructure/loads/load.mapper';
import { LoadStore } from '../../infrastructure/loads/load.store';
import { RoutesForLoads } from './load.routes';
import {
    catchError,
    combineLatest,
    forkJoin,
    map,
    Observable,
    of,
    switchMap,
    tap,
} from 'rxjs';
import { LoadStatusTypeFacade } from '../type-entities/LoadStatusTypeFacade';
import {
    EntitySaveOptions,
    IPagedResult,
    IQueryParams,
    PFLoadEntities,
    SafeAny,
} from '@pf/shared-common';
import { ReferenceTypeFacade } from '../type-entities/ReferenceTypeFacade';
import { Formula } from '@control-tower/platform-loads';
import { LoadFacadeConfig } from './load-facade.config';
import { LoadDocumentsFacade } from './load-documents.facade';
import {
    asArraySafe,
    isTempId,
    isValue,
    mapToDtoPageResult,
    optionalMap,
} from '@pf/shared-utility';
import { OneTimeLinkFacade } from '@pf/platform-communications/domain';
import { Operation } from 'fast-json-patch';
import { LoadWorkflowDataService } from '../../infrastructure/load-workflows/load-workflow.data.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class LoadFacade extends AbstractManageEntityFacade<
    LoadDto,
    LoadRead,
    LoadCreate,
    LoadSearchParams
> {
    nameField: keyof LoadDto = 'loadNumber';
    private readonly _loadWorkflowDataService = inject(LoadWorkflowDataService);
    private readonly _valueFormatterService = inject(ValueFormatterService);
    private readonly _oneTimeLinkFacade = inject(OneTimeLinkFacade);
    private readonly _referencesFacade = inject(ReferenceTypeFacade);
    private readonly _loadReferences$ = (
        this.config.references$ || of([])
    ).pipe(
        switchMap(references => this._referencesFacade.mapToCodes$(references))
    );

    private readonly _loadStatusTypeFacade = inject(LoadStatusTypeFacade);

    get canEditLoadNumber() {
        return !this.store.active?.id;
    }

    referencesConfig$: Observable<TypeConfig[]> = this._loadReferences$.pipe(
        map((references: TypeConfig[]) => {
            return references.map(config => {
                return {
                    ...config,
                    label: config.typeFragment?.name,
                } as TypeConfig;
            });
        })
    );

    get customFieldsConfig$(): Observable<CustomFieldConfig[]> {
        return this.config.customFields$ || of([]);
    }

    constructor(
        dataService: LoadDataService,
        routes: RoutesForLoads,
        store: LoadStore,
        mapper: LoadMapper,
        private readonly config: LoadFacadeConfig,
        private readonly documentsFacade: LoadDocumentsFacade
    ) {
        super(dataService, routes, store, mapper);
        this.listenForRealTimeEvents(PFLoadEntities.Load, {
            customEntityMapping: (entity: LoadRead) => {
                return dataService.get$(entity.id, true);
            },
        });
    }

    get loadDataService(): LoadDataService {
        return this.dataService as LoadDataService;
    }

    get newStatusTypeId$(): Observable<string> {
        return this._loadStatusTypeFacade
            .getByCode$(this.config.newStatusType.code)
            .pipe(
                tap(type => {
                    if (!type) {
                        console.warn(
                            `New status type with code ${this.config.newStatusType.code} not found`
                        );
                    }
                }),
                map(type => type?.id as string)
            );
    }

    get cancelledTypeId$(): Observable<string> {
        return this._loadStatusTypeFacade
            .getByCode$(this.config.cancelledStatusType.code)
            .pipe(
                tap(type => {
                    if (!type) {
                        console.warn(
                            `Cancelled status type with code ${this.config.cancelledStatusType.code} not found`
                        );
                    }
                }),
                map(type => type?.id as string)
            );
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        'loadNumber',
        (propertyName: string, value: SafeAny) => {
            const noTzDates: Array<keyof LoadDto> = [
                'loadDate',
                'financialDate',
                'loadRequestedDate',
            ];
            if (noTzDates.includes(propertyName as keyof LoadDto)) {
                return this._valueFormatterService.format('dateNoTz', value);
            }
            return this._valueFormatterService.autoFormat(value) as string;
        }
    );

    override search$(
        params: IQueryParams<LoadDto>
    ): Observable<IPagedResult<LoadDto>> {
        if (params.filters?.loadNumber) {
            params.filters = { loadNumber: params.filters.loadNumber };
        } else if (params.filters?.references) {
            params.filters = { references: params.filters.references };
        }

        this.lastQueryParams = params;
        let searchParams = this.mapper.toSearchParams(params);
        if (this.config.additionalSearchParams) {
            searchParams = {
                ...searchParams,
                ...this.config.additionalSearchParams(),
            };
        }
        return this.dataService
            .search$(searchParams)
            .pipe(mapToDtoPageResult(this.mapper));
    }

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof LoadDto, string>> {
        return { loadNumber: searchText };
    }

    getLoadCount$(queryParams: IQueryParams<LoadDto>) {
        const searchParams = this.mapper.toSearchParams(
            queryParams
        ) as LoadSearchParams;

        return this.loadDataService.countObs$(searchParams).pipe(
            catchError(err => {
                console.error(err);
                return of(0);
            }),
            map(result => result || 0)
        );
    }

    getFormulae$(loadDto: LoadDto): Observable<Formula[]> {
        const load = this.mapper.toEntity(loadDto);

        // Sanitize data before sending to the API to avoid errors
        load.loadNumber = load.loadNumber === '' ? 'temp' : load.loadNumber;

        load.customFields = load.customFields?.map(customField => {
            if (!customField.id) {
                delete customField.id;
            }
            return customField;
        });

        load.stops = load.stops?.map(stop => {
            const tempId = uuidv4();
            stop.id = tempId;

            stop.charges = stop.charges?.map(charge => {
                delete charge.equipmentType;
                if (charge.stopItem) {
                    charge.stopItem.id = uuidv4();
                }
                return charge;
            });

            stop.stopItems = stop.stopItems?.map(stopItem => {
                delete stopItem.value;
                delete stopItem.id;
                stopItem.stopId = tempId;
                return stopItem;
            });
            return stop;
        });

        return this._loadWorkflowDataService.executeWorkflowForLoad$(load);
    }

    override applyResourceAuth(body: LoadCreate, dto: LoadDto) {
        const stops = asArraySafe(dto.stops);
        body.resourceAuth = {
            locations: stops
                .filter(stop => !!stop)
                .map(stop => stop.location?.id)
                .filter(id => !!id) as string[],
            customers: stops
                .filter(stop => !!stop)
                .map(stop => stop.customer?.id)
                .filter(id => !!id) as string[],
            vendors: stops
                .filter(stop => !!stop)
                .map(stop => stop.vendor?.id)
                .filter(id => !!id) as string[],
            carriers: dto.carrier?.id ? [dto.carrier.id] : [],
        };

        if (body.stops?.length) {
            body.stops.forEach(stop => {
                stop.resourceAuth = body.resourceAuth;
                if (stop.stopItems?.length) {
                    stop.stopItems.forEach(item => {
                        item.resourceAuth = { allAuthorized: true };
                    });
                }
            });
        }
    }

    override save$(
        dto: LoadDto,
        options?: EntitySaveOptions<LoadDto>
    ): Observable<LoadDto> {
        const resolvedOptions = {
            saveCustomFields: true,
            emitEvent: true,
            ...(options || {}),
        };
        const update = !!dto.id;
        dto.stops.forEach(stop => {
            if (isTempId(stop.id)) {
                delete (stop as SafeAny).id;
            }
            stop.charges = stop.charges?.filter(
                charge =>
                    isValue(charge.amount) &&
                    charge.accountType &&
                    charge.chargeType?.id
            );
        });

        return forkJoin({
            generatedLoadNumber: this.generateLoadNumber$(dto),
            formulae: this.getFormulae$(dto).pipe(catchError(() => of(null))),
        }).pipe(
            switchMap(({ generatedLoadNumber, formulae }) => {
                dto.loadNumber = generatedLoadNumber.loadNumber;
                dto.formulas = formulae === null ? dto.formulas : formulae;
                return of(dto);
            }),
            optionalMap(this.config?.preSaveMap),
            map(updatedDto => {
                const updatingEntityCreate =
                    this.mapper.toCreateBody(updatedDto);
                this.applyResourceAuth(updatingEntityCreate, updatedDto);
                return updatingEntityCreate;
            }),
            switchMap(load => this.dataService.create$(load)),
            map(entity => this.mapper.toDTO(entity)),
            tap(load => {
                const carrierOneTimeLinks =
                    this._oneTimeLinkFacade.oneTimeLinks.carrierOneTimeLinks.filter(
                        l => !l.isDeleted && !l.id
                    );
                const vendorOneTimeLinks =
                    this._oneTimeLinkFacade.oneTimeLinks.vendorOneTimeLinks.filter(
                        l => !l.isDeleted && !l.id
                    );
                const oneTimeLinks = [
                    ...carrierOneTimeLinks,
                    ...vendorOneTimeLinks,
                ];

                oneTimeLinks.forEach(link => {
                    link.recipientResourceAuth = {
                        allAuthorized: !!load.resourceAuth?.allAuthorized,
                        locations: asArraySafe(load.resourceAuth?.locations),
                        customers:
                            link.recipientUserGroupType ===
                            UserAccountType.Customer
                                ? asArraySafe(load.resourceAuth?.customers)
                                : [],
                        carriers:
                            link.recipientUserGroupType ===
                            UserAccountType.Carrier
                                ? asArraySafe(load.resourceAuth?.carriers)
                                : [],
                        vendors:
                            link.recipientUserGroupType ===
                            UserAccountType.Vendor
                                ? asArraySafe(load.resourceAuth?.vendors)
                                : [],
                    };
                    this._oneTimeLinkFacade.save$(link).subscribe();
                });

                this._oneTimeLinkFacade.oneTimeLinks.carrierOneTimeLinks = [];
                this._oneTimeLinkFacade.oneTimeLinks.vendorOneTimeLinks = [];
            }),
            switchMap(load => {
                if (!update && dto.documents?.length) {
                    return combineLatest(
                        asArraySafe(dto.documents).map(doc => {
                            return this.documentsFacade.create$(load.id, doc);
                        })
                    ).pipe(map(_ => load));
                }
                return of(load);
            }),
            optionalMap(this.config?.postSaveMap),
            tap(entity => {
                if (resolvedOptions.emitEvent) {
                    if (update) {
                        this.updated$.next({
                            entity,
                            actionText: resolvedOptions.actionText,
                        });
                    } else {
                        this.added$.next({
                            entity,
                            actionText: resolvedOptions.actionText,
                        });
                    }
                }
            })
        );
    }

    cancelLoad$(loadId: string) {
        return combineLatest([
            this.getById$(loadId),
            this.cancelledTypeId$,
        ]).pipe(
            map(([load, cancelledTypeId]) => {
                return new LoadDto({
                    ...load,
                    loadStatus: { loadStatusType: { id: cancelledTypeId } },
                });
            }),
            switchMap(load =>
                this.save$(load, {
                    actionText: 'cancelled',
                })
            )
        );
    }

    private generateLoadNumber$<TLoad extends { loadNumber: string }>(
        load: TLoad
    ) {
        if (load.loadNumber) {
            return of(load);
        }

        return this.loadDataService.generateLoadNumber$().pipe(
            map(loadNumber => {
                load.loadNumber = loadNumber || '';
                return load;
            })
        );
    }

    updateManyWithQueryParams$(
        queryParams: Readonly<IQueryParams>,
        operations: Operation[]
    ): Observable<BulkUpdateResult> {
        const searchParams = this.mapper.toSearchParams(
            queryParams
        ) as LoadSearchParams;
        searchParams.pageSize = undefined;
        searchParams.pageNumber = undefined;

        return this.loadDataService.bulkUpdate$(searchParams, operations);
    }

    updateManyByIds$(
        ids: ReadonlyArray<string>,
        operations: Operation[]
    ): Observable<BulkUpdateResult> {
        const searchParams = {
            ids,
        } as LoadSearchParams;
        return this.loadDataService.bulkUpdate$(searchParams, operations);
    }

    deleteManyWithQueryParams$(
        queryParams: Readonly<IQueryParams>
    ): Observable<BulkUpdateResult> {
        const searchParams = this.mapper.toSearchParams(
            queryParams
        ) as LoadSearchParams;
        searchParams.pageSize = undefined;
        searchParams.pageNumber = undefined;

        return this.loadDataService.bulkDelete$(searchParams);
    }

    deleteManyByIds$(ids: ReadonlyArray<string>): Observable<BulkUpdateResult> {
        const searchParams = {
            ids,
        } as LoadSearchParams;
        return this.loadDataService.bulkDelete$(searchParams);
    }
}
