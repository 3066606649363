import {
    CarrierCreate,
    CarrierDto,
    CarrierRead,
    CarrierSearchParams,
} from '../entities/carrier.dto';

import { AbstractManageEntityFacade } from '@pf/shared-services';
import {
    BuildChangeTrackingFacadeFactory,
    IContact,
    IEntityWithContactsFacade,
} from '@pf/shared/util-platform';
import { CarrierDataService } from '../infrastructure/carriers/carrier.data.service';
import { CarrierMapper } from '../infrastructure/carriers/carrier.mapper';
import { CarrierStore } from '../infrastructure/carriers/carrier.store';
import { Injectable } from '@angular/core';
import { RoutesForCarriers } from './carrier.routes';
import { map, Observable, of, switchMap } from 'rxjs';
import { ResourceAuth } from '@control-tower/platform-loads';
import {
    ColumnSearchType,
    EntitySaveOptions,
    IColumnSearchFacade,
} from '@pf/shared-common';

@Injectable()
export class CarrierFacade
    extends AbstractManageEntityFacade<
        CarrierDto,
        CarrierRead,
        CarrierCreate,
        CarrierSearchParams
    >
    implements
        IEntityWithContactsFacade<CarrierDto>,
        IColumnSearchFacade<CarrierDto>
{
    nameField: keyof CarrierDto = 'carrierName';

    constructor(
        protected carrierDataService: CarrierDataService,
        routes: RoutesForCarriers,
        carrierStore: CarrierStore,
        carrierMapper: CarrierMapper
    ) {
        super(carrierDataService, routes, carrierStore, carrierMapper);
    }

    override applyResourceAuth(body: CarrierCreate, dto: CarrierDto) {
        body.resourceAuth = {
            carriers: dto.id ? [dto.id] : [],
        } as ResourceAuth;
    }

    override save$(
        dto: CarrierDto,
        options?: EntitySaveOptions<CarrierDto>
    ): Observable<CarrierDto> {
        return super.save$(dto, options).pipe(
            switchMap(result => {
                if (
                    !result.resourceAuth?.carriers?.find(x => x === result.id)
                ) {
                    // save again to update resourceAuth with new location id
                    return super.save$(result, {
                        emitEvent: false,
                        saveCustomFields: false,
                    });
                }
                return of(result);
            })
        );
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        this.nameField
    );

    getContacts$(entity: CarrierDto): Observable<IContact[]> {
        return this.carrierDataService.getContacts$(entity.id);
    }

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof CarrierDto, string>> {
        return { carrierName: searchText };
    }

    get columnListFilterName(): string {
        return 'carrier';
    }

    getColumnListFilters$(): Observable<ColumnSearchType[]> {
        const queryParams = {
            pageNumber: 1,
            pageSize: 50,
        };

        return this.dataService.searchAll$(queryParams).pipe(
            map(results =>
                results.sort((a, b) =>
                    a.carrierName.localeCompare(b.carrierName)
                )
            ),
            map(result =>
                result.map(carrier => ({
                    value: carrier.id,
                    label: carrier.carrierName,
                }))
            )
        );
    }
}
