import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    ICustomField,
    IPagedResult,
    PFLoadEntities,
    SafeAny,
} from '@pf/shared-common';
import {
    LoadCreate,
    LoadRead,
    LoadSearchParams,
} from '../../entities/load.dto';

import { Injectable } from '@angular/core';
import { LoadStore } from './load.store';
import {
    LoadCustomFieldsService,
    LoadExportRequestParams,
    LoadsService,
    LoadUnifiedViewModelRead,
} from '@control-tower/platform-loads';
import { AutoIncrementService } from '@control-tower/platform-core';
import { map, Observable, switchMap, tap } from 'rxjs';
import { Operation } from 'fast-json-patch';
import { StopStore } from '../stops/stop.store';
import { asArraySafe } from '@pf/shared-utility';
import { StopItemStore } from '../stop-items/stop-item.store';
import { StopRead } from '../../entities/stop.dto';
import { StopItemRead } from '../../entities/stop-item.dto';
import { LoadViewModelReadModelStateResult } from '@control-tower/platform-loads/model/loadViewModelReadModelStateResult';
import { ModelError } from '@control-tower/platform-loads/model/modelError';

export interface BulkUpdateResult {
    errors: string[];
    updated: LoadRead[];
}

@Injectable()
export class LoadDataService extends AbstractDataService<LoadRead, LoadCreate> {
    protected EntityName = PFLoadEntities.Load;

    // TODO: we probably want to configure this per tenant or something -terry
    static readonly LOAD_NUMBER_INCREMENT_TYPE = 'Load Number';

    constructor(
        private service: LoadsService,
        private customFieldsService: LoadCustomFieldsService,
        private autoIncrementService: AutoIncrementService,
        private stopStore: StopStore,
        private stopItemStore: StopItemStore,
        store: LoadStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: LoadCreate): Observable<LoadRead> {
        return (
            this.service.loadCreateCreateLoad({
                loadUnifiedViewModelCreate: body,
            }) as Observable<LoadRead>
        ).pipe(tap(load => this.updateStopsAndStopItemStores(load)));
    }

    protected deleteObs$(id: string): Observable<LoadRead> {
        return this.service
            .loadDelete({
                id,
            })
            .pipe(
                switchMap(load => this.getUnifiedLoad$(load.id as string))
            ) as Observable<LoadRead>;
    }

    protected undoDeleteObs$(id: string): Observable<LoadRead> {
        return this.service
            .loadUndoDelete({
                id,
            })
            .pipe(
                switchMap(load => this.getUnifiedLoad$(load.id as string))
            ) as Observable<LoadRead>;
    }

    protected getObs$(id: string): Observable<LoadRead> {
        return this.getUnifiedLoad$(id) as Observable<LoadRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<LoadRead> {
        return this.service
            .loadUpdatePatch({
                id,
                operation: operations,
            })
            .pipe(
                switchMap(load => this.getUnifiedLoad$(load.id as string))
            ) as Observable<LoadRead>;
    }

    protected override exportObs$(
        exportParams: LoadExportRequestParams
    ): Observable<void> {
        return this.service.loadExport(exportParams) as Observable<SafeAny>;
    }

    protected searchObs$(
        searchParams: LoadSearchParams,
        isOneTimeSearch = false
    ): Observable<IPagedResult<LoadRead>> {
        searchParams.isReadFromSecondary = true;
        return this.service.v1LoadsUnifiedGet(searchParams).pipe(
            tap(result => {
                const data = asArraySafe(result.data);
                if (isOneTimeSearch && data.length > 1) {
                    return;
                }
                const stops = data.map(x => x.stops).flat();
                this.stopStore.upsertMany(stops as StopRead[]);
                const stopItems = stops
                    .map(x => asArraySafe(x?.stopItems))
                    .flat();
                if (stopItems.length > 0) {
                    this.stopItemStore.upsertMany(stopItems as StopItemRead[]);
                }
            })
        ) as SafeAny as Observable<IPagedResult<LoadRead>>;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<LoadRead>>> {
        return this.service.loadGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingDto<LoadRead>>
        >;
    }

    generateLoadNumber$() {
        return this.autoIncrementService
            .autoIncrementerIncrement({
                type: LoadDataService.LOAD_NUMBER_INCREMENT_TYPE,
            })
            .pipe(map(x => x.formatted));
    }

    protected override createCustomFieldObs$(
        entityId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.loadCreateCustomField({
            entityId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override updateCustomFieldObs$(
        entityId: string,
        customFieldId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.loadCustomFieldsUpdatePut({
            entityId,
            id: customFieldId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override deleteCustomFieldObs$(
        entityId: string,
        customFieldId: string
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.loadDeleteCustomField({
            entityId,
            id: customFieldId,
        }) as Observable<ICustomField & { id: string }>;
    }

    countObs$(params: LoadSearchParams): Observable<number> {
        params.pageSize = 1;
        params.pageNumber = 1;
        return this.oneTimeSearchObs$(params).pipe(
            map(result => result.totalRecords || 0)
        );
    }

    bulkUpdate$(
        searchParams: LoadSearchParams,
        operations: Operation[]
    ): Observable<BulkUpdateResult> {
        return this.service
            .loadsBulkPatch({
                ...searchParams,
                operation: operations,
            })
            .pipe(
                tap(result => {
                    Object.values(result).forEach(updateResult => {
                        if (updateResult?.model) {
                            this.store.update(updateResult.model as LoadRead);
                        }
                    });
                }),
                map(result => {
                    return this.processBulkUpdateResponse(result);
                })
            );
    }

    bulkDelete$(searchParams: LoadSearchParams): Observable<BulkUpdateResult> {
        return this.service.loadBulkDelete(searchParams).pipe(
            tap(result => {
                Object.values(result).forEach(updateResult => {
                    if (updateResult?.model) {
                        this.store.delete(updateResult.model as LoadRead);
                    }
                });
            }),
            map(result => {
                return this.processBulkUpdateResponse(result);
            })
        );
    }

    private processBulkUpdateResponse(
        result: {
            [key: string]: LoadViewModelReadModelStateResult;
        } | null
    ) {
        if (!result || typeof result !== 'object') {
            return {
                errors: ['No loads were deleted.'],
                updated: [],
            } as BulkUpdateResult;
        }
        return Object.values(result).reduce(
            (acc, val) => {
                if (val?.errors?.length) {
                    acc.errors.push(
                        ...asArraySafe(val.errors).map(
                            (error: ModelError) => error.error as string
                        )
                    );
                } else if (val?.model) {
                    acc.updated.push(val.model as LoadRead);
                }
                return acc;
            },
            {
                errors: [],
                updated: [],
            } as BulkUpdateResult
        );
    }

    private getUnifiedLoad$(id: string) {
        return this.service
            .v1LoadsUnifiedGet({
                pageNumber: 1,
                pageSize: 1,
                ids: [id],
            })
            .pipe(
                map(result => (result?.data?.length ? result.data[0] : null)),
                tap((load: LoadUnifiedViewModelRead | null) => {
                    this.updateStopsAndStopItemStores(load as LoadRead);
                })
            ) as Observable<LoadRead>;
    }

    private updateStopsAndStopItemStores(
        load: LoadUnifiedViewModelRead | null
    ) {
        const stops = asArraySafe(load?.stops);
        if (stops.length > 0) {
            this.stopStore.upsertMany(stops as StopRead[]);
            const stopItems = stops.map(x => asArraySafe(x.stopItems)).flat();
            if (stopItems.length > 0) {
                this.stopItemStore.upsertMany(stopItems as StopItemRead[]);
            }
        }
    }
}
