import {
    AssociatedAddressDto,
    IAssociatedAddressDto,
    IContact,
    ICustomFieldDto,
} from '@pf/shared/util-platform';
import {
    CustomerSearchRequestParams,
    CustomerViewModelCreateBody,
    CustomerViewModelRead,
    SearchingCustomerEnum,
} from '@control-tower/platform-core';
import { IEntity, IOmniFieldSearchParams } from '@pf/shared-common';

import { asArraySafe, mapToClassSafe } from '@pf/shared-utility';
import { ResourceAuth } from '@control-tower/platform-loads';

export type CustomerRead = CustomerViewModelRead & IEntity;
export type CustomerCreate = CustomerViewModelCreateBody;
export type CustomerSearchParams = CustomerSearchRequestParams &
    IOmniFieldSearchParams;
export type CustomerOmniSearchFieldTypes = SearchingCustomerEnum;

export interface ICustomerDto extends IEntity {
    customerName: string;
    customerNumber?: string;
    contacts?: IContact[];
    associatedAddresses?: IAssociatedAddressDto[];
    shouldReceiveNotifications: boolean;
}

export class CustomerDto implements ICustomerDto {
    constructor(data: Partial<ICustomerDto>) {
        Object.assign(this, data);
        this.contacts = asArraySafe(data.contacts);
        this.associatedAddresses = mapToClassSafe(
            data.associatedAddresses,
            AssociatedAddressDto
        );
        this.shouldReceiveNotifications =
            data?.shouldReceiveNotifications !== false;
    }

    id!: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;

    customerName!: string;
    customerNumber?: string;
    associatedAddresses?: AssociatedAddressDto[];
    contacts?: IContact[];
    customFields?: ICustomFieldDto[];
    resourceAuth?: ResourceAuth;
    shouldReceiveNotifications: boolean;
}
