<div
    [@slideInOut]="(filterDisplay$ | async) ? 'visible' : 'hidden'"
    class="advanced-filters-container">
    <div class="advanced-filters">
        <label class="filter-actions-title">Filter Actions</label>
        <div class="filter-actions">
            <div>
                <pf-button
                    buttonType="clear-square"
                    (click)="filterService.clearFilters()">
                    Clear
                </pf-button>
            </div>
            <pf-button
                buttonType="apply"
                [nz-tooltip]="
                    hasValidationErrors
                        ? 'Enter required information to apply changes.'
                        : ''
                "
                (click)="filterService.applyFilters()"
                [disabled]="!filterService.canApply">
                Apply
            </pf-button>
        </div>
        <pf-text-search-filter
            [filter]="{
                name: 'Search All',
                filterKey: 'search',
                filterType: 'text'
            }"
            (filterComponentReady)="filterComponentsReadyHandler('Search')"
            (filterChangeEmitter)="filterChangeHandler($event)"
            [filtersAreReady$]="filtersAreReady$">
        </pf-text-search-filter>
        @for (filter of filters$ | async; track filter.name) {
            <ng-container [ngSwitch]="filter.filterType">
                <pf-text-search-filter
                    *ngSwitchCase="'text'"
                    [filter]="{
                        name: filter.name,
                        filterKey: filter.filterKey,
                        filterType: filter.filterType,
                        tooltip: filter.tooltip
                    }"
                    (filterChangeEmitter)="filterChangeHandler($event)"
                    (filterComponentReady)="
                        filterComponentsReadyHandler(filter.filterKey)
                    "
                    [filtersAreReady$]="filtersAreReady$">
                    >
                </pf-text-search-filter>
                <pf-checkbox-group-filter
                    pfStyle="stacked"
                    *ngSwitchCase="'multi-select'"
                    [filter]="filter"
                    [filtersAreReady$]="filtersAreReady$"
                    (filterChangeEmitter)="filterChangeHandler($event)"
                    (filterComponentReady)="
                        filterComponentsReadyHandler(filter.filterKey)
                    ">
                </pf-checkbox-group-filter>
                <pf-segmented-date-filter
                    *ngSwitchCase="'date'"
                    [filter]="filter"
                    [filtersAreReady$]="filtersAreReady$"
                    (filterChangeEmitter)="filterChangeHandler($event)"
                    (filterComponentReady)="
                        filterComponentsReadyHandler(filter.filterKey)
                    ">
                </pf-segmented-date-filter>
            </ng-container>
        } @empty {
            <div class="no-filters">No filters available</div>
        }
    </div>
</div>
