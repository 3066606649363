import { deflate, inflate } from 'pako';

export function compressURLSafeString(str: string): string {
    const inputData = stringToUint8Array(str);
    const compressedData = deflate(inputData);
    return base64Encode(compressedData);
}

export function decompressURLSafeString(compressedString: string): string {
    const compressedData = base64Decode(compressedString);
    const decompressedData = inflate(compressedData);
    return uint8ArrayToString(decompressedData);
}

function uint8ArrayToString(uint8Array: Uint8Array): string {
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(uint8Array);
}

function stringToUint8Array(str: string): Uint8Array {
    const encoder = new TextEncoder();
    return encoder.encode(str);
}

function base64Encode(uint8Array: Uint8Array): string {
    let binary = '';
    const len = uint8Array.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(uint8Array[i]);
    }
    const base64String = btoa(binary);
    return base64String
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
}

function base64Decode(base64String: string): Uint8Array {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; i++) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
