import { ItemDataService } from '../infrastructure/items/item.data.service';
import {
    CategoryViewModelDto,
    ItemCreate,
    ItemDto,
    ItemRead,
    ItemSearchParams,
} from '../entities/item.dto';
import { ItemStore, ItemUI } from '../infrastructure/items/item.store';
import { Injectable } from '@angular/core';
import { RoutesForItem } from './item.routes';
import { AbstractManageEntityFacade } from '@pf/shared-services';
import { ItemMapper } from '../infrastructure/items/item.mapper';
import { BuildChangeTrackingFacadeFactory } from '@pf/shared/util-platform';
import { v4 as uuidv4 } from 'uuid';
import {
    catchError,
    combineLatest,
    delay,
    first,
    map,
    Observable,
    of,
    skip,
    switchMap,
    tap,
} from 'rxjs';
import {
    ColumnSearchType,
    IColumnSearchFacade,
    IPagedResult,
    PFItemCatalogEntities,
    SelectOption,
} from '@pf/shared-common';
import { FormService } from '@pf/shared-ui';
import { flattenAndMapToSelectOptions } from '@pf/shared-utility';
import { ResourceAuth } from '@control-tower/platform-item-catalog';

@Injectable()
export class ItemFacade
    extends AbstractManageEntityFacade<
        ItemDto,
        ItemRead,
        ItemCreate,
        ItemSearchParams
    >
    implements IColumnSearchFacade<ItemDto>
{
    nameField: keyof ItemDto = 'name';

    uiItems$: Observable<ItemUI[]> = this.store.getUIEntities$();

    selectedIndex = 0;

    constructor(
        protected override dataService: ItemDataService,
        routes: RoutesForItem,
        protected override store: ItemStore,
        mapper: ItemMapper,
        private formService: FormService
    ) {
        super(dataService, routes, store, mapper);
    }

    override applyResourceAuth(body: ItemCreate) {
        body.resourceAuth = {
            allAuthorized: true,
        } as ResourceAuth;
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        this.nameField as keyof ItemRead
    );

    get columnListFilterName(): string {
        return 'item';
    }

    getColumnListFilters$(): Observable<ColumnSearchType[]> {
        const queryParams = {
            pageNumber: 1,
            pageSize: 50,
        };

        return this.dataService.searchAll$(queryParams).pipe(
            map(results =>
                results.sort((a, b) => a.name.localeCompare(b.name))
            ),
            map(result =>
                result
                    .filter(i => !i.isDeleted)
                    .map(item => ({
                        value: item.id,
                        label: (item.code ? item.code + ' - ' : '') + item.name,
                    }))
            )
        );
    }

    override save$(dto: ItemDto): Observable<ItemDto> {
        const assignedCategories = [...(dto.assignedCategories || [])];
        dto.assignedCategories = undefined;
        return super.save$(dto).pipe(
            switchMap(item => {
                return this.updateItemCategories(
                    item.id,
                    assignedCategories || []
                ).pipe(
                    tap(this.store.update.bind(this.store)),
                    map(entity => {
                        item.assignedCategories = (entity.assignedCategories ||
                            []) as CategoryViewModelDto[];
                        return item;
                    })
                );
            })
        );
    }

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof ItemDto, string>> {
        return { name: searchText };
    }

    getUIItemByIndex(index: number): ItemUI {
        return this.store.getUIEntities()[index];
    }

    override load(entityId: string) {
        super.load(entityId);
        this.active$.pipe(skip(1), first(), delay(0)).subscribe(item => {
            if (item) {
                this.addItemToUI(item);
            }
        });
    }

    addItemToUI(item: ItemDto) {
        const existingIndex = this.store
            .getUIEntities()
            .findIndex(uiItem => uiItem.id === item.id);
        if (existingIndex > -1) {
            this.selectedIndex = existingIndex;
            return;
        }

        const form = this.formService.registerForm(PFItemCatalogEntities.Item);
        this.store.addUIEntity({
            id: item.id || uuidv4(),
            formId: form.id,
            name: item.name || 'New Item',
            isDirty: false,
            state: item,
        });
        this.selectedIndex = this.store.getUIEntities().length - 1;
    }

    updateItemInUI(id: string, item: ItemDto, isDirty: boolean, name?: string) {
        const entity = this.store.getUIEntity(id);
        if (!entity) {
            throw new Error(`Item ${id} not found in UI`);
        }
        this.store.updateUIEntity(id, {
            name: name || entity.name,
            isDirty,
            state: item,
        });
    }

    removeItemFromUI(indexOrId: number | string) {
        if (typeof indexOrId === 'string') {
            this.store.removeUIEntity(indexOrId);
            return;
        }
        const entity = this.getUIItemByIndex(indexOrId);
        if (!entity) {
            throw new Error(`Item ${indexOrId} not found in UI`);
        }
        this.store.removeUIEntity(entity.id);
        this.formService.deRegisterFormId(entity.formId);
    }

    saveAndCloseAll$() {
        const entitiesToSave = this.store
            .getUIEntities()
            .filter(entity => entity.isDirty);
        return (
            entitiesToSave.length > 0
                ? combineLatest(
                      entitiesToSave.map(entity => this.save$(entity.state))
                  )
                : of([])
        ).pipe(
            tap(() => this.closeAll()),
            map(() => true),
            catchError(err => {
                console.error(err);
                return of(false);
            })
        );
    }

    closeAll() {
        this.store.clearUIEntities();
        this.formService.deRegisterAllForms(PFItemCatalogEntities.Item);
    }

    private updateItemCategories(
        id: string,
        assignedCategories: CategoryViewModelDto[]
    ) {
        const entity = this.store.get(id);
        const existingCategories = entity.assignedCategories || [];
        const categoriesToDelete = assignedCategories.filter(c => c.isDeleted);
        const categoriesToAdd = assignedCategories.filter(
            c => !c.isDeleted && existingCategories.every(ac => ac.id !== c.id)
        );

        const operations = [];
        if (categoriesToDelete.length > 0) {
            operations.push(
                ...this.dataService.deleteItemCategories$(
                    id,
                    categoriesToDelete
                )
            );
        }
        if (categoriesToAdd.length > 0) {
            operations.push(
                ...this.dataService.addItemCategories$(id, categoriesToAdd)
            );
        }
        return operations.length > 0
            ? combineLatest(operations).pipe(
                  map(() => {
                      entity.assignedCategories = assignedCategories.filter(
                          c => !c.isDeleted
                      );
                      return entity;
                  })
              )
            : of(entity);
    }

    override mapToSelectOptions(
        source: Observable<IPagedResult<ItemDto>>
    ): Observable<SelectOption[]> {
        return flattenAndMapToSelectOptions<ItemDto>(
            item => item.name + (item.code ? ' - ' + item.code : ''),
            'id',
            item =>
                item.assignedCategories?.map(x => x.name) ?? ['Uncategorized']
        )(source);
    }
}
