import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '@pf/shared-services';

@Pipe({
    name: 'PfDate',
})
export class DatePipe implements PipeTransform {
    constructor(private dateService: DateService) {}

    transform(
        value: string | Date | undefined,
        type: 'date' | 'time' | 'datetime' = 'datetime',
        timezone?: string,
        includeSeconds = false
    ): string {
        if (type === 'datetime') {
            return this.dateService.formatDateTime(
                value,
                timezone,
                includeSeconds
            );
        }
        if (type === 'date') {
            return this.dateService.formatDate(value);
        }
        if (type == 'time') {
            return this.dateService.formatTime(value, timezone, includeSeconds);
        }
        return '';
    }
}
