import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PFCommunicationsEntities } from '@pf/shared-common';
import { EntityBaseRootFormComponent } from '@pf/shared-ui';
import { OneTimeLinkDto } from '@pf/shared/util-platform';
import { Controls } from 'ngx-sub-form';

interface OneTimeLinkForm {
    email: string;
}

@UntilDestroy()
@Component({
    selector: 'platform-one-time-link-form',
    templateUrl: './one-time-link-form.component.html',
    styleUrls: ['./one-time-link-form.component.scss'],
})
export class OneTimeLinkFormComponent extends EntityBaseRootFormComponent<
    OneTimeLinkDto,
    OneTimeLinkForm
> {
    entityType = PFCommunicationsEntities.OneTimeLink;

    constructor() {
        super();
    }

    formControls(): Controls<OneTimeLinkForm> {
        return {
            email: new FormControl(null, Validators.required),
        };
    }
    toFormGroup(item: OneTimeLinkDto): OneTimeLinkForm {
        return {
            email: item.recipientAddress,
        };
    }
    fromFormGroup(formValue: OneTimeLinkForm): OneTimeLinkDto {
        return new OneTimeLinkDto({
            ...this.initialValue,
            recipientAddress: formValue.email || '',
        });
    }
}
