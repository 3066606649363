import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    InjectionToken,
} from '@angular/core';
import {
    IconType,
    PlatformAuthService,
    ProfileProvider,
    TenantProvider,
} from '@pf/shared-common';
import { filter, first } from 'rxjs';

import { BrandingService } from '../../../../services/branding.service';
import { LayoutService } from '../../services/layout.service';

export const MAIN_URL = new InjectionToken<string>('MAIN_URL');
export const IS_TEST_ENVIRONMENT = new InjectionToken<boolean>(
    'IS_TEST_ENVIRONMENT'
);

@Component({
    selector: 'pf-app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderComponent {
    environmentBannerType: IconType = IconType.hide;

    constructor(
        public layoutService: LayoutService,
        public brandingService: BrandingService,
        public profileProvider: ProfileProvider,
        public tenantProvider: TenantProvider,
        public authService: PlatformAuthService,
        @Inject(MAIN_URL) private mainUrl: string,
        @Inject(IS_TEST_ENVIRONMENT) protected isTestEnvironment: boolean
    ) {
        tenantProvider.branding$
            .pipe(
                filter(b => !!b),
                first()
            )
            .subscribe(branding => brandingService.updateBranding(branding));
    }

    logout() {
        this.authService.logout(this.mainUrl);
    }

    resetPassword() {
        this.profileProvider.resetPassword().subscribe(() => {
            // TODO show a notification that the password reset email has been sent // if the result is true
            // TODO show a notification that the password reset email has not been sent // if the result is false
        });
    }

    displayMenuFn() {
        this.layoutService.isHidden = !this.layoutService.isHidden;
    }

    switchEnvironmentBanner() {
        this.environmentBannerType =
            this.environmentBannerType === IconType.hide
                ? IconType.view
                : IconType.hide;
    }

    protected readonly IconType = IconType;
}
