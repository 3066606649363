import { IEntity, TableAction, TableRow } from '@pf/shared-common';

export class TableActionsBuilder<T extends IEntity> {
    private readonly _actions: TableAction<T>[] = [];
    get actions() {
        return this._actions;
    }

    with(
        builderFn: (builder: TableActionsBuilder<T>) => TableActionsBuilder<T>
    ) {
        return builderFn(this);
    }

    withAction(action: TableAction<T>) {
        this._actions.push(action);
        return this;
    }

    withEditAction(
        editFn: (row: T, index: number) => void,
        onRowChangeFn?: (row: T, action: TableAction<T>) => void,
        actionText = 'Edit'
    ) {
        return this.withAction({
            name: actionText,
            onClick: (row, index): void => {
                if (editFn) {
                    editFn(row.data, index);
                } else {
                    throw new Error('No handler setup for edit action!');
                }
            },
            onRowChange: (row, action) => {
                if (onRowChangeFn) {
                    onRowChangeFn(row.data, action);
                }
            },
            textType: 'primary',
            width: 75,
        });
    }

    withDeleteAction(
        deleteFn: (row: T, index: number, tableRow: TableRow<T>) => void,
        actionText = 'Delete',
        onRowChangeFn?: (row: T, action: TableAction<T>) => void
    ) {
        return this.withAction({
            name: actionText,
            onRowChange: (row, action) => {
                action.hide = row.data.isDeleted;
                if (onRowChangeFn) {
                    onRowChangeFn(row.data, action);
                }
            },
            onClick: (row, index): void => {
                if (deleteFn) {
                    deleteFn(row.data, index, row);
                } else {
                    throw new Error('No handler setup for delete action!');
                }
            },
            textType: 'danger',
            width: 75,
        });
    }

    withRestoreAction(
        restoreFn: (row: T, index: number, tableRow: TableRow<T>) => void,
        actionText = 'Restore'
    ) {
        return this.withAction({
            name: actionText,
            onRowChange: (row, action) => {
                action.hide = !row.data.isDeleted;
            },
            onClick: (row, index): void => {
                if (restoreFn) {
                    restoreFn(row.data, index, row);
                } else {
                    throw new Error('No handler setup for restore action!');
                }
            },
            textType: 'primary',
            width: 75,
        });
    }
}
