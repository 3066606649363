import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    IOmniFieldSearchParams,
    IPagedResult,
    IPagedSearchParams,
    PFLoadEntities,
    SafeAny,
} from '@pf/shared-common';
import { LaneStore } from './lane.store';
import {
    LaneCreate,
    LaneRead,
    LaneSearchParams,
} from '../../entities/lane.dto';
import { Operation } from 'fast-json-patch';
import { LaneLookupService, LanesService } from '@control-tower/platform-loads';
import { LaneRateStore } from '../lane-rates/lane-rate.store';
import { LaneRateRead } from '../../entities/lane-rate.dto';
import { asArraySafe } from '@pf/shared-utility';
import { LaneCarrierRead } from '../../entities/lane-carrier.dto';
import { LaneCarrierStore } from '../lane-carriers/lane-carrier.store';
import { ItemChargeRead } from '../../entities/item-charge.dto';
import { ItemChargeStore } from '../item-charges/item-charge.store';

@Injectable()
export class LaneDataService extends AbstractDataService<LaneRead, LaneCreate> {
    protected EntityName = PFLoadEntities.Lane;

    constructor(
        private service: LanesService,
        private lookupService: LaneLookupService,
        store: LaneStore,
        private laneRateStore: LaneRateStore,
        private itemChargeStore: ItemChargeStore,
        private laneCarrierStore: LaneCarrierStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: LaneCreate): Observable<LaneRead> {
        return this.service.laneCreate({
            laneViewModelBase: body,
        }) as Observable<LaneRead>;
    }

    protected deleteObs$(id: string): Observable<LaneRead> {
        return this.service.laneDelete({
            id,
        }) as Observable<LaneRead>;
    }

    protected undoDeleteObs$(id: string): Observable<LaneRead> {
        return this.service.laneUndoDelete({
            id,
        }) as Observable<LaneRead>;
    }

    protected getObs$(id: string): Observable<LaneRead> {
        return this.service.laneReadById({
            id,
        }) as Observable<LaneRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[],
        _: LaneCreate
    ): Observable<LaneRead> {
        return this.service.laneUpdatePatch({
            id,
            operation: operations,
        }) as Observable<LaneRead>;
    }

    override oneTimeSearchObs$(
        searchParams: IPagedSearchParams | IOmniFieldSearchParams
    ): Observable<IPagedResult<LaneRead>> {
        return this.searchObs$(searchParams as LaneSearchParams);
    }

    protected searchObs$(
        searchParams: LaneSearchParams
    ): Observable<IPagedResult<LaneRead>> {
        return this.lookupService
            .laneLookupLookup({
                ...searchParams,
                pageSize: Math.min(searchParams.pageSize || 20, 20),
                includeItemCharges: true,
                includeLocations: true,
                includeLaneCarriers: true,
            })
            .pipe(
                tap(result => {
                    asArraySafe(result.data).forEach(lane => {
                        lane.rates = asArraySafe(lane.rates).filter(
                            r => r.isDeleted !== true
                        );
                        asArraySafe(lane.rates).forEach(rate => {
                            this.laneRateStore.add(rate as LaneRateRead, false);
                            asArraySafe(rate.itemCharges).forEach(charge => {
                                this.itemChargeStore.add(
                                    charge as ItemChargeRead,
                                    false
                                );
                            });
                        });
                        lane.laneCarriers = asArraySafe(
                            lane.laneCarriers
                        ).filter(r => r.isDeleted !== true);
                        asArraySafe(lane.laneCarriers).forEach(laneCarrier => {
                            this.laneCarrierStore.add(
                                laneCarrier as LaneCarrierRead,
                                false
                            );
                        });
                    });
                })
            ) as SafeAny as Observable<IPagedResult<LaneRead>>;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<LaneRead>>> {
        return this.service.laneGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingDto<LaneRead>>
        >;
    }
}
