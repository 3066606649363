<nz-form-item class="pf-form-item">
    <nz-form-label
        *ngIf="!!label"
        class="pf-form-label"
        [nzRequired]="pfRequired"
        [nzNoColon]="true"
        [title]="label"
        [nzTooltipTitle]="tooltip"
        >{{ label }}
    </nz-form-label>
    <nz-form-control
        class="pf-form-control"
        [nzErrorTip]="pfErrorTip"
        [nzValidateStatus]="
            validating ? 'validating' : !!control?.errors ? 'error' : ''
        ">
        <ng-container [ngSwitch]="typeProps?.type">
            <ng-container *ngSwitchCase="'dateRange'">
                <nz-range-picker
                    class="pf-input"
                    [formControl]="formControl"
                    [nzRanges]="presetRanges"
                    [nzDisabled]="pfDisabled || loading"
                    [nzFormat]="dateFormat"
                    [nzPlaceHolder]="placeholder"
                    [nzShowTime]="
                        showTime
                            ? {
                                  nzUse12Hours: true,
                                  nzFormat: 'HH:mm'
                              }
                            : false
                    "
                    [nzStatus]="control?.errors ? 'error' : ''"
                    (nzOnCalendarChange)="calendarChangedHandler($event)">
                </nz-range-picker>
            </ng-container>
            <ng-container *ngSwitchCase="'date'">
                <nz-date-picker
                    class="pf-input"
                    [nzDisabled]="pfDisabled || loading"
                    [nzFormat]="dateFormat"
                    [nzPlaceHolder]="placeholder"
                    [formControl]="formControl"
                    [nzShowTime]="
                        showTime
                            ? {
                                  nzUse12Hours: true,
                                  nzFormat: 'HH:mm'
                              }
                            : false
                    "
                    [nzStatus]="control?.errors ? 'error' : ''">
                </nz-date-picker>
            </ng-container>
            <ng-container *ngSwitchCase="'time'">
                <nz-time-picker
                    class="pf-input"
                    [nzDisabled]="pfDisabled || loading"
                    [nzFormat]="timeFormat"
                    [nzUse12Hours]="true"
                    [nzPlaceHolder]="placeholder"
                    [formControl]="formControl"
                    [nzStatus]="control?.errors ? 'error' : ''">
                </nz-time-picker>
            </ng-container>
        </ng-container>
    </nz-form-control>
</nz-form-item>
