import { Injectable } from '@angular/core';
import {
    CustomFieldConfig,
    EntityFacadeConfig,
} from '@pf/shared/util-platform';
import { Observable } from 'rxjs';
import { CustomerDto } from '../entities/customer.dto';

@Injectable()
export abstract class CustomerFacadeConfig extends EntityFacadeConfig<CustomerDto> {
    abstract customFields$: Observable<CustomFieldConfig[]>;
}
