import {
    IEntity,
    IManageEntityFacade,
    ISearchFacade,
    QueryFilters,
    SafeAny,
} from '@pf/shared-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    inject,
    Input,
    OnInit,
    Optional,
} from '@angular/core';
import {
    catchError,
    first,
    mergeMap,
    Observable,
    of,
    race,
    switchMap,
    tap,
    throwError,
} from 'rxjs';
import { FormService } from '../services/form.service';
import { ActivatedRoute } from '@angular/router';
import { EntityNotificationService } from '../services/entity-notification.service';
import { ModalService } from '../../../services/modal.service';
import { addArticle } from '@pf/shared-utility';

@UntilDestroy()
@Component({
    selector: 'pf-entity-base-edit',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class EntityBaseEditComponent<TEntity extends object & IEntity>
    implements OnInit
{
    private readonly activatedRoute = inject(ActivatedRoute);
    protected readonly formService = inject(FormService);
    private readonly entityNotifications = inject(EntityNotificationService);
    protected readonly modalService = inject(ModalService);
    protected readonly cdr = inject(ChangeDetectorRef);

    abstract entityType: string;
    entityName?: string;
    drawerRendered = true;
    notifyOnEditCompletion = true;
    useIdFallback = true;
    checkDuplicateField?: keyof TEntity;

    @Input() isChild = false;
    @Input() entity?: TEntity;
    @Input() checkDuplicatesBeforeCreate = false;
    @Input() checkDuplicatePromptMessage?: string;
    @Input() duplicateRecordConfirmActionText?: string;
    @Input() duplicateRecordConfirmAction?: (
        entity: TEntity
    ) => Observable<TEntity>;
    @Input() duplicateRecordCancelAction?: () => Observable<TEntity>;

    // Arguments are optional to make Angular happy; however, they are required by derived classes
    protected constructor(
        @Optional()
        @Inject('ignore')
        protected facade: IManageEntityFacade<TEntity> & ISearchFacade<TEntity>
    ) {}

    ngOnInit(): void {
        this.checkIfDrawerRendered();
        this.listenForEditCompletion();
        this.cdr.markForCheck();
        setTimeout(() => {
            this.loadActiveEntity();
            if (!this.isChild) {
                this.loadEntityFromParams();
            }
        });
    }

    abstract newEntityFn$(): Observable<TEntity>;

    protected saveEntity(
        entity: TEntity,
        afterSaveFn?: (savedEntity: TEntity) => void
    ) {
        // this.formService.notifyState(this.entityType, { entity });
        if (!entity.id && this.checkDuplicatesBeforeCreate) {
            this.checkForDuplicates$(entity, afterSaveFn).subscribe();
            this.facade.reload$().subscribe();
            return;
        }

        this.save$(entity, afterSaveFn).subscribe();
    }

    protected notifyEntitySubmitted(entity: TEntity) {
        this.formService.submitted(this.entityType, entity);
    }

    // can be overridden in derived class
    protected afterEditFn() {
        this.facade.manage();
    }

    protected duplicateRecordDisplayFn(entity: TEntity): SafeAny {
        return entity;
    }

    private save$(
        entity: TEntity,
        afterSaveFn?: (savedEntity: TEntity) => void
    ) {
        return this.facade.save$(entity).pipe(
            first(),
            catchError(err => {
                this.formService.notifyError(this.entityType);
                return throwError(err);
            }),
            tap(() => {
                this.entity = entity;
                this.notifyEntitySubmitted(entity);
            }),
            tap(savedEntity => {
                if (afterSaveFn) {
                    afterSaveFn(savedEntity);
                }
            }),
            tap(() => {
                this.cdr.markForCheck();
            })
        );
    }

    private checkForDuplicates$(
        entity: TEntity,
        afterSaveFn?: (savedEntity: TEntity) => void
    ) {
        const field = this.checkDuplicateField;

        if (!field) {
            throw new Error(
                `duplicateCheckField is required when using checkForDuplicates$ function`
            );
        }

        if (!this.modalService) {
            throw new Error(
                'ModalService is required when using duplicateCheckOptions'
            );
        }

        if (!entity[field]) {
            return this.save$(entity, afterSaveFn);
        }

        const filters = {
            [field]: entity[field],
        };
        const entityName = this.entityName?.toLowerCase() ?? 'entity';

        return this.facade
            .oneTimeSearch$({
                pageIndex: 1,
                pageSize: 1,
                filters: filters as unknown as QueryFilters<TEntity>,
            })
            .pipe(
                first(),
                mergeMap(existingEntities => {
                    if (existingEntities.data.length > 0) {
                        const promptMessage = `There is already a potential duplicate ${addArticle(entityName)}. That ${addArticle(entityName)} is displayed below. ${this.checkDuplicatePromptMessage ?? 'Do you want to continue with the creation?'}`;
                        const existingEntity = existingEntities.data[0];
                        return this.modalService!.confirmAction$({
                            entityName,
                            onOk: () =>
                                this.duplicateRecordConfirmAction?.(
                                    existingEntity
                                ) ?? this.save$(entity, afterSaveFn),
                            action: 'Create',
                            onCancel: () => {
                                this.formService.resetSubmit(this.entityType);
                                return (
                                    this.duplicateRecordCancelAction?.() ??
                                    of(entity)
                                );
                            },
                            okText: this.duplicateRecordConfirmActionText,
                            content: promptMessage,
                            entityProps:
                                this.duplicateRecordDisplayFn(existingEntity),
                        });
                    } else {
                        return this.save$(entity, afterSaveFn);
                    }
                })
            );
    }

    private checkIfDrawerRendered() {
        this.drawerRendered = this.formService.isDrawerRendered(
            this.entityType
        );
    }

    private loadEntityFromParams() {
        if (!this.useIdFallback) {
            return;
        }
        const entityId = this.activatedRoute.snapshot.params['id'];
        if (entityId) {
            this.facade.load(entityId);
        } else {
            // fallback lookup
            const urlParts = location.pathname.split('/');
            const editIndex = urlParts.findIndex(part => part === 'edit');
            if (editIndex > 0) {
                console.log(
                    'Using fallback id lookup and found id',
                    urlParts[editIndex - 1]
                );
                this.facade.load(urlParts[editIndex - 1]);
            }
        }
    }

    private listenForEditCompletion() {
        race(
            this.formService.cancelled$(this.entityType),
            this.formService.submitted$(this.entityType)
        )
            .pipe(
                first(),
                tap(() => {
                    if (this.drawerRendered) {
                        this.facade.resetActive();
                    } else {
                        this.formService.resetForm(this.entityType);
                    }
                })
            )
            .subscribe(() => {
                this.afterEditFn();
            });

        if (!this.drawerRendered && this.notifyOnEditCompletion) {
            this.entityNotifications.notifyOnEntityUpdated(
                this.facade,
                this.entityName || this.entityType,
                untilDestroyed(this)
            );
        }
    }

    private loadActiveEntity() {
        if (this.entity) {
            return;
        }
        this.facade.active$
            .pipe(
                untilDestroyed(this),
                switchMap(entity => {
                    if (entity) return of(entity);
                    return this.newEntityFn$();
                }),
                tap(entity =>
                    this.formService.notifyState(this.entityType, { entity })
                )
            )
            .subscribe(entity => {
                this.entity = entity;
            });
    }
}
