import {
    AssociatedAddressDto,
    IAssociatedAddressDto,
    IContact,
} from '@pf/shared/util-platform';
import {
    CarrierSearchRequestParams,
    CarrierViewModelCreateBody,
    CarrierViewModelRead,
    SearchingCarrierEnum,
} from '@control-tower/platform-core';
import { IEntity, IOmniFieldSearchParams } from '@pf/shared-common';

import { asArraySafe, mapToClassSafe } from '@pf/shared-utility';
import { ResourceAuth } from '@control-tower/platform-loads';

export type CarrierRead = CarrierViewModelRead & IEntity;
export type CarrierCreate = CarrierViewModelCreateBody;
export type CarrierSearchParams = CarrierSearchRequestParams &
    IOmniFieldSearchParams;
export type CarrierOmniSearchFieldTypes = SearchingCarrierEnum;

export interface ICarrierDto extends IEntity {
    carrierName: string;
    scac?: string | null;
    contacts?: IContact[];
    associatedAddresses?: IAssociatedAddressDto[];
    shouldReceiveNotifications: boolean;
}

export class CarrierDto implements ICarrierDto {
    constructor(data: Partial<ICarrierDto>) {
        Object.assign(this, data);
        this.contacts = asArraySafe(data.contacts);
        this.associatedAddresses = mapToClassSafe(
            data.associatedAddresses,
            AssociatedAddressDto
        );
        this.shouldReceiveNotifications =
            data?.shouldReceiveNotifications !== false;
    }

    id!: string;
    carrierName!: string;
    scac?: string | null;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
    contacts?: IContact[];
    associatedAddresses?: AssociatedAddressDto[];
    resourceAuth?: ResourceAuth;
    shouldReceiveNotifications: boolean;
}
