import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    ICustomField,
    IPagedResult,
    IPagedSearchParams,
    PFLoadEntities,
} from '@pf/shared-common';
import { LaneCarrierStore } from './lane-carrier.store';
import { Operation } from 'fast-json-patch';
import {
    LaneCarriersCustomFieldsService,
    LaneCarriersService,
} from '@control-tower/platform-loads';
import {
    LaneCarrierCreate,
    LaneCarrierRead,
    LaneCarrierSearchParams,
} from '../../entities/lane-carrier.dto';

@Injectable()
export class LaneCarrierDataService extends AbstractDataService<
    LaneCarrierRead,
    LaneCarrierCreate
> {
    protected EntityName = PFLoadEntities.LaneCarrier;

    constructor(
        private service: LaneCarriersService,
        store: LaneCarrierStore,
        logger: LoggerService,
        private customFieldsService: LaneCarriersCustomFieldsService
    ) {
        super(store, logger);
    }

    protected createObs$(body: LaneCarrierCreate): Observable<LaneCarrierRead> {
        return this.service.laneCarrierCreate({
            laneCarrierViewModelCreateBody: body,
        }) as Observable<LaneCarrierRead>;
    }

    protected deleteObs$(id: string): Observable<LaneCarrierRead> {
        return this.service.laneCarrierDelete({
            id,
        }) as Observable<LaneCarrierRead>;
    }

    protected undoDeleteObs$(id: string): Observable<LaneCarrierRead> {
        return this.service.laneCarrierUndoDelete({
            id,
        }) as Observable<LaneCarrierRead>;
    }

    protected getObs$(id: string): Observable<LaneCarrierRead> {
        return this.service.laneCarrierReadById({
            id,
        }) as Observable<LaneCarrierRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<LaneCarrierRead> {
        return this.service.laneCarrierUpdatePatch({
            id,
            operation: operations,
        }) as Observable<LaneCarrierRead>;
    }

    protected searchObs$(
        searchParams: LaneCarrierSearchParams
    ): Observable<IPagedResult<LaneCarrierRead>> {
        return this.service.laneCarrierSearch(searchParams) as Observable<
            IPagedResult<LaneCarrierRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<LaneCarrierRead>>> {
        return this.service.laneCarrierGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingDto<LaneCarrierRead>>
        >;
    }

    protected override createCustomFieldObs$(
        entityId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.laneCarrierCreateCustomField({
            entityId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override updateCustomFieldObs$(
        entityId: string,
        customFieldId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.laneCarrierCustomFieldsUpdatePut({
            entityId,
            id: customFieldId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override searchCustomFieldsObs$(
        searchParams: IPagedSearchParams & { key?: string; value?: string }
    ): Observable<IPagedResult<ICustomField>> {
        return this.customFieldsService.laneCarrierSearchCustomFields(
            searchParams
        ) as Observable<IPagedResult<ICustomField>>;
    }
}
