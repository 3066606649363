import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    IPagedResult,
    PFCoreEntities,
} from '@pf/shared-common';
import { applyPatch, Operation } from 'fast-json-patch';
import {
    UserCreate,
    UserRead,
    UserSearchParams,
} from '../../entities/user.dto';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserMapper } from './user.mapper';
import { UserStore } from './user.store';
import { UsersService } from '@control-tower/platform-core';

@Injectable()
export class UserDataService extends AbstractDataService<UserRead, UserCreate> {
    protected EntityName = PFCoreEntities.User;

    constructor(
        private userService: UsersService,
        store: UserStore,
        logger: LoggerService,
        private userMapper: UserMapper
    ) {
        super(store, logger);
    }

    resetPassword$(id: string) {
        return this.userService.userPasswordReset({
            id,
        });
    }

    protected createObs$(body: UserCreate): Observable<UserRead> {
        return this.userService.userCreate({
            userViewModelCreateBody: body,
        }) as Observable<UserRead>;
    }

    protected deleteObs$(id: string): Observable<UserRead> {
        return this.userService.v1UsersIdDelete({
            id,
        }) as Observable<UserRead>;
    }

    protected undoDeleteObs$(id: string): Observable<UserRead> {
        return this.userService.v1UsersIdUndoDeletePost({
            id,
        }) as Observable<UserRead>;
    }

    protected getObs$(id: string): Observable<UserRead> {
        return this.userService.userReadById({
            id,
        }) as Observable<UserRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<UserRead> {
        const existing = this.store.get(id);
        const createBody = this.userMapper.toCreateBody(
            this.userMapper.toDTO(existing)
        );
        const patchedDocumentResult = applyPatch(
            createBody,
            operations,
            false,
            false
        );
        return this.userService.userUpdate({
            id,
            userViewModelCreateBody: patchedDocumentResult.newDocument,
        }) as Observable<UserRead>;
    }

    protected searchObs$(
        searchParams: UserSearchParams
    ): Observable<IPagedResult<UserRead>> {
        return this.userService.userSearch(searchParams) as Observable<
            IPagedResult<UserRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<UserRead>>> {
        return this.userService.userGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingDto<UserRead>>
        >;
    }
}
