<form [formGroup]="form.formGroup" nz-form>
    <div class="pf-form-group">
        <pf-input
            *ngIf="associatedAddress"
            label="Name"
            [controlName]="form.formControlNames.name"
            placeholder="Address Name"></pf-input>
        <pf-select
            *ngIf="associatedAddress"
            label="Address Type"
            [data]="addressTypeOptions"
            [controlName]="
                form.formControlNames.associatedAddressType
            "></pf-select>
        <pf-input
            label="Street Address"
            pfAutocomplete="street-address"
            [controlName]="form.formControlNames.streetAddress"
            placeholder="Street Address"></pf-input>
        <pf-input
            label="City/Town"
            pfAutocomplete="address-level2"
            [controlName]="form.formControlNames.city"
            placeholder="City"></pf-input>
        <pf-input
            label="State/Province"
            pfAutocomplete="address-level1"
            [controlName]="form.formControlNames.state"
            placeholder="State"
            [min]="2"
            [max]="2"></pf-input>
        <pf-input
            label="Postal Code"
            pfAutocomplete="postal-code"
            [controlName]="form.formControlNames.zip"
            placeholder="Zip"
            inputType="zip"></pf-input>
        <pf-select
            label="Country/Region"
            [data]="countries"
            [controlName]="form.formControlNames.country"
            placeholder="City"></pf-select>
        <pf-input
            *ngIf="associatedAddress"
            label="Description"
            [controlName]="form.formControlNames.description"
            inputType="textarea"
            placeholder="Title"></pf-input>
    </div>
</form>
