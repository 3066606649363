import { inject } from '@angular/core';
import {
    ExportConfig,
    getTableRowValue,
    IEntity,
    IEntityMapper,
    IEntityReadDataService,
    IEntityStore,
    IPagedResult,
    IPagedSearchParams,
    IQueryParams,
    ISearchFacade,
    QueryFilters,
    SafeAny,
    SelectOption,
    TreeSelectOption,
} from '@pf/shared-common';
import {
    mapToDtoPageResult,
    mapToSelectOptions,
    mapToTreeSelectOptions,
} from '@pf/shared-utility';
import * as saveAs from 'file-saver';
import { unparse } from 'papaparse';
import {
    catchError,
    map,
    Observable,
    of,
    switchMap,
    tap,
    throwError,
} from 'rxjs';
import { LoggerService } from '../logging/Logger.service';
import { ValueFormatterService } from '../value-formatter.service';

export abstract class AbstractSearchFacade<
    TDto extends IEntity,
    TEntity extends IEntity,
    TCreateBody,
    TSearchParams extends IPagedSearchParams,
> implements ISearchFacade<TDto>
{
    protected abstract nameField: keyof TDto;
    protected lastQueryParams?: IQueryParams<TDto>;

    getEntityName(entity: TDto): string {
        return entity ? (entity[this.nameField] as unknown as string) : '';
    }

    abstract textSearchFilter(searchText: string): QueryFilters<TDto>;

    private readonly valueFormatterService = inject(ValueFormatterService);
    private readonly logger = inject(LoggerService);

    protected constructor(
        protected dataService: IEntityReadDataService<TEntity>,
        protected store: IEntityStore<TEntity>,
        protected mapper: IEntityMapper<
            TEntity,
            TCreateBody,
            TDto,
            TSearchParams
        >
    ) {}

    currentPage$ = this.store.getPage$().pipe(mapToDtoPageResult(this.mapper));

    getById(id: string) {
        const entity = this.store.get(id);
        if (entity) {
            return this.mapper.toDTO(entity);
        }
        return null;
    }

    getById$(id: string) {
        const existing = this.getById(id);
        if (existing) {
            return of(existing);
        }
        return this.dataService.get$(id).pipe(
            tap(entity => this.store.add(entity, false)),
            map(entity => this.mapper.toDTO(entity))
        );
    }

    getMany$(ids: string[]) {
        return this.dataService
            .getMany$(ids)
            .pipe(map(entities => this.mapper.toDTOList(entities)));
    }

    reload$() {
        if (this.lastQueryParams) {
            this.store.clearPageHashes();
            return this.search$(this.lastQueryParams).pipe(map(() => true));
        }
        return of(false);
    }

    search$(params: IQueryParams<TDto>): Observable<IPagedResult<TDto>> {
        this.lastQueryParams = params;
        const searchParams = this.mapper.toSearchParams(params);
        return this.dataService
            .search$(searchParams)
            .pipe(mapToDtoPageResult(this.mapper));
    }

    oneTimeSearch$(params: IQueryParams<TDto>): Observable<IPagedResult<TDto>> {
        const searchParams = this.mapper.toSearchParams(params);
        return this.dataService
            .oneTimeSearch$(searchParams)
            .pipe(mapToDtoPageResult(this.mapper));
    }

    searchAll$(
        params: IQueryParams<TDto> = { pageIndex: 1, pageSize: 50 }
    ): Observable<TDto[]> {
        this.lastQueryParams = params;
        const searchParams = this.mapper.toSearchParams(params);

        return this.dataService
            .searchAll$(searchParams)
            .pipe(map(results => results.map(d => this.mapper.toDTO(d))));
    }

    searchByText$(
        text: string,
        pageSize = 20,
        defaultFilters?: QueryFilters<TDto>
    ): Observable<IPagedResult<TDto>> {
        const params: IQueryParams<TDto> = {
            pageIndex: 1,
            pageSize: pageSize,
            sortOrder: 'ascend',
            sortField: this.nameField,
            filters: this.textSearchFilter(text),
        };
        return this.oneTimeSearch$(
            this.addFiltersToParams(params, defaultFilters)
        );
    }

    export$<TExport = TDto>(
        config: ExportConfig<TDto, TExport>
    ): Observable<boolean | 'Cancelled'> {
        const searchParams = this.mapper.toSearchParams(config.params);
        const filename = `${this.valueFormatterService.format(
            'fileTimestamp',
            new Date()
        )}_${config.fileName}`;
        const exportBlobObs$ = config.rawExport
            ? this.dataService.exportRaw$(searchParams, filename)
            : this.exportFromConfig(config, searchParams);
        return exportBlobObs$.pipe(
            map(blob => {
                saveAs(blob, filename, { autoBom: false });
                return true;
            }),
            catchError(err => {
                if (err?.message?.indexOf('STATUS:') > -1) {
                    return of(err.message.replace('STATUS:', ''));
                }
                this.logger.error('Failed to export. ' + JSON.stringify(err));
                return of(false);
            })
        );
    }

    private exportFromConfig(
        config: ExportConfig<TDto, SafeAny>,
        searchParams: IPagedSearchParams
    ): Observable<Blob> {
        return this.dataService.searchAll$(searchParams).pipe(
            map(entities => this.mapper.toDTOList(entities)),
            map(dtos =>
                config.typeConverter ? dtos.map(config.typeConverter) : dtos
            ),
            switchMap(exportDtos => {
                return config.reducer$
                    ? config.reducer$(exportDtos)
                    : of(exportDtos);
            }),
            switchMap(exportDtos =>
                exportDtos.length > 0
                    ? of(exportDtos)
                    : throwError(() => new Error('STATUS:Cancelled'))
            ),
            map(exportDtos => {
                this.logger.debug('Mapping dto list to export data array');
                const columns = [] as string[];

                function addColumn(column: string, index: number) {
                    if (index === 0) {
                        columns.push(column);
                    }
                }

                const data = exportDtos.map((row, index) => {
                    return config.columns.reduce((acc, column) => {
                        const value = getTableRowValue(column, row);
                        if (column.exportValueFormatter) {
                            const exportValue = column.exportValueFormatter(
                                value,
                                row
                            );
                            if (typeof exportValue === 'string') {
                                acc.push(exportValue);
                                addColumn(column.headerName, index);
                            } else if (Array.isArray(exportValue)) {
                                exportValue.forEach(val => {
                                    addColumn(val.header, index);
                                    acc.push(
                                        val.type
                                            ? this.valueFormatterService.format(
                                                  val.type,
                                                  val.value
                                              )
                                            : val.value
                                    );
                                });
                            }
                        } else if (column.valueFormatter) {
                            acc.push(column.valueFormatter(value, row));
                            addColumn(column.headerName, index);
                        } else {
                            acc.push(
                                this.valueFormatterService.format(
                                    column.type,
                                    value,
                                    row
                                )
                            );
                            addColumn(column.headerName, index);
                        }
                        return acc;
                    }, [] as string[]);
                });

                return { fields: columns, data };
            }),
            map(unparsedData => {
                this.logger.debug('Parse data to csv string');
                return unparse(unparsedData);
            }),
            map(csvString => {
                return new Blob([csvString], { type: 'text/csv' });
            })
        );
    }

    mapToSelectOptions(
        source: Observable<IPagedResult<TDto>>
    ): Observable<SelectOption[]> {
        return mapToSelectOptions<TDto>(this.nameField)(source);
    }

    mapToTreeSelectOptions(
        source: Observable<IPagedResult<TDto>>
    ): Observable<TreeSelectOption[]> {
        return mapToTreeSelectOptions<TDto>(this.nameField)(source);
    }

    getCustomFieldsByKey$(key: string) {
        return this.dataService.searchCustomFields$({
            key,
        });
    }

    private addFiltersToParams(
        params: IQueryParams<TDto>,
        defaultFilters?: QueryFilters<TDto>
    ): IQueryParams<TDto> {
        if (!defaultFilters) {
            return params;
        }

        return {
            ...params,
            filters: {
                ...params.filters,
                ...defaultFilters,
            },
        };
    }
}
