import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserPermissionCheckService } from '@pf/shared-services';

@Injectable({
    providedIn: 'root',
})
export class FirebendAdminGuard {
    canActivate():
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        return this.permissionCheckService.isFirebendAdmin$();
    }

    canLoad():
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        return this.permissionCheckService.isFirebendAdmin$();
    }

    constructor(private permissionCheckService: UserPermissionCheckService) {}
}
