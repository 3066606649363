import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    IPagedResult,
    PFLoadEntities,
} from '@pf/shared-common';
import { ItemChargeStore } from './item-charge.store';
import {
    ItemChargeCreate,
    ItemChargeRead,
    ItemChargeSearchParams,
} from '../../entities/item-charge.dto';
import { Operation } from 'fast-json-patch';
import { ItemChargesService } from '@control-tower/platform-loads';

@Injectable()
export class ItemChargeDataService extends AbstractDataService<
    ItemChargeRead,
    ItemChargeCreate
> {
    protected EntityName = PFLoadEntities.ItemCharge;

    constructor(
        private service: ItemChargesService,
        private itemChargesService: ItemChargesService,
        store: ItemChargeStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: ItemChargeCreate): Observable<ItemChargeRead> {
        return this.service.itemChargeCreate({
            itemChargeViewModelBody: body,
        }) as Observable<ItemChargeRead>;
    }

    protected deleteObs$(id: string): Observable<ItemChargeRead> {
        return this.service.itemChargeDelete({
            id,
        }) as Observable<ItemChargeRead>;
    }

    protected undoDeleteObs$(id: string): Observable<ItemChargeRead> {
        return this.service.itemChargeUndoDelete({
            id,
        }) as Observable<ItemChargeRead>;
    }

    protected getObs$(id: string): Observable<ItemChargeRead> {
        return this.service.itemChargeReadById({
            id,
        }) as Observable<ItemChargeRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<ItemChargeRead> {
        return this.service.itemChargeUpdatePatch({
            id,
            operation: operations,
        }) as Observable<ItemChargeRead>;
    }

    protected searchObs$(
        searchParams: ItemChargeSearchParams
    ): Observable<IPagedResult<ItemChargeRead>> {
        return this.service.itemChargeSearch(searchParams) as Observable<
            IPagedResult<ItemChargeRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<ItemChargeRead>>> {
        return this.service.itemChargeGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingDto<ItemChargeRead>>
        >;
    }
}
