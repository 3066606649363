import { Injectable } from '@angular/core';
import { RoutePathBuilder } from '@ngspot/route-path-builder';
import { IManageEntityRoutes } from '@pf/shared-common';

@Injectable()
export abstract class AbstractRouteBuilder
    extends RoutePathBuilder
    implements IManageEntityRoutes
{
    main() {
        return this.url('');
    }

    edit(id: string) {
        return this.urlFromCommands([id, 'edit']);
    }

    add() {
        return this.url('add');
    }

    view(id: string) {
        return this.urlFromCommands([id]);
    }

    abstract permissionType: string;
}
