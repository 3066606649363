import {
    IOptionsSearchFacade,
    IPagedResult,
    IQueryParams,
    SelectOption,
} from '@pf/shared-common';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { asArraySafe } from '@pf/shared-utility';
import { mapToIPagedResult } from '../utility/operators.util';

export type MultiEntitySearchConfig = {
    name: string;
    facade: IOptionsSearchFacade;
    defaultParams?: Partial<IQueryParams>;
};

export function CreateMultiEntitySearchFacade(
    entities: Record<string, MultiEntitySearchConfig>,
    pageSize = 10
): IOptionsSearchFacade {
    return {
        searchByText$(text: string): Observable<IPagedResult<object>> {
            return combineLatest(
                Object.keys(entities).map(entityType =>
                    entities[entityType].facade
                        .searchByText$(text, pageSize)
                        .pipe(
                            map(result => {
                                result.data = asArraySafe(result.data).map(
                                    d => ({
                                        ...d,
                                        entityType: entityType,
                                    })
                                );
                                return result;
                            })
                        )
                )
            ).pipe(
                map(results => results.flatMap(result => result.data)),
                mapToIPagedResult
            );
        },

        mapToSelectOptions(
            source: Observable<IPagedResult<object & { entityType: string }>>
        ): Observable<SelectOption[]> {
            return source.pipe(
                switchMap(result => {
                    const groupedData = result.data.reduce(
                        (acc, d) => {
                            if (!acc[d.entityType]) {
                                acc[d.entityType] = [];
                            }
                            acc[d.entityType].push(d);
                            return acc;
                        },
                        {} as Record<string, object[]>
                    );

                    return combineLatest(
                        Object.keys(entities).map(entityType => {
                            const entityConfig = entities[entityType];
                            if (!entityConfig) {
                                return of([]);
                            }
                            return of(groupedData[entityType]).pipe(
                                mapToIPagedResult,
                                entityConfig.facade.mapToSelectOptions.bind(
                                    entityConfig.facade
                                ),
                                map((results: SelectOption[]) =>
                                    results.map(r => ({
                                        ...r,
                                        group: entityConfig.name,
                                    }))
                                )
                            );
                        })
                    ).pipe(map((results: SelectOption[][]) => results.flat()));
                })
            );
        },
    };
}
