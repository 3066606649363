import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFLoadEntities,
} from '@pf/shared-common';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';
import {
    StopItemCreate,
    StopItemDto,
    StopItemRead,
    StopItemSearchParams,
} from '../../entities/stop-item.dto';
import { ItemMetadataMap } from '@pf/platform-item-catalog/domain';
import {
    CurrencySymbol,
    CustomFieldMetadataMap,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import { ChargeMetadataMap } from '../../entities/charge.dto';

const symbols = entitySymbols(PFLoadEntities.StopItem);

withDefaultEntityMetadata<StopItemDto>(symbols.dto, {
    item: ItemMetadataMap.symbols.dto,
    quantity: Number,
    value: CurrencySymbol,
    valueUnitOfMeasurement: String,
    valueMeasurement: Number,
    valueDescription: String,
    stopId: String,
    customFields: [CustomFieldMetadataMap.symbols.dto],
    resourceAuth: ResourceAuthSymbol,
    charges: [ChargeMetadataMap.symbols.dto],
});

withDefaultEntityMetadata<StopItemRead>(symbols.entity, {
    item: ItemMetadataMap.symbols.entity,
    quantity: Number,
    value: CurrencySymbol,
    valueUnitOfMeasurement: String,
    valueMeasurement: Number,
    valueDescription: String,
    stopId: String,
    customFields: [CustomFieldMetadataMap.symbols.entity],
    resourceAuth: ResourceAuthSymbol,
});

withMetadata<StopItemCreate>(symbols.createBody, {
    id: String,
    itemId: String,
    quantity: Number,
    value: CurrencySymbol,
    valueUnitOfMeasurement: String,
    valueMeasurement: Number,
    valueDescription: String,
    isDeleted: Boolean,
    charges: [ChargeMetadataMap.symbols.createBody],
    customFields: [CustomFieldMetadataMap.symbols.createBody],
    resourceAuth: ResourceAuthSymbol,
});

withDefaultSearchParamsMetadata<StopItemSearchParams>(symbols.searchParams, {
    stopId: String,
});

export const StopItemMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<StopItemRead, StopItemDto>(
            mapper,
            symbols.entity,
            symbols.dto,
            // i had to add this to fix a bug but i don't know why it was broken to begin with -terry
            forMember(
                e => e.valueUnitOfMeasurement,
                mapFrom(s => {
                    return s.valueUnitOfMeasurement;
                })
            )
        );
        createMap<StopItemDto, StopItemRead>(
            mapper,
            symbols.dto,
            symbols.entity,
            // i had to add this to fix a bug but i don't know why it was broken to begin with -terry
            forMember(
                e => e.valueUnitOfMeasurement,
                mapFrom(s => {
                    return s.valueUnitOfMeasurement;
                })
            )
        );
        createMap<StopItemDto, StopItemCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                e => e.itemId,
                mapFrom(s => s.item?.id)
            ),
            forMember(
                e => e.value,
                mapFrom(s => (s.value?.amount ? s.value : null))
            ),
            // i had to add this to fix a bug but i don't know why it was broken to begin with -terry
            forMember(
                e => e.valueUnitOfMeasurement,
                mapFrom(s => {
                    return s.valueUnitOfMeasurement;
                })
            )
        );
        createMap<IQueryParams<StopItemDto>, StopItemSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('stopId'),
            ...withDefaultSearchParamsMappingConfiguration()
        );

        ItemMetadataMap.profile(mapper);
    },
};
