import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    inject,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {
    buildPermissionPath,
    ContactDto,
    ContactFacade,
    IContact,
} from '@pf/shared/util-platform';
import {
    ModalSize,
    PermissionTypes,
    PFCoreEntities,
    PFEntities,
    PlatformModules,
    TableAction,
    TableColumn,
    TableRow,
} from '@pf/shared-common';
import {
    DrawerComponent,
    DrawerService,
    ModalService,
    TableActionsBuilder,
    TableColumnBuilder,
} from '@pf/shared-ui';
import { FormControl } from '@angular/forms';
import { AssociatedEntitiesFormComponent } from './associated-entities-form.component';
import { ContactEditComponent } from './contact-edit.component';
import {
    contactCustomFieldColumn,
    ContactManageConfig,
} from '@pf/platform-core/domain';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ContactSummaryComponent } from '../templates/contact.component';
import { AssociatedContactSearchComponent } from './associated-contact-search.component';
import { UserPermissionCheckService } from '@pf/shared-services';
import { of } from 'rxjs';

@Component({
    selector: 'platform-associated-contacts-form',
    templateUrl: './associated-contacts-form.component.html',
    styles: [
        `
            :host {
                [data-pf-handle] {
                    max-width: 200px;
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociatedContactsFormComponent implements OnInit {
    private _cdr = inject(ChangeDetectorRef);
    private readonly _contactManageConfig = inject(ContactManageConfig);
    private _searchModalRef?: NzModalRef;
    private _formDrawerRef?: ComponentRef<DrawerComponent>;
    private _columns?: TableColumn<IContact>[];
    private readonly _userPermissionCheckService = inject(
        UserPermissionCheckService
    );

    entityType = PFCoreEntities.Contact;

    permissionPlatform = PlatformModules.PlatformCore;
    permissionEntity = PFCoreEntities.Contact;
    permissionType = PermissionTypes.Modify;

    @ViewChild(AssociatedEntitiesFormComponent, { static: false })
    associatedContactsForm!: AssociatedEntitiesFormComponent<
        IContact,
        IContact
    >;

    @Input() loading = false;
    @Input() control!: FormControl;
    @Input() baseEntityType!: PFEntities;
    @Input() baseEntityPlatform!: PlatformModules;
    @Input() postSaveFn?: () => void;
    @Input() postDeleteFn?: () => void;

    @Input() set columns(columns: TableColumn<IContact>[]) {
        if (columns) {
            this._columns = columns;
            return;
        }
        this._columns = this.setDefaultColumns();
    }

    get columns(): TableColumn<IContact>[] {
        return (this._columns ??= this.setDefaultColumns());
    }

    actions: TableAction<IContact>[] = [];

    ngOnInit() {
        this._userPermissionCheckService
            .checkPermission$(
                buildPermissionPath(
                    this.permissionPlatform,
                    this.permissionType,
                    this.permissionEntity
                )
            )
            .subscribe(hasPermission => {
                if (hasPermission) {
                    this.actions = [
                        ...this.actions,
                        ...new TableActionsBuilder<IContact>().withEditAction(
                            row => this.openContactDrawer(row as ContactDto)
                        ).actions,
                    ];
                }
            });
    }

    constructor(
        public contactFacade: ContactFacade,
        private drawerService: DrawerService,
        private modalService: ModalService,
        private viewContainerRef: ViewContainerRef
    ) {}

    contactFragmentFn(contact: IContact) {
        return {
            ...contact,
            fullName: `${contact.firstName} ${contact.lastName}`,
            contactTypeName: contact.contactType?.name,
        } as IContact;
    }

    addContact() {
        this.openContactDrawer();
    }

    saveContact(contact: ContactDto) {
        this.associatedContactsForm.addEntity({
            value: contact.id,
            label: contact.fullName,
        });
        setTimeout(() => this.postSaveFn?.(), 50);
    }

    updateContact(contact: ContactDto) {
        this.associatedContactsForm.updateEntity(contact);
    }

    openSearchContactModal() {
        this._searchModalRef = this.modalService.createComponentModal({
            title: 'Search Contacts',
            content: AssociatedContactSearchComponent,
            viewContainerRef: this.viewContainerRef,
            componentParams: {
                customFieldKey: this._contactManageConfig.customFieldKey,
                customFieldTitle: this._contactManageConfig.customFieldTitle,
                baseEntityType: this.baseEntityType,
                baseEntityPlatform: this.baseEntityPlatform,
                tableActions: new TableActionsBuilder<IContact>().withAction({
                    name: 'Select',
                    textType: 'primary',
                    onClick: (row, _): void => {
                        this.selectHandler(row.data);
                    },
                }).actions,
                addAction: () => {
                    this._searchModalRef?.close();
                    this.addContact();
                },
            },
            size: ModalSize.Large,
        });
    }

    private openContactDrawer(contact?: ContactDto) {
        this._formDrawerRef = this.drawerService.createFormDrawer({
            title: contact ? 'Edit Contact' : 'Add Contact',
            entityType: PFCoreEntities.Contact,
            component: ContactEditComponent,
            navigateBackOnClose: false,
            level: 2,
            componentProps: {
                entity: contact ?? new ContactDto({ isDeleted: false }),
                afterSaveFn: contact
                    ? this.updateContact.bind(this)
                    : this.saveContact.bind(this),
                useIdFallback: false,
                navigateOnSave: false,
                checkDuplicatePromptMessage:
                    'Do you want to use the other contact?',
                duplicateRecordConfirmActionText: 'Use Other Contact',
                duplicateRecordConfirmAction: (entity: ContactDto) =>
                    this.confirmUsingOtherContact(entity),
            },
        });
    }

    private confirmUsingOtherContact(contact: ContactDto) {
        this.saveContact(contact);
        this._formDrawerRef?.instance.close();
        return of(contact);
    }

    private setDefaultColumns() {
        const columns = new TableColumnBuilder<IContact>()
            .withNameColumn('fullName')
            .withColumn({
                headerName: 'Email',
                field: 'email',
            })
            .withColumn({
                headerName: 'Phone',
                field: 'phone',
            })
            .withColumn({
                headerName: 'Type',
                field: ['contactType', 'name'],
            })
            .withColumn({
                headerName: 'Description',
                field: 'description',
            });

        if (this._contactManageConfig.customFieldKey) {
            columns.with(
                contactCustomFieldColumn,
                this._contactManageConfig.customFieldKey,
                this._contactManageConfig.customFieldTitle,
                ContactSummaryComponent
            );
        }

        columns.withColumn({
            headerName: 'Is Default',
            field: 'isDefault',
            type: 'checkbox',
            checkedChange: (contact: TableRow<IContact>, checked: boolean) => {
                if (!checked) {
                    return;
                }
                for (
                    let i = 0;
                    i < this.associatedContactsForm.value.length;
                    i++
                ) {
                    const row = this.associatedContactsForm.value[i];
                    this.associatedContactsForm.updateEntity(
                        {
                            ...row,
                            isDefault: row.id === contact.data.id,
                        },
                        i
                    );
                }
                this._cdr.detectChanges();
            },
        });

        return columns.columns;
    }

    private selectHandler(contact: IContact) {
        this.saveContact(contact as ContactDto);
        this._searchModalRef?.close();
    }
}
