import {
    inject,
    Inject,
    InjectionToken,
    Optional,
    Pipe,
    PipeTransform,
} from '@angular/core';
import { AppConstantsConfig } from '@pf/shared-common';

export function ResolveValue(
    value: string,
    config?: Record<string, string>,
    defaultValue?: string
) {
    return config
        ? config[value] || defaultValue || value
        : defaultValue || value;
}

export function ResolveTemplate(
    value: string,
    config?: Record<string, string>
) {
    const parts = value.split(/{/);
    let resolvedString = '';
    for (let i = 0; i < parts.length; i++) {
        const closingBracketIndex = parts[i].indexOf('}');
        if (closingBracketIndex > -1) {
            resolvedString += ResolveValue(
                parts[i].substring(0, closingBracketIndex),
                config
            );
            resolvedString += parts[i].substring(closingBracketIndex + 1);
        } else {
            resolvedString += parts[i];
        }
    }
    return resolvedString;
}

export const APP_CONSTANTS_CONFIG = new InjectionToken<AppConstantsConfig>(
    'app-constants.config'
);
export const ResolveAppConstant = (
    key: string,
    defaultValue?: string | Record<string, string>
) => {
    const config = inject(APP_CONSTANTS_CONFIG);
    const resolvedDefault =
        typeof defaultValue === 'string' ? defaultValue : defaultValue?.[key];
    return ResolveValue(key, config, resolvedDefault);
};
export const ResolveTemplateWithAppConstants = (value: string) => {
    const config = inject(APP_CONSTANTS_CONFIG);
    return ResolveTemplate(value, config);
};

@Pipe({
    name: 'AppConstant',
})
export class AppConstantPipe implements PipeTransform {
    constructor(
        @Optional()
        @Inject(APP_CONSTANTS_CONFIG)
        private readonly config?: AppConstantsConfig
    ) {}

    transform(configKey?: string | null, defaultValue?: string): string {
        if (!configKey) {
            return '';
        }
        return ResolveValue(configKey, this.config, defaultValue);
    }
}

@Pipe({
    name: 'AppConstantTemplate',
})
export class AppConstantTemplatePipe implements PipeTransform {
    constructor(
        @Optional()
        @Inject(APP_CONSTANTS_CONFIG)
        private readonly config?: AppConstantsConfig
    ) {}

    transform(value?: string | null): string {
        if (!value) {
            return '';
        }
        return ResolveTemplate(value, this.config);
    }
}
