import { IEntity } from '@pf/shared-common';
import { differenceInMinutes } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

/**
 * Determines if an entity is considered stale based on its modifiedDate property.
 * @param entity - The entity to check.
 * @returns True if the entity is stale, otherwise false.
 */
export function isEntityStale(entity: IEntity): boolean {
    if (!entity.modifiedDate) {
        return true;
    }
    // check if modified date is greater than 5 minutes
    return (
        differenceInMinutes(
            new Date(),
            new Date(entity.modifiedDate as string)
        ) > 5
    );
}

const tempIdPrefix = 'temp_';

/**
 * Generates a temporary unique identifier prefixed with 'temp_'.
 * @returns A temporary unique identifier.
 */
export function getTempId() {
    return `${tempIdPrefix}${uuidv4()}`;
}

/**
 * Checks if a given ID is a temporary ID.
 * @param id - The ID to check.
 * @returns True if the ID is a temporary ID, otherwise false.
 */
export function isTempId(id?: string | null) {
    return !!id && id.startsWith(tempIdPrefix);
}
