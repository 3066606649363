<ng-container *ngIf="fullPageLayout">
    <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="!fullPageLayout">
    <pf-app-layout>
        <pf-app-header>
            <pf-tenant-selector
                header-actions
                pfFirebendAdmin></pf-tenant-selector>
        </pf-app-header>
        <pf-app-nav-menu
            *ngIf="!layoutService.isHidden"
            [navigation]="appRouter.navigation"></pf-app-nav-menu>
    </pf-app-layout>
</ng-container>
