import { LoadWorkflowDataService } from '../infrastructure/load-workflows/load-workflow.data.service';
import {
    LoadWorkflowCreate,
    LoadWorkflowDto,
    LoadWorkflowRead,
    LoadWorkflowSearchParams,
} from '../entities/load-workflow.dto';
import { LoadWorkflowStore } from '../infrastructure/load-workflows/load-workflow.store';
import { Injectable } from '@angular/core';
import { RoutesForLoadWorkflow } from './load-workflow.routes';
import { AbstractManageEntityFacade } from '@pf/shared-services';
import { LoadWorkflowMapper } from '../infrastructure/load-workflows/load-workflow.mapper';
import { BuildChangeTrackingFacadeFactory } from '@pf/shared/util-platform';
import { PlatformWorkflowDefinition, SafeAny } from '@pf/shared-common';
import { asArraySafe } from '@pf/shared-utility';
import {
    ComponentTypesMap,
    TypePropertiesMapper,
    WorkflowType,
} from '../types/load-workflow.types';
import {
    BranchedStep,
    Step as SwdStep,
    Uid,
} from 'sequential-workflow-designer';
import {
    ParamViewModel,
    Step as PlatformStep,
    StepType,
} from '@control-tower/platform-loads';
import { delay, map, Observable, of } from 'rxjs';
import { SequentialStep } from 'sequential-workflow-model';

export interface LoadWorkflowDefinition extends PlatformWorkflowDefinition {
    properties: {
        name: string;
        description: string;
        globals: {
            variables: {
                name: string;
                type: string;
            }[];
        };
    };
}

@Injectable()
export class LoadWorkflowFacade extends AbstractManageEntityFacade<
    LoadWorkflowDto,
    LoadWorkflowRead,
    LoadWorkflowCreate,
    LoadWorkflowSearchParams
> {
    nameField: keyof LoadWorkflowDto = 'name';
    override mergeOnUpdate = false;

    constructor(
        private loadWorkflowDataService: LoadWorkflowDataService,
        routes: RoutesForLoadWorkflow,
        store: LoadWorkflowStore,
        mapper: LoadWorkflowMapper
    ) {
        super(loadWorkflowDataService, routes, store, mapper);
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        this.nameField
    );

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof LoadWorkflowDto, string>> {
        return { name: searchText };
    }

    toWorkflowDefinition$(
        workflow: LoadWorkflowDto,
        globalParameters?: ParamViewModel[]
    ): Observable<LoadWorkflowDefinition> {
        function mapSequence(sequence?: PlatformStep[] | null): SwdStep[] {
            return asArraySafe(sequence)
                .filter(s => !!s.type && s.type !== StepType.Unknown)
                .map(p => {
                    // TODO switch logic will need to be enhanced to handle other types
                    const type =
                        p.type === StepType.Switch
                            ? WorkflowType.MapUom
                            : p.action || p.type;
                    const propertiesFn =
                        TypePropertiesMapper[type as WorkflowType]
                            ?.toProperties || (p => p || {});
                    const branches = Object.keys(p.branches || {});
                    const hasBranches = branches.length > 0;
                    return {
                        id: Uid.next(),
                        name: p.name!,
                        type: type,
                        componentType: ComponentTypesMap[p.type!],
                        properties: propertiesFn(p.properties || {}),
                        branches: hasBranches
                            ? Object.keys(p.branches || {}).reduce((pv, cv) => {
                                  pv[cv] = mapSequence(p.branches![cv]);
                                  return pv;
                              }, {} as SafeAny)
                            : null,
                        sequence: !hasBranches
                            ? mapSequence(p.sequence)
                            : undefined,
                    } as SwdStep;
                });
        }

        return of({
            properties: {
                name: workflow.name || '',
                description: workflow.description || '',
                globals: {
                    variables: [
                        { name: 'load', type: 'object' },
                        ...asArraySafe(globalParameters)
                            .concat(asArraySafe(workflow.workflow.params))
                            .map(p => {
                                return {
                                    name: p.name!,
                                    type: p.outputType?.toLowerCase() || 'any',
                                };
                            }),
                    ],
                },
            },
            sequence: mapSequence(workflow.workflow.sequence),
        });
    }

    fromWorkflowDefinition$(
        workflowId: string | undefined,
        definition: LoadWorkflowDefinition
    ) {
        function mapSequence(sequence?: SwdStep[] | null): PlatformStep[] {
            return asArraySafe(sequence).map(p => {
                const propertiesFn =
                    TypePropertiesMapper[p.type as WorkflowType]
                        ?.fromProperties || (p => p || {});
                const step = {
                    name: p.name!,
                    action: p.type,
                    type:
                        p.type === 'If'
                            ? StepType.If
                            : ComponentTypesMap[p.componentType!],
                    properties: propertiesFn(p.properties || {}),
                } as PlatformStep;

                if (
                    (p as BranchedStep).branches &&
                    (step.type === StepType.Switch || step.type === StepType.If)
                ) {
                    step.branches = Object.keys(
                        (p as BranchedStep).branches
                    ).reduce((pv, cv) => {
                        pv[cv] = mapSequence((p as BranchedStep).branches![cv]);
                        return pv;
                    }, {} as SafeAny);
                }

                if ((p as SequentialStep).sequence) {
                    step.sequence = mapSequence((p as SequentialStep).sequence);
                }

                return step;
            });
        }

        return of(
            new LoadWorkflowDto({
                id: workflowId,
                name: definition.properties.name,
                description: definition.properties.description,
                workflow: {
                    workflowName: definition.properties.name,
                    params: [],
                    sequence: mapSequence(definition.sequence),
                },
            })
        ).pipe(delay(900));
    }

    testLoadWorkflow(workflow: LoadWorkflowDto, loadId: string) {
        const createBody = this.mapper.toCreateBody(workflow);
        return this.loadWorkflowDataService
            .testWorkflow(createBody, loadId, workflow.id)
            .pipe(
                map(r =>
                    r.hasErrors
                        ? { success: false, formulas: [] }
                        : {
                              success: true,
                              formulas: asArraySafe(r.results).map(
                                  f => f.output
                              ),
                          }
                )
            );
    }
}
