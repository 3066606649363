import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFLoadEntities,
    SafeAny,
} from '@pf/shared-common';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
} from '@pf/shared-utility';
import {
    LoadScheduleSummaryCreate,
    LoadScheduleSummaryDto,
    LoadScheduleSummaryRead,
    LoadScheduleSummarySearchParams,
} from '../../entities/load-schedule-summary.dto';
import { DefaultCustomerFragmentMetadataMap } from '@pf/shared/util-platform';
import { LocationMetadataMap } from '@pf/platform-core/domain';
import { LoadScheduleMetadataMap } from './load-schedule.mapping-profile';

const symbols = entitySymbols(PFLoadEntities.LoadScheduleSummary);

withMetadata<Omit<LoadScheduleSummaryDto, 'name'>>(symbols.dto, {
    id: String,
    origin: LocationMetadataMap.symbols.dto,
    numberOfSchedules: Number,
    customer: DefaultCustomerFragmentMetadataMap.symbols.dto,
    schedules: [LoadScheduleMetadataMap.symbols.dto],
});

withMetadata<LoadScheduleSummaryRead>(symbols.entity, {
    id: String,
    origin: LocationMetadataMap.symbols.entity,
    numberOfSchedules: Number,
});

withMetadata<LoadScheduleSummaryCreate>(symbols.createBody, {
    origin: LocationMetadataMap.symbols.entity,
    schedules: [LoadScheduleMetadataMap.symbols.createBody],
});

withDefaultSearchParamsMetadata<LoadScheduleSummarySearchParams>(
    symbols.searchParams,
    {
        originLocationId: String,
    }
);

export const LoadScheduleSummaryMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<LoadScheduleSummaryRead, LoadScheduleSummaryDto>(
            mapper,
            symbols.entity,
            symbols.dto,
            forMember(
                d => d.customer,
                mapFrom(s => s.origin?.customers?.[0] || null)
            )
        );
        createMap<LoadScheduleSummaryDto, LoadScheduleSummaryRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<LoadScheduleSummaryDto, LoadScheduleSummaryCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
        createMap<
            IQueryParams<LoadScheduleSummaryDto>,
            LoadScheduleSummarySearchParams
        >(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            forMember(
                d => d.originLocationId,
                mapFrom(s => {
                    return (s.filters || ({} as SafeAny)).locationSearch;
                })
            ),
            forMember(
                d => d.jobNameStartsWith,
                mapFrom(s => {
                    return (s.filters || ({} as SafeAny)).customerSearch;
                })
            ),
            ...withDefaultSearchParamsMappingConfiguration()
        );

        LocationMetadataMap.profile(mapper);
        DefaultCustomerFragmentMetadataMap.profile(mapper);
    },
};
