<pf-table-toolbar
    [showSearch]="showSearch"
    [showClearFilters]="showClearFilters"
    [disableClearFilters]="loading"
    [showSelectionToolbar]="!!showSelection"
    [showAdvancedFilterButton]="hasAdvancedFilters"
    (resetTable)="reset()"
    (refreshTable)="refresh()"
    (search)="onSearchChanged($event)">
    <ng-content
        select="[selection-toolbar]"
        ngProjectAs="[selection-toolbar]"></ng-content>
    <ng-content
        select="[toolbar-title]"
        ngProjectAs="[toolbar-title]"></ng-content>
    <ng-content
        select="[toolbar-left]"
        ngProjectAs="[toolbar-left]"></ng-content>
    <pf-editable-table-actions
        [showSaveButton]="!manualSave"
        (saveChanges)="saveEditedData()"
        toolbar-right
        *ngIf="editable"></pf-editable-table-actions>
    <ng-content select="[toolbar-right]" ngProjectAs="[toolbar-right]">
    </ng-content>
</pf-table-toolbar>
<ng-container *ngIf="hasAdvancedFilters">
    <pf-advanced-filters
        [class]="this.filterService.filtersDisplayed ? 'open' : ''"
        [columns]="columns"
        [tableKey]="tableKey"></pf-advanced-filters>
</ng-container>
<nz-table
    #table
    [nzLoading]="loading"
    [nzData]="tableService.tableState.rows"
    [nzFrontPagination]="!serverRendered"
    [nzPageIndex]="tableService.tableState.pageIndex"
    [nzPageSize]="pageSize"
    [nzTotal]="tableService.tableState.total"
    [nzShowPagination]="showPagination"
    [nzFooter]="tableFooterTemplate"
    [nzShowTotal]="showTotalTemplate"
    [nzItemRender]="renderItemTemplate"
    [nzShowSizeChanger]="pageSizeOptions.length > 1"
    [nzPageSizeOptions]="pageSizeOptions"
    nzTableLayout="fixed"
    [nzScroll]="scrollSettings"
    [nzSize]="size"
    (nzQueryParams)="onQueryParamsChange($event)"
    (nzPageIndexChange)="pageIndexChanged($event)"
    (nzPageSizeChange)="pageSizeChanged($event)">
    <ng-template #renderItemTemplate let-type let-page="page">
        <ng-container [ngSwitch]="type">
            <a *ngSwitchCase="'page'">{{ page }}</a>
            <a *ngSwitchCase="'prev'">Previous</a>
            <a *ngSwitchCase="'next'">Next</a>
            <a *ngSwitchCase="'prev_5'"><<</a>
            <a *ngSwitchCase="'next_5'">>></a>
        </ng-container>
    </ng-template>
    <ng-template #showTotalTemplate let-range="range">
        <span
        >{{ range[0] }}-{{ range[1] }} of
            {{ tableService.tableState.total }} items</span
        >
        |
    </ng-template>
    <ng-template #tableFooterTemplate>
        <pf-button
            *ngIf="serverRendered && showExport"
            buttonType="export"
            [loading]="exporting"
            (click)="exportHandler()"
        >Export
        </pf-button>
        <ng-content select="[footer-left]"></ng-content>
    </ng-template>
    <thead>
    <tr>
        <th nzWidth="40px" *ngIf="expandable"></th>
        <th
            *ngIf="showSelection"
            nzWidth="80px"
            [(nzChecked)]="tableService.checked"
            [nzIndeterminate]="tableService.indeterminate"
            (nzCheckedChange)="onAllRowsChecked($event)"></th>
        <th
            *ngFor="
                    let column of tableService.tableState.columns;
                    trackBy: headerTrackBy
                "
            [nzColumnKey]="column.field"
            [(nzSortOrder)]="column.sortOrder"
            [nzSortDirections]="column.sortDirections"
            [nzSortFn]="column.sortFn"
            [nzWidth]="column.width"
            nzCustomFilter>
            <pf-table-header
                [column]="column"
                (checkChanged)="
                        onTableHeaderChecked(column, $event)
                    "></pf-table-header>
            <pf-table-header-search
                *ngIf="
                        column.search ||
                        column.serverSearch ||
                        column.listFilterFacade
                    "
                nz-th-extra
                [column]="column"
                (searchChanged)="
                        onColumnSearchChanged(column)
                    "></pf-table-header-search>
        </th>
        <th
            *ngIf="!!actions && actions.length"
            style="text-align: center; font-size: smaller"
            nzRight
            [nzWidth]="
                    (actions[0].width ||
                        ((actions[0].name || '').length || 4) * 10 + 30) + 'px'
                ">
            Actions
        </th>
    </tr>
    </thead>
    <tbody>
    <pf-table-rows
        [expandToContent]="expandToContent"
        [columns]="tableService.tableState.columns"
        [actions]="actions"
        [expandRowRef]="expandRowRef"
        [options]="options"
        [expandable]="expandable"
        [expand]="isFiltered"
        [singleExpand]="singleExpand"
        [data]="table.data"
        [showSelection]="!!showSelection"></pf-table-rows>
    </tbody>
</nz-table>
