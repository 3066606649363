<platform-associated-entities-form
    title="Contacts"
    [loading]="loading"
    [formControl]="control"
    [facade]="$any(contactFacade)"
    [columns]="columns"
    [actions]="actions"
    [fragmentFn]="contactFragmentFn"
    [useCustomToolbarContent]="true"
    [baseEntityType]="baseEntityType"
    [baseEntityPlatform]="baseEntityPlatform"
    [postDeleteFn]="postDeleteFn">
    <pf-button
        buttonType="view"
        data-pf-handle="search-contacts"
        platformRequiredPermissions
        [permissionPlatform]="baseEntityPlatform"
        [permissionEntity]="baseEntityType"
        [permissionType]="permissionType"
        [hideElementIfNoPermission]="true"
        (click)="openSearchContactModal()"
        >Add Existing Contact
    </pf-button>
    <pf-button
        buttonType="add"
        data-pf-handle="add-contact"
        platformRequiredPermissions
        [permissionPlatform]="permissionPlatform"
        [permissionEntity]="permissionEntity"
        [permissionType]="permissionType"
        [hideElementIfNoPermission]="true"
        (click)="addContact()"
        >Add New Contact
    </pf-button>
</platform-associated-entities-form>
