import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFLoadEntities,
    SafeAny,
} from '@pf/shared-common';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';
import {
    LaneCreate,
    LaneDto,
    LaneRead,
    LaneSearchParams,
} from '../../entities/lane.dto';
import {
    CustomFieldMetadataMap,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';
import { LocationMetadataMap } from '@pf/platform-core/domain';
import { LaneRateMetadataMap } from '../lane-rates/lane-rate.mapping-profile';
import { ItemMetadataMap } from '@pf/platform-item-catalog/domain';
import { LaneLookupLookupRequestParams } from '@control-tower/platform-loads';
import { LaneCarrierMetadataMap } from '../lane-carriers/lane-carrier.mapping-profile';

const symbols = entitySymbols(PFLoadEntities.Lane);

withDefaultEntityMetadata<LaneDto>(symbols.dto, {
    laneGroupId: String,
    name: String,
    code: String,
    description: String,
    originLocation: LocationMetadataMap.symbols.dto,
    destinationLocation: LocationMetadataMap.symbols.dto,
    laneCarriers: [LaneCarrierMetadataMap.symbols.dto],
    rates: [LaneRateMetadataMap.symbols.dto],
    customFields: CustomFieldMetadataMap.symbols.dto,
});

withDefaultEntityMetadata<LaneRead>(symbols.entity, {
    laneGroupId: String,
    originLocationId: String,
    destinationLocationId: String,
    originRegionId: String,
    destinationRegionId: String,
    laneGroup: [Object],
    name: String,
    code: String,
    description: String,
    originLocation: LocationMetadataMap.symbols.entity,
    destinationLocation: LocationMetadataMap.symbols.entity,
    originSearchString: String,
    originAddress1: String,
    originAddress2: String,
    originCity: String,
    originGeoZone: String,
    originPostalCode: String,
    originCountry: String,
    destinationSearchString: String,
    destinationAddress1: String,
    destinationAddress2: String,
    destinationCity: String,
    destinationGeoZone: String,
    destinationPostalCode: String,
    destinationCountry: String,
    originRegion: Object,
    destinationRegion: Object,
    itemId: String,
    item: Object,
    rates: [LaneRateMetadataMap.symbols.entity],
    customFields: CustomFieldMetadataMap.symbols.entity,
    laneCarriers: [LaneCarrierMetadataMap.symbols.entity],
    resourceAuth: ResourceAuthSymbol,
});

withMetadata<LaneCreate>(symbols.createBody, {
    name: String,
    code: String,
    description: String,
    originSearchString: String,
    originAddress1: String,
    originAddress2: String,
    originCity: String,
    originGeoZone: String,
    originPostalCode: String,
    originCountry: String,
    destinationSearchString: String,
    destinationAddress1: String,
    destinationAddress2: String,
    destinationCity: String,
    destinationGeoZone: String,
    destinationPostalCode: String,
    destinationCountry: String,
    laneGroupId: String,
    originLocationId: String,
    originRegionId: String,
    destinationLocationId: String,
    destinationRegionId: String,
    itemId: String,
    resourceAuth: ResourceAuthSymbol,
});

// TODO add metadata
withDefaultSearchParamsMetadata<LaneSearchParams | SafeAny>(
    symbols.searchParams,
    {
        includeLocations: Boolean,
        includeItemCharges: Boolean,
        includeLaneCarriers: Boolean,
        originLocationId: String,
        laneOriginLocationIds: [String],
        destinationLocationId: String,
        laneDestinationLocationIds: [String],
    }
);

export const LaneMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<LaneRead, LaneDto>(mapper, symbols.entity, symbols.dto);
        createMap<LaneDto, LaneRead>(mapper, symbols.dto, symbols.entity);
        createMap<LaneDto, LaneCreate>(mapper, symbols.dto, symbols.createBody);
        createMap<IQueryParams<LaneDto>, LaneSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter(
                'originLocation',
                'originLocationId' as keyof LaneSearchParams
            ),
            withSearchFilter(
                'destinationLocation',
                'destinationLocationId' as keyof LaneSearchParams
            ),
            forMember(
                d => (d as LaneLookupLookupRequestParams).laneOriginLocationIds,
                mapFrom(s =>
                    typeof s.filters?.originLocation === 'string'
                        ? [s.filters.originLocation]
                        : s.filters?.originLocation
                )
            ),
            forMember(
                d =>
                    (d as LaneLookupLookupRequestParams)
                        .laneDestinationLocationIds,
                mapFrom(s =>
                    typeof s.filters?.destinationLocation === 'string'
                        ? [s.filters.destinationLocation]
                        : s.filters?.destinationLocation
                )
            ),
            ...withDefaultSearchParamsMappingConfiguration()
        );

        LocationMetadataMap.profile(mapper);
        ItemMetadataMap.profile(mapper);
        LaneRateMetadataMap.profile(mapper);
        LaneCarrierMetadataMap.profile(mapper);
    },
};
