import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    IPagedResult,
    PFCoreEntities,
} from '@pf/shared-common';
import {
    UserRoleCreate,
    UserRoleRead,
    UserRoleSearchParams,
} from '../../entities/user-role.dto';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Operation } from 'fast-json-patch';
import { UserRoleStore } from './user-role.store';
import { UserRolesService } from '@control-tower/platform-core';

@Injectable()
export class UserRoleDataService extends AbstractDataService<
    UserRoleRead,
    UserRoleCreate
> {
    protected EntityName = PFCoreEntities.UserRole;

    constructor(
        private service: UserRolesService,
        store: UserRoleStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: UserRoleCreate): Observable<UserRoleRead> {
        return this.service.userRoleCreate({
            userRoleViewModelBase: body,
        }) as Observable<UserRoleRead>;
    }

    protected deleteObs$(id: string): Observable<UserRoleRead> {
        return this.service.userRoleDelete({
            id,
        }) as Observable<UserRoleRead>;
    }

    protected undoDeleteObs$(id: string): Observable<UserRoleRead> {
        return this.service.userRoleUndoDelete({
            id,
        }) as Observable<UserRoleRead>;
    }

    protected getObs$(id: string): Observable<UserRoleRead> {
        return this.service.userRoleReadById({
            id,
        }) as Observable<UserRoleRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<UserRoleRead> {
        return this.service.userRoleUpdatePatch({
            id,
            operation: operations,
        }) as Observable<UserRoleRead>;
    }

    protected searchObs$(
        searchParams: UserRoleSearchParams
    ): Observable<IPagedResult<UserRoleRead>> {
        return this.service.userRoleSearch(searchParams) as Observable<
            IPagedResult<UserRoleRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<UserRoleRead>>> {
        return this.service.userRoleGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingDto<UserRoleRead>>
        >;
    }
}
