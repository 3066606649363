import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    ItemCategoriesService,
    ItemCustomFieldsService,
    ItemsService,
} from '@control-tower/platform-item-catalog';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    ICustomField,
    IPagedResult,
    PFItemCatalogEntities,
} from '@pf/shared-common';
import { ItemStore } from './item.store';
import {
    CategoryViewModelDto,
    ItemCreate,
    ItemRead,
    ItemSearchParams,
} from '../../entities/item.dto';
import { Operation } from 'fast-json-patch';

@Injectable()
export class ItemDataService extends AbstractDataService<ItemRead, ItemCreate> {
    protected EntityName = PFItemCatalogEntities.Item;

    constructor(
        private service: ItemsService,
        private itemCategoryService: ItemCategoriesService,
        private customFieldsService: ItemCustomFieldsService,
        store: ItemStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: ItemCreate): Observable<ItemRead> {
        return this.service.itemEntityCreate({
            itemViewModelBody: body,
        }) as Observable<ItemRead>;
    }

    protected deleteObs$(id: string): Observable<ItemRead> {
        return this.service.itemEntityDelete({
            id,
        }) as Observable<ItemRead>;
    }

    protected undoDeleteObs$(id: string): Observable<ItemRead> {
        return this.service.itemEntityUndoDelete({
            id,
        }) as Observable<ItemRead>;
    }

    protected getObs$(id: string): Observable<ItemRead> {
        return this.service.itemEntityReadById({
            id,
        }) as Observable<ItemRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<ItemRead> {
        return this.service.itemEntityUpdatePatch({
            id,
            operation: operations,
        }) as Observable<ItemRead>;
    }

    protected searchObs$(
        searchParams: ItemSearchParams
    ): Observable<IPagedResult<ItemRead>> {
        return this.service.itemEntitySearch(searchParams) as Observable<
            IPagedResult<ItemRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<ItemRead>>> {
        return this.service.itemEntityGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingDto<ItemRead>>
        >;
    }

    addItemCategories$(
        itemId: string,
        categoriesToAdd: CategoryViewModelDto[]
    ) {
        return categoriesToAdd.map(category => {
            return this.itemCategoryService.itemCategoryCreate({
                itemId,
                categoryId: category.id,
            });
        });
    }

    deleteItemCategories$(
        itemId: string,
        categoriesToDelete: CategoryViewModelDto[]
    ) {
        return categoriesToDelete.map(category => {
            return this.itemCategoryService.itemCategoryDelete({
                itemId,
                categoryId: category.id,
            });
        });
    }

    protected override createCustomFieldObs$(
        entityId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.itemEntityCreateCustomField({
            entityId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override updateCustomFieldObs$(
        entityId: string,
        customFieldId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.itemEntityCustomFieldsUpdatePut({
            entityId,
            id: customFieldId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override deleteCustomFieldObs$(
        entityId: string,
        customFieldId: string
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.itemEntityDeleteCustomField({
            entityId,
            id: customFieldId,
        }) as Observable<ICustomField & { id: string }>;
    }
}
