import { IEntity } from '@pf/shared-common';
import {
    OneTimeLinkSearchRequestParams,
    OneTimeLinkViewModelCreateBody,
    OneTimeLinkViewModelRead,
    ResourceAuth,
} from '@control-tower/platform-communications';
import { asArraySafe } from '@pf/shared-utility';

export type OneTimeLinkRead = Omit<OneTimeLinkViewModelRead, 'accessLogs'> &
    IEntity;
export type OneTimeLinkCreate = OneTimeLinkViewModelCreateBody;
export type OneTimeLinkSearchParams = OneTimeLinkSearchRequestParams;

export class OneTimeLinkDto implements IEntity {
    constructor(data: Partial<OneTimeLinkDto>) {
        Object.assign(this, data);
        this.recipientRoles = asArraySafe(data.recipientRoles);
        this.isActive =
            !this.isDeleted &&
            (this.expirationDate
                ? new Date(this.expirationDate) > new Date()
                : true);
    }

    id!: string;
    isDeleted?: boolean;
    expirationDate?: string | undefined;
    recipientAddress!: string;
    subject!: string;
    recipientRoles!: string[];
    templateUrl!: string;
    templateData!: object;
    sendEmailImmediately?: boolean | undefined;
    associatedEntityId?: string | null | undefined;
    userId?: string | undefined;
    recipientResourceAuth?: ResourceAuth;
    createdDate?: string;
    isActive?: boolean;
    recipientUserGroupType!: string;
}

export class OneTimeTokenDto {
    constructor(data: Partial<OneTimeTokenDto>) {
        this.token = data.token ?? '';
        this.expiresAt = data.expiresAt ?? '';
    }
    token: string;
    expiresAt: string;
}
