import {
    AssociatedAddressDto,
    IAssociatedAddressDto,
    IContact,
    ICustomFieldDto,
} from '@pf/shared/util-platform';
import { IEntity, IOmniFieldSearchParams } from '@pf/shared-common';
import {
    SearchingVendorEnum,
    VendorsSearchRequestParams,
    VendorViewModelCreateBody,
    VendorViewModelRead,
} from '@control-tower/platform-core';

import { asArraySafe, mapToClassSafe } from '@pf/shared-utility';
import { ResourceAuth } from '@control-tower/platform-loads';

export type VendorRead = VendorViewModelRead & IEntity;
export type VendorCreate = VendorViewModelCreateBody;
export type VendorSearchParams = VendorsSearchRequestParams &
    IOmniFieldSearchParams;
export type VendorOmniSearchFieldTypes = SearchingVendorEnum;

export interface IVendorDto extends IEntity {
    vendorName: string;
    vendorNumber?: string;
    associatedAddresses?: IAssociatedAddressDto[];
    contacts?: IContact[];
    shouldReceiveNotifications: boolean;
}

export class VendorDto implements IVendorDto {
    constructor(data: Partial<IVendorDto>) {
        Object.assign(this, data);
        this.contacts = asArraySafe(data.contacts);
        this.associatedAddresses = mapToClassSafe(
            data.associatedAddresses,
            AssociatedAddressDto
        );
        this.shouldReceiveNotifications =
            data?.shouldReceiveNotifications !== false;
    }

    id!: string;
    vendorName!: string;
    vendorNumber?: string;
    contacts?: IContact[];
    associatedAddresses?: AssociatedAddressDto[];
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
    resourceAuth?: ResourceAuth;
    customFields?: ICustomFieldDto[];
    shouldReceiveNotifications: boolean;
}
