import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
    QueryList,
    TemplateRef,
    ViewChildren,
} from '@angular/core';
import {
    BehaviorSubject,
    combineLatest,
    map,
    Observable,
    startWith,
} from 'rxjs';
import { ICustomFieldDto } from '@pf/shared/util-platform';
import { FormControl } from '@angular/forms';
import { asArraySafe } from '@pf/shared-utility';
import { BaseControlComponent, NotificationsService } from '@pf/shared-ui';
import { v4 as uuidV4 } from 'uuid';
import {
    NotificationType,
    SelectOption,
    ToastMessage,
    ToastType,
} from '@pf/shared-common';

type CustomFieldListItem = ICustomFieldDto & {
    formControl: FormControl<string | null>;
    formControl2: FormControl<string | null>;
    listId: string;
};

@Component({
    selector: 'platform-custom-field-list',
    templateUrl: './custom-field-list.component.html',
    styleUrls: ['./custom-field-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldListComponent {
    private notificationsService = inject(NotificationsService);

    private readonly customFieldsSubject = new BehaviorSubject<
        CustomFieldListItem[]
    >([]);
    searchControl = new FormControl<string>('');

    @ViewChildren('inputItem')
    inputComponents!: QueryList<BaseControlComponent>;
    @Input({ required: true }) customFieldKey!: string;
    @Input() listLabel = '';
    @Input() listHeaders?: string[] = [];
    @Input() listItemLimit?: number;
    @Input() multiTextAutoCompleteOptions?: string[] = [];
    @Input() inputType: 'text' | 'select' | 'multi-text' = 'text';
    @Input() options: SelectOption[] = [];
    @Input() grouped = false;
    @Input() showSearch = true;
    @Input() searchPlaceholder = 'Search';
    @Input() tooltip?: string | TemplateRef<void>;

    @Input() set customFields(customFields: ICustomFieldDto[] | undefined) {
        const value = asArraySafe(customFields);
        if (value.length === 0) {
            this.customFieldsSubject.next([
                {
                    key: this.customFieldKey,
                    value: '',
                    listId: uuidV4(),
                    formControl: new FormControl(''),
                    formControl2: new FormControl(''),
                },
            ]);
            return;
        }

        this.customFieldsSubject.next(
            value.map((customField: ICustomFieldDto) => {
                const delimiter = `|`;
                const customFieldValues = customField.value?.split(delimiter);
                return {
                    ...customField,
                    listId: uuidV4(),
                    formControl: new FormControl(
                        customFieldValues?.[0] || null
                    ),
                    formControl2: new FormControl(
                        customFieldValues?.[1] || null
                    ),
                };
            })
        );
    }

    get customFields(): ICustomFieldDto[] | undefined {
        return this.customFieldsSubject.value
            .map(customField => {
                const value =
                    this.inputType === 'multi-text'
                        ? `${customField.formControl.value || ''}|${
                              customField.formControl2.value || ''
                          }`
                        : customField.formControl.value;

                return {
                    ...customField,
                    value: value,
                };
            })
            .filter(customField => !!customField.value);
    }

    @Output() itemsChanged = new EventEmitter<ICustomFieldDto[]>();

    listControls$: Observable<CustomFieldListItem[]> = combineLatest([
        this.searchControl.valueChanges.pipe(startWith('')),
        this.customFieldsSubject.asObservable(),
    ]).pipe(
        map(([search, customFields]) => {
            const filteredByKey = customFields.filter(
                customField => customField.key === this.customFieldKey
            );
            const searchValue = search?.toLowerCase()?.trim();
            return searchValue
                ? filteredByKey.filter(customField =>
                      customField.value?.toLowerCase()?.includes(searchValue)
                  )
                : filteredByKey;
        })
    );

    constructor(private cdr: ChangeDetectorRef) {}

    addNewItem(): void {
        this.customFieldsSubject.next([
            ...this.customFieldsSubject.value,
            {
                key: this.customFieldKey,
                value: '',
                listId: uuidV4(),
                formControl: new FormControl(''),
                formControl2: new FormControl(''),
            },
        ]);
        setTimeout(() => {
            if (this.inputType === 'multi-text') {
                this.inputComponents
                    ?.get(this.inputComponents.length - 2)
                    ?.focus();
            } else {
                this.inputComponents.last.focus();
            }
        });
    }

    removeItem(listId: string): void {
        const customFields = this.customFieldsSubject.value.filter(
            item => item.listId !== listId
        );
        this.customFieldsSubject.next(customFields);

        if (customFields.length === 0) {
            this.addNewItem();
        }

        this.onInput();
        this.cdr.markForCheck();
    }

    clearAllItems(): void {
        this.customFieldsSubject.next([]);
        this.addNewItem();
        this.onInput();
    }

    focusNextOrAddNewItem(index: number): void {
        if (index === this.inputComponents.length - 1) {
            this.addNewItem();
        } else {
            this.inputComponents.toArray()[index + 1].focus();
        }
    }

    itemSelected(index: number): void {
        const selectedItem = this.customFieldsSubject.value[index];
        const isDuplicate = this.customFieldsSubject.value.some(
            (item, idx) =>
                item.formControl.value === selectedItem.formControl.value &&
                idx !== index
        );

        if (isDuplicate) {
            this.removeItem(selectedItem.listId);
            this.addNewItem();
            this.notificationsService.create({
                type: NotificationType.ToastMessage,
                toastType: ToastType.warning,
                message: `Duplicate item selected: ${selectedItem.formControl.value}`,
                duration: 2000,
            } as ToastMessage);
        } else {
            this.onInput();
            this.focusNextOrAddNewItem(index);
        }
    }

    onInput() {
        this.itemsChanged.emit(this.customFields);
    }

    checkItemLimit(lastItem: boolean) {
        return (
            lastItem &&
            (!this.listItemLimit ||
                this.inputComponents === undefined ||
                this.inputComponents.length <
                    (this.inputType === 'multi-text'
                        ? this.listItemLimit * 2
                        : this.listItemLimit))
        );
    }
}
