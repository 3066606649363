import * as saveAs from 'file-saver';

import {
    BehaviorSubject,
    catchError,
    forkJoin,
    map,
    Observable,
    of,
    switchMap,
    tap,
} from 'rxjs';
import {
    ChangeTrackingValueFormatter,
    EntityChangeTrackingDto,
    ExportConfig,
    getTableRowValue,
    IChangesSearchParams,
    IChangeTrackingFacade,
    IEntity,
    IEntityChangeTrackingService,
    IEntityDataService,
    IPagedResult,
    IQueryParams,
    SafeAny,
    SelectOption,
    TableColumn,
} from '@pf/shared-common';
import { sortOrderConverter } from '@pf/shared-utility';

import { LoggerService, ValueFormatterService } from '@pf/shared-services';
import { getValueByPointer } from 'fast-json-patch';
import { inject } from '@angular/core';
import { normalizeChanges } from '../utility/change-tracking.util';
import { unparse } from 'papaparse';

export type ChangeTrackingFacadeFactory<TEntity extends IEntity> = (
    entityId: string
) => IChangeTrackingFacade<TEntity>;

export function BuildChangeTrackingFacadeFactory<TEntity extends IEntity>(
    dataService: IEntityDataService<TEntity, unknown>,
    nameField: IChangeTrackingFacade<TEntity>['nameField'],
    customValueFormatter?: ChangeTrackingValueFormatter
): ChangeTrackingFacadeFactory<TEntity> {
    const vfs = inject(ValueFormatterService);
    const logger = inject(LoggerService);
    return (entityId: string) =>
        new ChangeTrackingFacade(
            dataService,
            entityId,
            vfs,
            logger,
            nameField,
            customValueFormatter
        );
}

export class ChangeTrackingFacade<TEntity extends IEntity>
    implements IChangeTrackingFacade<TEntity>
{
    private readonly _currentPageSubject = new BehaviorSubject<
        IPagedResult<EntityChangeTrackingDto<TEntity>>
    >({} as IPagedResult<EntityChangeTrackingDto<TEntity>>);
    private lastQueryParams?: IQueryParams<EntityChangeTrackingDto<TEntity>>;

    constructor(
        private dataService: IEntityChangeTrackingService<TEntity>,
        private entityId: string,
        private valueFormatterService: ValueFormatterService,
        private logger: LoggerService,
        nameField: keyof TEntity,
        customValueFormatter?: ChangeTrackingValueFormatter
    ) {
        this.nameField = nameField;
        this.customValueFormatter = customValueFormatter;
    }

    customValueFormatter?: ChangeTrackingValueFormatter;

    searchAll$(): Observable<EntityChangeTrackingDto<TEntity>[]> {
        throw new Error('Method not implemented.');
    }

    nameField!: keyof TEntity;

    currentPage$ = this._currentPageSubject.asObservable();

    getEntityName(changeTrackingVm: EntityChangeTrackingDto<TEntity>): string {
        return changeTrackingVm?.entity
            ? (changeTrackingVm.entity[this.nameField] as unknown as string)
            : '';
    }

    getRecentChanges$(): Observable<EntityChangeTrackingDto<TEntity>[]> {
        return this.dataService
            .searchChanges$({
                entityId: this.entityId,
                pageNumber: 1,
                pageSize: 3,
                orderBy: ['createdDate:desc'],
            })
            .pipe(
                map(
                    pageResult =>
                        pageResult.data as EntityChangeTrackingDto<TEntity>[]
                )
            );
    }

    reload$() {
        if (this.lastQueryParams) {
            return this.search$(this.lastQueryParams).pipe(map(() => true));
        }
        return of(false);
    }

    search$(
        params: IQueryParams<EntityChangeTrackingDto<TEntity>>
    ): Observable<IPagedResult<EntityChangeTrackingDto<TEntity>>> {
        this.lastQueryParams = params;
        return this.searchChanges$(params).pipe(
            tap(result => this._currentPageSubject.next(result))
        );
    }

    oneTimeSearch$(
        params: IQueryParams<EntityChangeTrackingDto<TEntity>>
    ): Observable<IPagedResult<EntityChangeTrackingDto<TEntity>>> {
        return this.searchChanges$(params);
    }

    searchByText$(
        text: string
    ): Observable<IPagedResult<EntityChangeTrackingDto<TEntity>>> {
        return this.search$({
            pageIndex: 1,
            pageSize: 5,
            sortOrder: 'descend',
            sortField: 'createdDate',
            filters: {
                entity: text,
            },
        });
    }

    getMany$(): Observable<EntityChangeTrackingDto<TEntity>[]> {
        throw new Error('Method not implemented.');
    }

    getAll$(): Observable<EntityChangeTrackingDto<TEntity>[]> {
        throw new Error('Method not implemented.');
    }

    export$(
        config: ExportConfig<EntityChangeTrackingDto<TEntity>>
    ): Observable<boolean> {
        const searchParams = {
            entityId: this.entityId,
        };
        this.logger.debug(`Exporting change tracking`);
        const exportColumns: TableColumn<EntityChangeExportVm>[] = [
            {
                headerName: 'Field',
                field: 'field',
            },
            {
                headerName: 'Previous Value',
                field: 'previousValue',
            },
            {
                headerName: 'New Value',
                field: 'newValue',
            },
            {
                headerName: 'User',
                field: 'userEmail',
            },
            {
                headerName: 'Time Changed',
                field: 'createdDate',
            },
        ];
        return this.fetchAllChanges$(searchParams).pipe(
            map(result => {
                this.logger.debug('Mapping result to export data array');
                return result.map(row => {
                    return exportColumns.map(column => {
                        return getTableRowValue(column, row);
                    });
                });
            }),
            map(data => {
                this.logger.debug('Parse data to csv string');
                const fields = exportColumns.map(c => c.headerName);
                return unparse({
                    fields,
                    data,
                });
            }),
            map(csvString => {
                this.logger.debug('Save csv string. ' + csvString);
                saveAs(
                    new Blob([csvString], { type: 'text/csv' }),
                    `${this.valueFormatterService.format(
                        'fileTimestamp',
                        new Date()
                    )}_${config.fileName}`,
                    { autoBom: false }
                );
                return true;
            }),
            catchError(err => {
                this.logger.error('Failed to export. ' + JSON.stringify(err));
                return of(false);
            })
        );
    }

    private searchChanges$(
        params: IQueryParams<EntityChangeTrackingDto<TEntity>>
    ): Observable<IPagedResult<EntityChangeTrackingDto<TEntity>>> {
        return this.dataService.searchChanges$({
            entityId: this.entityId,
            pageNumber: params.pageIndex,
            pageSize: params.pageSize,
            search: params.filters?.entity as string,
            orderBy: sortOrderConverter.convert(params) || undefined,
        });
    }

    private fetchAllChanges$(
        searchParams: IChangesSearchParams
    ): Observable<EntityChangeExportVm[]> {
        const pageSize = 100;
        searchParams.pageNumber = 1;
        searchParams.pageSize = pageSize;
        return this.dataService.searchChanges$(searchParams).pipe(
            switchMap(result => {
                const pages = Math.ceil(result.totalRecords / pageSize);

                if (pages === 1) {
                    return of([result]);
                }

                const requests = new Array(pages - 1).fill(0).map((_, i) => {
                    const nextQueryParams = {
                        ...searchParams,
                        pageNumber: i + 2,
                    };
                    return this.dataService.searchChanges$(nextQueryParams);
                });
                return forkJoin([of(result), ...requests]);
            }),
            // Reduce array of results into one array
            map(results => {
                return results.reduce((acc, result) => {
                    acc.push(
                        ...(result.data as EntityChangeTrackingDto<TEntity>[])
                    );
                    return acc;
                }, [] as EntityChangeTrackingDto<TEntity>[]);
            }),
            // Reduce change vm into EntityChangeExportVm array
            map(results => {
                return results.reduce((pv, cv) => {
                    return pv.concat(
                        normalizeChanges(cv.changes).map(change => {
                            const previousValue =
                                (change.path &&
                                    getValueByPointer(
                                        cv.entity,
                                        change.path
                                    )) ||
                                'None';
                            return {
                                type: change.op,
                                field: change.path
                                    .substring(1)
                                    .replace(/\//g, '.'),
                                previousValue: JSON.stringify(previousValue),
                                newValue: JSON.stringify(
                                    change.value || 'None'
                                ),
                                userEmail: cv.userEmail || '',
                                createdDate: this.valueFormatterService.format(
                                    'datetime',
                                    cv.createdDate
                                ),
                            };
                        })
                    );
                }, [] as EntityChangeExportVm[]);
            })
        );
    }

    getById(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _: string
    ): EntityChangeTrackingDto<TEntity> {
        throw new Error('Method not implemented.');
    }

    getById$(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _: string
    ): Observable<EntityChangeTrackingDto<TEntity>> {
        throw new Error('Method not implemented.');
    }

    mapToSelectOptions(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _: Observable<IPagedResult<EntityChangeTrackingDto<TEntity>>>
    ): Observable<SelectOption[]> {
        throw new Error('Method not implemented.');
    }
}

interface EntityChangeExportVm {
    type: string;
    field: string;
    previousValue: SafeAny;
    newValue: SafeAny;
    userEmail: string;
    createdDate: string;
}
