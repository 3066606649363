import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OneTimeLinkService {
    private _token: string | null = null;
    private _oneTimeLinkId: string | null = null;

    private tokenLocalStorageKey = 'token';
    private oneTimeLinkIdLocalStorageKey = 'oneTimeLinkId';

    private _isAuthenticated = new BehaviorSubject<boolean>(
        this.token !== null
    );

    get isAuthenticated$(): Observable<boolean> {
        return this._isAuthenticated
            .asObservable()
            .pipe(distinctUntilChanged());
    }

    get isAuthenticated(): boolean {
        return this._isAuthenticated.value;
    }

    set token(token: string | null) {
        if (token === null) {
            localStorage.removeItem(this.tokenLocalStorageKey);
        } else {
            localStorage.setItem(this.tokenLocalStorageKey, token);
        }
        this._token = token;
        this._isAuthenticated.next(this.token !== null);
    }

    get token(): string | null {
        if (!localStorage) {
            return this._token;
        }

        const storedToken = localStorage.getItem(this.tokenLocalStorageKey);
        if (storedToken) {
            this._token = storedToken;
        }

        return this._token;
    }

    set oneTimeLinkId(oneTimeLinkId: string | null) {
        if (oneTimeLinkId === null) {
            localStorage.removeItem(this.oneTimeLinkIdLocalStorageKey);
        } else {
            localStorage.setItem(
                this.oneTimeLinkIdLocalStorageKey,
                oneTimeLinkId
            );
        }
        this._oneTimeLinkId = oneTimeLinkId;
    }

    get oneTimeLinkId(): string | null {
        if (!localStorage) {
            return this._oneTimeLinkId;
        }

        const storedOneTimeLinkId = localStorage.getItem(
            this.oneTimeLinkIdLocalStorageKey
        );
        if (storedOneTimeLinkId) {
            this._oneTimeLinkId = storedOneTimeLinkId;
        }

        return this._oneTimeLinkId;
    }

    clear() {
        this.oneTimeLinkId = null;
        this.token = null;
    }
}
