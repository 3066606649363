import { Pipe, PipeTransform } from '@angular/core';
import { SafeAny } from '@pf/shared-common';

@Pipe({
    name: 'safeNumber',
})
export class SafeNumberPipe implements PipeTransform {
    transform(
        value: number | string | null | undefined,
        defaultType: 'infinity' | 'negativeInfinity' | 'zero' | 'none' = 'zero'
    ): number {
        if (value === null || value === undefined) {
            switch (defaultType) {
                case 'infinity':
                    return Infinity;
                case 'negativeInfinity':
                    return -Infinity;
                case 'zero':
                    return 0;
                case 'none':
                    return undefined as SafeAny as number;
            }
        }
        if (typeof value === 'string') {
            return +value;
        }
        return value;
    }
}
