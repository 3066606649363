import {
    LoadScheduleSummaryCreate,
    LoadScheduleSummaryDto,
    LoadScheduleSummaryRead,
    LoadScheduleSummarySearchParams,
} from '../entities/load-schedule-summary.dto';
import { Injectable } from '@angular/core';
import { AbstractManageEntityFacade } from '@pf/shared-services';
import { LoadScheduleSummaryDataService } from '../infrastructure/load-schedules/load-schedule-summary.data.service';
import { LoadScheduleSummaryMapper } from '../infrastructure/load-schedules/load-schedule-summary.mapper';
import { LoadScheduleSummaryStore } from '../infrastructure/load-schedules/load-schedule-summary.store';
import { LoadScheduleFacade } from './load-schedule.facade';
import {
    bufferCount,
    combineLatest,
    concatMap,
    from,
    map,
    Observable,
    of,
    switchMap,
    tap,
} from 'rxjs';
import { asArraySafe } from '@pf/shared-utility';
import { RoutesForLoadSchedule } from './load-schedule.routes';
import { v4 as uuidV4 } from 'uuid';
import { IQueryParams } from '@pf/shared-common';

@Injectable()
export class LoadScheduleSummaryFacade extends AbstractManageEntityFacade<
    LoadScheduleSummaryDto,
    LoadScheduleSummaryRead,
    LoadScheduleSummaryCreate,
    LoadScheduleSummarySearchParams
> {
    nameField: keyof LoadScheduleSummaryDto = 'name';

    constructor(
        dataService: LoadScheduleSummaryDataService,
        routes: RoutesForLoadSchedule,
        store: LoadScheduleSummaryStore,
        mapper: LoadScheduleSummaryMapper,
        private loadScheduleFacade: LoadScheduleFacade
    ) {
        super(dataService, routes, store, mapper);
    }

    getByOriginLocationId$(id: string) {
        const searchParams = {
            filters: {
                locationSearch: [id],
            },
            pageSize: 1,
            pageIndex: 1,
            isDeleted: false,
        } as IQueryParams<LoadScheduleSummaryDto>;

        return this.oneTimeSearch$(searchParams).pipe(
            map(r => {
                return r.totalRecords && r.data.length ? r.data[0] : null;
            })
        );
    }

    override save$(
        dto: LoadScheduleSummaryDto
    ): Observable<LoadScheduleSummaryDto> {
        const summaryObservables = asArraySafe(dto.schedules).map(schedule => {
            return this.loadScheduleFacade.save$(schedule, {
                emitEvent: false,
            });
        });

        return summaryObservables.length
            ? from(summaryObservables).pipe(
                  bufferCount(10),
                  concatMap(batch => combineLatest(batch)),
                  map(schedules => {
                      return new LoadScheduleSummaryDto({
                          ...dto,
                          schedules,
                      });
                  }),
                  tap(summary => {
                      if (summary.id) {
                          const existing = this.store.get(summary.id);
                          existing.numberOfSchedules =
                              summary.schedules?.length || 0;
                          this.store.update(existing);
                          this.updated$.next({
                              entity: summary,
                          });
                      } else {
                          summary.id = uuidV4();
                          summary.numberOfSchedules =
                              summary.schedules?.length || 0;
                          this.store.add(this.mapper.toEntity(summary), true);
                          this.added$.next({
                              entity: summary,
                          });
                      }
                  })
              )
            : of(dto);
    }

    override delete$(
        entityId: string,
        actionText?: string,
        notify = true
    ): Observable<LoadScheduleSummaryDto> {
        const dto = this.getById(entityId);

        if (!dto) {
            throw new Error(`Could not find entity with id ${entityId}`);
        }

        return this.loadScheduleFacade.getByOriginLocationId$(entityId).pipe(
            map(schedules =>
                schedules.map(schedule =>
                    this.loadScheduleFacade.delete$(
                        schedule.id,
                        'delete',
                        false
                    )
                )
            ),
            switchMap(scheduleDeleteObservables => {
                return scheduleDeleteObservables.length
                    ? from(scheduleDeleteObservables).pipe(
                          bufferCount(10),
                          concatMap(batch => combineLatest(batch)),
                          map(schedules => {
                              return new LoadScheduleSummaryDto({
                                  ...dto,
                                  schedules,
                              });
                          })
                      )
                    : of(dto);
            }),
            tap(deleted => {
                this.store.delete(this.store.get(entityId));
                if (notify) {
                    this.deleted$.next({ entity: deleted, actionText });
                }
            })
        );
    }

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof LoadScheduleSummaryDto, string>> {
        return { origin: searchText };
    }
}
