@if (!loading) {
    @if (definition) {
        <sqd-designer
            theme="light"
            [definition]="definition"
            [toolboxConfiguration]="toolboxConfiguration"
            [stepsConfiguration]="stepsConfiguration"
            [validatorConfiguration]="validatorConfiguration"
            [controlBar]="true"
            [areEditorsHidden]="false"
            [rootEditor]="rootEditorProvider"
            [stepEditor]="stepEditorProvider"
            (onReady)="onDesignerReady($event)"
            (onDefinitionChanged)="onDefinitionChanged($event)">
        </sqd-designer>
    } @else {
        <p>No definition provided!</p>
    }
} @else {
    <div id="workflow-loader">
        <p>Loading workflow...</p>
    </div>
}
