<nz-drawer
    [nzTitle]="drawerTitle"
    [nzVisible]="openDrawer"
    nzHeight="80%"
    [nzClosable]="true"
    nzPlacement="bottom"
    (nzOnClose)="close()">
    <div *nzDrawerContent>
        <ng-container *ngIf="openDrawer">
            <nz-spin
                *ngIf="loading"
                style="height: 5rem; padding: var(--pf-section-gap)"
                nzTip="Fetching contacts for {{ entityName }}"></nz-spin>
            <platform-associated-contacts-form
                [control]="control"
                [columns]="columns"
                [baseEntityPlatform]="baseEntityPlatform"
                [baseEntityType]="baseEntityType"
                [postSaveFn]="postSaveContactFn"
                [postDeleteFn]="deleteContact">
            </platform-associated-contacts-form>
        </ng-container>
    </div>
</nz-drawer>
