import {
    VendorCreate,
    VendorDto,
    VendorRead,
    VendorSearchParams,
} from '../entities/vendor.dto';

import { AbstractManageEntityFacade } from '@pf/shared-services';
import {
    BuildChangeTrackingFacadeFactory,
    IContact,
    IEntityWithContactsFacade,
} from '@pf/shared/util-platform';
import { inject, Injectable } from '@angular/core';
import { RoutesForVendors } from './vendor.routes';
import { VendorDataService } from '../infrastructure/vendors/vendor.data.service';
import { VendorMapper } from '../infrastructure/vendors/vendor.mapper';
import { VendorStore } from '../infrastructure/vendors/vendor.store';
import { map, Observable, of, switchMap } from 'rxjs';
import { ResourceAuth } from '@control-tower/platform-loads';
import {
    ColumnSearchType,
    EntitySaveOptions,
    IColumnSearchFacade,
} from '@pf/shared-common';
import { VENDOR_CUSTOM_FIELDS } from '../platform-core-domain.module';

@Injectable()
export class VendorFacade
    extends AbstractManageEntityFacade<
        VendorDto,
        VendorRead,
        VendorCreate,
        VendorSearchParams
    >
    implements
        IEntityWithContactsFacade<VendorDto>,
        IColumnSearchFacade<VendorDto>
{
    nameField: keyof VendorDto = 'vendorName';
    customFieldsConfig$ = inject(VENDOR_CUSTOM_FIELDS, { optional: true });

    constructor(
        protected vendorDataService: VendorDataService,
        routes: RoutesForVendors,
        vendorStore: VendorStore,
        vendorMapper: VendorMapper
    ) {
        super(vendorDataService, routes, vendorStore, vendorMapper);
    }

    override applyResourceAuth(body: VendorCreate, dto: VendorDto) {
        body.resourceAuth = {
            vendors: dto.id ? [dto.id] : [],
        } as ResourceAuth;
    }

    override save$(
        dto: VendorDto,
        options?: EntitySaveOptions<VendorDto>
    ): Observable<VendorDto> {
        return super.save$(dto, options).pipe(
            switchMap(result => {
                if (!result.resourceAuth?.vendors?.find(x => x === result.id)) {
                    // save again to update resourceAuth with new location id
                    return super.save$(result, {
                        emitEvent: false,
                        saveCustomFields: false,
                    });
                }
                return of(result);
            })
        );
    }

    getContacts$(entity: VendorDto): Observable<IContact[]> {
        return this.vendorDataService.getContacts$(entity.id);
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        this.nameField
    );

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof VendorDto, string>> {
        return { vendorName: searchText };
    }

    get columnListFilterName(): string {
        return 'vendor';
    }

    getColumnListFilters$(): Observable<ColumnSearchType[]> {
        const queryParams = {
            pageNumber: 1,
            pageSize: 50,
        };

        return this.dataService.searchAll$(queryParams).pipe(
            map(results =>
                results.sort((a, b) => a.vendorName.localeCompare(b.vendorName))
            ),
            map(result =>
                result.map(vendor => ({
                    value: vendor.id,
                    label: vendor.vendorName,
                }))
            )
        );
    }
}
