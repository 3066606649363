import { LoadDateFilter, LoadDto } from '../../entities/load.dto';
import {
    CustomFieldConfig,
    IFormFacadeConfig,
    ManageFacadeConfig,
    ManagePageView,
    TypeMatch,
} from '@pf/shared/util-platform';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormSubmitAction } from '@pf/shared-ui';
import {
    ButtonType,
    IModalContent,
    SafeAny,
    TableSelection,
} from '@pf/shared-common';
import { DateRange, DateRangeOption } from '@pf/shared-utility';

export interface LoadNote {
    label: string;
    rows: number;
    note?: string;
}

export enum LoadStopView {
    Stops = 0,
    ItemsAndCharges = 1,
}

export interface LoadView extends ManagePageView {
    hideDetailedHistoryExport?: boolean;
    hideAddLoadButton?: boolean;
    manageMileage?: boolean;
    manageDrivers?: boolean;
    manageVehicles?: boolean;
    manageTrailers?: boolean;
    canAddStops?: boolean;
    canReorderStops?: boolean;
    canManageFinancials?: boolean;
    beforeStops?: ComponentWithInputs;
    defaultStopsView?: LoadStopView;
}

export interface ComponentWithInputs {
    component: SafeAny;
    inputs?: SafeAny;
}

export interface OneTimeLinkConfig {
    carrierRoleId: string;
    vendorRoleId: string;
}

export interface LoadBulkUpdateConfig {
    allowedStatuses: ReadonlyArray<string>;
    canDelete: boolean;
}

export interface BulkActionConfig {
    label: string;
    confirmationTitle: string;
    action: 'delete' | 'update';
    onConfirm: (selection: TableSelection) => void;
}

export interface AdditionalActionConfig {
    danger?: boolean;
    text: string;
    onClick: () => void;
}

export interface AdditionalButtonConfig extends AdditionalActionConfig {
    buttonType: ButtonType;
}

export interface LoadsManageState {
    loadStatusType: string;
    dateFilter: LoadDateFilter;
    fiscalPeriod?: string;
    historicDateRange: DateRange;
    dateRange: DateRangeOption;
}

export type LoadStat = {
    count$: Observable<number | undefined>;
    title: string;
    isAccounting?: boolean;
};

export type LoadTypeStat = LoadStat & {
    code: string;
};

export type LoadTypeStats = {
    stats: LoadTypeStat[];
};

@Injectable()
export abstract class LoadManageFacadeConfig
    extends ManageFacadeConfig<LoadDto, LoadView>
    implements IFormFacadeConfig<LoadDto>
{
    abstract pickupLocationTypeCode: string;
    abstract deliveryLocationTypeIds: string[];
    abstract pickupStopTypeCodes: string[];
    abstract deliveryStopTypeCodes: string[];
    abstract equipmentTypes$?: Observable<TypeMatch[]>;
    abstract notes$?: Observable<LoadNote[]>;
    abstract showMileageInput: boolean;
    abstract financialsCustomFieldsConfig$?: Observable<CustomFieldConfig[]>;
    abstract oneTimeLinkDefaultEmail?: string;

    abstract canCancelLoad?(load: LoadDto): boolean;

    abstract submitActions$?: Observable<Array<
        FormSubmitAction & { performAction: (load: LoadDto) => void }
    > | null>;

    abstract bulkUpdateConfig$?: Observable<LoadBulkUpdateConfig>;

    abstract customBulkActions$: Observable<BulkActionConfig[]>;

    abstract oneTimeLinkConfig$(): Observable<OneTimeLinkConfig>;

    abstract additionalButtons$: Observable<AdditionalButtonConfig[]>;

    abstract additionalEditActions$: Observable<AdditionalActionConfig[]>;

    abstract editTableConfirmation(loads: LoadDto[]): IModalContent;

    onFormChange?(currentValue: LoadDto): void;

    /**
     * Called when the form is submitted.
     * @returns An observable that emits a boolean value indicating whether the form should be submitted.
     * @param loadDto
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onFormSubmit$(loadDto: LoadDto): Observable<boolean> {
        return of(true);
    }

    readonly shouldDetectChanges$ = new EventEmitter<void>();
}
