import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import {
    format,
    formatDuration,
    formatISO,
    intervalToDuration,
} from 'date-fns';
import { IDateConfig, IDateService } from '@pf/shared-common';
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { parseDate } from '@pf/shared-utility';

export const DATE_CONFIG = new InjectionToken<IDateConfig>('config');

export const DEFAULT_DATE_CONFIG: IDateConfig = {
    dateFormat: 'MM/dd/yyyy',
    timeFormat: 'hh:mm aaa',
    fileTimestampFormat: 'MM_dd_yyyy_hh_mm_ss_aaa_z',
};

@Injectable({
    providedIn: 'root',
})
export class DateService implements IDateService {
    private dateConfig: IDateConfig;

    constructor(@Optional() @Inject(DATE_CONFIG) dateConfig?: IDateConfig) {
        this.dateConfig = dateConfig || DEFAULT_DATE_CONFIG;
    }

    getYear(date?: string | Date) {
        return !date ? 0 : new Date(date).getFullYear();
    }

    formatDate(
        date: string | Date | number | undefined | null,
        formatString?: string
    ) {
        try {
            return !date
                ? ''
                : format(
                      new Date(date),
                      formatString || this.dateConfig.dateFormat
                  );
        } catch (e) {
            return '';
        }
    }

    parseServerDateOnly(date: string | undefined | null): Date | null {
        try {
            if (!date) {
                return null;
            }
            const timeZoneIdentifierIndex = date.indexOf('T');
            if (timeZoneIdentifierIndex > -1) {
                date = date.substring(0, timeZoneIdentifierIndex);
            }

            return parseDate(date, 'yyyy-MM-dd');
        } catch (e) {
            return null;
        }
    }

    formatServerDateOnly(date: string | Date | number | undefined | null) {
        try {
            return !date ? '' : format(new Date(date), 'yyyy-MM-dd');
        } catch (e) {
            return '';
        }
    }

    formatDateToISO(
        date: string | Date | number | undefined,
        representation: 'date' | 'time' | 'complete' = 'date',
        timezone?: string
    ) {
        if (!date) {
            return '';
        }
        const dateObj = new Date(date);
        return timezone
            ? formatInTimeZone(
                  dateObj,
                  timezone,
                  "yyyy-MM-dd'T'hh:mm:ss.sssXXX"
              )
            : formatISO(dateObj, {
                  representation,
              });
    }

    formatDateTime(
        date: string | Date | undefined,
        timezone?: string,
        includeSeconds = false
    ) {
        if (!date) {
            return '';
        }

        const dateObj = new Date(date);

        const timeFormat = includeSeconds
            ? this.dateConfig.timeFormat
            : this.dateConfig.timeFormat.replace(/:ss/, '');

        return timezone
            ? formatInTimeZone(
                  dateObj,
                  timezone,
                  `${this.dateConfig.dateFormat} ${timeFormat} z`
              )
            : format(dateObj, `${this.dateConfig.dateFormat} ${timeFormat}`);
    }

    formatTime(
        date: string | Date | undefined,
        timezone?: string,
        includeSeconds = false
    ) {
        if (!date) {
            return '';
        }

        const dateObj = new Date(date);

        const timeFormat = includeSeconds
            ? this.dateConfig.timeFormat
            : this.dateConfig.timeFormat.replace(/:ss/, '');

        return timezone
            ? formatInTimeZone(dateObj, timezone, timeFormat + ' z')
            : format(dateObj, this.dateConfig.timeFormat);
    }

    formatFileTimestamp(date?: string | Date) {
        return format(
            date ? new Date(date) : new Date(),
            this.dateConfig.fileTimestampFormat
        );
    }

    timeElapsed(
        start: string | Date | number,
        end?: string | Date | number | undefined
    ) {
        return formatDuration(
            intervalToDuration({
                start: new Date(start),
                end: end ? new Date(end) : new Date(),
            }),
            {
                format: ['days', 'hours', 'minutes'],
            }
        );
    }

    getFutureDate(days: number) {
        const date = new Date();
        date.setDate(date.getDate() + days);
        return this.formatDateToISO(date);
    }

    utcToZonedTime(date: string | Date | null | undefined, timezone?: string) {
        if (!date) {
            return '';
        }
        if (!timezone) {
            return date.toString();
        }
        return utcToZonedTime(new Date(date), timezone).toLocaleString();
    }

    zonedTimeToUtc(date: string | Date | null | undefined, timezone?: string) {
        if (!date) {
            return '';
        }
        if (!timezone) {
            return date.toString();
        }
        return zonedTimeToUtc(new Date(date), timezone).toUTCString();
    }
}
