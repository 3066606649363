import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
import { IAppMenu, INavMenu } from '../../../../interfaces/IAppRoutes';

import { LayoutService } from './../../services/layout.service';
import { PreviousRouteService } from '../../services/previous-route.service';
import { AppUpdateService } from '../../services/app-update.service';

@Component({
    selector: 'pf-app-layout',
    templateUrl: './app-layout.component.html',
    styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements AfterViewInit {
    @ViewChild('layoutContainer', { static: true, read: ElementRef })
    layoutContainer!: ElementRef<HTMLElement>;
    @Input() navigation: IAppMenu[] = [];

    constructor(
        public layoutService: LayoutService,
        _appUpdateService: AppUpdateService, // inject to make sure it's instantiated
        _previousRouteService: PreviousRouteService // inject to make sure it's instantiated
    ) {}

    ngAfterViewInit(): void {
        this.layoutService.scrollContainer = this.layoutContainer.nativeElement;
    }

    expanderClickHander() {
        this.layoutService.isCollapsed = !this.layoutService.isCollapsed;
    }

    navigationTrackByFn(_: number, menu: INavMenu) {
        return menu.displayName;
    }
}
