import { Component, Input, TemplateRef } from '@angular/core';
import {
    FrameworkColumn,
    SafeAny,
    TableAction,
    TableOptions,
    TableRow,
} from '@pf/shared-common';
import { TableService } from '../services/table.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'pf-table-rows',
    templateUrl: './table-rows.component.html',
    styleUrls: ['./table-rows.component.scss'],
})
export class TableRowsComponent {
    @Input() expandToContent = false;
    @Input() columns: FrameworkColumn[] = [];
    @Input() actions: TableAction<SafeAny>[] = [];
    @Input() expandRowRef?: TemplateRef<{
        columns?: FrameworkColumn[];
        row: TableRow;
        actions?: TableAction<SafeAny>[];
        index?: number;
        expanded: boolean;
    }>;

    @Input() set data(data: Readonly<TableRow[]>) {
        this.tableRows = data;
        this.setExpandRecursively(data, this.expand);
        this.refreshCheckedStatus();
    }

    @Input() expandable = false;
    @Input() expand = false;
    @Input() singleExpand = false;
    @Input() options: Partial<TableOptions> | null = {};
    @Input() showSelection = false;

    tableRows: Readonly<TableRow[]> = [];

    constructor(private tableService: TableService) {
        this.listenForAllRowsChecked();
    }

    dataTrackBy(_: number, row: TableRow) {
        return row.data?.id ? row.data.id : row.index;
    }

    headerTrackBy(index: number, column: FrameworkColumn) {
        return `${column.field}_${column.sortOrder}_${
            this.tableRows?.[index]?.expanded || false
        }`;
    }

    shouldDisplayExpandIcon(row: TableRow): boolean {
        return !!this.expandRowRef || !!row.children?.length;
    }

    expandHandler(expanded: SafeAny, index: number) {
        if (this.singleExpand && expanded === true) {
            this.setExpandRecursively(this.tableRows, false);
        }
        this.tableRows[index].expanded = expanded;
    }

    checkedHandler(checked: boolean, index: number) {
        this.tableRows[index].checked = checked;
        this.refreshCheckedStatus();
    }

    private refreshCheckedStatus(): void {
        if (this.tableService.tableState.total === 0) {
            this.tableService.checked = false;
            this.tableService.indeterminate = false;
            return;
        }
        const selectedRows = this.tableRows.filter(row => row.checked);
        this.tableService.selectedRows$.next(selectedRows);
        this.tableService.checked =
            selectedRows.length === this.tableService.tableState.total ||
            (this.tableService.checked &&
                selectedRows.length === this.tableRows.length);
        this.tableService.indeterminate =
            selectedRows.length > 0 && !this.tableService.checked;
    }

    private setExpandRecursively(
        rows: Readonly<TableRow[]>,
        expanded: boolean
    ) {
        if (rows.length === 0) {
            return;
        }

        if (this.singleExpand) {
            if (rows.length === 1) {
                rows[0].expanded = expanded;
            }
            return;
        }

        rows.forEach(row => {
            row.expanded = expanded;
            if (row.children) {
                this.setExpandRecursively(row.children, expanded);
            }
        });
    }

    private listenForAllRowsChecked() {
        this.tableService.allRowsChecked$
            .pipe(untilDestroyed(this))
            .subscribe(allRowsChecked => {
                this.tableRows.forEach(row => (row.checked = allRowsChecked));
                this.refreshCheckedStatus();
            });
    }
}
