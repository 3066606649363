import { SafeAny, ValueFormatterFn, ValueType } from '@pf/shared-common';
import { Injectable } from '@angular/core';
import { isBoolean } from 'lodash-es';
import { isValid, parseISO } from 'date-fns';
import { DateService } from './date.service';
import { CurrencyPipe } from '@angular/common';

const DefaultValueFormatter = (value: SafeAny) =>
    value === null || value === undefined ? '' : value;

@Injectable({ providedIn: 'root' })
export class ValueFormatterService {
    private readonly formatters: Record<ValueType, ValueFormatterFn>;

    constructor(dateService: DateService) {
        this.formatters = {
            string: value => value || '',
            number: value =>
                value === null || value === undefined ? '' : value,
            select: value => (value?.label ? value.label : value || ''),
            date: value => dateService.formatDate(value),
            time: value => dateService.formatTime(value),
            datetime: value => dateService.formatDateTime(value),
            dateNoTz: value =>
                dateService.formatDate(dateService.parseServerDateOnly(value)),
            fileTimestamp: (value?) => dateService.formatFileTimestamp(value),
            currency: value =>
                value
                    ? (new CurrencyPipe('en-US').transform(
                          value,
                          'USD',
                          'symbol',
                          '1.2-3'
                      ) as string)
                    : '',
        };
    }

    registerFormatter(type: ValueType, formatterFn: ValueFormatterFn) {
        this.formatters[type] = formatterFn;
    }

    removeFormatter(type: ValueType) {
        if (this.formatters[type]) {
            delete this.formatters[type];
        }
    }

    getFormatter(type: ValueType | undefined): ValueFormatterFn {
        if (!type) {
            return DefaultValueFormatter;
        }
        const formatter = this.formatters[type];
        if (!formatter) {
            console.warn(
                `No formatter found for type ${type}. Using DefaultValueFormatter.`
            );
        }
        return formatter || DefaultValueFormatter;
    }

    format(
        type: ValueType | undefined,
        value: SafeAny,
        record?: SafeAny
    ): string {
        const formatter = this.getFormatter(type);
        return formatter(value, record);
    }

    autoFormat(value: SafeAny, record?: SafeAny) {
        if (value === null || value === undefined) {
            return 'None';
        }
        if (isValid(parseISO(value))) {
            const valueType =
                typeof value === 'string' && value.indexOf(':')
                    ? value.indexOf('/')
                        ? 'datetime'
                        : 'time'
                    : 'date';
            return this.format(valueType, value, record);
        }

        if (isBoolean(value)) {
            return value;
        }

        return this.format('string', value, record);
    }
}
