<nz-form-item class="pf-form-item">
    <nz-form-label
        *ngIf="!!label"
        class="pf-form-label"
        [nzRequired]="pfRequired"
        [nzNoColon]="true"
        [title]="label"
        [nzTooltipTitle]="tooltip"
        [nzFor]="label"
        >{{ label }}
    </nz-form-label>
    <ng-template #tooltipIcon>
        <pf-icon iconType="info"></pf-icon>
    </ng-template>
    <nz-form-control
        [nzAutoTips]="autoTips"
        class="pf-form-control"
        [nzErrorTip]="pfErrorTip">
        <ng-container [ngSwitch]="typeProps?.componentType">
            <ng-container *ngSwitchCase="'inputNumber'">
                <nz-input-number
                    class="pf-input"
                    [attr.name]="label || null"
                    [formControl]="formControl"
                    label="input-label"
                    [nzMax]="typeProps?.max | safeNumber: 'infinity'"
                    [nzMin]="typeProps?.min | safeNumber: 'negativeInfinity'"
                    [nzParser]="parseNumber"
                    [nzPlaceHolder]="placeholder"
                    (input)="pfInput.emit($event)"></nz-input-number>
            </ng-container>
            <ng-container *ngSwitchCase="'textarea'">
                <nz-textarea-count
                    [nzMaxCharacterCount]="
                        typeProps?.maxLength | safeNumber: 'none'
                    ">
                    <textarea
                        [rows]="textAreaRows"
                        nz-input
                        class="pf-input"
                        [attr.name]="label || null"
                        [formControl]="formControl"
                        [placeholder]="placeholder"
                        [attr.minLength]="typeProps?.minLength"
                        [attr.maxLength]="typeProps?.maxLength"
                        (input)="pfInput.emit($event)"></textarea>
                </nz-textarea-count>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <nz-input-group [nzSuffix]="iconSuffixTpl">
                    <input
                        class="pf-input"
                        nz-input
                        [attr.name]="label || null"
                        [formControl]="formControl"
                        [attr.type]="typeProps?.type"
                        [attr.placeholder]="placeholder"
                        [attr.minLength]="typeProps?.minLength || null"
                        [attr.maxLength]="typeProps?.maxLength || null"
                        [attr.inputMode]="typeProps?.inputMode || null"
                        (input)="search($event)"
                        (ngModelChange)="onChange($event)"
                        [nzAutocomplete]="autoCompleteList" />
                    <nz-autocomplete #autoCompleteList>
                        <nz-auto-option
                            *ngFor="let option of filteredDataList"
                            [nzValue]="option"
                            >{{ option }}
                        </nz-auto-option>
                    </nz-autocomplete>
                    <ng-template #iconSuffixTpl>
                        <pf-icon
                            *ngIf="typeProps?.suffixIcon"
                            [iconType]="typeProps!.suffixIcon!"></pf-icon>
                    </ng-template>
                </nz-input-group>
            </ng-container>
        </ng-container>
    </nz-form-control>
</nz-form-item>
