import {
    ComponentRef,
    Inject,
    Injectable,
    NgModuleRef,
    Optional,
    Type,
} from '@angular/core';
import { AppConstantsConfig, SafeAny } from '@pf/shared-common';
import { DrawerComponent } from '../../../interfaces/DrawerComponent';
import { DrawerFormComponent } from '../../forms/components/drawer-form.component';
import { InjectionService } from '../../../services/injection.service';
import { FormService } from '../../forms/services/form.service';
import {
    APP_CONSTANTS_CONFIG,
    ResolveTemplate,
} from '../../../pipes/app-constants.pipe';

export enum DrawerType {
    Form = 'form',
}

export const DrawerTypeComponent: Record<DrawerType, Type<DrawerComponent>> = {
    [DrawerType.Form]: DrawerFormComponent,
};

export interface CreateFormDrawerOptions<T> {
    entityType: string;
    parentFormId?: string;
    title: string;
    component: Type<T>;
    componentProps?: Partial<T>;
    hash?: string;
    navigateBackOnClose?: boolean;
    level?: number;
}

@Injectable({
    providedIn: 'root',
})
export class DrawerService {
    private readonly _drawers: Record<string, ComponentRef<DrawerComponent>> =
        {};

    createFormDrawer<T>(createDrawerOptions: CreateFormDrawerOptions<T>) {
        const level =
            createDrawerOptions.level ||
            this.injectionService.getElementsFromComponentHost(
                'root',
                'pf-drawer-form'
            ).length;

        const formState = this.formService.registerForm(
            createDrawerOptions.entityType,
            undefined,
            createDrawerOptions.parentFormId
        );

        const componentRef = this.addComponentToApp(DrawerType.Form);
        const instance = componentRef.instance;
        instance.createDrawer({
            title: ResolveTemplate(
                createDrawerOptions.title,
                this.appConstants
            ),
            entityType: createDrawerOptions.entityType,
            component: createDrawerOptions.component,
            componentProps: createDrawerOptions.componentProps,
            level,
            hash: createDrawerOptions.hash,
            navigateBackOnClose:
                createDrawerOptions.navigateBackOnClose === undefined
                    ? true
                    : createDrawerOptions.navigateBackOnClose,
        });

        // Use formState.id since it is already unique in this scenario
        this._drawers[formState.id] = componentRef;
        this.formService.setDrawerRendered(
            createDrawerOptions.entityType,
            true,
            formState.id
        );
        instance.open();
        return componentRef;
    }

    constructor(
        private injectionService: InjectionService,
        private moduleRef: NgModuleRef<SafeAny>,
        private formService: FormService,
        @Optional()
        @Inject(APP_CONSTANTS_CONFIG)
        private readonly appConstants?: AppConstantsConfig
    ) {}

    private addComponentToApp(
        drawerType: DrawerType
    ): ComponentRef<DrawerComponent> {
        const component = DrawerTypeComponent[drawerType];
        return this.injectionService.appendComponentHost({
            component,
            hostLocation: 'root',
            ngModuleRef: this.moduleRef,
        });
    }

    drawer(id: string): DrawerComponent {
        return this._drawers[id]?.instance;
    }
}
