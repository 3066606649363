import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OneTimeLinkFacade } from '@pf/platform-communications/domain';
import { PFCommunicationsEntities } from '@pf/shared-common';
import { EntityBaseEditComponent, FormService } from '@pf/shared-ui';
import { OneTimeLinkDto } from '@pf/shared/util-platform';
import { Observable, of } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'platform-one-time-link-edit',
    templateUrl: './one-time-link-edit.component.html',
    styleUrls: ['./one-time-link-edit.component.scss'],
    providers: [FormService],
})
export class OneTimeLinkEditComponent
    extends EntityBaseEditComponent<OneTimeLinkDto>
    implements OnInit
{
    entityType = PFCommunicationsEntities.OneTimeLink;
    @Output() saveOneTimeLinkSaved = new EventEmitter<OneTimeLinkDto>();

    constructor(public oneTimeLinkFacade: OneTimeLinkFacade) {
        super(oneTimeLinkFacade);
    }

    newEntityFn$(): Observable<OneTimeLinkDto> {
        return of(new OneTimeLinkDto({}));
    }

    saveOneTimeLink(entity: OneTimeLinkDto): void {
        this.saveOneTimeLinkSaved.emit(entity);

        if (entity.recipientUserGroupType === 'carrier') {
            this.oneTimeLinkFacade.oneTimeLinks.carrierOneTimeLinks = [
                ...this.oneTimeLinkFacade.oneTimeLinks.carrierOneTimeLinks,
                entity,
            ];
        }
        if (entity.recipientUserGroupType === 'vendor') {
            this.oneTimeLinkFacade.oneTimeLinks.vendorOneTimeLinks = [
                ...this.oneTimeLinkFacade.oneTimeLinks.vendorOneTimeLinks,
                entity,
            ];
        }
        this.notifyEntitySubmitted(entity);
    }
}
