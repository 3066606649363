import { IEntity } from '@pf/shared-common';
import {
    WorkflowGlobalParametersCreateBody,
    WorkflowGlobalParametersEntitySearchRequestParams,
    WorkflowGlobalParameterViewModelRead,
} from '@control-tower/platform-loads';
import { ParamViewModel } from '@control-tower/platform-loads/model/paramViewModel';

export type WorkflowGlobalParametersRead =
    WorkflowGlobalParameterViewModelRead & IEntity;
export type WorkflowGlobalParametersCreate = WorkflowGlobalParametersCreateBody;
export type WorkflowGlobalParametersSearchParams =
    WorkflowGlobalParametersEntitySearchRequestParams;

export class WorkflowGlobalParametersDto implements IEntity {
    constructor(data: Partial<WorkflowGlobalParametersDto>) {
        Object.assign(this, data);
        this.name = data.name || 'Default';
    }

    id!: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;

    name!: string;
    description?: string;
    parameters?: ParamViewModel[];
}
