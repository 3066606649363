<pf-content [scrollable]="drawerRendered">
    <pf-content-header *ngIf="!drawerRendered">
        <pf-page-header pageTitle="Edit Contact">
            <pf-form-buttons
                page-header-extra
                [entityType]="entityType"
                [hideCancel]="true"></pf-form-buttons>
        </pf-page-header>
    </pf-content-header>
    <platform-core-contact-form
        [entity]="entity"
        (entityUpdate)="saveEntity($event)">
    </platform-core-contact-form>
</pf-content>
