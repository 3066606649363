import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import { Formula, LoadWorkflowsService } from '@control-tower/platform-loads';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    IPagedResult,
    PFLoadEntities,
} from '@pf/shared-common';
import { LoadWorkflowStore } from './load-workflow.store';
import {
    LoadWorkflowCreate,
    LoadWorkflowRead,
    LoadWorkflowSearchParams,
} from '../../entities/load-workflow.dto';
import { Operation } from 'fast-json-patch';
import { LoadRead } from '../../entities/load.dto';
import { asArraySafe } from '@pf/shared-utility';

@Injectable()
export class LoadWorkflowDataService extends AbstractDataService<
    LoadWorkflowRead,
    LoadWorkflowCreate
> {
    protected EntityName = PFLoadEntities.LoadWorkflow;

    constructor(
        private service: LoadWorkflowsService,
        store: LoadWorkflowStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(
        body: LoadWorkflowCreate
    ): Observable<LoadWorkflowRead> {
        return this.service.loadWorkflowEntityCreate({
            workflowCreateBody: body,
        }) as Observable<LoadWorkflowRead>;
    }

    protected deleteObs$(id: string): Observable<LoadWorkflowRead> {
        return this.service.loadWorkflowEntityDelete({
            id,
        }) as Observable<LoadWorkflowRead>;
    }

    protected undoDeleteObs$(id: string): Observable<LoadWorkflowRead> {
        return this.service.loadWorkflowEntityUndoDelete({
            id,
        }) as Observable<LoadWorkflowRead>;
    }

    protected getObs$(id: string): Observable<LoadWorkflowRead> {
        return this.service.loadWorkflowEntityReadById({
            id,
        }) as Observable<LoadWorkflowRead>;
    }

    protected updateObs$(
        id: string,
        _: Operation[],
        createBody: LoadWorkflowCreate
    ): Observable<LoadWorkflowRead> {
        return this.service.loadWorkflowEntityUpdatePut({
            id,
            workflowCreateBody: createBody,
        }) as Observable<LoadWorkflowRead>;
    }

    protected searchObs$(
        searchParams: LoadWorkflowSearchParams
    ): Observable<IPagedResult<LoadWorkflowRead>> {
        return this.service.loadWorkflowEntitySearch(
            searchParams
        ) as Observable<IPagedResult<LoadWorkflowRead>>;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<LoadWorkflowRead>>> {
        return this.service.loadWorkflowEntityGetChanges(
            searchParams
        ) as Observable<
            IPagedResult<EntityChangeTrackingDto<LoadWorkflowRead>>
        >;
    }

    testWorkflow(
        workflow: LoadWorkflowCreate,
        entityId: string,
        workflowId?: string
    ) {
        return this.service.loadWorkflowEntityValidateWorkflow({
            entityId,
            workflowId,
            workflowCreateBody: workflow,
        });
    }

    executeWorkflowForLoad$(load: LoadRead) {
        return this.service
            .loadWorkflowEntityExecute({
                loadUnifiedViewModelRead: load,
            })
            .pipe(
                map(result => {
                    return result.hasErrors
                        ? []
                        : asArraySafe(result.results)
                              .map(r => r.output as Formula)
                              .filter(f => f);
                })
            );
    }
}
