import { ApplicationRef, Injectable, Optional } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concat, filter, interval, Subject, tap } from 'rxjs';
import { first } from 'rxjs/operators';
import { AppNotificationService } from '../../notifications/services/app-notification.service';
import {
    AppNotificationType,
    AppNotificationVm,
    NotificationActionVm,
    NotificationType,
} from '@pf/shared-common';

@Injectable({
    providedIn: 'root',
})
export class AppUpdateService {
    private onClickSubject = new Subject<AppNotificationVm>();

    constructor(
        private appRef: ApplicationRef,
        @Optional() private updates: SwUpdate,
        private appNotifications: AppNotificationService
    ) {
        if (!updates || !updates.isEnabled) {
            return;
        }
        this.registerAction();
        this.checkForUpdates();
        this.listenForUpdates();
    }

    private checkForUpdates() {
        const appIsStable$ = this.appRef.isStable.pipe(
            first(isStable => isStable)
        );
        const everyTwoHours$ = interval(2 * 60 * 60 * 1000);
        const everyTwoHoursOnceAppIsStable$ = concat(
            appIsStable$,
            everyTwoHours$
        );

        everyTwoHoursOnceAppIsStable$.subscribe(() =>
            this.updates.checkForUpdate()
        );
    }

    private listenForUpdates() {
        this.updates.versionUpdates
            .pipe(filter(x => x.type === 'VERSION_READY'))
            .subscribe(event => {
                console.log(
                    'current version is',
                    (event as VersionReadyEvent).currentVersion
                );
                console.log(
                    'available version is',
                    (event as VersionReadyEvent).latestVersion
                );
                this.promptRefresh();
            });
    }

    private registerAction() {
        this.appNotifications.registerAction(
            'app-refresh',
            this.onClickSubject
        );
        this.onClickSubject
            .pipe(
                first(),
                tap(() => document.location.reload())
            )
            .subscribe();
    }

    private promptRefresh() {
        const refreshAction: NotificationActionVm = {
            onClickAction: 'app-refresh',
            buttonType: 'submit',
            text: 'Refresh',
            onClick: this.onClickSubject,
        };
        this.appNotifications.create({
            type: NotificationType.AppNotification,
            appNotificationType: AppNotificationType.info,
            title: 'New Version Available',
            action: refreshAction,
            message:
                'A new version of the application is available. Please refresh to update.',
            persist: true,
        });
    }
}
