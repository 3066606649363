import { Component, Input, OnInit } from '@angular/core';
import { FilterService } from '../services/filter.service';
import { IEntity, IFilterConfig, TableColumn } from '@pf/shared-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    BehaviorSubject,
    combineLatest,
    filter,
    map,
    Observable,
    skipUntil,
    Subject,
    switchMap,
    take,
    tap,
} from 'rxjs';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { shareReplay } from 'rxjs/operators';
import { FilterMetadata } from '../stores/filter.store';

@UntilDestroy()
@Component({
    selector: 'pf-advanced-filters',
    templateUrl: './advanced-filters.component.html',
    styleUrls: ['./advanced-filters.component.scss'],
    animations: [
        trigger('slideInOut', [
            state(
                'hidden',
                style({
                    transform: 'translateX(-100%)',
                    opacity: 0,
                    display: 'none',
                })
            ),
            state(
                'visible',
                style({
                    transform: 'translateX(0)',
                    opacity: 1,
                    display: 'block',
                })
            ),
            transition('hidden <=> visible', [animate('300ms ease-in-out')]),
        ]),
    ],
})
export class AdvancedFiltersComponent<T extends IEntity> implements OnInit {
    private _filtersAreReadySubject = new BehaviorSubject<Set<string>>(
        new Set()
    );
    private readonly _columnsSubject = new BehaviorSubject<TableColumn<T>[]>(
        []
    );
    private filterChange$ = new Subject<FilterMetadata>();

    @Input() set columns(columns: TableColumn<T>[]) {
        this._columnsSubject.next(columns);
    }

    @Input() tableKey?: string;

    hasValidationIssues = false;

    filterDisplay$!: Observable<boolean>;
    appliedFiltersCount$!: Observable<number>;
    filteredKeysNames$!: Observable<string[]>;

    filters$: Observable<IFilterConfig[]> =
        this.filterService.initialized$.pipe(
            untilDestroyed(this),
            switchMap(_ => this._columnsSubject),
            filter(columns => columns.length > 0),
            map(columns => this.getFilters(columns))
        );

    filtersAreReady$ = combineLatest([
        this._filtersAreReadySubject,
        this.filters$,
    ]).pipe(
        filter(
            ([filtersReadySet, filters]) =>
                filters.length === filtersReadySet.size
        ),
        shareReplay(1)
    );

    constructor(public filterService: FilterService) {}

    ngOnInit() {
        if (!this.tableKey) {
            throw new Error('tableKey is required for advanced filters');
        }

        this.filterService.initialize(this.tableKey);
        this.filterDisplay$ = this.filterService.filterDisplay$.pipe(
            untilDestroyed(this)
        );
        this.appliedFiltersCount$ =
            this.filterService.appliedFiltersCount$.pipe(untilDestroyed(this));
        this.filteredKeysNames$ = this.filterService.filteredKeysNames$.pipe(
            untilDestroyed(this)
        );
        this.filtersAreReady$
            .pipe(
                take(1),
                tap(_ => this.filterService.applyFilters(false))
            )
            .subscribe();
        this.filterChange$
            .pipe(skipUntil(this.filtersAreReady$), untilDestroyed(this))
            .subscribe(filter => {
                this.filterService.updateFilters(filter);
            });
        this.filterService.filterValidationIssues$
            .pipe(untilDestroyed(this))
            .subscribe(hasIssues => (this.hasValidationIssues = !!hasIssues));
    }

    filterComponentsReadyHandler(componentKey: string) {
        this._filtersAreReadySubject.next(
            new Set([...this._filtersAreReadySubject.value, componentKey])
        );
    }

    filterChangeHandler(filter: FilterMetadata) {
        this.filterChange$.next(filter);
    }

    getFilters(columns: TableColumn<T>[]): IFilterConfig[] {
        const filterConfigs = columns
            .map(column => column.filterConfig)
            .filter(
                (filterConfig): filterConfig is IFilterConfig => !!filterConfig
            )
            .sort((a, b) => {
                const indexA = a.index ?? Number.MAX_SAFE_INTEGER;
                const indexB = b.index ?? Number.MAX_SAFE_INTEGER;
                return indexA - indexB;
            });

        const filterPopoverMapping: Record<string, string> =
            filterConfigs.reduce(
                (acc, filter) => {
                    if (filter.popoverKeyMapping) {
                        filter.popoverKeyMapping.forEach(filterKey => {
                            acc[filterKey] = filter.name;
                        });
                        return acc;
                    } else {
                        acc[filter.filterKey] = filter.name;
                        return acc;
                    }
                },
                {} as Record<string, string>
            );

        this.filterService.setPopoverNameMapping(filterPopoverMapping);

        return filterConfigs;
    }

    get hasValidationErrors() {
        return this.hasValidationIssues;
    }
}
