import { IEntity, SafeAny } from '@pf/shared-common';
import { Operation } from 'fast-json-patch';

/**
 * Adjusts a list of JSON Patch operations to handle specific cases for add and remove operations.
 *
 * The function performs the following operations:
 * 1. Filters out 'add' operations with null values.
 * 2. Adds 'remove' operations for sub-entities marked as deleted in the DTO.
 * 3. Converts 'add' operations to 'replace' for single-item arrays.
 * 4. Sorts 'remove' operations to the end of the operations list.
 *
 * @param dto - The data transfer object that contains the entity data.
 * @param ops - The list of JSON Patch operations to be adjusted.
 * @returns The adjusted list of JSON Patch operations.
 *
 * @example
 * const dto = {
 *   items: [{ isDeleted: true }, { isDeleted: false }]
 * };
 * const ops = [
 *   { op: 'add', path: '/items/0', value: { id: 1 } },
 *   { op: 'add', path: '/items/1', value: null }
 * ];
 * const result = fixEntityOperations(dto, ops);
 * // result will be:
 * // [
 * //   { op: 'remove', path: '/items/0' },
 * //   { op: 'replace', path: '/items', value: [{ id: 1 }] }
 * // ]
 */
export function fixEntityOperations(dto: SafeAny, ops: Operation[]) {
    let updatedOps = ops.filter(o => o.op !== 'add' || o.value !== null);

    function fixDeleteOps() {
        Object.keys(dto).forEach(key => {
            if (!Array.isArray(dto[key])) {
                return;
            }
            (dto[key] as IEntity[]).forEach((subEntity, subEntityIndex) => {
                if (subEntity.isDeleted) {
                    const path = `/${key}/${subEntityIndex}`;
                    updatedOps = updatedOps.filter(
                        o => !o.path.startsWith(path)
                    );
                    updatedOps.unshift({
                        op: 'remove',
                        path,
                    } as Operation);
                }
            });
        });
    }

    function fixAddOps() {
        updatedOps.forEach(o => {
            if (o.op === 'add' && typeof o.value === 'object') {
                const path = o.path.split('/');
                const key = path[1];
                if (Array.isArray(dto[key]) && dto[key].length <= 1) {
                    (o as Operation).op = 'replace';
                    o.path = `/${key}`;
                    o.value = [o.value];
                }
            }
        });
    }

    fixDeleteOps();
    fixAddOps();
    // sort operations so array delete operations are done in reverse order and after all other operations
    return updatedOps.sort((a, b) => {
        if (a.op === 'remove') {
            return 1;
        }
        if (b.op === 'remove') {
            return -1;
        }
        return 0;
    });
}
