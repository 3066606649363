import {
    GetLocationString,
    LocationCreate,
    LocationDto,
    LocationRead,
    LocationSearchParams,
} from '../entities/location.dto';

import { AbstractManageEntityFacade } from '@pf/shared-services';
import { Injectable, Optional, signal, WritableSignal } from '@angular/core';
import { LocationDataService } from '../infrastructure/locations/location.data.service';
import { LocationMapper } from '../infrastructure/locations/location.mapper';
import { LocationStore } from '../infrastructure/locations/location.store';
import { RoutesForLocation } from './location.routes';
import { map, Observable, of, switchMap } from 'rxjs';
import { TimezoneDto } from '../entities/timezone.dto';
import {
    BuildChangeTrackingFacadeFactory,
    IContact,
    IEntityWithContactsFacade,
} from '@pf/shared/util-platform';
import {
    ColumnSearchType,
    EntitySaveOptions,
    IColumnSearchFacade,
    IPagedResult,
    IQueryParams,
    QueryFilters,
    SafeAny,
    SearchStrategy,
    SelectOption,
} from '@pf/shared-common';
import {
    AddressFormatter,
    asArraySafe,
    mapToSelectOptions,
    toSelectOptions,
} from '@pf/shared-utility';
import { ResourceAuth } from '@control-tower/platform-core';
import { TypedFormGroup } from 'ngx-sub-form';
import {
    LocationFacadeConfig,
    ManageLocationView,
} from './location-facade.config';

export function mapLocationsToSelectOptions(
    source: Observable<LocationDto[]>
): Observable<SelectOption[]> {
    return source.pipe(
        map(locations =>
            toSelectOptions(
                locations,
                location =>
                    `${location.locationName}\n${AddressFormatter(
                        location.address
                    )}`
            )
        )
    );
}

@Injectable()
export class LocationFacade
    extends AbstractManageEntityFacade<
        LocationDto,
        LocationRead,
        LocationCreate,
        LocationSearchParams
    >
    implements
        IEntityWithContactsFacade<LocationDto>,
        IColumnSearchFacade<LocationDto>
{
    nameField: keyof LocationDto = 'locationName';
    locationManageView!: WritableSignal<ManageLocationView>;
    customFieldsConfig$ = this.config?.customFields$ ?? of([]);

    constructor(
        protected locationDataService: LocationDataService,
        routes: RoutesForLocation,
        store: LocationStore,
        mapper: LocationMapper,
        @Optional() public config?: LocationFacadeConfig
    ) {
        super(locationDataService, routes, store, mapper);
        this.setViewSignal();
    }

    notifyFormChange(
        location: LocationDto,
        formGroup: TypedFormGroup<SafeAny>
    ) {
        if (this.config?.onFormChange) {
            this.config.onFormChange(location, formGroup);
        }
    }

    getContacts$(entity: LocationDto): Observable<IContact[]> {
        return this.locationDataService.getContacts$(entity.id);
    }

    override applyResourceAuth(body: LocationCreate, dto: LocationDto) {
        body.resourceAuth = {
            locations: dto.id ? [dto.id] : [],
            customers: asArraySafe(body.customers),
            vendors: asArraySafe(body.vendors),
            carriers: asArraySafe(body.carriers),
        } as ResourceAuth;
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        'locationName'
    );

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof LocationDto, string>> {
        return { locationName: searchText };
    }

    get columnListFilterName(): string {
        return 'location';
    }

    get subLocationTypes(): string[] {
        return this.config?.subLocationTypes ?? [];
    }

    getColumnListFilters$(
        additionalFilters?: QueryFilters<LocationDto>
    ): Observable<ColumnSearchType[]> {
        const queryParams = {
            pageIndex: 1,
            pageSize: 50,
            filters: additionalFilters,
        } as IQueryParams<LocationDto>;
        const searchParams = this.mapper.toSearchParams(queryParams);

        return this.dataService.searchAll$(searchParams).pipe(
            map(results =>
                results.sort((a, b) =>
                    a.locationName.localeCompare(b.locationName)
                )
            ),
            map(result =>
                result.map(location => ({
                    value: location.id,
                    label: GetLocationString(location),
                }))
            )
        );
    }

    override save$(
        dto: LocationDto,
        options?: EntitySaveOptions<LocationDto>
    ): Observable<LocationDto> {
        if (dto.timeZone) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, ...rest } = dto.timeZone;
            dto.timeZone = rest as TimezoneDto;
        }

        return super.save$({ ...dto }, options).pipe(
            switchMap(result => {
                if (
                    !result.resourceAuth?.locations?.find(x => x === result.id)
                ) {
                    // save again to update resourceAuth with new location id
                    return super.save$(result, {
                        emitEvent: false,
                        saveCustomFields: false,
                    });
                }
                return of(result);
            })
        );
    }

    override searchByText$(
        text: string,
        pageSize = 20,
        defaultFilters?: QueryFilters<LocationDto>
    ): Observable<IPagedResult<LocationDto>> {
        const params: IQueryParams<LocationDto> = {
            pageIndex: 1,
            pageSize: pageSize,
            sortOrder: 'ascend',
            sortField: this.nameField,
            searchValue: text,
            searchStrategy: SearchStrategy.Contains,
            searchFields: ['locationName', 'locationNumber'],
            filters: defaultFilters,
        };
        return this.oneTimeSearch$(params);
    }

    override mapToSelectOptions(
        source: Observable<IPagedResult<LocationDto>>
    ): Observable<SelectOption[]> {
        return mapToSelectOptions<LocationDto>(
            this.nameField,
            'id',
            loc => loc.locationTypeName
        )(source);
    }

    getCustomerLocationOptions$(customerId?: string | null) {
        return customerId
            ? this.searchAll$({
                  pageSize: 50,
                  pageIndex: 1,
                  filters: {
                      customers: [customerId],
                  },
              }).pipe(mapLocationsToSelectOptions)
            : of([] as SelectOption[]);
    }

    getVendorLocationOptions$(vendorId?: string | null) {
        return vendorId
            ? this.searchAll$({
                  pageSize: 50,
                  pageIndex: 1,
                  filters: {
                      vendors: [vendorId],
                  },
              }).pipe(mapLocationsToSelectOptions)
            : of([] as SelectOption[]);
    }

    private setViewSignal() {
        const defaultView: ManageLocationView = {
            maxAssociatedCustomers: 0,
        };
        if (!this.config?.view) {
            this.locationManageView = signal<ManageLocationView>(defaultView);
        } else {
            this.config.view.update(view => {
                return {
                    ...defaultView,
                    ...view,
                };
            });
            this.locationManageView = this.config.view;
        }
    }
}
