import { WorkflowGlobalParametersDataService } from '../infrastructure/workflow-global-parameters/workflow-global-parameters-data.service';
import {
    WorkflowGlobalParametersCreate,
    WorkflowGlobalParametersDto,
    WorkflowGlobalParametersRead,
    WorkflowGlobalParametersSearchParams,
} from '../entities/workflow-global-parameters.dto';
import { WorkflowGlobalParameterStore } from '../infrastructure/workflow-global-parameters/workflow-global-parameter.store';
import { inject, Injectable } from '@angular/core';
import { AbstractManageEntityFacade } from '@pf/shared-services';
import { WorkflowGlobalParameterMapper } from '../infrastructure/workflow-global-parameters/workflow-global-parameter.mapper';
import { BuildChangeTrackingFacadeFactory } from '@pf/shared/util-platform';
import {
    IManageEntityRoutes,
    PlatformWorkflowDefinition,
    SafeAny,
} from '@pf/shared-common';
import { asArraySafe } from '@pf/shared-utility';
import { Step, Uid } from 'sequential-workflow-designer';
import {
    ComponentTypesMap,
    TypePropertiesMapper,
    WorkflowType,
} from '../types/load-workflow.types';
import { StepType } from '@control-tower/platform-loads';
import { combineLatest, delay, map, Observable } from 'rxjs';
import { StopTypeFacade } from './type-entities/StopTypeFacade';
import { ChargeTypeFacade } from './type-entities/ChargeTypeFacade';

export interface GlobalParametersDefinition extends PlatformWorkflowDefinition {
    properties: {
        name: string;
        description: string;
        globals: {
            variables: {
                name: string;
                type: string;
            }[];
        };
    };
}

@Injectable()
export class WorkflowGlobalParametersFacade extends AbstractManageEntityFacade<
    WorkflowGlobalParametersDto,
    WorkflowGlobalParametersRead,
    WorkflowGlobalParametersCreate,
    WorkflowGlobalParametersSearchParams
> {
    private readonly stopTypeFacade = inject(StopTypeFacade);
    private readonly chargeTypeFacade = inject(ChargeTypeFacade);

    nameField: keyof WorkflowGlobalParametersRead = 'name';
    override mergeOnUpdate = false;

    constructor(
        dataService: WorkflowGlobalParametersDataService,
        store: WorkflowGlobalParameterStore,
        mapper: WorkflowGlobalParameterMapper
    ) {
        super(
            dataService,
            null as unknown as IManageEntityRoutes,
            store,
            mapper
        );
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        this.nameField
    );

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof WorkflowGlobalParametersDto, string>> {
        return { id: searchText };
    }

    toWorkflowDefinition$(
        workflowGlobalParameters: WorkflowGlobalParametersDto
    ): Observable<GlobalParametersDefinition> {
        return combineLatest([
            this.stopTypeFacade.getAll$(),
            this.chargeTypeFacade.getAll$(),
        ]).pipe(
            map(([stopTypes, chargeTypes]) => {
                const lookups = {
                    stopTypeId: stopTypes,
                    chargeTypeId: chargeTypes,
                } as Record<string, { name: string; id: string }[]>;

                return (properties: SafeAny) => {
                    const keys = Object.keys(properties);
                    for (const key of keys) {
                        if (lookups[key]) {
                            properties[key] =
                                lookups[key].find(l => l.id === properties[key])
                                    ?.name || properties[key];
                        }
                    }
                    return properties;
                };
            }),
            map(guidLookupFn => {
                return {
                    properties: {
                        name: workflowGlobalParameters.name || '',
                        description: workflowGlobalParameters.description || '',
                        globals: {
                            variables: [
                                { name: 'load', type: 'object' },
                                ...asArraySafe(
                                    workflowGlobalParameters.parameters
                                ).map(p => {
                                    return {
                                        name: p.name!,
                                        type:
                                            p.outputType?.toLowerCase() ||
                                            'any',
                                    };
                                }),
                            ],
                        },
                    },
                    sequence: asArraySafe(
                        workflowGlobalParameters.parameters
                    ).map(p => {
                        const type = p.type || WorkflowType.StaticValue;
                        const propertiesFn =
                            TypePropertiesMapper[type as WorkflowType]
                                ?.toProperties || (p => p || {});
                        return {
                            id: Uid.next(),
                            name: p.name!,
                            type: type,
                            componentType: ComponentTypesMap[StepType.Action],
                            properties: guidLookupFn(
                                propertiesFn(
                                    p.properties || { staticValue: p.value }
                                )
                            ),
                        } as Step;
                    }),
                } as GlobalParametersDefinition;
            })
        );
    }

    fromWorkflowDefinition$(
        entityId: string | undefined,
        definition: GlobalParametersDefinition
    ) {
        return combineLatest([
            this.stopTypeFacade.getAll$(),
            this.chargeTypeFacade.getAll$(),
        ])
            .pipe(
                map(([stopTypes, chargeTypes]) => {
                    const lookups = {
                        stopTypeId: stopTypes,
                        chargeTypeId: chargeTypes,
                    } as Record<string, { name: string; id: string }[]>;

                    return (properties: SafeAny) => {
                        const keys = Object.keys(properties);
                        for (const key of keys) {
                            if (lookups[key]) {
                                properties[key] =
                                    lookups[key].find(
                                        l => l.name === properties[key]
                                    )?.id || properties[key];
                            }
                        }
                        return properties;
                    };
                }),
                map(guidLookupFn => {
                    return new WorkflowGlobalParametersDto({
                        id: entityId,
                        name: definition.properties.name,
                        description: definition.properties.description,
                        parameters: asArraySafe(definition.sequence).map(s => {
                            const propertiesFn =
                                TypePropertiesMapper[s.type as WorkflowType]
                                    ?.fromProperties || (p => p || {});
                            return {
                                name: s.name,
                                type:
                                    s.type === WorkflowType.StaticValue
                                        ? ''
                                        : s.type,
                                properties: guidLookupFn(
                                    propertiesFn(s.properties)
                                ),
                                value: s.properties['staticValue'],
                            };
                        }),
                    });
                })
            )
            .pipe(delay(900));
    }
}
