<nz-layout id="layout-container" #layoutContainer>
    <nz-sider
        *ngIf="!layoutService.isHidden"
        nzWidth="250px"
        nzTheme="light"
        nzCollapsible
        nzBreakpoint="lg"
        [nzCollapsedWidth]="50"
        [(nzCollapsed)]="layoutService.isCollapsed">
        <ng-content select="aside"></ng-content>
        <ng-content select="pf-app-nav-menu"></ng-content>
    </nz-sider>
    <nz-header>
        <ng-content select="header"></ng-content>
        <ng-content select="pf-app-header"></ng-content>
    </nz-header>
    <nz-content id="main-content">
        <router-outlet></router-outlet>
    </nz-content>
    <nz-footer>
        <ng-content select="footer"></ng-content>
    </nz-footer>
</nz-layout>
<div hidden>
    <ng-template pfHost="root"></ng-template>
</div>
