import {
    ListPreviewProps,
    ListPreviewRendererComponent,
} from './list-preview-renderer.component';
import {
    getTableRowValue,
    ICustomFieldsEntity,
    IEntity,
    IManageEntityFacade,
    SafeAny,
    TableColumn,
} from '@pf/shared-common';

import {
    StatusOptions,
    StatusRendererComponent,
} from './status-renderer.component';
import { ActionRendererComponent } from './action-renderer.component';
import {
    MultiValueCellRendererComponent,
    MultiValueCellRendererProps,
} from './multi-value-cell-renderer.component';

export class TableColumnBuilder<T> {
    private readonly _columns: TableColumn<T>[] = [];
    get columns() {
        return this._columns;
    }

    with(
        builderFn: (
            builder: TableColumnBuilder<T>,
            ...args: SafeAny[]
        ) => TableColumnBuilder<T>,
        ...args: SafeAny[]
    ) {
        return builderFn(this, ...args);
    }

    withColumn(column: TableColumn<T>) {
        this._columns.push({
            search: true,
            showSort: true,
            ...column,
        });
        return this;
    }

    withNameColumn(
        field: keyof T = 'name' as keyof T,
        headerName = 'Name',
        options?: Omit<TableColumn<T>, 'field' | 'headerName'>
    ) {
        return this.withColumn({
            field,
            type: 'string',
            headerName: headerName,
            width: 300,
            ...(options || {}),
        });
    }

    withFullNameColumn(field: keyof T = 'firstName' as keyof T) {
        return this.withColumn({
            field,
            type: 'string',
            headerName: 'Name',
            width: 300,
            valueFormatter: (_, record) =>
                record.firstName + ' ' + record.lastName,
        });
    }

    withDescriptionColumn(field: keyof T = 'description' as keyof T) {
        return this.withColumn({
            field,
            type: 'string',
            headerName: 'Description',
            width: 400,
        });
    }

    withActiveColumn(field: keyof T = 'isDeleted' as keyof T) {
        return this.withStatusColumn(
            field,
            'Show Inactive',
            {
                false: {
                    status: 'success',
                    label: 'Active',
                },
                true: {
                    status: 'danger',
                    label: 'Inactive',
                },
                defaultOption: {
                    status: 'success',
                    label: 'Active',
                },
            },
            {
                exportValueFormatter: value => (value ? 'Inactive' : 'Active'),
            }
        );
    }

    withStatusColumn(
        field: keyof T,
        checkboxLabel: string | null,
        options: StatusOptions,
        additionalOpts: Pick<
            TableColumn<unknown>,
            'valueFormatter' | 'exportValueFormatter' | 'width'
        > & { headerName?: string }
    ) {
        return this.withColumn({
            field,
            headerName: additionalOpts.headerName || 'Status',
            showCheckbox: !!checkboxLabel,
            checkboxLabel: checkboxLabel || '',
            valueFormatter: additionalOpts.valueFormatter,
            exportValueFormatter: additionalOpts.exportValueFormatter,
            component: StatusRendererComponent,
            componentProps: {
                options,
            },
            width: additionalOpts.width || 220,
            search: false,
            showSort: true,
        } as TableColumn<T>);
    }

    withDateColumn(field: keyof T, headerName: string) {
        return this.withColumn({
            field: field,
            type: 'date',
            search: false,
            headerName,
        });
    }

    withDateTimeColumn(field: keyof T, headerName: string) {
        return this.withColumn({
            field: field,
            type: 'datetime',
            search: false,
            headerName,
        });
    }

    withCreatedDateColumn(field: keyof T = 'createdDate' as keyof T) {
        return this.withDateColumn(field, 'Created Date');
    }

    withModifiedDateColumn(field: keyof T = 'modifiedDate' as keyof T) {
        return this.withDateColumn(field, 'Modified Date');
    }

    withListPreviewColumn(
        field: keyof T,
        headerName: string,
        listPreviewProps: Omit<ListPreviewProps, 'actionFn'>,
        facade?: IManageEntityFacade<T & IEntity>,
        hash?: string
    ) {
        return this.withColumn({
            field: field,
            type: 'string',
            headerName: headerName,
            search: false,
            showSort: false,
            width: 250,
            exportValueFormatter: (value: SafeAny[] | null) => {
                return (value || []).map(listPreviewProps.displayFn).join(',');
            },
            component: ListPreviewRendererComponent,
            componentProps: {
                ...listPreviewProps,
                actionFn: (_: string, row: T & IEntity) =>
                    facade?.edit(row.id, hash),
            },
        });
    }

    withDistinctCountColumn(
        field: keyof T,
        headerName: string,
        distinctValueFn: (value: SafeAny) => string,
        facade?: IManageEntityFacade<T & IEntity>,
        hash?: string
    ) {
        return this.withColumn({
            field,
            type: 'number',
            headerName,
            search: false,
            showSort: false,
            width: 100,
            valueFormatter: (value: SafeAny[]) =>
                value
                    ? [...new Set(value.map(distinctValueFn))].length.toString()
                    : '0',
            component: facade ? ActionRendererComponent : undefined,
            componentProps: {
                actionFn: (_: string, row: T & IEntity) =>
                    facade?.edit(row.id, hash),
            },
        });
    }

    withMultiValueColumn(
        primaryField: keyof T,
        headerName: string,
        fields: MultiValueCellRendererProps<T>['fields'],
        options?: Omit<
            TableColumn<T>,
            'field' | 'headerName' | 'component' | 'componentProps'
        >
    ) {
        return this.withColumn({
            field: primaryField,
            headerName,
            type: 'string',
            width: options?.width || 150,
            exportValueFormatter: (_: SafeAny, record?: T) => {
                return record
                    ? fields.map(field => ({
                          header: field.headerName,
                          value: getTableRowValue(field, record),
                          type: field.type,
                      }))
                    : '';
            },
            component: MultiValueCellRendererComponent,
            componentProps: {
                fields,
            },
        });
    }

    withCustomFieldColumn(
        headerName: string,
        key: string,
        valueMapper?: (value: string) => string,
        options?: Omit<
            TableColumn<T>,
            'field' | 'headerName' | 'component' | 'componentProps'
        >
    ) {
        return this.withColumn({
            field: 'customFields' as SafeAny,
            headerName,
            type: 'string',
            width: options?.width || 150,
            valueFormatter: (_: SafeAny, record?: T & ICustomFieldsEntity) => {
                if (!record?.customFields) {
                    return '';
                }
                const value =
                    record.customFields.find(cf => cf.key === key)?.value || '';
                return valueMapper ? valueMapper(value) : value;
            },
        });
    }
}
