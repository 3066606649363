import { Component, Input } from '@angular/core';
import {
    FilterOption,
    IEntity,
    ISearchFacade,
    SafeAny,
    SelectOption,
} from '@pf/shared-common';
import { FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseFilterComponent } from './base-filter.component';
import { combineLatest, take } from 'rxjs';
import { FilterMetadata } from '../stores/filter.store';

type CheckboxGroupFilterOption = FilterOption & {
    checked: boolean;
    customFilterKey: string;
};

interface MultiEntityFilterProps {
    filterKey: string;
    value: string[];
}

@UntilDestroy()
@Component({
    selector: 'pf-checkbox-group-filter',
    templateUrl: './checkbox-group-filter.component.html',
    styleUrl: './checkbox-group-filter.component.scss',
})
export class CheckboxGroupFilterComponent extends BaseFilterComponent {
    private _selectOptions: CheckboxGroupFilterOption[] = [];

    selectEntityOptions: Record<string, CheckboxGroupFilterOption[]> = {};
    searchControl = new FormControl<string>('');
    indeterminate = false;

    @Input() allChecked = false;

    get selectOptions(): CheckboxGroupFilterOption[] {
        return this._selectOptions;
    }

    set selectOptions(options: CheckboxGroupFilterOption[]) {
        this._selectOptions = options;
    }

    isEntityOptionsAvailable(key: string): boolean {
        return !!key && this.selectEntityOptions?.[key]?.length > 0;
    }

    updateAllChecked(): void {
        this.indeterminate = false;
        if (this.allChecked) {
            this.updateAllOptions(true);
        } else {
            this.updateAllOptions(false);
        }

        this.filterChangedChecks();
    }

    updateSingleChecked(): void {
        const allChecked = this._selectOptions.every(item => item.checked);
        const noneChecked = this._selectOptions.every(item => !item.checked);

        this.allChecked = allChecked;
        this.indeterminate = !allChecked && !noneChecked;

        this.filterChangedChecks();
    }

    selectItem(option: SelectOption) {
        if (option.group) {
            this.selectEntityItem(option);
            return;
        }

        const selectedOption = this._selectOptions.find(
            item => item.value === option.value
        );
        if (selectedOption) {
            return;
        }

        this._selectOptions.push({
            label: option.label,
            value: option.value,
            checked: true,
        } as CheckboxGroupFilterOption);

        this.checkAllOptionsAreChecked();
        this.filterChangedChecks();
    }

    entityGroupDelete(entityType: string) {
        delete this.selectEntityOptions[entityType];
        this._selectOptions = Object.values(this.selectEntityOptions).flat();
        this.checkAllOptionsAreChecked();
        this.filterChangedChecks();
    }

    override filterChangedChecks() {
        this.filterChangeHandler({
            key: this.filterKey,
            name: this.filterName,
            value: this.getSelectedValuesMetadata(),
            props: this.isGrouped
                ? this.getSelectedEntitiesMetadata()
                : undefined,
        });
        this.validate();
    }

    override validate() {
        const isAnyOptionChecked = this._selectOptions.some(
            item => item.checked
        );

        this.displayValidationMessage =
            !isAnyOptionChecked && this.selectionRequired;
    }

    override setFilterOptions(options: FilterOption[] | undefined) {
        if (!options) return;

        this._selectOptions = this.mapToCheckboxGroupFilterOptions(options);
    }

    override handleInitialValue() {
        combineLatest([
            this.filterService.checkInitialFilterIsDefinedByName$(
                this.filterName
            ),
            this.filterService.getAppliedFilterByName$(this.filterName),
        ])
            .pipe(take(1))
            .subscribe(([initialFiltersDefined, filter]) => {
                if (filter) {
                    this.transformFilterMetadataToFilterOptions(filter);
                }

                if (!initialFiltersDefined && this.initialSelection === 'all') {
                    this.allChecked = true;
                    this.updateAllChecked();
                    this.updateInitialFilter({});
                }
                super.handleInitialValue();
            });
    }

    override updateInitialFilter(_: SafeAny) {
        const filter = {
            key: this.filterKey,
            name: this.filterName,
            value: this._selectOptions.map(option => option.value.toString()),
        } as FilterMetadata;
        this.filterService.setInitialFilter(filter);
    }

    override filterResetFn() {
        this.resetOrClearOptions(true);
    }

    override filterClearFn() {
        this.resetOrClearOptions(false);
    }

    private clearAllOptions() {
        this._selectOptions = [];
        this.selectEntityOptions = {};
    }

    private updateAllOptions(value: boolean) {
        this._selectOptions = this._selectOptions.map(item => ({
            ...item,
            checked: value,
        }));

        for (const key in this.selectEntityOptions) {
            this.selectEntityOptions[key] = this.selectEntityOptions[key].map(
                item => ({
                    ...item,
                    checked: value,
                })
            );
        }
    }

    private checkAllOptionsAreChecked() {
        const isAllChecked = this._selectOptions.every(item => item.checked);
        const isAnyOptionChecked = this._selectOptions.some(
            item => item.checked
        );

        this.allChecked = isAllChecked;
        this.indeterminate = isAnyOptionChecked && !isAllChecked;
    }

    private cleanUnselectedOptionsFromFilters(
        filters: CheckboxGroupFilterOption[]
    ) {
        const unSelectedFilters = filters.filter(filter => !filter.checked);

        for (const key in unSelectedFilters) {
            const unselectedFilter = unSelectedFilters[key];

            if (unselectedFilter.customFilterKey) {
                this.filterService.deleteFilter(
                    unselectedFilter.customFilterKey
                );
            } else {
                this.filterService.deleteFilter(this.filterKey);
            }
        }
    }

    private fetchOptionsForFacade(ids: string[]) {
        if (!ids || ids.length === 0) return;

        (this.searchFacade as ISearchFacade<IEntity>)
            .getMany$(ids)
            .subscribe(entities => {
                entities.forEach(entity => {
                    this._selectOptions.push({
                        label: (entity as SafeAny)[this.searchFacadeLabel!],
                        value: entity.id ?? '',
                        checked: true,
                        customFilterKey: this.filterKey,
                    });
                });
            });
    }

    private fetchOptionsForEntityTypes(metadata: FilterMetadata) {
        for (const key in this.searchEntityTypes) {
            const entityIds = (metadata.props as MultiEntityFilterProps[]).find(
                prop =>
                    prop.filterKey === this.searchEntityTypes?.[key].filterKey
            )?.value;

            if (entityIds && entityIds?.length > 0) {
                (
                    this.searchEntityTypes[key]
                        .searchFacade as ISearchFacade<IEntity>
                )
                    ?.getMany$(entityIds as string[])
                    .subscribe(entities => {
                        entities.forEach(entity => {
                            this.selectEntityItem({
                                label: (entity as SafeAny)[
                                    this.searchEntityTypes?.[key]
                                        .searchFacadeLabel ?? ''
                                ],
                                value: entity.id ?? '',
                                group: key,
                            });
                        });
                    });
            }
        }
    }

    private selectEntityItem(
        selection: SelectOption,
        filterChangeCheck = true
    ) {
        const entityType = selection.group;

        if (!entityType) {
            console.error('Entity type is not provided');
            return;
        }

        if (!this.selectEntityOptions[entityType]) {
            this.selectEntityOptions[entityType] = [];
        }

        const isOptionExists = this.selectEntityOptions[entityType].some(
            item => item.value === selection.value
        );

        if (isOptionExists) return;

        const customFilterKey = this.searchEntityTypes?.[entityType]?.filterKey;

        this.selectEntityOptions[entityType].push({
            label: selection.label ?? selection.value,
            value: selection.value,
            checked: true,
            customFilterKey: customFilterKey ?? this.filterKey,
        });

        this.updateSelectOptions(filterChangeCheck);
    }

    private mapToCheckboxGroupFilterOptions(
        options: FilterOption[]
    ): CheckboxGroupFilterOption[] {
        return options.map(o => ({
            label: o.label,
            value: o.value,
            checked: false,
            customFilterKey: this.filterKey,
        }));
    }

    private updateSelectOptions(filterChangeCheck = true) {
        this._selectOptions = Object.values(this.selectEntityOptions).flat();
        this.checkAllOptionsAreChecked();
        if (filterChangeCheck) {
            this.filterChangedChecks();
        }
    }

    private resetOrClearOptions(resetAll: boolean) {
        if (!this.searchFacade && resetAll) {
            this.allChecked = false;
            this.indeterminate = false;
            this.updateAllOptions(false);
            return;
        } else if (!this.searchFacade && !resetAll) {
            this.indeterminate = false;

            if (this.initialSelection === 'all') {
                this.allChecked = true;
                this.updateAllOptions(true);
            } else {
                this.allChecked = false;
                this.updateAllOptions(false);
            }
            return;
        }

        this.allChecked = false;
        this.indeterminate = false;

        if (resetAll) {
            this.clearAllOptions();
        } else {
            this.updateAllOptions(false);
        }

        this.validate();
    }

    private getSelectedValuesMetadata() {
        this.cleanUnselectedOptionsFromFilters(this._selectOptions);

        const selectedFilters = this._selectOptions.filter(
            filter => filter.checked
        );

        return selectedFilters.map(filter => filter.value.toString());
    }

    private getSelectedEntitiesMetadata() {
        const selectedEntities: MultiEntityFilterProps[] = [];
        for (const key in this.selectEntityOptions) {
            const selectedFilters = this.selectEntityOptions[key].filter(
                filter => filter.checked
            );

            selectedEntities.push({
                filterKey: this.searchEntityTypes?.[key]?.filterKey ?? '',
                value: selectedFilters.map(filter => filter.value.toString()),
            });
        }

        return selectedEntities;
    }

    private transformFilterMetadataToFilterOptions(
        metadata: FilterMetadata
    ): void {
        if (!metadata?.value) return;

        if (metadata.value && !this.searchFacade && !this.searchEntityTypes) {
            this._selectOptions.forEach(option => {
                option.checked = (metadata.value as string[]).includes(
                    option.value.toString()
                );
            });
        }

        if (this.searchEntityTypes) {
            this.fetchOptionsForEntityTypes(metadata);
        }

        if (!this.searchEntityTypes && this.searchFacade) {
            this.fetchOptionsForFacade(metadata.value as string[]);
        }

        this.checkAllOptionsAreChecked();
    }
}
