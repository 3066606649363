import {
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    FrameworkColumn,
    getTableRowValue,
    IEntity,
    IManageEntityFacade,
    IModalContent,
    IPagedResult,
    IQueryParams,
    ISearchFacade,
    ISearchFields,
    NotificationType,
    QueryFilters,
    SafeAny,
    SearchStrategy,
    SortOrder,
    TableAction,
    TableColumn,
    TableOptions,
    TableQueryParams,
    TableRow,
    TableSelection,
    TableState,
    TableValueType,
    ToastMessage,
    ToastType,
} from '@pf/shared-common';
import { NzTableComponent, NzTableQueryParams } from 'ng-zorro-antd/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    bufferCount,
    catchError,
    combineLatest,
    concatMap,
    debounceTime,
    filter,
    first,
    from,
    of,
    switchMap,
    tap,
} from 'rxjs';

import { NotificationsService } from '../../notifications';
import { TableService } from '../services/table.service';
import { ValueFormatterService } from '@pf/shared-services';
import { ModalService } from '../../../services/modal.service';
import { NavigationStart, Router } from '@angular/router';
import { asArraySafe } from '@pf/shared-utility';
import { FilterService } from '../services/filter.service';

const defaultTypeWidths: Partial<Record<TableValueType, string>> = {
    date: '140px',
    time: '140px',
    datetime: '200px',
};

const DefaultSortFn =
    (column: TableColumn<SafeAny>) =>
    (
        a: { data: SafeAny; index: number },
        b: { data: SafeAny; index: number }
    ) => {
        const aValue = getTableRowValue(column, a.data);
        const bValue = getTableRowValue(column, b.data);
        if (aValue === bValue) {
            return 0;
        }
        if (!aValue) {
            return -1;
        }
        if (!bValue) {
            return 1;
        }
        if (typeof aValue === 'string' || typeof bValue === 'string') {
            return (aValue as string).localeCompare(bValue as string);
        }
        return aValue - bValue;
    };

@UntilDestroy()
@Component({
    selector: 'pf-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    providers: [TableService, FilterService],
})
export class TableComponent<T extends IEntity> implements OnInit {
    private _tableData: T[] = [];
    private _columns!: TableColumn<T>[];
    private _editable = false;
    private _advancedFilterActive = false;

    @ViewChild('table', { static: true }) table!: NzTableComponent<T>;
    @Input() searchFacade: ISearchFacade<T> | null = null;
    @Input() manageFacade: IManageEntityFacade | null = null;
    @Input() pageSize = 10;

    @Input() set data(data: IPagedResult<T> | T[] | undefined) {
        if (!data) {
            return;
        }
        this.setupTableData(data);
    }

    @Input() loading = false;

    @Input() set columns(columns: TableColumn<T>[]) {
        this._columns = columns;
        this.setupFrameworkColumns(columns);
    }

    get columns() {
        return this._columns;
    }

    @Input() options: Partial<TableOptions<T>> | null = {};
    @Input() actions: TableAction<T>[] = [];
    @Input() title = '';
    @Input() searchField: ISearchFields<T> | Array<SafeAny> | null = [];
    @Input() searchStrategy: SearchStrategy | undefined = undefined;
    @Input() disableAutoPageSize = false;
    @Input() width?: string;
    @Input() expandable = false;
    @Input() hasAdvancedFilters = false;

    @Input() set editable(editable: boolean) {
        if (editable) {
            this.reset();
            this.setupEditableTable();
            this.setupTableData([...this._tableData]);
        }
        this._editable = editable;
    }

    get editable() {
        return this._editable;
    }

    get editedTableData() {
        return this.tableService.editedTableData;
    }

    @Input() persistEditedData = false;
    @Input() manualSave = false;
    @Input() singleExpand = false;
    @Input() expandToContent = false;
    @Input() expandRowRef?: TemplateRef<SafeAny>;
    @Input() estimatedRowHeight = 48;
    @Input() pageSizeOptions: number[] = [];
    @Input() tableKey?: string;
    @Input() showExport = true;
    @Input() showSelection: boolean | null = false;

    @Input() confirmationTemplate?: (data: T[]) => IModalContent;

    @Output() queryParamsChanged = this.tableService.queryParamsChanged$;
    @Output() dataChanged = new EventEmitter<TableRow<T>[]>();
    @Output() tableReset = this.tableService.reset$;
    @Output() tableRowEdited = this.tableService.tableRowEdited$;

    size: 'middle' | 'small' | 'default' = 'default';
    exporting = false;

    get scrollSettings() {
        return {
            x: this.width || '100%',
        };
    }

    get serverRendered() {
        return !!this.searchFacade?.search$;
    }

    get isFiltered() {
        return (
            !!this.tableService.queryParams.searchValue ||
            Object.values(this.tableService.queryParams.filters || {}).some(
                filter => !!filter
            )
        );
    }

    get showClearFilters(): boolean {
        return (
            !this._advancedFilterActive ||
            (!!this.searchField &&
                this.columns?.some(column => {
                    return column.showCheckbox || column.search;
                }))
        );
    }

    get queryParams(): TableQueryParams {
        return this.tableService.queryParams;
    }

    get selection(): TableSelection {
        return {
            checked: this.tableService.checked,
            indeterminate: this.tableService.indeterminate,
            selectedRows: this.tableService.selectedRows$.value,
            queryParams: this.tableService.queryParams,
            selectedRowCount: this.tableService.selectedRowCount,
        };
    }

    get showSearch() {
        return (
            !this._advancedFilterActive &&
            !!this.searchField &&
            !!this.searchField.length
        );
    }

    @HostBinding('class.has-pagination')
    showPagination = false;

    constructor(
        public tableService: TableService,
        public filterService: FilterService,
        private valueFormatterService: ValueFormatterService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.setupQueryParamsNotifier();
        this.setupTableSizing();
        this.setupEditableTable();

        this.reset();
        this.setInitialState();
        this.setAdvancedFilterState();

        this.tableService.initialize();
    }

    headerTrackBy(_: number, column: FrameworkColumn) {
        return `${column.field}_${column.sortOrder}`;
    }

    onQueryParamsChange(params: NzTableQueryParams): void {
        const { pageSize, sort } = params;
        let pageIndex = params.pageIndex;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        const sortOrder =
            ((currentSort && currentSort.value) as SortOrder) || null;
        if (
            sortField !== this.tableService.queryParams?.sortField ||
            sortOrder !== this.tableService.queryParams?.sortOrder
        ) {
            pageIndex = 1;
        }

        this.tableService.updateTableQueryParams({
            pageSize,
            pageIndex,
            sortField,
            sortOrder,
            serverRendered: this.serverRendered,
        });
    }

    onTableHeaderChecked(column: FrameworkColumn, checked: boolean) {
        const checkboxes: Record<string, boolean> = {
            ...(this.tableService.queryParams?.checkboxes || {}),
        };
        checkboxes[column.field] = checked;

        this.pageIndexChanged(1);
        this.tableService.updateTableQueryParams({
            pageIndex: 1,
            checkboxes,
        } as TableQueryParams);
    }

    onAllRowsChecked(checked: boolean) {
        this.tableService.allRowsChecked$.next(checked);
    }

    reset() {
        if (this.hasAdvancedFilters) {
            return;
        }

        this.tableService.tableState = {
            ...this.tableService.tableState,
            columns: (this.tableService.tableState?.columns || []).map(
                column => {
                    return {
                        ...column,
                        sortOrder: null,
                        searchValue: '',
                        checked: false,
                    };
                }
            ),
            pageIndex: 1,
        } as TableState;
        this.pageIndexChanged(1);
        this.tableService.reset({ pageSize: this.pageSize });
    }

    onSearchChanged(searchQuery: string) {
        if (!this.searchField || searchQuery?.length < 1) {
            return;
        }
        this.tableService.unloadVerifier(() => {
            this.searchByText(searchQuery);
        });
    }

    onColumnSearchChanged(column: FrameworkColumn) {
        if (column.serverSearch) {
            this.tableService.updateFilters({
                [column.key || column.field]: column.searchValue,
            });
        } else {
            this.filterData();
        }
    }

    exportHandler(rawExport = false) {
        this.exporting = true;
        const loadingMessageRef = this.notificationsService.create({
            type: NotificationType.ToastMessage,
            toastType: ToastType.loading,
            message: 'Gathering data. Download will begin shortly.',
        } as ToastMessage);
        this.searchFacade
            ?.export$({
                rawExport,
                columns: this.columns,
                fileName: `${this.title}.csv`,
                params: this.tableService.queryParams as IQueryParams<T>,
            })
            .subscribe(result => {
                this.notificationsService.remove(loadingMessageRef);
                this.notificationsService.create({
                    type: NotificationType.ToastMessage,
                    toastType:
                        result === true ? ToastType.success : ToastType.error,
                    message:
                        result === true
                            ? 'Download complete.'
                            : result === false
                              ? 'Export failed!'
                              : result,
                } as ToastMessage);
                this.exporting = false;
            });
    }

    private setAdvancedFilterState() {
        this.filterService.filterDisplay$
            .pipe(untilDestroyed(this))
            .subscribe(filterDisplay => {
                this._advancedFilterActive = filterDisplay;
            });
    }

    private searchByText(searchQuery: string) {
        const filters: Record<string, string | string[]> = {
            ...(this.tableService.queryParams?.filters || {}),
        };

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        filters[this.searchField!.toString()] = searchQuery;
        const pageIndex =
            searchQuery && this.tableService.tableState.rows.length
                ? 1
                : this.tableService.tableState.pageIndex;
        this.pageIndexChanged(pageIndex);
        this.tableService.updateTableQueryParams({
            pageIndex: pageIndex,
            filters,
            searchStrategy: this.searchStrategy,
            searchValue: searchQuery,
            searchFields: this.searchField as string[],
        });
    }

    private setupFrameworkColumns(columns: TableColumn<T>[]) {
        const frameworkColumns: FrameworkColumn[] = columns.map(
            (col, index) => {
                return {
                    index: index,
                    field: col.field as string,
                    headerName: col.headerName,
                    sortOrder: col.sortOrder || null,
                    sortDirections: col.showSort
                        ? ['ascend', 'descend', null]
                        : [null],
                    sortFn: this.serverRendered || DefaultSortFn(col),
                    search: !!col.search && !col.component,
                    searchValue: '',
                    listFilterFacade: col.listFilterFacade,
                    additionalListFilters: col.additionalListFilters,
                    key: col.serverKey,
                    serverSearch: !!col.serverSearch,
                    showCheckbox: !!col.showCheckbox,
                    checkboxLabel: col.checkboxLabel || '',
                    checkedChange: col.checkedChange,
                    type: col.type || 'string',
                    width: this.calcColumnWidth(col.width, col.type),
                    valueFormatter:
                        col.valueFormatter ||
                        this.valueFormatterService.getFormatter(col.type),
                    component: col.component,
                    componentProps: col.componentProps,
                    editable: !!col.editable,
                    editableDataSource$: col.editableDataSource$,
                    editableDataSelectedOption: col.editableDataSelectedOption,
                    isEditableDataGrouped: col.isEditableDataGrouped,
                    editValueSetter: col.editValueSetter,
                    editCellInitialized: col.editCellInitialized,
                    defaultValue: col.defaultValue,
                } as FrameworkColumn;
            }
        );
        this.tableService.tableState = {
            ...this.tableService.tableState,
            columns: frameworkColumns,
        };
    }

    calcColumnWidth(width?: number, type?: TableValueType): string | null {
        if (!width && !type) {
            return null;
        }
        if (width) {
            return width + 'px';
        }
        return defaultTypeWidths[type as TableValueType] || null;
    }

    refresh() {
        this.tableService.unloadVerifier(() => {
            this.loading = true;
            (this.searchFacade as ISearchFacade<T>)
                .reload$()
                .pipe(
                    untilDestroyed(this),
                    first(),
                    catchError(() => of(false)),
                    tap(() => (this.loading = false))
                )
                .subscribe();
        });
    }

    saveEditedData() {
        if (!this.confirmationTemplate) {
            this.saveChanges();
            return;
        }
        of(this.confirmationTemplate(this.editedTableData)).subscribe(
            (template: string | SafeAny | TemplateRef<SafeAny>) => {
                const modalRef = {
                    ...template,
                    onOk: () => this.saveChanges(),
                };
                this.modalService.warning(modalRef);
            }
        );
    }

    private saveChanges() {
        if (this.manualSave) {
            return;
        }

        if (!this.manageFacade) {
            throw new Error(
                'A manage facade is required to save changes for editable tables.'
            );
        }

        const dtoObservables = asArraySafe(this.editedTableData).map(dto => {
            return this.manageFacade?.save$(dto as IEntity, {
                emitEvent: false,
            });
        });

        if (dtoObservables.length) {
            from(dtoObservables)
                .pipe(
                    bufferCount(10),
                    concatMap(batch => combineLatest(batch)),
                    catchError(err => {
                        throw `Error saving changes: ${err}`;
                    })
                )
                .subscribe({
                    next: _ => {
                        this.tableService.submit();
                        this.refresh();

                        this.notificationsService.create({
                            type: NotificationType.ToastMessage,
                            toastType: ToastType.success,
                            message: `${this.title} Data Successfully Updated.`,
                        } as ToastMessage);
                    },
                    error: err => console.log(err),
                });
        }
    }

    private setupTableData(data: IPagedResult<T> | T[]) {
        if (!data) {
            this.setData([]);
            return;
        }
        if (this.serverRendered) {
            const tableData = data as IPagedResult<T>;
            this.tableService.tableState = {
                ...this.tableService.tableState,
                pageIndex: tableData.currentPage || 1,
                total: tableData.totalRecords || 0,
            };
            this.setData(tableData.data);
        } else {
            const tableData = data as T[];
            this.setData(tableData);
            this.pageIndexChanged(1);
            this.tableService.tableState = {
                ...this.tableService.tableState,
                pageIndex: 1,
                total: tableData.length,
            };
        }
    }

    private setupEditableTable() {
        if (!this.editable || this.tableService.isTableEditable) {
            return;
        }
        if (!this.tableKey) {
            throw new Error('Table key is required for editable tables');
        }
        this.tableService.initiateEditableTableState(
            this.tableKey,
            this.persistEditedData
        );

        this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
            if (
                event instanceof NavigationStart &&
                this.editedTableData.length
            ) {
                this.router
                    .navigateByUrl(this.router.routerState.snapshot.url, {
                        skipLocationChange: true,
                    })
                    .then(() => {
                        this.tableService.unloadVerifier(() =>
                            this.router.navigateByUrl(event.url)
                        );
                    });
            }
        });
    }

    @HostListener('window:resize', ['$event'])
    private setupTableSizing() {
        this.setTableSize();
        this.setPageSize();
    }

    private setTableSize() {
        if (window.innerWidth < 1200) {
            this.size = 'small';
        }
    }

    private setPageSize() {
        if (this.disableAutoPageSize) {
            return;
        }

        if (this.pageSizeOptions.length > 0) {
            this.pageSize = this.pageSizeOptions[0];
            return;
        }

        this.pageSize = Math.max(
            Math.floor((window.innerHeight - 100) / this.estimatedRowHeight) -
                1,
            3
        );
    }

    private setupQueryParamsNotifier() {
        this.tableService.queryParamsChanged$
            .pipe(
                untilDestroyed(this),
                tap(() => (this.loading = true)),
                filter(
                    _ =>
                        !this.hasAdvancedFilters ||
                        this.filterService.initialized
                ),
                debounceTime(this.hasAdvancedFilters ? 0 : 700),
                switchMap(params => {
                    let searchParams = params as TableQueryParams<T>;
                    if (this.options?.additionalQueryParams) {
                        const { filters, ...other } =
                            this.options.additionalQueryParams(searchParams);
                        searchParams = {
                            ...searchParams,
                            ...other,
                            filters: {
                                ...(searchParams.filters || {}),
                                ...filters,
                            } as QueryFilters<T>,
                        };
                    }
                    return (this.searchFacade as ISearchFacade<T>).search$(
                        searchParams
                    );
                }),
                tap(() => (this.loading = false)),
                tap(
                    () =>
                        (this.tableService.tableState.lastUpdated = new Date())
                ),
                catchError(error => {
                    console.log(error);
                    // api error handled by interceptor
                    this.loading = false;
                    return of([]);
                })
            )
            .subscribe();
        this.searchFacade?.currentPage$
            .pipe(untilDestroyed(this))
            .subscribe(result => (this.data = result));
    }

    private toTableRows(data: T[]): TableRow<T>[] {
        const currentCheckedRows = this.tableService.selectedRows$.value;
        return data
            .map(
                (row, index) =>
                    new TableRow(index, row, this.expandable, this.options)
            )
            .map(x => {
                x.checked = currentCheckedRows.some(
                    y => y.data.id == x.data.id
                );
                x.editable = this.editable;
                return x;
            });
    }

    private setData(data: T[]) {
        this._tableData = data;
        this.filterData();
        this.dataChanged.emit(this.tableService.tableState.rows);
        this.showPagination =
            (this._tableData?.length &&
                this._tableData.length >= this.pageSize) ||
            this.tableService.tableState.total >= this.pageSize;
    }

    private filterData() {
        const searchColumns = this.tableService.tableState.columns.filter(
            c => c.searchValue && !c.serverSearch
        );
        this.tableService.tableState = {
            ...this.tableService.tableState,
            rows: this.toTableRows(
                (this._tableData || []).filter((row: SafeAny) => {
                    return searchColumns.every(column => {
                        const columnValue = getTableRowValue(column, row);
                        const formattedValue = column.valueFormatter
                            ? column.valueFormatter(columnValue, row)
                            : columnValue;
                        return (
                            formattedValue &&
                            formattedValue
                                .toLowerCase()
                                .indexOf(column.searchValue.toLowerCase()) > -1
                        );
                    });
                })
            ),
        };
    }

    pageIndexChanged($event: number) {
        if ($event && localStorage && this.tableKey) {
            localStorage.setItem(
                `${this.tableKey}-table-page-index`,
                $event.toString()
            );
        }
    }

    pageSizeChanged($event: number) {
        if ($event && localStorage && this.tableKey) {
            localStorage.setItem(
                `${this.tableKey}-table-page-size`,
                $event.toString()
            );
        }
    }

    private setInitialState() {
        if (!localStorage || !this.tableKey) {
            return;
        }

        const pageSize = localStorage.getItem(
            `${this.tableKey}-table-page-size`
        );
        if (pageSize) {
            this.pageSize = Number(pageSize);
        }

        const pageIndex = localStorage.getItem(
            `${this.tableKey}-table-page-index`
        );
        if (pageIndex) {
            this.tableService.tableState = {
                ...this.tableService.tableState,
                pageIndex: Number(pageIndex),
            };
        }
    }
}
