import {
    CustomFieldViewModel,
    CustomFieldViewModelCreate,
} from '@control-tower/platform-core';

import { ICustomField } from '@pf/shared-common';

export type CustomFieldRead = CustomFieldViewModel & ICustomField;
export type CustomFieldCreate = CustomFieldViewModelCreate;

export interface ICustomFieldDto extends ICustomField {
    key: string;
}

export class CustomFieldDto implements ICustomFieldDto {
    constructor(data: Partial<ICustomFieldDto>) {
        Object.assign(this, data);
    }

    id!: string;
    key!: string;
    value?: string | null;
}
