import { AbstractEntityMapper } from '@pf/shared-services';
import {
    LoadWorkflowCreate,
    LoadWorkflowDto,
    LoadWorkflowRead,
    LoadWorkflowSearchParams,
} from '../../entities/load-workflow.dto';
import { Injectable } from '@angular/core';
import { PlatformLoadsMapper } from '../platform-loads.mapper';
import { LoadWorkflowMetadataMap } from './load-workflow.mapping-profile';

@Injectable()
export class LoadWorkflowMapper extends AbstractEntityMapper<
    LoadWorkflowRead,
    LoadWorkflowCreate,
    LoadWorkflowDto,
    LoadWorkflowSearchParams
> {
    constructor() {
        super(
            LoadWorkflowDto,
            PlatformLoadsMapper,
            LoadWorkflowMetadataMap.symbols
        );
    }
}
