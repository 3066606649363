import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    OnInit,
} from '@angular/core';
import {
    ChangeOperation,
    ChangeTrackingValueFormatter,
    EntityChangeTrackingDto,
    IEntity,
    SafeAny,
} from '@pf/shared-common';
import { NzTreeFlatDataSource, NzTreeFlattener } from 'ng-zorro-antd/tree-view';
import { isArray, startCase } from 'lodash-es';

import { FlatTreeControl } from '@angular/cdk/tree';
import { ValueFormatterService } from '@pf/shared-services';

@Component({
    selector: 'platform-change-tree',
    templateUrl: './change-tree.component.html',
    styleUrls: ['./change-tree.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeTreeComponent implements OnInit {
    @Input() autoExpand = false;
    @Input() entityName!: string;
    private _entityChange!: EntityChangeTrackingDto<IEntity>;
    private treeData: TreeNode[] = [];

    private valueFormatterService = inject(ValueFormatterService);
    private transformer = () => {
        return (node: TreeNode, level: number): FlatNode => {
            return {
                expandable: !!node.children && node.children.length > 0,
                prop: startCase(node.prop),
                value: this.customValueTransformer
                    ? this.customValueTransformer(node.prop, node.value)
                    : this.valueFormatterService.autoFormat(node.value),
                level,
                relatedChange: node.relatedChange,
            };
        };
    };

    @Input() set entityChange(changeVm: EntityChangeTrackingDto<IEntity>) {
        this._entityChange = changeVm;
        if (!changeVm) {
            return;
        }
        const entity = changeVm.entity as SafeAny;
        this.treeData = this.buildChangeTree(
            {
                [this.entityName]: entity,
            },
            changeVm.changes || []
        );

        this.setTreeData();
    }

    get entityChange() {
        return this._entityChange;
    }

    @Input() customValueTransformer?: ChangeTrackingValueFormatter;

    treeControl = new FlatTreeControl<FlatNode>(
        node => node.level,
        node => node.expandable
    );

    treeFlattener?: NzTreeFlattener<TreeNode, FlatNode, FlatNode>;

    dataSource?: NzTreeFlatDataSource<TreeNode, FlatNode, FlatNode>;
    showChangeData = false;

    ngOnInit() {
        this.treeFlattener = new NzTreeFlattener(
            this.transformer(),
            node => node.level,
            node => node.expandable,
            node => node.children
        );
        this.dataSource = new NzTreeFlatDataSource(
            this.treeControl,
            this.treeFlattener
        );
        this.setTreeData();
    }

    hasChild = (_: number, node: FlatNode): boolean => node.expandable;

    openChangeData() {
        this.showChangeData = true;
    }

    closeChangeData() {
        this.showChangeData = false;
    }

    private setTreeData() {
        if (!this.dataSource) {
            return;
        }
        this.dataSource.setData(this.treeData);
        if (this.autoExpand) {
            this.treeControl.expand(this.treeControl.dataNodes[0]);
        }
    }

    private buildChangeTree(
        value: SafeAny,
        changes: ChangeOperation[],
        path = '',
        level = 1,
        arrayItemKeyPrefix = ''
    ) {
        const data: TreeNode[] = [];
        for (const key in value) {
            const keyPath = level > 1 ? `${path}/${key}` : '';
            const keyValue = value[key];
            const node: TreeNode = {
                prop: arrayItemKeyPrefix
                    ? `${arrayItemKeyPrefix} ${+key + 1}`
                    : key,
                value: 'None',
            };
            if (keyValue === null || keyValue === undefined) {
                // no action
            } else if (typeof keyValue === 'object') {
                const keyValueIsArray = isArray(keyValue);
                const prefix = keyValueIsArray ? getArrayItemPrefix(key) : '';
                node.children = this.buildChangeTree(
                    keyValue,
                    changes,
                    keyPath,
                    level + 1,
                    prefix
                );
                if (keyValueIsArray) {
                    node.children = this.pushAddChanges(
                        changes,
                        node.children,
                        keyPath,
                        keyValue,
                        prefix,
                        level
                    );
                }
            } else {
                node.value = keyValue;
            }
            this.addRelatedChange(changes, keyPath, node);
            data.push(node);
        }
        return data;
    }

    private pushAddChanges(
        changes: ChangeOperation[],
        children: TreeNode[],
        keyPath: string,
        value: SafeAny[],
        prefix: string,
        level: number
    ): TreeNode[] {
        const additionChanges = changes.filter(
            change =>
                change.op?.toLocaleLowerCase() === 'add' &&
                change.path
                    ?.toLowerCase()
                    .startsWith(keyPath.toLocaleLowerCase())
        );
        if (additionChanges.length === 0) {
            return children;
        }
        return [
            ...children,
            ...additionChanges.map((change, index) => {
                const newIndex = value.length + 1 + index;
                return {
                    prop: `${prefix} ${newIndex}`,
                    value: '',
                    children: this.buildChangeTree(
                        change.value,
                        changes,
                        keyPath,
                        level + 1,
                        ''
                    ),
                    relatedChange: {
                        type: 'add',
                        value: change.value,
                    },
                } as TreeNode;
            }),
        ] as TreeNode[];
    }

    private addRelatedChange(
        changes: ChangeOperation[],
        keyPath: string,
        node: TreeNode
    ) {
        const relatedChange = changes.find(
            change =>
                change.path?.toLocaleLowerCase() === keyPath.toLocaleLowerCase()
        );
        if (relatedChange) {
            node.relatedChange = {
                type: relatedChange.op || 'replace',
                value: relatedChange.value,
            };
        }
    }
}

const getArrayItemPrefix = (key: string) => {
    let prefix = key;
    if (key.endsWith('List')) {
        prefix = key.replace('List', '');
    } else if (key.endsWith('s')) {
        prefix = key.substring(0, key.length - 1);
    }
    return startCase(prefix);
};

type RelatedChange = {
    value: string;
    type: 'add' | 'remove' | 'replace' | string;
} | null;

type TreeNode = {
    children?: TreeNode[];
    prop: string;
    value: string;
    relatedChange?: RelatedChange;
};

type FlatNode = {
    expandable: boolean;
    prop: string;
    value: SafeAny;
    level: number;
    relatedChange?: RelatedChange;
};
