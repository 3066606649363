import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { PFLoadEntities } from '@pf/shared-common';
import { LoadWorkflowRead } from '../../entities/load-workflow.dto';

@Injectable()
export class LoadWorkflowStore extends AbstractEntityStore<LoadWorkflowRead> {
    constructor() {
        super(PFLoadEntities.LoadWorkflow);
    }
}
