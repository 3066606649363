<pf-table
    [columns]="columns"
    [title]="entityName + ' Changes'"
    [searchFacade]="changeTrackingFacade"
    [expandable]="true"
    [expandRowRef]="expandRowRef"
    [searchField]="searchField"
    (dataChanged)="onDataChanged()">
    <ng-template #expandRowRef let-row="row">
        <ng-container *ngIf="row?.data">
            <platform-change-tree
                [autoExpand]="true"
                [entityName]="entityName"
                [entityChange]="row.data"></platform-change-tree>
        </ng-container>
    </ng-template>
</pf-table>
