import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { FormControl } from '@angular/forms';
import {
    DynamicControlConfig,
    DynamicControlSelectConfig,
} from '@pf/shared-common';
import { DateService } from '@pf/shared-services';
import {
    BaseControlComponent,
    PfStyle,
} from '../../../components/base-control/base-control.component';
import { isObservable } from 'rxjs';

interface DynamicControlForm {
    value: string | number | boolean | Date | null;
}

@Component({
    selector: 'pf-dynamic-control',
    templateUrl: './dynamic-control.component.html',
    styleUrls: ['./dynamic-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [subformComponentProviders(DynamicControlComponent)],
})
export class DynamicControlComponent {
    private dateProvider = inject(DateService);
    private _config!: DynamicControlConfig;

    @ViewChild(BaseControlComponent, { static: false })
    baseControl!: BaseControlComponent;

    /**
     * @internal
     */
    form = createForm<string, DynamicControlForm>(this, {
        formType: FormType.SUB,
        formControls: {
            value: new FormControl(null),
        },
        toFormGroup: (value: string) => {
            return { value: this.castValue(value) };
        },
        fromFormGroup: (formValue: DynamicControlForm) => {
            return this.toStringValue(formValue.value);
        },
    });

    @Input()
    pfStyle: PfStyle = 'default';

    /**
     * The `config` input is used to configure the dynamic control.
     */
    @Input() set config(config: DynamicControlConfig) {
        this._config = config;
        this.valueControl.setValidators(config.validators || []);

        setTimeout(() => {
            if (!this.valueControl.value && config.defaultValue) {
                if (isObservable(config.defaultValue)) {
                    config.defaultValue.subscribe(df => {
                        this.valueControl.setValue(df);
                        this.form.formGroup.controls.value.updateValueAndValidity();
                    });
                } else {
                    this.valueControl.setValue(config.defaultValue);
                }
            }
            this.cdr.markForCheck();
        });
    }

    get config() {
        return this._config;
    }

    /**
     * @internal
     */
    get selectConfig() {
        return this.config as DynamicControlSelectConfig;
    }

    /**
     * @internal
     */
    get valueControl() {
        return this.form?.formGroup?.controls?.value as FormControl;
    }

    @Output()
    valueChange = new EventEmitter<string | number | boolean | null>();

    constructor(
        private cdr: ChangeDetectorRef,
        private dateService: DateService
    ) {}

    private castValue(value: string): string | Date | number | boolean | null {
        if (!this.config) {
            throw new Error('No config provided to DynamicControlComponent');
        }

        if (value === undefined || value === null) {
            return this.config.defaultValue?.toString() ?? null;
        }

        switch (this.config.type) {
            case 'number':
                return +value;
            case 'boolean':
                return value === 'true';
            case 'date':
                return this.dateProvider.parseServerDateOnly(value as string);
            case 'datetime':
                return this.dateProvider.formatDateTime(value as string);
            default:
                return value;
        }
    }

    private toStringValue(
        value: string | number | boolean | Date | null
    ): string {
        if (value === undefined || value === null) {
            return '';
        }
        switch (this.config.type) {
            case 'date':
                return this.dateService.formatServerDateOnly(value as string);
            case 'datetime':
                return this.dateService.formatDateToISO(value as string);
            default:
                return value.toString();
        }
    }
}
