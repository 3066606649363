<div>
    <platform-associated-contact-table
        [contacts$]="data$"
        [customFieldKey]="componentData.customFieldKey"
        [customFieldTitle]="componentData.customFieldTitle"
        [tableActions]="componentData.tableActions"
        [baseEntityType]="componentData.baseEntityType"
        [baseEntityPlatform]="componentData.baseEntityPlatform"
        [canAdd]="true"
        (addAction)="componentData.addAction()"
        (tableReset)="searchControl.setValue('')">
        <pf-input
            id="contact-search"
            placeholder="Search Contacts"
            inputType="search"
            [control]="searchControl"></pf-input>
    </platform-associated-contact-table>
</div>
