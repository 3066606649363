import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { LaneCarrierRead } from '../../entities/lane-carrier.dto';
import { PFLoadEntities } from '@pf/shared-common';

@Injectable()
export class LaneCarrierStore extends AbstractEntityStore<LaneCarrierRead> {
    constructor() {
        super(PFLoadEntities.LaneCarrier);
    }
}
