import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    CarrierCreate,
    CarrierRead,
    CarrierSearchParams,
} from '../../entities/carrier.dto';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    IPagedResult,
    PFCoreEntities,
} from '@pf/shared-common';

import { CarriersService } from '@control-tower/platform-core';
import { CarrierStore } from './carrier.store';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { Operation } from 'fast-json-patch';
import { ContactStore, IContact } from '@pf/shared/util-platform';

@Injectable()
export class CarrierDataService extends AbstractDataService<
    CarrierRead,
    CarrierCreate
> {
    protected EntityName = PFCoreEntities.Carrier;

    constructor(
        private carrierService: CarriersService,
        store: CarrierStore,
        private contactStore: ContactStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: CarrierCreate): Observable<CarrierRead> {
        return this.carrierService.carrierCreate({
            carrierViewModelCreateBody: body,
        }) as Observable<CarrierRead>;
    }

    protected deleteObs$(id: string): Observable<CarrierRead> {
        return this.carrierService.carrierDelete({
            id,
        }) as Observable<CarrierRead>;
    }

    protected undoDeleteObs$(id: string): Observable<CarrierRead> {
        return this.carrierService.carrierUndoDelete({
            id,
        }) as Observable<CarrierRead>;
    }

    protected getObs$(id: string): Observable<CarrierRead> {
        return this.carrierService.carrierReadById({
            id,
        }) as Observable<CarrierRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<CarrierRead> {
        return (
            this.carrierService.carrierUpdatePatch({
                id,
                operation: operations,
            }) as Observable<CarrierRead>
        ).pipe(
            map(x => {
                // TODO: figure out a better way to fix this
                // maybe always return booleans from api
                x.shouldReceiveNotifications ??= false;
                return x;
            })
        );
    }

    protected searchObs$(
        searchParams: CarrierSearchParams
    ): Observable<IPagedResult<CarrierRead>> {
        return this.carrierService.carrierSearch(searchParams) as Observable<
            IPagedResult<CarrierRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<CarrierRead>>> {
        return this.carrierService.carrierGetChanges(
            searchParams
        ) as Observable<IPagedResult<EntityChangeTrackingDto<CarrierRead>>>;
    }

    getContacts$(id: string): Observable<IContact[]> {
        return this.carrierService
            .v1CarriersIdContactsGet({
                id,
            })
            .pipe(
                map(response => response.contacts as IContact[]),
                tap(contacts => {
                    contacts.forEach(contact =>
                        this.contactStore.add(contact, false)
                    );
                })
            ) as Observable<IContact[]>;
    }
}
