import { AbstractEntityMapper } from '@pf/shared-services';
import {
    LaneRateCreate,
    LaneRateDto,
    LaneRateRead,
    LaneRateSearchParams,
} from '../../entities/lane-rate.dto';
import { Injectable } from '@angular/core';
import { LaneRateMetadataMap } from './lane-rate.mapping-profile';
import { PlatformLoadsMapper } from '../platform-loads.mapper';

@Injectable()
export class LaneRateMapper extends AbstractEntityMapper<
    LaneRateRead,
    LaneRateCreate,
    LaneRateDto,
    LaneRateSearchParams
> {
    constructor() {
        super(LaneRateDto, PlatformLoadsMapper, LaneRateMetadataMap.symbols);
    }
}
