import { IQueryParams, QueryFilters, SortOrder } from '../models/QueryParams';
import { NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';

import { SafeAny } from './SafeTypes';
import { TextType } from './text.types';
import { Type } from '@angular/core';
import {
    ExportValueFormatterFn,
    ValueFormatterFn,
} from './value-formatter.types';
import { IEntity } from '../models/IEntity';
import { IColumnSearchFacade } from '../interfaces/IColumnSearchFacade';
import { Observable, Subject } from 'rxjs';
import { SelectOption } from './select.types';
import { AbstractControl } from '@angular/forms';
import { IFilterConfig } from '../interfaces/IFilterConfig';

export interface TableState {
    pageIndex: number;
    total: number;
    rows: TableRow[];
    columns: FrameworkColumn[];
    lastUpdated: Date;
}

export interface TableAction<TRowData extends IEntity = SafeAny> {
    tooltip?: string;
    name: string;
    textType: TextType;
    disable?: boolean;
    hide?: boolean;
    width?: number;
    onClick: (row: TableRow<TRowData>, index: number) => void;
    onRowChange?: (
        row: TableRow<TRowData>,
        action: TableAction<TRowData>
    ) => void;
}

export type TableValueType =
    | 'string'
    | 'number'
    | 'date'
    | 'time'
    | 'datetime'
    | 'dateNoTz'
    | 'checkbox'
    | 'currency'
    | 'select';

export interface ITableCellValueRenderer {
    value?: SafeAny;
    column: FrameworkColumn;
    row: SafeAny;
}

export interface TableOptions<TRowData extends IEntity = SafeAny> {
    rowClassRules?: Record<string, (row: TableRow<TRowData>) => boolean>;
    additionalQueryParams?: (
        params: Readonly<TableQueryParams<TRowData>>
    ) => Readonly<TableQueryParams<TRowData>>;
    canRowExpand?: (row: TRowData) => boolean;
    getChildNodes?: (row: TRowData) => TRowData[];
}

export class TableRow<TRowData extends IEntity = SafeAny> {
    private _classes: string[] = [];
    private _data: TRowData;
    children?: TableRow<TRowData>[];
    expanded = false;
    checked = false;
    private readonly _updated$ = new Subject<void>();
    private readonly _manualEdit$ = new Subject<Partial<TRowData>>();
    editable = false;

    get updated$() {
        return this._updated$.asObservable();
    }

    get manualEdit$() {
        return this._manualEdit$.asObservable();
    }

    get data() {
        return this._data;
    }

    get id() {
        return this._data?.id;
    }

    constructor(
        public index: number,
        data: TRowData,
        public expandable: boolean,
        private tableOptions: TableOptions<TRowData> | null,
        public parent?: TableRow<TRowData>
    ) {
        this._data = data;
        if (tableOptions) {
            this.setClasses(tableOptions.rowClassRules);
            this.expandable = tableOptions.canRowExpand
                ? tableOptions.canRowExpand(data)
                : expandable;
            this.setTreeData(tableOptions);
        }
    }

    get classes() {
        return this._classes;
    }

    get level(): number {
        return this.parent ? this.parent.level + 1 : 0;
    }

    get indentSize(): number {
        return this.level * 20;
    }

    updateData(data: Partial<TRowData>) {
        this._data = { ...((this.data || {}) as TRowData), ...data };
        this._updated$.next();
        this.setClasses(this.tableOptions?.rowClassRules);
    }

    manualEdit(data: Partial<TRowData>) {
        this._manualEdit$.next(data);
    }

    private setTreeData(tableOptions: TableOptions<TRowData>) {
        if (this.expandable && tableOptions.getChildNodes) {
            const childNodes = tableOptions.getChildNodes(this.data);
            if (childNodes && childNodes.length > 0) {
                this.children = childNodes.map(
                    (childNode, index) =>
                        new TableRow(
                            index,
                            childNode,
                            this.expandable,
                            tableOptions,
                            this
                        )
                );
            }
        }
    }

    private setClasses(
        rowClassRules?: Record<string, (row: TableRow<TRowData>) => boolean>
    ) {
        if (!rowClassRules) {
            this._classes = [];
            return;
        }
        this._classes = Object.keys(rowClassRules).filter((className: string) =>
            rowClassRules[className](this)
        );
    }
}

export interface TableColumn<TRowData> {
    field: keyof TRowData | [keyof TRowData, string];
    type?: TableValueType;
    headerName: string;
    sortOrder?: SortOrder | null;
    showSort?: boolean;
    search?: boolean;
    showCheckbox?: boolean;
    checkboxLabel?: string;
    checkedChange?: (row: TableRow, checked: boolean) => void;
    width?: number;
    exportValueFormatter?: ExportValueFormatterFn;
    valueFormatter?: ValueFormatterFn;
    component?: Type<ITableCellValueRenderer>;
    componentProps?: Record<string, SafeAny>;
    filterConfig?: IFilterConfig;
    listFilterFacade?: IColumnSearchFacade<IEntity>;
    additionalListFilters?: () => QueryFilters<SafeAny>;
    serverKey?: string; // key to use for the server side search and sort
    serverSearch?: boolean;
    editable?: boolean;
    editableDataSource$?: Observable<SafeAny[]>;
    editableDataSelectedOption?: (entity: TRowData) => SelectOption | null;
    isEditableDataGrouped?: boolean;
    editValueSetter?: (entity: TRowData, value: SafeAny) => Promise<TRowData>;
    editCellInitialized?: (row: TableRow, control: AbstractControl) => void;
    defaultValue?: string;
}

export const getTableRowValue = <TRowData>(
    column: {
        field: TableColumn<SafeAny>['field'];
        defaultValue?: TableColumn<SafeAny>['defaultValue'];
    },
    row: TRowData
) => {
    if (!row) {
        return '';
    }
    if (Array.isArray(column.field)) {
        return (
            column.field.reduce((acc, curr) => {
                return (acc as SafeAny)?.[curr];
            }, row) || ''
        );
    } else {
        const fieldValue = (row as SafeAny)[column.field];
        return fieldValue !== undefined &&
            fieldValue !== null &&
            fieldValue !== ''
            ? fieldValue
            : column.defaultValue || '';
    }
};

export interface TableQueryParams<T = unknown> extends IQueryParams<T> {
    serverRendered?: boolean;
    checkboxes?: Record<keyof T, boolean> | undefined;
}

export interface FrameworkColumn {
    index: number;
    field: string;
    headerName: string;
    sortOrder: NzTableSortOrder;
    sortDirections: NzTableSortOrder[];
    sortFn: NzTableSortFn | true;
    search: boolean;
    searchValue: string;
    width: string | null;
    type: TableValueType;
    showCheckbox: boolean;
    checkboxLabel: string;
    checkedChange: (row: TableRow, checked: boolean) => void;
    checked?: boolean | null;
    valueFormatter: ValueFormatterFn;
    component?: Type<ITableCellValueRenderer>;
    componentProps?: Record<string, SafeAny>;
    listFilterFacade?: IColumnSearchFacade<IEntity>;
    key?: string;
    serverSearch?: boolean;
    additionalListFilters?: () => QueryFilters<SafeAny>;
    editable: boolean;
    editableDataSource$?: Observable<SelectOption[]>;
    editableDataSelectedOption: (entity: SafeAny) => SelectOption;
    isEditableDataGrouped: boolean;
    editValueSetter?: (entity: SafeAny, value: SafeAny) => SafeAny;
    editCellInitialized?: (row: TableRow, control: AbstractControl) => void;
    defaultValue?: string;
}

export interface TableSelection {
    checked: boolean;
    indeterminate: boolean;
    selectedRows: ReadonlyArray<TableRow>;
    queryParams: Readonly<TableQueryParams>;
    selectedRowCount: number;
}
