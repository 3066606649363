import { Component, inject } from '@angular/core';
import { BaseAppComponent } from '@pf/shared/ui-app-shared';
import { AppRouter } from './app.routes';

@Component({
    selector: 'pf-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseAppComponent {
    override title = 'Control Tower - Demo';
    override appRouter = inject(AppRouter);
}
