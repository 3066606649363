import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ContentService } from './../../services/content.service';
import { LayoutService } from './../../services/layout.service';
import { fromEvent } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'pf-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
    providers: [ContentService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentComponent {
    @ViewChild('siderTrigger', { static: false })
    siderTrigger!: ElementRef<HTMLElement>;

    @ViewChild('mainContent', { static: false, read: ElementRef })
    mainContent!: ElementRef<HTMLElement>;

    @HostBinding('attr.scrollable')
    @Input()
    scrollable = false;

    isCollapsed = true;

    siderScrollStyle: string | null = null;

    @Input()
    siderWidth: string | number = 200;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private layoutService: LayoutService,
        private cdr: ChangeDetectorRef,
        contentService: ContentService
    ) {
        contentService.registerContent(this);
        this.listenForScroll();
    }

    get scrollContainer() {
        return this.scrollable
            ? this.mainContent?.nativeElement
            : this.layoutService.scrollContainer;
    }

    @HostListener('click', ['$event.target'])
    private clickHandler(target: HTMLElement) {
        if (
            window.innerWidth >= 992 ||
            this.siderTrigger.nativeElement.contains(target)
        ) {
            return;
        }
        this.isCollapsed = true;
    }

    private listenForScroll() {
        const scrollObs = this.scrollable
            ? fromEvent(this.elementRef.nativeElement, 'scroll')
            : this.layoutService.onScroll$;
        scrollObs.pipe(untilDestroyed(this)).subscribe(() => {
            this.setSiderTriggerScrollTop();
        });
    }

    private setSiderTriggerScrollTop() {
        if (!this.siderTrigger || this.scrollable) {
            // No sidebar
            return;
        }

        const innerContentTop =
            this.siderTrigger.nativeElement.parentElement?.getBoundingClientRect()
                .top;
        if (innerContentTop && innerContentTop < 0) {
            const translateY = Math.abs(innerContentTop);
            this.siderScrollStyle = `transform: translateY(${translateY}px);`;
        } else {
            this.siderScrollStyle = null;
        }
        this.cdr.markForCheck();
    }
}
