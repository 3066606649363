<pf-input
    *ngIf="showSearch"
    class="custom-field-list-search"
    inputType="search"
    [control]="searchControl"
    [placeholder]="searchPlaceholder"></pf-input>
<div class="custom-field-list">
    <div class="custom-field-list-header">
        <pf-text *ngIf="listLabel" type="primary" bold
            >{{ listLabel }}
            <pf-icon
                *ngIf="tooltip"
                iconType="info"
                [nz-tooltip]="tooltip"></pf-icon>
        </pf-text>
        <pf-button buttonType="action" (click)="clearAllItems()"
            >Clear All</pf-button
        >
    </div>
    <div
        class="custom-field-list-item"
        *ngFor="
            let item of listControls$ | async;
            let inputIndex = index;
            let firstItem = first;
            let lastItem = last
        ">
        <ng-container *ngIf="inputType === 'text'">
            <pf-input
                #inputItem
                (keyup.enter)="focusNextOrAddNewItem(inputIndex)"
                pfStyle="compact"
                [control]="item.formControl"
                (pfInput)="onInput()"
                [datalist]="multiTextAutoCompleteOptions | safeArray"
                inputType="text"></pf-input>
        </ng-container>
        <ng-container *ngIf="inputType === 'select'">
            <pf-select
                #inputItem
                (selected)="itemSelected(inputIndex)"
                pfStyle="compact"
                [control]="item.formControl"
                [grouped]="grouped"
                [data]="options | safeArray"></pf-select>
        </ng-container>
        <ng-container *ngIf="inputType === 'multi-text'">
            <pf-input
                #inputItem
                [label]="listHeaders && firstItem ? listHeaders[0] : ''"
                pfStyle="stacked"
                [control]="item.formControl"
                (pfInput)="onInput()"
                inputType="text"></pf-input>
            <pf-input
                #inputItem
                (keyup.enter)="focusNextOrAddNewItem(inputIndex)"
                [label]="listHeaders && firstItem ? listHeaders[1] : ''"
                pfStyle="stacked"
                [control]="item.formControl2"
                (pfInput)="onInput()"
                [datalist]="multiTextAutoCompleteOptions | safeArray"
                inputType="text"></pf-input>
        </ng-container>
        <span class="custom-field-list-action">
            <pf-icon
                [ngClass]="{
                    'custom-field-list-action-offset':
                        firstItem && listHeaders?.length
                }"
                iconType="close"
                (click)="removeItem(item.listId)"></pf-icon>
        </span>
        <span class="custom-field-list-action">
            <pf-icon
                *ngIf="checkItemLimit(lastItem)"
                [ngClass]="{
                    'custom-field-list-action-offset':
                        firstItem && listHeaders?.length
                }"
                iconType="plus"
                (click)="addNewItem()"></pf-icon>
        </span>
    </div>
</div>
