import { Injectable } from '@angular/core';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import { OneTimeLinksService } from '@control-tower/platform-communications';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    IPagedResult,
    PFCommunicationsEntities,
} from '@pf/shared-common';
import { Operation } from 'fast-json-patch';
import { Observable } from 'rxjs';
import { OneTimeLinkStore } from './one-time-link.store';
import {
    OneTimeLinkCreate,
    OneTimeLinkRead,
    OneTimeLinkSearchParams,
    OneTimeTokenDto,
} from '@pf/shared/util-platform';

@Injectable()
export class OneTimeLinkDataService extends AbstractDataService<
    OneTimeLinkRead,
    OneTimeLinkCreate
> {
    protected EntityName = PFCommunicationsEntities.OneTimeLink;

    constructor(
        private service: OneTimeLinksService,
        store: OneTimeLinkStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: OneTimeLinkCreate): Observable<OneTimeLinkRead> {
        return this.service.oneTimeLinkCreate({
            oneTimeLinkViewModelCreateBody: body,
        }) as Observable<OneTimeLinkRead>;
    }

    protected deleteObs$(id: string): Observable<OneTimeLinkRead> {
        return this.service.oneTimeLinkDelete({
            id,
        }) as Observable<OneTimeLinkRead>;
    }

    protected undoDeleteObs$(id: string): Observable<OneTimeLinkRead> {
        return this.service.oneTimeLinkUndoDelete({
            id,
        }) as Observable<OneTimeLinkRead>;
    }

    protected getObs$(id: string): Observable<OneTimeLinkRead> {
        return this.service.oneTimeLinkReadById({
            id,
        }) as Observable<OneTimeLinkRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<OneTimeLinkRead> {
        return this.service.oneTimeLinkUpdatePatch({
            id,
            operation: operations,
        }) as Observable<OneTimeLinkRead>;
    }

    protected searchObs$(
        searchParams: OneTimeLinkSearchParams
    ): Observable<IPagedResult<OneTimeLinkRead>> {
        return this.service.oneTimeLinkSearch(searchParams) as Observable<
            IPagedResult<OneTimeLinkRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<OneTimeLinkRead>>> {
        return this.service.oneTimeLinkGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingDto<OneTimeLinkRead>>
        >;
    }

    authenticate(id: string) {
        return this.service.authenticateOneTimeLink({
            id,
        }) as Observable<OneTimeTokenDto>;
    }
}
