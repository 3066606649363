import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoadsView } from './load-facade.config';

@Injectable({
    providedIn: 'root',
})
export class LoadStateProvider {
    private _loadsViewSubject = new BehaviorSubject<LoadsView | null>(null);

    get loadsView$() {
        return this._loadsViewSubject.asObservable();
    }

    setLoadsView(view: LoadsView | null) {
        this._loadsViewSubject.next(view);
    }

    get loadsView() {
        return this._loadsViewSubject.value;
    }
}
