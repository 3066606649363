import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import { EquipmentService } from '@control-tower/platform-transportation';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    IPagedResult,
    PFTransportationEntities,
} from '@pf/shared-common';
import { EquipmentStore } from './equipment.store';
import {
    EquipmentCreate,
    EquipmentRead,
    EquipmentSearchParams,
} from '../../entities/equipment.dto';
import { Operation } from 'fast-json-patch';

@Injectable()
export class EquipmentDataService extends AbstractDataService<
    EquipmentRead,
    EquipmentCreate
> {
    protected EntityName = PFTransportationEntities.Equipment;

    constructor(
        private service: EquipmentService,
        store: EquipmentStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: EquipmentCreate): Observable<EquipmentRead> {
        return this.service.equipmentCreate({
            equipmentViewModelCreateBody: body,
        }) as Observable<EquipmentRead>;
    }

    protected deleteObs$(id: string): Observable<EquipmentRead> {
        return this.service.equipmentDelete({
            id,
        }) as Observable<EquipmentRead>;
    }

    protected undoDeleteObs$(_: string): Observable<EquipmentRead> {
        // return this.service.equipmentUndoDelete({
        //     id,
        // }) as Observable<EquipmentRead>;
        throw new Error('Method not implemented.');
    }

    protected getObs$(id: string): Observable<EquipmentRead> {
        return this.service.equipmentReadById({
            id,
        }) as Observable<EquipmentRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<EquipmentRead> {
        return this.service.equipmentUpdatePatch({
            id,
            operation: operations,
        }) as Observable<EquipmentRead>;
    }

    protected searchObs$(
        searchParams: EquipmentSearchParams
    ): Observable<IPagedResult<EquipmentRead>> {
        return this.service.equipmentSearch(searchParams) as Observable<
            IPagedResult<EquipmentRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<EquipmentRead>>> {
        return this.service.equipmentGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingDto<EquipmentRead>>
        >;
    }
}
