import { Injectable } from '@angular/core';
import { User } from '@control-tower/platform-core';
import { filter } from 'rxjs';
import { createStore, select, withProps } from '@ngneat/elf';

const userStore = createStore(
    { name: 'user' },
    withProps<User>({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
    })
);

@Injectable()
export class UserStore {
    get user() {
        return userStore.getValue();
    }

    set user(user: User) {
        userStore.update(state => ({
            ...state,
            ...user,
        }));
    }

    getProperty$<TReturn>(prop: keyof User, mapFn: (user: User) => TReturn) {
        return userStore.pipe(
            filter(p => !!p[prop]),
            select(mapFn)
        );
    }
}
