import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    ICustomField,
    IPagedResult,
    IPagedSearchParams,
    PFLoadEntities,
} from '@pf/shared-common';
import { LaneRateStore } from './lane-rate.store';
import {
    LaneRateCreate,
    LaneRateRead,
    LaneRateSearchParams,
} from '../../entities/lane-rate.dto';
import { Operation } from 'fast-json-patch';
import {
    RatesCustomFieldsService,
    RatesService,
} from '@control-tower/platform-loads';

@Injectable()
export class LaneRateDataService extends AbstractDataService<
    LaneRateRead,
    LaneRateCreate
> {
    protected EntityName = PFLoadEntities.LaneRate;

    constructor(
        private service: RatesService,
        private customFieldsService: RatesCustomFieldsService,
        store: LaneRateStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: LaneRateCreate): Observable<LaneRateRead> {
        return this.service.rateCreate({
            rateViewModelCreateBody: body,
        }) as Observable<LaneRateRead>;
    }

    protected deleteObs$(id: string): Observable<LaneRateRead> {
        return this.service.rateDelete({
            id,
        }) as Observable<LaneRateRead>;
    }

    protected undoDeleteObs$(id: string): Observable<LaneRateRead> {
        return this.service.rateUndoDelete({
            id,
        }) as Observable<LaneRateRead>;
    }

    protected getObs$(id: string): Observable<LaneRateRead> {
        return this.service.rateReadById({
            id,
        }) as Observable<LaneRateRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<LaneRateRead> {
        return this.service.rateUpdatePatch({
            id,
            operation: operations,
        }) as Observable<LaneRateRead>;
    }

    protected searchObs$(
        searchParams: LaneRateSearchParams
    ): Observable<IPagedResult<LaneRateRead>> {
        return this.service.rateSearch(searchParams) as Observable<
            IPagedResult<LaneRateRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<LaneRateRead>>> {
        return this.service.rateGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingDto<LaneRateRead>>
        >;
    }

    protected override createCustomFieldObs$(
        entityId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.rateCreateCustomField({
            entityId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override updateCustomFieldObs$(
        entityId: string,
        customFieldId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.rateCustomFieldsUpdatePut({
            entityId,
            id: customFieldId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override searchCustomFieldsObs$(
        searchParams: IPagedSearchParams & { key?: string; value?: string }
    ): Observable<IPagedResult<ICustomField>> {
        return this.customFieldsService.rateSearchCustomFields(
            searchParams
        ) as Observable<IPagedResult<ICustomField>>;
    }

    protected override deleteCustomFieldObs$(
        entityId: string,
        customFieldId: string
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.rateDeleteCustomField({
            entityId,
            id: customFieldId,
        }) as Observable<ICustomField & { id: string }>;
    }
}
