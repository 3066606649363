import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ColumnSearchType, FrameworkColumn } from '@pf/shared-common';
import { InputComponent } from '../../../../components/input/input.component';
import { TableService } from '../../services/table.service';

@Component({
    selector: 'pf-table-header-search',
    templateUrl: './table-header-search.component.html',
    styleUrls: ['./table-header-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableHeaderSearchComponent implements OnInit {
    private _searchList: ColumnSearchType[] = [];
    private get searchList(): ColumnSearchType[] {
        return this._searchList;
    }

    private set searchList(value: ColumnSearchType[]) {
        this._searchList = value;
        this.filteredSearchList = this.searchList;
    }

    tableService = inject(TableService);

    @ViewChild(InputComponent) input!: InputComponent;
    @Input() column!: FrameworkColumn;
    @Output() searchChanged = new EventEmitter<FrameworkColumn>();
    searchControl = new FormControl<string>('');
    searchVisible = false;

    filteredSearchList: ColumnSearchType[] = [];

    get isFilterActive() {
        return (
            this.column.searchValue.length > 0 ||
            this.filteredSearchList.some(x => x.checked)
        );
    }

    search(event?: Event) {
        event?.stopImmediatePropagation();
        this.column.searchValue = this.searchControl.value?.trim() || '';
        this.searchChanged.next(this.column);
    }

    searchOnList(event?: Event) {
        event?.stopImmediatePropagation();
        const searchValue = this.searchControl.value?.trim() || '';
        this.filteredSearchList = this.searchList.filter(
            item =>
                item.label.toLowerCase().indexOf(searchValue.toLowerCase()) !==
                -1
        );
    }

    listFilterSelected() {
        this.tableService.updateFilters({
            [this.column.key || this.column.field]: this.searchList
                .filter(s => s.checked)
                .map(s => s.value),
        });
    }

    reset(event?: Event) {
        event?.stopImmediatePropagation();
        this.searchControl.reset('');
        this.column.searchValue = '';
        this.search();
    }

    onVisibleChange() {
        if (this.searchVisible) {
            setTimeout(() => this.input.focus());
        }
    }

    getSearchableItemList() {
        const filters = this.column.additionalListFilters
            ? this.column.additionalListFilters()
            : {};
        return this.column.listFilterFacade
            ?.getColumnListFilters$(filters)
            .subscribe(filters => {
                this.searchList = filters;
            });
    }

    ngOnInit(): void {
        this.getSearchableItemList();
    }

    constructor() {
        this.setupClearListFilterFn();
    }

    private setupClearListFilterFn() {
        this.tableService.reset$.subscribe(() => {
            this.filteredSearchList.map(item => (item.checked = false));
        });
    }
}
