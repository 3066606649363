import {
    CustomFieldDto,
    ICarrierFragmentDto,
    ICustomFieldDto,
    ITrailerFragmentDto,
    IVehicleFragmentDto,
    IVendorFragmentDto,
} from '@pf/shared/util-platform';
import {
    FiscalPeriodFragment,
    Formula,
    LoadStatus,
    LoadUnifiedViewModelCreate,
    LoadUnifiedViewModelRead,
    ResourceAuth,
    V1LoadsUnifiedGetRequestParams,
    WorkflowStatus,
} from '@control-tower/platform-loads';
import { asArraySafe, mapToClassSafe } from '@pf/shared-utility';
import { ChargeDto } from './charge.dto';
import { EquipmentDto } from './equipment.dto';
import { EventDto } from './event.dto';
import { DocumentDto, IEntity, ITypeEntityFragment } from '@pf/shared-common';
import { LoadDriverDto } from './load-driver.dto';
import { DistanceDto } from './mile.dto';
import { NoteDto } from './notes.dto';
import { ReferenceDto } from './reference.dto';
import { IStopUnifiedFragmentDto } from './stop.unified.dto';

export type LoadRead = LoadUnifiedViewModelRead & IEntity;
export type LoadCreate = LoadUnifiedViewModelCreate;
export type LoadSearchParams = V1LoadsUnifiedGetRequestParams;

export type LoadDateFilter =
    | 'created'
    | 'events'
    | 'arrival'
    | 'departure'
    | 'expectedArrival'
    | 'expectedDeparture'
    | 'loadDate'
    | 'loadRequestedDate'
    | 'financialDate';

export class LoadDto implements IEntity {
    constructor(data: Partial<LoadDto>) {
        Object.assign(this, data);
        this.charges = mapToClassSafe(data.charges, ChargeDto);
        this.references = mapToClassSafe(data.references, ReferenceDto);
        this.stops = asArraySafe(data.stops);
        this.vendors = asArraySafe(data.vendors);
        this.notes = data.notes
            ? mapToClassSafe(data.notes, NoteDto)
            : undefined;
        this.distances = mapToClassSafe(data.distances, DistanceDto);
        this.equipment = mapToClassSafe(data.equipment, EquipmentDto);
        this.events = asArraySafe(data.events);
        this.drivers = asArraySafe(data.drivers);
        this.trailers = asArraySafe(data.trailers);
        this.customFields = mapToClassSafe(data.customFields, CustomFieldDto);
        this.documents = mapToClassSafe(data.documents, DocumentDto);
        this.formulas = asArraySafe(data.formulas);
    }

    get displayDate() {
        return this.loadDate || this.loadRequestedDate || this.createdDate;
    }

    id!: string;
    loadNumber!: string;
    loadRequestedDate?: string;
    loadDate?: string;
    mode?: ITypeEntityFragment;
    loadType?: ITypeEntityFragment;
    references: ReferenceDto[];
    loadStatus?: LoadStatus;
    workflowStatus?: WorkflowStatus;
    notes?: NoteDto[];
    distances: DistanceDto[];
    wmsShipmentId?: string;
    wmsAsnHeaderId?: string;
    createdDate?: string;
    modifiedDate?: string;
    stops: IStopUnifiedFragmentDto[];
    vendors: IVendorFragmentDto[];
    charges: ChargeDto[];
    equipment: EquipmentDto[];
    events: EventDto[];
    drivers: LoadDriverDto[];
    trailers: ITrailerFragmentDto[];
    vehicle?: IVehicleFragmentDto;
    isDeleted?: boolean;
    carrier?: ICarrierFragmentDto;
    financialDate?: string;
    fiscalPeriod?: FiscalPeriodFragment;
    customFields: ICustomFieldDto[];
    documents?: DocumentDto[] = [];
    resourceAuth?: ResourceAuth;
    laneId?: string;
    formulas?: Formula[];
}
