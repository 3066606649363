import { AbstractEntityMapper } from '@pf/shared-services';
import {
    LaneCreate,
    LaneDto,
    LaneRead,
    LaneSearchParams,
} from '../../entities/lane.dto';
import { Injectable } from '@angular/core';
import { LaneMetadataMap } from './lane.mapping-profile';
import { PlatformLoadsMapper } from '../platform-loads.mapper';

@Injectable()
export class LaneMapper extends AbstractEntityMapper<
    LaneRead,
    LaneCreate,
    LaneDto,
    LaneSearchParams
> {
    constructor() {
        super(LaneDto, PlatformLoadsMapper, LaneMetadataMap.symbols);
    }
}
