<pf-result [resultProps]="errorProps" [hideHomeButton]="true">
    <div class="error-content">
        <pf-text type="subtitle"
        >There are a few reasons this could be erroring out:
        </pf-text
        >
        <pf-text type="description">
            <ul>
                <li>
                    <pf-icon iconType="circleX"></pf-icon>
                    You are not authorized to view this information.
                </li>
                <li>
                    <pf-icon iconType="circleX"></pf-icon>
                    Your link has been deactivated or too much time has elapsed
                    and it is expired.
                </li>
            </ul>
        </pf-text>
    </div>
    <pf-text bold style="text-align: center"
    >For any questions contact
        <a href="mailto:{{ 'help-email' | AppConstant }}">{{
            "help-email" | AppConstant
            }}</a></pf-text
    >
</pf-result>
