<ng-content select="header"></ng-content>
<ng-content select="pf-content-header"></ng-content>
<nz-layout #mainContent>
    <nz-sider
        nzCollapsible
        nzBreakpoint="lg"
        [nzCollapsedWidth]="40"
        [nzWidth]="siderWidth"
        [(nzCollapsed)]="isCollapsed"
        [nzTrigger]="null"
        [style]="siderScrollStyle"
        [ngClass]="{ expanded: !isCollapsed }">
        <div sider-content>
            <ng-content select="aside"></ng-content>
            <ng-content select="pf-content-aside"></ng-content>
        </div>
    </nz-sider>
    <div
        #siderTrigger
        sider-trigger
        (click)="isCollapsed = !isCollapsed"
        [style]="siderScrollStyle">
        <pf-icon iconType="list"></pf-icon>
    </div>
    <nz-content [ngClass]="{ collapsed: !isCollapsed }">
        <ng-content></ng-content>
    </nz-content>
</nz-layout>
