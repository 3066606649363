import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { appRouteProviders, appRoutes } from './app.routes';
import {
    AppModuleConfiguration,
    playerFactory,
    SharedUiAppSharedModule,
} from '@pf/shared/ui-app-shared';
import loadProviderConfig from './config/load-provider-config';
import globalConfig from './config/global-config';
import appBranding from './config/app-branding';
import appConstants from './config/app-constants';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    IconModule,
    LayoutModule,
    NotificationsModule,
    SharedUiModule,
} from '@pf/shared-ui';
import { AuthModule } from '@pf/shared-services';
import { PlatformCoreDomainModule } from '@pf/platform-core/domain';
import { PlatformItemCatalogDomainModule } from '@pf/platform-item-catalog/domain';
import { PlatformLoadsDomainModule } from '@pf/platform-loads/domain';
import { PlatformTransportationDomainModule } from '@pf/platform-transportation/domain';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { PlatformAuthService, sharedEnv } from '@pf/shared-common';
import { lastValueFrom, tap } from 'rxjs';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';

const appModuleConfiguration = new AppModuleConfiguration(environment)
    .withRoutes(appRoutes, appRouteProviders)
    .withAppBranding(appBranding)
    .withGlobalConfig(globalConfig)
    .withAppConstants(appConstants)
    .withLoadProviderConfig(loadProviderConfig);

export function jwtOptionsFactory(tokenService: PlatformAuthService) {
    return {
        tokenGetter: () => {
            return lastValueFrom(
                tokenService.getAccessToken().pipe(
                    tap(token => {
                        if (!token) {
                            tokenService.login();
                        }
                    })
                )
            );
        },
        allowedDomains: sharedEnv.jwtConfig.allowedList,
        disallowedRoutes: sharedEnv.jwtConfig.disallowedList,
    };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        IconModule,
        SharedUiModule,
        LayoutModule.forRoot(
            appModuleConfiguration.environment.mainUrl,
            environment.environment !== 'prod'
        ),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [PlatformAuthService],
            },
        }),
        AuthModule.forRoot(
            appModuleConfiguration.environment.auth0ClientId,
            appModuleConfiguration.environment.auth0AuthParams
        ),
        PlatformCoreDomainModule.ApiModule(),
        PlatformCoreDomainModule,
        PlatformItemCatalogDomainModule.ApiModule(),
        PlatformItemCatalogDomainModule,
        PlatformLoadsDomainModule.ApiModule(),
        PlatformLoadsDomainModule,
        PlatformTransportationDomainModule.ApiModule(),
        PlatformTransportationDomainModule,
        NotificationsModule.forRoot(),
        FontAwesomeModule,
        LottieModule.forRoot({ player: playerFactory }),
        LottieCacheModule.forRoot(),
        SharedUiAppSharedModule,
        RouterModule.forRoot(appModuleConfiguration.appRoutes, {
            preloadingStrategy: PreloadAllModules,
        }),
    ],
    providers: [...appModuleConfiguration.providers],
    bootstrap: [AppComponent],
})
export class AppModule {}
