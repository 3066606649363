import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableService } from '../services/table.service';

@Component({
    selector: 'pf-editable-table-actions',
    template: `
        <ng-container>
            <pf-button
                *ngIf="showUndoButton"
                buttonType="undo"
                (click)="undoChanges()"
                [disabled]="!hasChanges"
                >Undo Changes
            </pf-button>
            <pf-button
                *ngIf="showSaveButton"
                buttonType="submit"
                (click)="saveEventHandler()"
                [disabled]="!hasChanges"
                >Save
            </pf-button>
        </ng-container>
    `,
    styles: [
        `
            :host {
                display: flex;
                justify-content: flex-end;

                pf-button {
                    margin-left: var(--pf-gap);
                }
            }
        `,
    ],
})
export class EditableTableActionsComponent {
    hasChanges = false;

    @Input() showSaveButton = true;
    @Input() showUndoButton = true;

    @Output() saveChanges = new EventEmitter<void>();

    constructor(private tableService: TableService) {
        this.tableService.editedTableData$.subscribe(editedData => {
            this.hasChanges = !!editedData.length;
        });
    }

    saveEventHandler() {
        this.saveChanges.emit();
    }

    undoChanges() {
        this.tableService.resetEditableTableState();
    }
}
