import { Injectable, Optional } from '@angular/core';
import { AbstractManageEntityFacade } from '@pf/shared-services';
import {
    ContactCreate,
    ContactDto,
    ContactRead,
    ContactSearchParams,
} from './contact.dto';
import { ContactMapper } from './contact.mapper';
import { ContactStore } from './contact.store';
import { ContactDataService } from './contact.data.service';
import { BuildChangeTrackingFacadeFactory } from '../facades/ChangeTrackingFacade';
import { RoutesForContact } from './contact.routes';
import { ResourceAuth } from '@control-tower/platform-core';
import { IPagedResult, IQueryParams } from '@pf/shared-common';
import { Observable, of } from 'rxjs';
import { ContactFacadeConfig } from './contact-facade.config';

@Injectable()
export class ContactFacade extends AbstractManageEntityFacade<
    ContactDto,
    ContactRead,
    ContactCreate,
    ContactSearchParams
> {
    nameField: keyof ContactDto = 'searchName';
    customFieldsConfig$ = this.config?.customFields$ ?? of([]);

    constructor(
        dataService: ContactDataService,
        routes: RoutesForContact,
        store: ContactStore,
        mapper: ContactMapper,
        @Optional() public config?: ContactFacadeConfig
    ) {
        super(dataService, routes, store, mapper);
    }

    override applyResourceAuth(body: ContactCreate) {
        body.resourceAuth = {
            allAuthorized: true,
        } as ResourceAuth;
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        'firstName'
    );

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof ContactDto, string>> {
        return { firstName: searchText };
    }

    override searchByText$(text: string): Observable<IPagedResult<ContactDto>> {
        console.log('searchByText$', text);
        const params: IQueryParams<ContactDto> = {
            pageIndex: 1,
            pageSize: 20,
            filters: { fullName: text },
        };
        return this.oneTimeSearch$(params);
    }
}
