import { IPagedResult } from '@pf/shared-common';
import { map, Observable } from 'rxjs';
import { asArraySafe } from '@pf/shared-utility';

export function mapToIPagedResult<T>(
    source: Observable<T[]>
): Observable<IPagedResult<T>> {
    return source.pipe(
        map(entities => {
            const entitiesSafe = asArraySafe(entities);
            return {
                data: entitiesSafe,
                currentPage: 1,
                currentPageSize: entitiesSafe.length,
                totalRecords: entitiesSafe.length,
            } as IPagedResult<T>;
        })
    );
}
