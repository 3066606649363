export function addArticle(word: string): string {
    const lowerCaseWord = word.toLowerCase();

    const startsWithVowelSound =
        ['a', 'e', 'i', 'o', 'u'].indexOf(lowerCaseWord.charAt(0)) !== -1;

    return startsWithVowelSound ? `an ${word}` : `a ${word}`;
}

export function convertToReadable(propertyName: string): string {
    return propertyName
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        .replace(/^./, str => str.toUpperCase());
}
