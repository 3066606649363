import {
    ChangeDetectionStrategy,
    Component,
    Host,
    Input,
    OnChanges,
    Optional,
} from '@angular/core';
import { StatisticGroupComponent } from './statistic-group.component';

@Component({
    selector: 'pf-statistic',
    templateUrl: './statistic.component.html',
    styleUrls: ['./statistic.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticComponent implements OnChanges {
    @Input() pfTitle!: string;
    private _pfValue!: string | number | undefined;
    private _pfIsAccounting = false;

    @Input() set pfValue(value: string | number | undefined | null) {
        this._pfValue = value === null ? undefined : value;
        this.calculateColor();
    }

    get pfValue(): string | number | undefined {
        return this._pfValue;
    }

    @Input() set pfIsAccounting(isAccounting: boolean | undefined) {
        this._pfIsAccounting = !!isAccounting;
        this.calculateColor();
    }

    @Input() pfSize?: 'small' | 'large';
    @Input() pfColor?: string;

    constructor(@Optional() @Host() private group?: StatisticGroupComponent) {}

    ngOnChanges(): void {
        if (this.group) {
            this.group.setStat(this.pfTitle, this.pfValue as number);
        }
    }

    private calculateColor() {
        if (this._pfIsAccounting) {
            this.pfColor =
                this._pfValue && +this._pfValue < 0
                    ? 'var(--ant-error-color)'
                    : 'var(--ant-success-color)';
        }
    }
}
