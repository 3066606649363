import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    combineLatest,
    debounceTime,
    filter,
    first,
    Observable,
    of,
    Subject,
} from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { TableService } from './table.service';
import {
    FilterMetadata,
    filterState$,
    getFilterState,
    removeAppliedFilterForScreen,
    resetAppliedFiltersForScreen,
    selectAppliedFiltersByScreen,
    selectInitialFiltersByScreen,
    setAppliedFiltersForScreen,
    setInitialFiltersForScreen,
    upsertAppliedFilterForScreen,
    upsertInitialFilterForScreen,
} from '../stores/filter.store';
import {
    areFiltersEqual,
    castFilterMetadataToQueryFilters,
    countAppliedFilters,
    findFilterByName,
} from '../utils/filter-utils';
import {
    getQueryParam,
    resetQueryParams,
    setQueryParams,
} from '@pf/shared-utility';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class FilterService {
    private _lastAppliedFilters: { [screen: string]: FilterMetadata[] } = {};
    private _popoverNameMapping: Record<string, string> = {};
    private _queryParamsApplied = false;

    private readonly _applyFiltersSubject = new Subject<boolean>();

    private readonly _filterDisplaySubject = new BehaviorSubject<boolean>(
        false
    );
    private readonly _filterValidationIssues = new BehaviorSubject<
        Record<string, string>
    >({});
    private readonly _currentScreenSubject = new BehaviorSubject<string>('');
    private readonly _filterResetSubject = new BehaviorSubject<boolean>(false);
    private readonly _filterClearSubject = new BehaviorSubject<boolean>(false);
    private readonly _initializedSubject = new BehaviorSubject<boolean>(false);

    filterDisplay$ = this._filterDisplaySubject.asObservable();
    filterReset$ = this._filterResetSubject.asObservable();
    filterClear$ = this._filterClearSubject.asObservable();
    filterValidationIssues$ = this._filterValidationIssues.asObservable();
    initialized$ = this._initializedSubject
        .asObservable()
        .pipe(filter(initialized => initialized));
    appliedFiltersCount$: Observable<number> = of(0);
    filteredKeysNames$: Observable<string[]> = of([]);

    filters$: Observable<FilterMetadata[]> = this._currentScreenSubject.pipe(
        switchMap(screen =>
            filterState$.pipe(selectAppliedFiltersByScreen(screen))
        ),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    canReset$: Observable<boolean> = this.filters$.pipe(
        map(filters => filters.length > 0)
    );

    get canApply() {
        return Object.keys(this._filterValidationIssues.value).length <= 0;
    }

    get initialized(): boolean {
        return this._initializedSubject.value;
    }

    get filtersDisplayed(): boolean {
        return this._filterDisplaySubject.value;
    }

    constructor(private tableService: TableService) {
        this.setAppliedFilterCount();
        this.setApplyFiltersLogic();
        this.setupFilteredKeyNames();
    }

    /**
     * Sets the mapping between filter keys and their display names.
     *
     * @param mapping - Record mapping filter keys to display names
     */
    setPopoverNameMapping(mapping: Record<string, string>) {
        this._popoverNameMapping = mapping;
    }

    /**
     * Applies the current filters to the table and updates the query parameters.
     *
     * @param toggleFilter - Whether to toggle the filter display after applying filters.
     */
    applyFilters(toggleFilter: boolean = true) {
        this._applyFiltersSubject.next(toggleFilter);
    }

    /**
     * Toggles the filter display state.
     */
    toggleFilterDisplay() {
        const currentValue = this._filterDisplaySubject.value;
        this._filterDisplaySubject.next(!currentValue);
    }

    /**
     * Adds or updates a filter for the current screen.
     *
     * @param filter - The filter to add or update
     */
    updateFilters(filter: FilterMetadata) {
        if (!filter || !filter.key) return;

        if (filter.value === undefined || filter.value === null) {
            this.deleteFilter(filter.name);
            return;
        }

        upsertAppliedFilterForScreen(this._currentScreenSubject.value, filter);
        setQueryParams({
            filters: this.getCurrentFilters(),
        });
    }

    /**
     * Deletes a filter by name from the applied filters array.
     *
     * @param filterName - The name of the filter to delete
     */
    deleteFilter(filterName: string) {
        removeAppliedFilterForScreen(
            this._currentScreenSubject.value,
            filterName
        );
    }

    /**
     * Resets all the filters for the current screen.
     *
     * @param toggleFilter - Whether to toggle the filter display after resetting filters.
     */
    resetFilters(toggleFilter: boolean = true) {
        const currentScreen = this._currentScreenSubject.value;
        resetAppliedFiltersForScreen(currentScreen);
        resetQueryParams();

        this._filterResetSubject.next(true);
        setTimeout(() => this._filterResetSubject.next(false), 0);

        this.tableService.reset({ filters: {} });

        if (toggleFilter) {
            this.toggleFilterDisplay();
        }
    }

    getCurrentFilters() {
        return getFilterState()[this._currentScreenSubject.value || '']
            ?.appliedFilters;
    }

    /**
     * Clears all filters and resets to initial filters for the current screen.
     */
    clearFilters() {
        const currentScreen = this._currentScreenSubject.value;
        filterState$
            .pipe(selectInitialFiltersByScreen(currentScreen), take(1))
            .subscribe(initialFilters => {
                setInitialFiltersForScreen(currentScreen, initialFilters);
                setAppliedFiltersForScreen(currentScreen, initialFilters);
                this.applyFilters(false);
            });

        this._filterClearSubject.next(true);
        setTimeout(() => this._filterClearSubject.next(false), 0);
    }

    /**
     * Sets initial filters for the current screen from the store.
     */
    setInitialFilter(initialFilter: FilterMetadata) {
        filterState$.pipe(first()).subscribe(_ => {
            const currentScreen = this._currentScreenSubject.value;
            upsertInitialFilterForScreen(currentScreen, initialFilter);

            if (!this._queryParamsApplied) {
                this.updateFilters(initialFilter);
            }
        });
    }

    /**
     * Checks if initial filters are defined for the current screen.
     * @param name - The filter key to check
     * @returns True if initial filters are defined, false otherwise
     */
    checkInitialFilterIsDefinedByName$(name: string): Observable<boolean> {
        return filterState$.pipe(
            untilDestroyed(this),
            selectInitialFiltersByScreen(this._currentScreenSubject.value),
            map(filters => findFilterByName(filters, name) !== undefined)
        );
    }

    /**
     * Retrieves the applied filters for the current screen.
     */
    getAppliedFilters$(): Observable<FilterMetadata[]> {
        return this.initialized$.pipe(
            switchMap(_ =>
                filterState$.pipe(
                    selectAppliedFiltersByScreen(
                        this._currentScreenSubject.value
                    )
                )
            )
        );
    }

    /**
     * Retrieves a specific applied filter by key.
     * @param name - The filter key
     * @returns Observable of the filter or undefined
     */
    getAppliedFilterByName$(
        name: string
    ): Observable<FilterMetadata | undefined> {
        return this.getAppliedFilters$().pipe(
            map(filters => findFilterByName(filters, name))
        );
    }

    /**
     * initialize filters
     * check is there any filters in query params
     * if yes, add query param filters to the applied filters
     * if no, add filters from the initial filters to the applied filters
     *
     */

    /**
     * Initializes the filter service with the given table key.
     * @param tableKey - The identifier for the table/screen
     */
    initialize(tableKey: string) {
        this._currentScreenSubject.next(tableKey);
        combineLatest([this.tableService.tableInitialized$, filterState$])
            .pipe(
                filter(
                    ([tableInitialized, filterState]) =>
                        tableInitialized && !!filterState
                ),
                first(),
                tap(_ => {
                    this.addQueryParamsToStore(this.getQueryParamsFromUrl());
                }),
                tap(_ => {
                    this._initializedSubject.next(true);
                })
            )
            .subscribe();
    }

    /**
     * Returns whether filters can be reset as an observable.
     */
    getCanReset(): Observable<boolean> {
        return this.canReset$;
    }

    /**
     * Adds a validation issue.
     *
     * @param key - The filter key
     * @param message - The validation message
     */
    addValidationIssue(key: string, message: string) {
        const currentIssues = this._filterValidationIssues.value;
        this._filterValidationIssues.next({
            ...currentIssues,
            [key]: message,
        });
    }

    /**
     * Removes a validation issue.
     *
     * @param key - The filter key
     */
    removeValidationIssue(key: string) {
        const currentIssues = { ...this._filterValidationIssues.value };
        delete currentIssues[key];
        this._filterValidationIssues.next(currentIssues);
    }

    updateLastAppliedFilters(value: FilterMetadata) {
        const currentScreen = this._currentScreenSubject.value;
        this._lastAppliedFilters[currentScreen] = [
            ...(this._lastAppliedFilters[currentScreen] ?? []),
            value,
        ];
    }

    /**
     * Maps a filter key to its corresponding display name.
     *
     * @param key - The filter key to map
     * @returns The display name or undefined
     */
    private getKeyNameMapping(key: string): string | undefined {
        if (key in this._popoverNameMapping) {
            return this._popoverNameMapping[key];
        }

        for (const mappingKey of Object.keys(this._popoverNameMapping)) {
            if (mappingKey.includes(key)) {
                return this._popoverNameMapping[mappingKey];
            }
        }

        for (const mappingKey of Object.keys(this._popoverNameMapping)) {
            if (key.includes(mappingKey)) {
                return this._popoverNameMapping[mappingKey];
            }
        }

        return undefined;
    }

    /**
     * Retrieves initial filters for a screen from the store
     *
     * @param screen - The screen identifier
     * @returns Array of initial filters
     */
    private getInitialFilters(screen: string): FilterMetadata[] {
        let initialFilters: FilterMetadata[] = [];
        filterState$
            .pipe(selectInitialFiltersByScreen(screen), take(1))
            .subscribe(filters => {
                initialFilters = filters;
            });
        return initialFilters;
    }

    /**
     * Sets the count of applied filters.
     */
    private setAppliedFilterCount() {
        this.appliedFiltersCount$ = this.filters$.pipe(
            untilDestroyed(this),
            map(currentFilters =>
                countAppliedFilters(
                    currentFilters,
                    this.getInitialFilters(this._currentScreenSubject.value)
                )
            )
        );
    }

    /**
     * Applies filters from query parameters.
     *
     * @param filters - The filters to apply
     */
    private addQueryParamsToStore(filters: FilterMetadata[]) {
        if (filters?.length === 0) {
            return;
        }

        this._queryParamsApplied = true;
        resetAppliedFiltersForScreen(this._currentScreenSubject.value);
        filters.forEach(f => this.updateFilters(f));
    }

    private getQueryParamsFromUrl(): FilterMetadata[] {
        const filters = getQueryParam('filters');
        return filters ? filters : [];
    }

    /**
     * Sets up the logic to apply filters based on the applyFiltersSubject.
     */
    private setApplyFiltersLogic() {
        this.filters$
            .pipe(
                untilDestroyed(this),
                switchMap(filters =>
                    this._applyFiltersSubject.asObservable().pipe(
                        tap(toggleFilter => {
                            if (toggleFilter) {
                                this.toggleFilterDisplay();
                            }
                        }),
                        map(() => filters)
                    )
                ),
                debounceTime(300),
                tap(filters => {
                    if (
                        Object.keys(this._filterValidationIssues.value).length >
                        0
                    ) {
                        console.warn(
                            'Cannot apply filters due to validation issues:',
                            this._filterValidationIssues.value
                        );
                        return;
                    }

                    const currentScreen = this._currentScreenSubject.value;

                    const filtersHaveChanged = !areFiltersEqual(
                        this._lastAppliedFilters[currentScreen] || [],
                        filters
                    );

                    if (filtersHaveChanged) {
                        this._lastAppliedFilters[currentScreen] = [...filters];
                        const mappedFilters =
                            castFilterMetadataToQueryFilters(filters);
                        this.tableService.updateFilters(mappedFilters, true);
                    }
                })
            )
            .subscribe();
    }

    private setupFilteredKeyNames() {
        this.filteredKeysNames$ = this.filters$.pipe(
            map(filters =>
                filters
                    .map(filter => this.getKeyNameMapping(filter.key))
                    .filter((f): f is string => f !== undefined)
                    .reduce<string[]>((uniqueNames, name) => {
                        if (!uniqueNames.includes(name)) {
                            uniqueNames.push(name);
                        }
                        return uniqueNames;
                    }, [])
            )
        );
    }
}
