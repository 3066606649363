import { AbstractEntityMapper } from '@pf/shared-services';
import {
    LoadScheduleSummaryCreate,
    LoadScheduleSummaryDto,
    LoadScheduleSummaryRead,
    LoadScheduleSummarySearchParams,
} from '../../entities/load-schedule-summary.dto';
import { Injectable } from '@angular/core';
import { PlatformLoadsMapper } from '../platform-loads.mapper';
import { LoadScheduleSummaryMetadataMap } from './load-schedule-summary.mapping-profile';

@Injectable()
export class LoadScheduleSummaryMapper extends AbstractEntityMapper<
    LoadScheduleSummaryRead,
    LoadScheduleSummaryCreate,
    LoadScheduleSummaryDto,
    LoadScheduleSummarySearchParams
> {
    constructor() {
        super(
            LoadScheduleSummaryDto,
            PlatformLoadsMapper,
            LoadScheduleSummaryMetadataMap.symbols
        );
    }
}
