import { Injectable } from '@angular/core';
import {
    UserPermissionsService,
    UserPermissionViewModelCreateBody,
} from '@control-tower/platform-core';
import {
    EntityChangeTrackingDto,
    IOmniFieldSearchParams,
    IPagedResult,
    IPagedSearchParams,
    PFCoreEntities,
} from '@pf/shared-common';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import { Operation } from 'fast-json-patch';
import { Observable } from 'rxjs';
import {
    UserPermissionCreate,
    UserPermissionRead,
} from '../../entities/user-permission.dto';
import { UserPermissionStore } from './user-permission.store';

@Injectable()
export class UserPermissionDataService extends AbstractDataService<
    UserPermissionRead,
    UserPermissionCreate
> {
    protected EntityName = PFCoreEntities.UserPermission;

    constructor(
        private userPermissionService: UserPermissionsService,
        store: UserPermissionStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected searchChangesObs$(): Observable<
        IPagedResult<EntityChangeTrackingDto<UserPermissionRead>>
    > {
        throw new Error('Method not implemented.');
    }

    protected createObs$(
        body: UserPermissionViewModelCreateBody
    ): Observable<UserPermissionRead> {
        return this.userPermissionService.userPermissionEntityCreate({
            userPermissionViewModelCreateBody: body,
        }) as Observable<UserPermissionRead>;
    }

    protected deleteObs$(id: string): Observable<UserPermissionRead> {
        return this.userPermissionService.userPermissionEntityDelete({
            id,
        }) as Observable<UserPermissionRead>;
    }

    protected undoDeleteObs$(id: string): Observable<UserPermissionRead> {
        return this.userPermissionService.userPermissionEntityUndoDelete({
            id,
        }) as Observable<UserPermissionRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<UserPermissionRead> {
        return this.userPermissionService.userPermissionEntityUpdatePatch({
            id,
            operation: operations,
        }) as Observable<UserPermissionRead>;
    }

    protected getObs$(id: string): Observable<UserPermissionRead> {
        return this.userPermissionService.userPermissionEntityReadById({
            id,
        }) as Observable<UserPermissionRead>;
    }

    protected searchObs$(
        searchParams: IPagedSearchParams | IOmniFieldSearchParams
    ): Observable<IPagedResult<UserPermissionRead>> {
        return this.userPermissionService.userPermissionEntitySearch(
            searchParams
        ) as Observable<IPagedResult<UserPermissionRead>>;
    }
}
