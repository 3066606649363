import {
    AssociatedAddressMetadataMap,
    ContactMetadataMap,
    CustomFieldMetadataMap,
    IdIsDefaultSymbol,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';
import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFCoreEntities,
} from '@pf/shared-common';
import { createMap, Mapper } from '@automapper/core';
import {
    VendorCreate,
    VendorDto,
    VendorOmniSearchFieldTypes,
    VendorRead,
    VendorSearchParams,
} from '../../entities/vendor.dto';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultOmniSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';

const symbols = entitySymbols(PFCoreEntities.Vendor);
withDefaultEntityMetadata<VendorDto>(symbols.dto, {
    vendorName: String,
    vendorNumber: String,
    associatedAddresses: [AssociatedAddressMetadataMap.symbols.dto],
    contacts: [ContactMetadataMap.symbols.dto],
    resourceAuth: ResourceAuthSymbol,
    customFields: [CustomFieldMetadataMap.symbols.dto],
    shouldReceiveNotifications: Boolean,
});
withDefaultEntityMetadata<VendorRead>(symbols.entity, {
    vendorName: String,
    vendorNumber: String,
    associatedAddresses: [AssociatedAddressMetadataMap.symbols.entity],
    resourceAuth: ResourceAuthSymbol,
    contacts: [ContactMetadataMap.symbols.entity],
    customFields: [CustomFieldMetadataMap.symbols.entity],
    shouldReceiveNotifications: Boolean,
});
withMetadata<VendorCreate>(symbols.createBody, {
    vendorName: String,
    vendorNumber: String,
    associatedAddresses: [AssociatedAddressMetadataMap.symbols.createBody],
    resourceAuth: ResourceAuthSymbol,
    contacts: [IdIsDefaultSymbol],
    shouldReceiveNotifications: Boolean,
});
withDefaultSearchParamsMetadata<VendorSearchParams>(symbols.searchParams, {
    vendorName: String,
    vendorNumber: String,
});

export const VendorMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<VendorRead, VendorDto>(mapper, symbols.entity, symbols.dto);
        createMap<VendorDto, VendorRead>(mapper, symbols.dto, symbols.entity);
        createMap<VendorDto, VendorCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
        createMap<IQueryParams<VendorDto>, VendorSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('vendorName'),
            withSearchFilter('vendorNumber'),
            ...withDefaultOmniSearchParamsMappingConfiguration(
                VendorSearchFieldsMapper
            )
        );
        ContactMetadataMap.profile(mapper);
        AssociatedAddressMetadataMap.profile(mapper);
        CustomFieldMetadataMap.profile(mapper);
    },
};

export const VendorSearchFieldsMapper: Partial<
    Record<keyof VendorDto, VendorOmniSearchFieldTypes[]>
> = {
    vendorName: ['Name'],
    vendorNumber: ['Number'],
};
