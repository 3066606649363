import { Component } from '@angular/core';
import { FilterService } from '../services/filter.service';
import { map, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'pf-filter-button',
    templateUrl: './filter-button.component.html',
    styleUrl: './filter-button.component.scss',
})
export class FilterButtonComponent {
    appliedFiltersCount$ = this.filterService.appliedFiltersCount$;
    appliedFilterNames$: Observable<string> =
        this.filterService.filteredKeysNames$.pipe(
            untilDestroyed(this),
            map(filterKeys =>
                filterKeys.length
                    ? 'Filters Applied: ' + filterKeys.join(', ')
                    : 'No filters applied'
            )
        );

    constructor(protected filterService: FilterService) {}

    toggleFilter() {
        if (!this.filterService.filtersDisplayed) {
            this.filterService.toggleFilterDisplay();
        } else {
            this.filterService.applyFilters(true);
        }
    }
}
