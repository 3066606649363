import {
    NotificationType,
    SafeAny,
    ToastMessage,
    ToastType,
} from '@pf/shared-common';
import { Observable, tap } from 'rxjs';
import { FormService } from '../services/form.service';
import { NgxRootForm, TypedFormGroup } from 'ngx-sub-form';
import { AbstractControl, FormGroup } from '@angular/forms';
import { NotificationsService } from '../../notifications';

export const validateAndMarkDirty = (formGroup: FormGroup) => {
    formGroup.markAllAsTouched();
    formGroup.markAsDirty();
    Object.values(formGroup.controls).forEach(control => {
        control.markAsDirty();
        if (typeof control.value === 'string') {
            control.setValue(control.value.trim());
        } else {
            control.updateValueAndValidity({
                onlySelf: false,
                emitEvent: true,
            });
        }

        if (control instanceof FormGroup) {
            validateAndMarkDirty(control as FormGroup);
        }
    });
};

export const markDirtyOnSubmitIfInvalid = (
    formService: FormService,
    entityType: string,
    formGroup: FormGroup | TypedFormGroup<SafeAny>,
    takeUntil: (source: Observable<SafeAny>) => Observable<SafeAny>,
    notificationsService: NotificationsService
) => {
    formService
        .submit$(entityType)
        .pipe(
            takeUntil,
            tap(() => {
                validateAndMarkDirty(formGroup);
            }),
            tap(() => {
                if (formGroup.invalid) {
                    formService.notifyError(entityType);
                    notificationsService.create({
                        type: NotificationType.ToastMessage,
                        toastType: ToastType.error,
                        message: 'Please correct the errors on the form.',
                    } as ToastMessage);
                }
            })
        )
        .subscribe();
};

export function addControl<T, TInterface extends object>(
    form: NgxRootForm<T, TInterface>,
    controlName: keyof TInterface & string,
    control: AbstractControl
) {
    form.formGroup.addControl(controlName, control);
    form.formControlNames[controlName] = controlName;
}
