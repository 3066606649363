<div class="filter-title">
    <pf-text bold>{{ filterName }}</pf-text>
    <ng-container *ngIf="displayValidationMessage">
        <pf-text type="danger">{{ selectionRequiredMessage }}</pf-text>
    </ng-container>
</div>
<ng-container *ngIf="filterKeys">
    <pf-segmented
        [segmentedOptions]="filterKeys"
        [(ngModel)]="filterKeyIndex"
        (selectedModelChange)="changeFilterKeyHandler($event)"></pf-segmented>
</ng-container>
<ng-container
    *ngIf="filterKeyObject?.searchFacade; else segmentedDateFilterOptions">
    <pf-select
        [searchFacade]="filterKeyObject?.searchFacade"
        [control]="searchControl"
        (selected)="selectFiscalPeriod($event)"></pf-select>
</ng-container>
<ng-template #segmentedDateFilterOptions>
    <pf-segmented
        [segmentedOptions]="segmentedOptions"
        [(ngModel)]="selectedTimeSegmentIndex"
        (selectedModelChange)="handleSegmentedChange($event)"></pf-segmented>
    <ng-container *ngIf="customDateRangeActivated">
        <pf-date-filter
            [lazyProcess]="true"
            [filterOptions]="segmentedOptions"
            (calendarChanged)="calendarChangedHandler($event)"
            (dateRangeChange)="dateRangeChangeHandler($event)"
            (dateRangeClear)="dateRangeClearHandler()"></pf-date-filter>
    </ng-container>
</ng-template>
