<pf-content>
    <section pfSection pfTitle="Most Recent Changes">
        <nz-timeline>
            <nz-timeline-item
                *ngFor="
                    let change of recentChanges$ | async;
                    trackBy: trackByFn;
                    let first = first
                "
                [nzLabel]="changeLabelTmpl">
                <ng-template #changeLabelTmpl>
                    <div class="timeline-label">
                        <pf-text>{{ change.userEmail }}</pf-text>
                        <pf-text type="description"
                            >{{ change.createdDate | PfDate }}
                        </pf-text>
                    </div>
                </ng-template>
                <platform-change-view
                    [autoExpand]="first"
                    [entityName]="entityName"
                    [entityChange]="change"
                    [customValueTransformer]="
                        facade.customValueFormatter
                    "></platform-change-view>
            </nz-timeline-item>
        </nz-timeline>
        <pf-button buttonType="submit" block (click)="openDetailedHistory()"
            >View Detailed History
        </pf-button>
    </section>
</pf-content>
