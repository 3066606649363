import { FiscalPeriodDataService } from '../infrastructure/fiscal-periods/fiscal-period.data.service';
import {
    FiscalPeriodCreate,
    FiscalPeriodDto,
    FiscalPeriodRead,
    FiscalPeriodSearchParams,
} from '../entities/fiscal-period.dto';
import { FiscalPeriodStore } from '../infrastructure/fiscal-periods/fiscal-period.store';
import { inject, Injectable, ViewContainerRef } from '@angular/core';
import { RoutesForFiscalPeriod } from './fiscal-period.routes';
import { AbstractManageEntityFacade, DateService } from '@pf/shared-services';
import { FiscalPeriodMapper } from '../infrastructure/fiscal-periods/fiscal-period.mapper';
import { BuildChangeTrackingFacadeFactory } from '@pf/shared/util-platform';
import { map, mergeMap, Observable, of } from 'rxjs';
import { EntitySaveOptions, SafeAny } from '@pf/shared-common';
import { ModalService } from '@pf/shared-ui';
import { FiscalPeriodStatus, ResourceAuth } from '@control-tower/platform-core';

@Injectable()
export class FiscalPeriodFacade extends AbstractManageEntityFacade<
    FiscalPeriodDto,
    FiscalPeriodRead,
    FiscalPeriodCreate,
    FiscalPeriodSearchParams
> {
    private _modalService = inject(ModalService);
    private _dateService = inject(DateService);
    nameField: keyof FiscalPeriodDto = 'name';
    overlappingModalComponent: SafeAny;
    viewContainerRef?: ViewContainerRef;

    constructor(
        dataService: FiscalPeriodDataService,
        routes: RoutesForFiscalPeriod,
        store: FiscalPeriodStore,
        mapper: FiscalPeriodMapper
    ) {
        super(dataService, routes, store, mapper);
    }

    override applyResourceAuth(body: FiscalPeriodCreate) {
        body.resourceAuth = {
            allAuthorized: true,
        } as ResourceAuth;
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        this.nameField
    );

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof FiscalPeriodDto, string>> {
        return { name: searchText };
    }

    closeFiscalPeriod$(id: string): Observable<FiscalPeriodRead> {
        const existing = this.store.get(id);
        existing.status = FiscalPeriodStatus.Closed;
        return this.dataService.update$(
            id,
            [
                {
                    op: 'replace',
                    path: '/status',
                    value: 'Closed',
                },
            ],
            existing
        );
    }

    checkOverlappingFiscalPeriods$(startDate: string, endDate: string) {
        return (
            this.dataService as FiscalPeriodDataService
        ).getOverlappingFiscalPeriods$(startDate, endDate);
    }

    override save$(
        dto: FiscalPeriodDto,
        options?: EntitySaveOptions<FiscalPeriodDto>
    ): Observable<FiscalPeriodDto> {
        return (
            dto.status === FiscalPeriodStatus.Open
                ? this.checkOverlappingFiscalPeriods$(
                      dto.startDate,
                      dto.endDate
                  )
                : of([])
        ).pipe(
            mergeMap(overlappingFiscalPeriods => {
                if (overlappingFiscalPeriods.length > 0) {
                    const isTheSamePeriod = overlappingFiscalPeriods.some(
                        period => period.id === dto.id
                    );

                    if (isTheSamePeriod) {
                        return super.save$(dto, options);
                    }

                    this._modalService.createComponentModal({
                        title: 'Conflicting Fiscal Periods',
                        content: this.overlappingModalComponent,
                        viewContainerRef: this.viewContainerRef,
                        componentParams: {
                            period: overlappingFiscalPeriods[0].name,
                            id: overlappingFiscalPeriods[0].id,
                        },
                        cancelText: 'Cancel',
                        okText: `View ${overlappingFiscalPeriods[0].name} Period`,
                        onOk: () => {
                            this.edit(
                                overlappingFiscalPeriods[0].id,
                                undefined,
                                true
                            );
                        },
                    });
                    throw new Error(
                        'Fiscal Periods cannot overlap. Please select a different date range.'
                    );
                }
                return super.save$(dto, options);
            })
        );
    }

    isFiscalPeriodOpen$(dateString: string): Observable<boolean> {
        const date = this._dateService.formatDate(dateString);
        return this.dataService
            .oneTimeSearch$({
                dateRangeStart: date,
                dateRangeEnd: date,
                status: FiscalPeriodStatus.Open,
                pageSize: 1,
                pageNumber: 1,
            } as FiscalPeriodSearchParams)
            .pipe(map(result => result.totalRecords > 0));
    }
}
