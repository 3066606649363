import {
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    IEntity,
    PFCommunicationsEntities,
    SafeAny,
    TableAction,
    TableColumn,
    TenantProvider,
} from '@pf/shared-common';
import {
    DrawerFormComponent,
    DrawerService,
    FormService,
    ModalService,
    TableActionsBuilder,
    TableColumnBuilder,
} from '@pf/shared-ui';
import { OneTimeLinkDto } from '@pf/shared/util-platform';
import { OneTimeLinkEditComponent } from './one-time-link-edit.component';
import { DateService, UserAccountType } from '@pf/shared-services';
import { OneTimeLinkFacade } from '@pf/platform-communications/domain';
import { ResourceAuth } from '@control-tower/platform-core';

@Component({
    selector: 'platform-one-time-links-form',
    templateUrl: './one-time-links-form.component.html',
    styleUrls: ['./one-time-links-form.component.scss'],
})
export class OneTimeLinksFormComponent implements OnInit {
    entityType = PFCommunicationsEntities.OneTimeLink;
    @Input() recipientEntityType!: string;
    @Input() recipientEntityRoleId!: string;
    @Input() associatedEntity!: IEntity & {
        resourceAuth: ResourceAuth;
    };
    @Input() parentEntityType!: string;
    @Input() collapsed = true;
    @Input() data: OneTimeLinkDto[] = [];
    @Input() defaultEmail?: string;

    @Output() oneTimeLinkSaved = new EventEmitter<OneTimeLinkDto>();
    @Output() oneTimeLinkDeleted = new EventEmitter<OneTimeLinkDto>();

    @ViewChild(DrawerFormComponent, { static: false })
    drawerForm!: DrawerFormComponent;

    drawerService = inject(DrawerService);
    formService = inject(FormService);
    dateService = inject(DateService);
    oneTimeLinkFacade = inject(OneTimeLinkFacade);
    modalService = inject(ModalService);

    columns: TableColumn<OneTimeLinkDto>[] = [];
    actions: TableAction<OneTimeLinkDto>[] = [];

    ngOnInit(): void {
        this.columns = this.setColumns();
        this.actions = this.setActions();
    }

    constructor(private tenantProvider: TenantProvider) {}

    addOneTimeLink() {
        const activeFormState = this.formService.activeState(
            this.parentEntityType
        );

        const expirationDayCount = this.tenantProvider.tenant?.emailing
            ?.defaultOneTimeLinkExpirationInHours
            ? this.tenantProvider.tenant.emailing
                  .defaultOneTimeLinkExpirationInHours / 24
            : 60;

        this.drawerService.createFormDrawer({
            entityType: this.entityType,
            parentFormId: activeFormState?.id,
            title: 'Create One-Time Link',
            component: OneTimeLinkEditComponent,
            componentProps: {
                isChild: true,
                entity: {
                    id: '',
                    associatedEntityId: this.associatedEntity.id,
                    expirationDate:
                        this.dateService.getFutureDate(expirationDayCount),
                    subject: 'Load Assigned',
                    sendEmailImmediately: true,
                    recipientAddress: '',
                    templateUrl: 'load-onetimelink.html',
                    templateData: {
                        ...this.associatedEntity,
                    },
                    recipientRoles: [this.recipientEntityRoleId],
                    recipientUserGroupType:
                        this.recipientEntityType.toLowerCase(),
                },
                saveOneTimeLinkSaved: this.oneTimeLinkSaved,
            },
        });
    }

    setColumns(): TableColumn<OneTimeLinkDto>[] {
        return new TableColumnBuilder<OneTimeLinkDto>()
            .withColumn({
                field: 'recipientAddress',
                headerName: 'Email',
                width: 250,
                showSort: false,
                search: false,
                defaultValue: this.defaultEmail,
            })
            .withStatusColumn(
                'isActive',
                null,
                {
                    false: {
                        status: 'danger',
                        label: 'Inactive',
                    },
                    true: {
                        status: 'success',
                        label: 'Active',
                    },
                    defaultOption: {
                        status: 'success',
                        label: 'Active',
                    },
                },
                {
                    exportValueFormatter: value =>
                        value ? 'Active' : 'Inactive',
                }
            )
            .withColumn({
                field: 'createdDate',
                type: 'date',
                search: false,
                headerName: 'Sent',
                showSort: false,
            })
            .withColumn({
                field: 'expirationDate',
                type: 'date',
                search: false,
                headerName: 'Expires',
                showSort: false,
            }).columns;
    }

    setActions(): TableAction<OneTimeLinkDto>[] {
        return new TableActionsBuilder<SafeAny>().withAction({
            name: 'Inactivate',
            textType: 'menu',
            onClick: row => {
                const rowData = row.data;
                if (rowData.id) {
                    this.modalService.confirmAction(
                        'One-Time Link',
                        () => {
                            this.oneTimeLinkFacade
                                .delete$(rowData.id)
                                .subscribe();
                            this.checkDeletedOneTimeLinks(rowData);
                            this.oneTimeLinkDeleted.emit(rowData);
                        },
                        'Inactivate',
                        undefined
                    );
                }
            },
            onRowChange: (row, action) => {
                action.hide = !row.data.isActive;
            },
        }).actions;
    }

    get isCreateDisabled(): boolean {
        return (
            this.data &&
            this.data.length > 0 &&
            this.data.filter(d => !d.isDeleted)?.length > 0
        );
    }

    private checkDeletedOneTimeLinks(row: OneTimeLinkDto) {
        switch (row.recipientUserGroupType) {
            case UserAccountType.Carrier:
                this.oneTimeLinkFacade.oneTimeLinks.carrierOneTimeLinks =
                    this.setDeletedOneTimeLinks(
                        this.oneTimeLinkFacade.oneTimeLinks.carrierOneTimeLinks,
                        row
                    );
                break;
            case UserAccountType.Vendor:
                this.oneTimeLinkFacade.oneTimeLinks.vendorOneTimeLinks =
                    this.setDeletedOneTimeLinks(
                        this.oneTimeLinkFacade.oneTimeLinks.vendorOneTimeLinks,
                        row
                    );
                break;
        }
    }

    private setDeletedOneTimeLinks(
        storedLinks: OneTimeLinkDto[],
        row: OneTimeLinkDto
    ) {
        return storedLinks.map(oneTimeLink => {
            if (oneTimeLink.recipientAddress === row.recipientAddress) {
                oneTimeLink.isDeleted = true;
                return new OneTimeLinkDto(oneTimeLink);
            }
            return oneTimeLink;
        });
    }
}
