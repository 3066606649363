import {
    CustomFieldConfig,
    EntityFacadeConfig,
    StatusTypeConfig,
    TypeConfig,
    TypeMatch,
} from '@pf/shared/util-platform';
import { ITypeEntityFragment } from '@pf/shared-common';
import { Observable } from 'rxjs';
import { LoadStatusTypeFragment } from '@control-tower/platform-loads';
import { Injectable } from '@angular/core';
import { LoadDto, LoadSearchParams } from '../../entities/load.dto';

export type LoadsView = 'recent' | 'history';

export type LoadStatusTypeConfig = StatusTypeConfig<
    LoadStatusTypeFragment & ITypeEntityFragment
> & { displayStats: boolean };
export type LoadStatusTypesConfig = LoadStatusTypeConfig[];

@Injectable()
export abstract class LoadFacadeConfig extends EntityFacadeConfig<LoadDto> {
    abstract newStatusType: TypeMatch;
    abstract cancelledStatusType: TypeMatch;
    abstract recentStatusTypes$: Observable<LoadStatusTypesConfig>;
    abstract historyStatusTypes$: Observable<LoadStatusTypesConfig>;
    abstract customFields$?: Observable<CustomFieldConfig[]>;
    abstract references$?: Observable<TypeConfig[]>;

    additionalSearchParams?(): Partial<LoadSearchParams>;
}
