import { Injectable } from '@angular/core';
import { PermissionTypes, PFLoadEntities } from '@pf/shared-common';
import { AbstractRouteBuilder } from '@pf/shared-services';
import { buildLoadPermissionPath } from '@pf/shared/util-platform';

@Injectable()
export class RoutesForLoadWorkflow extends AbstractRouteBuilder {
    permissionType = buildLoadPermissionPath(
        PermissionTypes.Modify,
        PFLoadEntities.LoadWorkflow
    );
}
