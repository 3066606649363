import { InjectionToken, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUtilPlatformModule } from '@pf/shared/util-platform';
import { LoadDataService } from './infrastructure/loads/load.data.service';
import { LoadFacade } from './application/loads/load.facade';
import { LoadStore } from './infrastructure/loads/load.store';
import { LoadMapper } from './infrastructure/loads/load.mapper';
import { LoadStatusTypeFacade } from './application/type-entities/LoadStatusTypeFacade';
import { StopDataService } from './infrastructure/stops/stop.data.service';
import { StopFacade } from './application/stops/stop.facade';
import { StopMapper } from './infrastructure/stops/stop.mapper';
import { StopStore } from './infrastructure/stops/stop.store';
import { StopTypeFacade } from './application/type-entities/StopTypeFacade';
import { ChargeTypeFacade } from './application/type-entities/ChargeTypeFacade';
import { ReferenceTypeFacade } from './application/type-entities/ReferenceTypeFacade';
import { SafeAny, sharedEnv } from '@pf/shared-common';
import {
    ApiModule as LoadsApiModule,
    Configuration as LoadsApiConfig,
    ConfigurationParameters,
} from '@control-tower/platform-loads';

import { StopItemFacade } from './application/stop-item.facade';
import { StopItemDataService } from './infrastructure/stop-items/stop-item.data.service';
import { StopItemMapper } from './infrastructure/stop-items/stop-item.mapper';
import { StopItemStore } from './infrastructure/stop-items/stop-item.store';
import { RoutesForStopItems } from './application/stop-item.routes';
import { LoadFacadeConfig } from './application/loads/load-facade.config';
import { LoadManageFacadeConfig } from './application/loads/load-manage-facade.config';
import { StopFacadeConfig } from './application/stops/stop-facade.config';
import {
    LoadDocumentsFacade,
    LoadDocumentsFacadeConfig,
} from './application/loads/load-documents.facade';
import { StopChargeFacadeConfig } from './application/stop-charge-facade.config';
import { LoadScheduleFacade } from './application/load-schedule.facade';
import { LoadScheduleDataService } from './infrastructure/load-schedules/load-schedule.data.service';
import { LoadScheduleStore } from './infrastructure/load-schedules/load-schedule.store';
import { LoadScheduleMapper } from './infrastructure/load-schedules/load-schedule.mapper';
import { LoadScheduleSummaryMapper } from './infrastructure/load-schedules/load-schedule-summary.mapper';
import { LoadScheduleSummaryStore } from './infrastructure/load-schedules/load-schedule-summary.store';
import { LoadScheduleSummaryDataService } from './infrastructure/load-schedules/load-schedule-summary.data.service';
import { LoadScheduleSummaryFacade } from './application/load-schedule-summary.facade';
import { LaneDataService } from './infrastructure/lanes/lane.data.service';
import { LaneStore } from './infrastructure/lanes/lane.store';
import { LaneMapper } from './infrastructure/lanes/lane.mapper';
import { LaneRateDataService } from './infrastructure/lane-rates/lane-rate.data.service';
import { LaneRateStore } from './infrastructure/lane-rates/lane-rate.store';
import { LaneRateMapper } from './infrastructure/lane-rates/lane-rate.mapper';
import { ItemChargeMapper } from './infrastructure/item-charges/item-charge.mapper';
import { ItemChargeStore } from './infrastructure/item-charges/item-charge.store';
import { ItemChargeDataService } from './infrastructure/item-charges/item-charge.data.service';
import { LaneCarrierDataService } from './infrastructure/lane-carriers/lane-carrier.data.service';
import { LaneCarrierStore } from './infrastructure/lane-carriers/lane-carrier.store';
import { LaneCarrierMapper } from './infrastructure/lane-carriers/lane-carrier.mapper';
import { LoadWorkflowFacade } from './application/load-workflow.facade';
import { LoadWorkflowDataService } from './infrastructure/load-workflows/load-workflow.data.service';
import { LoadWorkflowStore } from './infrastructure/load-workflows/load-workflow.store';
import { LoadWorkflowMapper } from './infrastructure/load-workflows/load-workflow.mapper';
import { WorkflowGlobalParametersFacade } from './application/workflow-global-parameters-facade.service';
import { WorkflowGlobalParametersDataService } from './infrastructure/workflow-global-parameters/workflow-global-parameters-data.service';
import { WorkflowGlobalParameterStore } from './infrastructure/workflow-global-parameters/workflow-global-parameter.store';
import { WorkflowGlobalParameterMapper } from './infrastructure/workflow-global-parameters/workflow-global-parameter.mapper';
import { WorkflowDefinitionModelProviderToken } from '@pf/shared/ui-platform';
import { LoadWorkflowDefinitionModelProvider } from './application/load-workflow-definition-model.provider';
import { LoadWorkflowGlobalParametersDefinitionModelProvider } from './application/load-workflow-global-parameters-definition-model.provider';
import { LoadFilterFacadeConfig } from './application/loads/load-filter-facade.config';

export const LOAD_SCHEDULER_WEBHOOK_URL = new InjectionToken(
    'LOAD_SCHEDULER_WEBHOOK_URL'
);

export type LoadProviderConfig = {
    loadFacadeConfig: Type<LoadFacadeConfig>;
    loadManageFacadeConfig?: Type<LoadManageFacadeConfig>;
    loadFilterFacadeConfig?: Type<LoadFilterFacadeConfig>;
    loadDocumentsFacadeConfig?: Type<LoadDocumentsFacadeConfig>;
    stopFacadeConfig?: Type<StopFacadeConfig>;
    stopChargeFacadeConfig?: Type<StopChargeFacadeConfig>;
    loadSchedulerWebhookUrl?: string;
};

const loadsApiConfigFactory = (basePath: string) => {
    return (): LoadsApiConfig => {
        const params: ConfigurationParameters = {
            basePath,
        };
        return new LoadsApiConfig(params);
    };
};

@NgModule({
    imports: [CommonModule, SharedUtilPlatformModule],
    providers: [
        LoadDataService,
        LoadFacade,
        LoadDocumentsFacade,
        LoadStore,
        LoadMapper,
        LoadStatusTypeFacade,
        StopDataService,
        StopFacade,
        StopStore,
        StopMapper,
        StopItemDataService,
        StopItemFacade,
        StopItemStore,
        StopItemMapper,
        RoutesForStopItems,
        StopTypeFacade,
        ChargeTypeFacade,
        ReferenceTypeFacade,
        LoadScheduleSummaryFacade,
        LoadScheduleSummaryDataService,
        LoadScheduleSummaryStore,
        LoadScheduleSummaryMapper,
        LoadScheduleFacade,
        LoadScheduleDataService,
        LoadScheduleStore,
        LoadScheduleMapper,
        LaneDataService,
        LaneStore,
        LaneMapper,
        LaneRateDataService,
        LaneRateStore,
        LaneRateMapper,
        ItemChargeMapper,
        ItemChargeStore,
        ItemChargeDataService,
        LaneCarrierDataService,
        LaneCarrierStore,
        LaneCarrierMapper,
        LoadWorkflowFacade,
        LoadWorkflowDataService,
        LoadWorkflowStore,
        LoadWorkflowMapper,
        WorkflowGlobalParametersFacade,
        WorkflowGlobalParametersDataService,
        WorkflowGlobalParameterStore,
        WorkflowGlobalParameterMapper,
        {
            provide: WorkflowDefinitionModelProviderToken,
            useClass: LoadWorkflowDefinitionModelProvider,
            multi: true,
        },
        {
            provide: WorkflowDefinitionModelProviderToken,
            useClass: LoadWorkflowGlobalParametersDefinitionModelProvider,
            multi: true,
        },
    ],
})
export class PlatformLoadsDomainModule {
    static ApiModule() {
        return LoadsApiModule.forRoot(
            loadsApiConfigFactory(sharedEnv.baseApiUrl + '/loads')
        );
    }

    static ConfigProviders(providers: LoadProviderConfig) {
        const configuredProviders: SafeAny[] = [
            {
                provide: LoadFacadeConfig,
                useClass: providers.loadFacadeConfig,
            },
            {
                provide: StopChargeFacadeConfig,
                useClass: providers.stopChargeFacadeConfig,
            },
            {
                provide: LoadDocumentsFacadeConfig,
                useClass: providers.loadDocumentsFacadeConfig,
            },
        ];
        if (providers.loadManageFacadeConfig) {
            configuredProviders.push({
                provide: LoadManageFacadeConfig,
                useClass: providers.loadManageFacadeConfig,
            });
        }
        if (providers.loadFilterFacadeConfig) {
            configuredProviders.push({
                provide: LoadFilterFacadeConfig,
                useClass: providers.loadFilterFacadeConfig,
            });
        }
        if (providers.stopFacadeConfig) {
            configuredProviders.push({
                provide: StopFacadeConfig,
                useClass: providers.stopFacadeConfig,
            });
        }
        if (providers.loadSchedulerWebhookUrl) {
            configuredProviders.push({
                provide: LOAD_SCHEDULER_WEBHOOK_URL,
                useValue: providers.loadSchedulerWebhookUrl,
            });
        }
        return configuredProviders;
    }
}
