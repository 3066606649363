import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFLoadEntities,
} from '@pf/shared-common';
import { createMap, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';
import {
    LoadWorkflowCreate,
    LoadWorkflowDto,
    LoadWorkflowRead,
    LoadWorkflowSearchParams,
} from '../../entities/load-workflow.dto';

const symbols = entitySymbols(PFLoadEntities.LoadWorkflow);

withDefaultEntityMetadata<LoadWorkflowDto>(symbols.dto, {
    workflow: Object,
    name: String,
    customerIds: [String],
    description: String,
    filterExpression: String,
    locationIds: [String],
    vendorIds: [String],
    carrierIds: [String],
});

withDefaultEntityMetadata<LoadWorkflowRead>(symbols.entity, {
    workflow: Object,
    name: String,
    customerIds: [String],
    description: String,
    filterExpression: String,
    locationIds: [String],
    vendorIds: [String],
    carrierIds: [String],
    isDefault: Boolean,
});

withMetadata<LoadWorkflowCreate>(symbols.createBody, {
    workflow: Object,
    name: String,
    customerIds: [String],
    description: String,
    filterExpression: String,
    locationIds: [String],
    vendorIds: [String],
    carrierIds: [String],
    isDefault: Boolean,
});

withDefaultSearchParamsMetadata<LoadWorkflowSearchParams>(
    symbols.searchParams,
    {
        name: String,
    }
);

export const LoadWorkflowMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<LoadWorkflowRead, LoadWorkflowDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<LoadWorkflowDto, LoadWorkflowRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<LoadWorkflowDto, LoadWorkflowCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
        createMap<IQueryParams<LoadWorkflowDto>, LoadWorkflowSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('name'),
            ...withDefaultSearchParamsMappingConfiguration()
        );
    },
};
