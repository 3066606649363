import { LoadScheduleDataService } from '../infrastructure/load-schedules/load-schedule.data.service';
import {
    LoadScheduleCreate,
    LoadScheduleDto,
    LoadScheduleRead,
    LoadScheduleSearchParams,
} from '../entities/load-schedule.dto';
import { LoadScheduleStore } from '../infrastructure/load-schedules/load-schedule.store';
import { Inject, Injectable } from '@angular/core';
import { AbstractManageEntityFacade } from '@pf/shared-services';
import { LoadScheduleMapper } from '../infrastructure/load-schedules/load-schedule.mapper';
import { EntitySaveOptions, IManageEntityRoutes } from '@pf/shared-common';
import { catchError, map, Observable, of } from 'rxjs';
import { LOAD_SCHEDULER_WEBHOOK_URL } from '../platform-loads-domain.module';

@Injectable()
export class LoadScheduleFacade extends AbstractManageEntityFacade<
    LoadScheduleDto,
    LoadScheduleRead,
    LoadScheduleCreate,
    LoadScheduleSearchParams
> {
    nameField: keyof LoadScheduleDto = 'jobName';

    constructor(
        dataService: LoadScheduleDataService,
        store: LoadScheduleStore,
        mapper: LoadScheduleMapper,
        @Inject(LOAD_SCHEDULER_WEBHOOK_URL)
        private loadSchedulerWebhookUrl: string
    ) {
        super(
            dataService,
            null as unknown as IManageEntityRoutes,
            store,
            mapper
        );
    }

    override add() {
        this.store.resetActiveEntity();
    }

    override manage() {
        this.store.resetActiveEntity();
    }

    override edit(entityId: string): void {
        this.store.setActiveEntity(entityId);
    }

    override save$(
        dto: LoadScheduleDto,
        options?: EntitySaveOptions<LoadScheduleDto>
    ): Observable<LoadScheduleDto> {
        if (!dto.parameters) {
            throw new Error('Parameters are required');
        }
        dto.parameters.webHookUrl = this.loadSchedulerWebhookUrl;
        return super.save$(dto, options);
    }

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof LoadScheduleDto, string>> {
        return { jobName: searchText };
    }

    getByOriginLocationId$(originLocationId: string) {
        return this.oneTimeSearch$({
            pageIndex: 1,
            pageSize: 50,
            filters: {
                originLocationId: originLocationId,
            },
        }).pipe(
            map(result => result.data),
            catchError(() => of([] as LoadScheduleDto[]))
        );
    }
}
