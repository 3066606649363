import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    EntityChangeTrackingDto,
    IPagedResult,
    PFLoadEntities,
} from '@pf/shared-common';
import {
    LoadScheduleSummaryCreate,
    LoadScheduleSummaryRead,
    LoadScheduleSummarySearchParams,
} from '../../entities/load-schedule-summary.dto';
import { LoadScheduleSummaryStore } from './load-schedule-summary.store';
import { v4 as uuidV4 } from 'uuid';
import {
    RecurringLoadSchedulesService,
    V1RecurringLoadSchedulesSummaryGetRequestParams,
} from '@control-tower/platform-loads';
import { asArraySafe } from '@pf/shared-utility';

@Injectable()
export class LoadScheduleSummaryDataService extends AbstractDataService<
    LoadScheduleSummaryRead,
    LoadScheduleSummaryCreate
> {
    protected EntityName = PFLoadEntities.LoadSchedule;

    constructor(
        private service: RecurringLoadSchedulesService,
        store: LoadScheduleSummaryStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(): Observable<LoadScheduleSummaryRead> {
        throw new Error(
            'Method not implemented. Schedules must be created via the LoadScheduleDataService'
        );
    }

    protected deleteObs$(): Observable<LoadScheduleSummaryRead> {
        throw new Error(
            'Method not implemented. Schedules must be deleted via the LoadScheduleDataService'
        );
    }

    protected undoDeleteObs$(): Observable<LoadScheduleSummaryRead> {
        throw new Error(
            'Method not implemented. Schedules must be deleted via the LoadScheduleDataService'
        );
    }

    protected getObs$(
        originLocationId: string
    ): Observable<LoadScheduleSummaryRead> {
        return this.searchObs$({
            originLocationId: [originLocationId],
            isDeleted: false,
            pageSize: 1,
            pageNumber: 1,
        }).pipe(
            map(result => {
                return (
                    result.data?.length > 0 ? result.data[0] : null
                ) as LoadScheduleSummaryRead;
            })
        );
    }

    protected updateObs$(): Observable<LoadScheduleSummaryRead> {
        throw new Error(
            'Method not implemented. Schedules must be updated via the LoadScheduleDataService'
        );
    }

    protected searchObs$(
        searchParams: LoadScheduleSummarySearchParams
    ): Observable<IPagedResult<LoadScheduleSummaryRead>> {
        return this.service
            .v1RecurringLoadSchedulesSummaryGet(
                searchParams as V1RecurringLoadSchedulesSummaryGetRequestParams
            )
            .pipe(
                map(result => {
                    asArraySafe(result.data).forEach(r => {
                        (r as LoadScheduleSummaryRead).id =
                            r.origin?.id || uuidV4();
                    });
                    return result as IPagedResult<LoadScheduleSummaryRead>;
                })
            );
    }

    protected searchChangesObs$(): Observable<
        IPagedResult<EntityChangeTrackingDto<LoadScheduleSummaryRead>>
    > {
        throw new Error('Method not implemented. Changes are not supported');
    }
}
