import { IEntity } from '@pf/shared-common';
import {
    LoadWorkflowEntitySearchRequestParams,
    WorkflowCreateBody,
    WorkflowViewModelRead,
} from '@control-tower/platform-loads';
import { WorkflowViewModel } from '@control-tower/platform-loads/model/workflowViewModel';

export type LoadWorkflowRead = WorkflowViewModelRead & IEntity;
export type LoadWorkflowCreate = WorkflowCreateBody;
export type LoadWorkflowSearchParams = LoadWorkflowEntitySearchRequestParams;

export class LoadWorkflowDto implements IEntity {
    constructor(data: Partial<LoadWorkflowDto>) {
        Object.assign(this, data);
    }

    id!: string;
    createdDate?: string;
    modifiedDate?: string;

    customerIds?: string[] | null;
    description?: null | string;
    filterExpression?: null | string;
    isDeleted?: boolean;
    locationIds?: string[] | null;
    name?: null | string;
    vendorIds?: string[] | null;
    carrierIds?: string[] | null;
    workflow!: WorkflowViewModel;
}
