<pf-table
    [data]="contacts$ | async | safeArray"
    [columns]="contactColumns"
    [actions]="tableActions"
    (tableReset)="tableReset.emit()">
    <pf-button
        *ngIf="canAdd"
        buttonType="add"
        toolbar-right
        platformRequiredPermissions
        [permissions]="permissions"
        [hideElementIfNoPermission]="true"
        permissionCheckMethod="All"
        (click)="addAction.emit()"
        >Add New
    </pf-button>
    <ng-content ngProjectAs="[toolbar-right]"></ng-content>
</pf-table>
