<nz-statistic
    [nzValue]="pfValue === undefined ? '' : (pfValue! | number: '1.0-3')!"
    [nzTitle]="pfTitle"
    [nzPrefix]="statisticValueRenderer"
    [nzValueStyle]="{
        fontSize: this.pfSize === 'small' ? '14px' : '24px',
        color: this.pfColor ? this.pfColor! : 'inherit'
    }"></nz-statistic>
<ng-template #statisticValueRenderer>
    <ng-container *ngIf="pfValue === undefined || pfValue === ''">
        <nz-skeleton-element
            nzType="avatar"
            [nzActive]="true"
            nzShape="square"></nz-skeleton-element>
    </ng-container>
</ng-template>
