import { Component, Input } from '@angular/core';
import {
    FrameworkColumn,
    ITableCellValueRenderer,
    IconType,
    SafeAny,
} from '@pf/shared-common';

export interface ActionRendererProps {
    actionFn: (value: SafeAny, row: SafeAny) => void;
}

@Component({
    selector: 'pf-action-renderer',
    template: ` <a (click)="actionFn(value, row)"
        ><pf-icon *ngIf="iconType && value" [iconType]="iconType"></pf-icon>
        {{ value }}</a
    >`,
    styles: [],
})
export class ActionRendererComponent
    implements ITableCellValueRenderer, ActionRendererProps
{
    @Input() value!: SafeAny;
    @Input() column!: FrameworkColumn;
    @Input() row!: SafeAny;
    @Input() actionFn!: (value: SafeAny, row: SafeAny) => void;
    @Input() iconType?: keyof typeof IconType;
}
