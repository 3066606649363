import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { SafeAny } from '@pf/shared-common';
import { switchMap } from 'rxjs';
import { asArraySafe } from '@pf/shared-utility';

export interface FilterMetadata {
    name: string;
    key: string;
    value: SafeAny | SafeAny[];
    props?: SafeAny;
}

export interface ScreenFilters {
    initialFilters: FilterMetadata[];
    appliedFilters: FilterMetadata[];
}

export interface FilterState {
    [screen: string]: ScreenFilters;
}

const filterStore = createStore(
    { name: 'filter-store' },
    withProps<FilterState>({})
);

export const persistedState = persistState(filterStore as SafeAny, {
    storage: localStorageStrategy,
});

export const filterState$ = persistedState.initialized$.pipe(
    switchMap(() => {
        return filterStore;
    })
);

export function getFilterState() {
    return filterStore.value;
}

export const selectAppliedFiltersByScreen = (screen: string) =>
    select((state: FilterState) => state[screen]?.appliedFilters || []);

export const selectInitialFiltersByScreen = (screen: string) =>
    select((state: FilterState) => state[screen]?.initialFilters || []);

export const setInitialFiltersForScreen = (
    screen: string,
    filters: FilterMetadata[]
) =>
    filterStore.update(state => ({
        ...state,
        [screen]: {
            ...state[screen],
            initialFilters: filters,
            appliedFilters: state[screen]?.appliedFilters || [],
        },
    }));

export const setAppliedFiltersForScreen = (
    screen: string,
    filters: FilterMetadata[]
) =>
    filterStore.update(state => ({
        ...state,
        [screen]: {
            ...state[screen],
            appliedFilters: filters,
            initialFilters: state[screen]?.initialFilters || [],
        },
    }));

export const upsertAppliedFilterForScreen = (
    screen: string,
    filter: FilterMetadata
) => {
    filterStore.update(state => {
        const screenFilters = state[screen] || {
            initialFilters: [],
            appliedFilters: [],
        };
        const appliedFilters = [...asArraySafe(screenFilters.appliedFilters)];
        const filterId = filter.name;
        const index = appliedFilters.findIndex(f => f.name === filterId);

        if (index !== -1) {
            appliedFilters[index] = filter;
        } else {
            appliedFilters.push(filter);
        }

        return {
            ...state,
            [screen]: {
                ...screenFilters,
                appliedFilters,
            },
        };
    });
};

export const upsertInitialFilterForScreen = (
    screen: string,
    filter: FilterMetadata
) => {
    filterStore.update(state => {
        const screenFilters = state[screen] || {
            initialFilters: [],
            appliedFilters: [],
        };
        const initialFilters = [...asArraySafe(screenFilters.initialFilters)];
        const filterId = filter.name;
        const index = initialFilters.findIndex(f => f.name === filterId);

        if (index !== -1) {
            initialFilters[index] = filter;
        } else {
            initialFilters.push(filter);
        }

        return {
            ...state,
            [screen]: {
                ...screenFilters,
                initialFilters,
            },
        };
    });
};

export const removeAppliedFilterForScreen = (
    screen: string,
    filterName: string
) => {
    filterStore.update(state => {
        const screenFilters = state[screen] || {
            initialFilters: [],
            appliedFilters: [],
        };
        const appliedFilters = screenFilters.appliedFilters.filter(
            f => f.name !== filterName
        );

        return {
            ...state,
            [screen]: {
                ...screenFilters,
                appliedFilters,
            },
        };
    });
};

export const resetAppliedFiltersForScreen = (screen: string) =>
    filterStore.update(state => ({
        ...state,
        [screen]: {
            ...state[screen],
            appliedFilters: [],
        },
    }));
