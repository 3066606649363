import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { ProfileProvider } from '@pf/shared-common';
import { combineLatest, take } from 'rxjs';

const AdministratorGroupType = 'administrator';
const FirebendEmailDomain = '@firebend.com';

@Directive({
    selector: '[pfFirebendAdmin]',
})
export class FirebendAdminDirective implements AfterViewInit {
    constructor(
        private profileProvider: ProfileProvider,
        private elRef: ElementRef,
        private renderer: Renderer2
    ) {}

    ngAfterViewInit(): void {
        this.checkGroupType();
    }

    private checkGroupType() {
        combineLatest([
            this.profileProvider.userGroupType$,
            this.profileProvider.email$,
        ])
            .pipe(take(1))
            .subscribe(([userGroupType, email]) => {
                if (
                    userGroupType.toLowerCase() === AdministratorGroupType &&
                    email.endsWith(FirebendEmailDomain)
                ) {
                    return;
                }
                this.renderer.removeChild(
                    this.elRef.nativeElement.parentNode,
                    this.elRef.nativeElement);
            });
    }
}
