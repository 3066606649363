import { Injectable } from '@angular/core';
import { AbstractCustomFieldConfig, IContact } from '@pf/shared/util-platform';
import { TableColumnBuilder } from '@pf/shared-ui';
import { ICustomField, SafeAny } from '@pf/shared-common';

@Injectable()
export abstract class ContactManageConfig extends AbstractCustomFieldConfig {}

export const contactCustomFieldColumn = (
    builder: TableColumnBuilder<IContact>,
    customFieldKey: string,
    customFieldTitle?: string,
    popoverDisplayComponent?: SafeAny
) => {
    return builder.withListPreviewColumn(
        'customFields',
        customFieldTitle || 'Custom Field',
        {
            displayFn: (field: ICustomField) => {
                if (field.key === customFieldKey) {
                    return field.value || '';
                }
                return '';
            },
            popoverDisplayComponent: popoverDisplayComponent,
        },
        {} as SafeAny,
        'customFields'
    );
};
