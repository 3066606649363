import { TemplateRef, ViewContainerRef } from '@angular/core';
import { SafeAny } from '../types/SafeTypes';

export interface IModalContent {
    title: string;
    content: string | SafeAny | TemplateRef<SafeAny>;
    onOk?: () => void | false | Promise<void | false>;
    okText?: string;
    onCancel?: () => void;
    /// If null, the cancel button will not be shown
    cancelText?: string | null;
    isOkDisabled?: boolean;
    maskClosable?: boolean;
    size?: ModalSize;
}

export interface IComponentModalContent<TComponentParams>
    extends IModalContent {
    viewContainerRef?: ViewContainerRef;
    componentParams?: TComponentParams;
}

export enum ModalSize {
    Small = '30%',
    Medium = '50%',
    Large = '75%',
}
