import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
    OperationStatusService,
    OperationType,
} from '../../services/operation-status.service';

@Component({
    selector: 'pf-lazy-content',
    templateUrl: './lazy-content.component.html',
    styleUrls: ['./lazy-content.component.scss'],
})
export class LazyContentComponent implements OnInit, OnDestroy {
    @Input() operation!: OperationType;
    @Input() isLoading: boolean = true;
    private operationStatusSubscription!: Subscription;

    constructor(private operationStatusService: OperationStatusService) {}

    ngOnInit() {
        console.log(
            'LazyContentComponent initialized with operation:',
            this.operation
        );

        if (this.operation) {
            const operationStatus$ =
                this.operationStatusService.getOperationStatus(this.operation);
            if (operationStatus$) {
                this.operationStatusSubscription = operationStatus$!.subscribe(
                    isLoading => {
                        this.isLoading = isLoading;
                    }
                );
            }
        }
    }

    ngOnDestroy() {
        this.operationStatusSubscription?.unsubscribe();
    }
}
