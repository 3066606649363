import { AbstractEntityMapper } from '@pf/shared-services';
import {
    LoadScheduleCreate,
    LoadScheduleDto,
    LoadScheduleRead,
    LoadScheduleSearchParams,
} from '../../entities/load-schedule.dto';
import { Injectable } from '@angular/core';
import { PlatformLoadsMapper } from '../platform-loads.mapper';
import { LoadScheduleMetadataMap } from './load-schedule.mapping-profile';

@Injectable()
export class LoadScheduleMapper extends AbstractEntityMapper<
    LoadScheduleRead,
    LoadScheduleCreate,
    LoadScheduleDto,
    LoadScheduleSearchParams
> {
    constructor() {
        super(
            LoadScheduleDto,
            PlatformLoadsMapper,
            LoadScheduleMetadataMap.symbols
        );
    }
}
