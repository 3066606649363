import { IEntity, ITypeEntityFragment } from '@pf/shared-common';
import {
    ContactSearchRequestParams,
    ContactViewModelCreateBody,
    ContactViewModelRead,
} from '@control-tower/platform-core';
import { IContact } from '../models/IContact';
import { ICustomFieldDto } from '../models/custom-fields.dto';

export type ContactRead = ContactViewModelRead & IEntity;
export type ContactCreate = ContactViewModelCreateBody;
export type ContactSearchParams = ContactSearchRequestParams;

export class ContactDto implements IContact {
    constructor(data: Partial<IContact>) {
        Object.assign(this, data);
        if (!this.fullName) {
            this.fullName = `${this.firstName || ''} ${
                this.lastName || ''
            }`.trim();
        }

        if (!this.id) {
            this.id = this.fullName;
        }

        if (!this.searchName) {
            this.searchName = `${this.firstName || ''} ${
                this.lastName || ''
            } \n ${this.email || ''}`.trim();
        }
    }

    id!: string;
    fullName!: string;
    firstName!: string;
    lastName!: string;
    email?: string;
    phone?: string;
    fax?: string;
    description?: string;
    contactType?: ITypeEntityFragment;
    isDefault = false;
    searchName!: string;
    customFields?: ICustomFieldDto[];
}

export class ContactDisplayDto {
    constructor(contactDto: ContactDto) {
        this.fullName = contactDto.fullName;
        this.email = contactDto.email;
        this.phone = contactDto.phone;
        this.fax = contactDto.fax;
        this.description = contactDto.description;
        this.contactType = contactDto.contactType?.name;
        this.customFields = contactDto.customFields?.map(
            field => `${field.key}: ${field.value}`
        );
    }

    fullName!: string;
    email?: string;
    phone?: string;
    fax?: string;
    description?: string;
    contactType?: string;
    customFields?: string[];
}
