import { IEnvironment } from './IEnvironment';

export const sharedEnv: IEnvironment = {
    baseApiUrl: 'https://platform-qa.controltower.tech/api',
    baseRealTimeUrl: 'https://platform-qa.controltower.tech',
    auth0: {
        domain: 'auth-qa.controltowerapp.com',
        authorizationParams: {
            audience: 'https://ct-platform-qa.us.auth0.com/api/v2/',
        },
    },
    jwtConfig: {
        allowedList: [
            'platform-qa.controltower.tech',
            'radiusrecycling-qa.api.controltowerapp.com',
        ],
        disallowedList: [/\/one-time-links\/[a-f\d-]+\/authenticate/],
    },
};
