import { IconProps, IconType } from '@pf/shared-common';
import {
    faAddressCard,
    faAngleDown,
    faAnglesDown,
    faAnglesUp,
    faAngleUp,
    faArrowsRotate,
    faBarcode,
    faBars,
    faBox,
    faChartPie,
    faCircleCheck,
    faCircleExclamation,
    faCircleInfo,
    faCircleXmark,
    faCloudArrowUp,
    faCopy,
    faDragon,
    faEdit,
    faEllipsisV,
    faEnvelope,
    faEye,
    faFileExport,
    faFilter,
    faGear,
    faGripVertical,
    faHome,
    faList,
    faLocationPin,
    faMoneyCheck,
    faPhone,
    faPlay,
    faPlus,
    faQuestionCircle,
    faRotateLeft,
    faRoute,
    faSearch,
    faTimesCircle,
    faTriangleExclamation,
    faTruck,
    faTruckRampBox,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import {
    faBell,
    faClipboard,
    faEyeSlash,
    faSquareMinus,
    faSquarePlus,
    faUser,
} from '@fortawesome/free-regular-svg-icons';

const getProps = (iconDef: IconDefinition, style?: string) => {
    return {
        faIcon: iconDef,
        pathData: iconDef.icon[4],
        width: iconDef.icon[0],
        height: iconDef.icon[1],
        style: style,
    };
};

export const IconMap: Map<IconType, IconProps> = new Map<IconType, IconProps>([
    [IconType.barcode, getProps(faBarcode)],
    [IconType.carrier, getProps(faTruck)],
    [IconType.circleX, getProps(faCircleXmark, 'regular')],
    [IconType.close, getProps(faTimesCircle)],
    [IconType.clone, getProps(faCopy)],
    [IconType.collapse, getProps(faAngleUp)],
    [IconType.collapseAll, getProps(faAnglesUp)],
    [IconType.collapseTree, getProps(faSquareMinus)],
    [IconType.contact, getProps(faAddressCard)],
    [IconType.copy, getProps(faClipboard)],
    [IconType.drag, getProps(faGripVertical)],
    [IconType.edit, getProps(faEdit)],
    [IconType.email, getProps(faEnvelope)],
    [
        IconType.error,
        getProps(faCircleExclamation, 'color: var(--ant-error-color)'),
    ],
    [IconType.expand, getProps(faAngleDown)],
    [IconType.expandAll, getProps(faAnglesDown)],
    [IconType.expandTree, getProps(faSquarePlus)],
    [IconType.export, getProps(faFileExport)],
    [IconType.filter, getProps(faFilter)],
    [IconType.firebend, getProps(faDragon)],
    [IconType.hide, getProps(faEyeSlash)],
    [IconType.home, getProps(faHome)],
    [IconType.info, getProps(faCircleInfo, 'color: var(--ant-info-color)')],
    [IconType.inlineMenu, getProps(faEllipsisV)],
    [IconType.item, getProps(faBox)],
    [IconType.list, getProps(faList)],
    [IconType.load, getProps(faTruckRampBox)],
    [IconType.location, getProps(faLocationPin)],
    [IconType.manage, getProps(faGear)],
    [IconType.menu, getProps(faBars)],
    [IconType.moneyCheck, getProps(faMoneyCheck)],
    [IconType.notification, getProps(faBell)],
    [IconType.phone, getProps(faPhone)],
    [IconType.play, getProps(faPlay)],
    [IconType.plus, getProps(faPlus)],
    [IconType.question, getProps(faQuestionCircle)],
    [IconType.refresh, getProps(faArrowsRotate)],
    [IconType.reporting, getProps(faChartPie)],
    [IconType.route, getProps(faRoute)],
    [IconType.search, getProps(faSearch)],
    [
        IconType.success,
        getProps(faCircleCheck, 'color: var(--ant-success-color)'),
    ],
    [IconType.transportation, getProps(faTruck)],
    [IconType.undo, getProps(faRotateLeft)],
    [IconType.upload, getProps(faCloudArrowUp)],
    [IconType.user, getProps(faUser)],
    [IconType.view, getProps(faEye)],
    [
        IconType.warning,
        getProps(faTriangleExclamation, 'color: var(--ant-warning-color)'),
    ],
]);

export interface IIconPopover {
    title: string;
    content: string;
}
