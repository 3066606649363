<article pfArticle [pfTitle]="title">
    <form [formGroup]="form.formGroup">
        <pf-table
            [data]="loading ? [] : form.formGroup.controls.entities.value"
            [loading]="loading"
            [columns]="columns"
            [actions]="actions"
            [options]="tableOptions"
            [disableAutoPageSize]="true"
            [pageSize]="5"
            [width]="'100%'">
            <ng-container *ngIf="!useCustomToolbarContent" toolbar-title>
                <pf-select
                    label=""
                    id="entitySearch"
                    [tree]="tree"
                    [placeholder]="'Search ' + title"
                    [clearAfterSelect]="true"
                    [searchFacade]="facade"
                    [controlName]="form.formControlNames.selector"
                    (selected)="addEntity($event)"
                    [pfDisabled]="disableSelector"
                    [title]="
                        disableSelector
                            ? maxAssociationsSelectTitle
                            : selectTitle
                    "></pf-select>
            </ng-container>
            <ng-content ngProjectAs="[toolbar-title]"></ng-content>
            <ng-content ngProjectAs="[toolbar-right]"></ng-content>
        </pf-table>
    </form>
</article>
