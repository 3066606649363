import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFLoadEntities,
} from '@pf/shared-common';
import { createMap, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';
import {
    LaneRateCreate,
    LaneRateDto,
    LaneRateRead,
    LaneRateSearchParams,
} from '../../entities/lane-rate.dto';
import {
    CustomFieldMetadataMap,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';
import { ItemChargeMetadataMap } from '../item-charges/item-charge.mapping-profile';

const symbols = entitySymbols(PFLoadEntities.LaneRate);

withDefaultEntityMetadata<LaneRateDto>(symbols.dto, {
    laneId: String,
    classification: String,
    code: String,
    customFields: [CustomFieldMetadataMap.symbols.dto],
    description: String,
    endDate: String,
    itemCharges: [ItemChargeMetadataMap.symbols.dto],
    maxRate: Number,
    minRate: Number,
    name: String,
    startDate: String,
});

withDefaultEntityMetadata<Omit<LaneRateRead, 'transportationCharges'>>(
    symbols.entity,
    {
        classification: String,
        code: String,
        customFields: [CustomFieldMetadataMap.symbols.entity],
        description: String,
        endDate: String,
        itemCharges: [ItemChargeMetadataMap.symbols.entity],
        maxRate: Number,
        minRate: Number,
        name: String,
        startDate: String,
        resourceAuth: ResourceAuthSymbol,
    }
);

withMetadata<LaneRateCreate>(symbols.createBody, {
    classification: String,
    code: String,
    description: String,
    endDate: String,
    laneId: String,
    maxRate: Number,
    minRate: Number,
    name: String,
    resourceAuth: ResourceAuthSymbol,
    startDate: String,
});

withDefaultSearchParamsMetadata<LaneRateSearchParams>(symbols.searchParams, {
    name: String,
    laneId: String,
    startDateMin: String,
    endDateMax: String,
});

export const LaneRateMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<LaneRateRead, LaneRateDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<LaneRateDto, LaneRateRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<LaneRateDto, LaneRateCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
        createMap<IQueryParams<LaneRateDto>, LaneRateSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('name'),
            withSearchFilter('laneId'),
            withSearchFilter('code'),
            ...withDefaultSearchParamsMappingConfiguration()
        );

        ItemChargeMetadataMap.profile(mapper);
    },
};
