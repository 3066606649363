import {
    ChangeDetectorRef,
    Component,
    HostBinding,
    HostListener,
    inject,
    Input,
    OnInit,
    TemplateRef,
    Type,
    ViewChild,
} from '@angular/core';
import {
    FrameworkColumn,
    ITableCellValueRenderer,
    SafeAny,
} from '@pf/shared-common';

import { IValuePresentationComponent } from '../interfaces/IValuePresentationComponent';
import { InjectionHostDirective } from '../directives/injection-host.directive';
import { InjectionService } from '../services/injection.service';

export interface ListPreviewProps {
    actionFn?: (value: SafeAny, row: SafeAny) => void;
    displayFn: (value: SafeAny) => string;
    popoverDisplayFn?: (value: SafeAny) => string[];
    popoverDisplayComponent?: Type<{ value: SafeAny }>;
}

@Component({
    selector: 'pf-list-preview-renderer',
    template: `
        <pf-text
            type="button"
            nz-popover
            [nzPopoverTrigger]="popoverTrigger"
            [nzPopoverTitle]="first"
            [nzPopoverContent]="summaryRef || contentTemplate"
            >{{ first }}
        </pf-text>
        <ng-container *ngIf="others">
            <pf-text nz-popover [nzPopoverContent]="othersContentTemplate"
                >{{ others }}
            </pf-text>
            <ng-template #othersContentTemplate>
                <pf-text *ngFor="let item of othersPopoverInfo"
                    >{{ item }}
                </pf-text>
            </ng-template>
        </ng-container>
        <ng-template #contentTemplate>
            <pf-text *ngFor="let item of popoverInfo">{{ item }}</pf-text>
        </ng-template>
        <ng-container pfHost></ng-container>
    `,
    styles: [
        `
            :host {
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: var(--pf-gap);
                flex-wrap: wrap;

                pf-text {
                    white-space: pre;
                }
            }

            :host.actionable {
                cursor: pointer;
            }
        `,
    ],
})
export class ListPreviewRendererComponent
    implements ITableCellValueRenderer, ListPreviewProps, OnInit
{
    private _cdr = inject(ChangeDetectorRef);
    private _value: SafeAny[] = [];

    @ViewChild(InjectionHostDirective, { static: true })
    injectionHost!: InjectionHostDirective;

    @Input() column!: FrameworkColumn;
    @Input() row!: SafeAny;
    @Input() displayFn!: (value: SafeAny) => string;
    @Input() popoverDisplayFn?: (value: SafeAny) => string[];
    @Input() popoverDisplayComponent?: Type<IValuePresentationComponent>;
    @Input() actionFn?: (value: SafeAny, row: SafeAny) => void;
    summaryRef?: TemplateRef<void>;
    @Input() noDataValue = '';

    @Input() set value(data: SafeAny[]) {
        this._value = data;
        this._cdr.markForCheck();
    }

    get value() {
        return this._value;
    }

    constructor(private injectionService: InjectionService) {}

    ngOnInit(): void {
        this.loadComponent();
    }

    get popoverTrigger() {
        return this.popoverDisplayFn || this.popoverDisplayComponent
            ? 'hover'
            : null;
    }

    get first() {
        if (!this.displayFn) {
            console.error(
                'firstItemDisplayFn was not provided to ListPreviewRendererComponent'
            );
            return '';
        }
        return this.value.length > 0
            ? this.displayFn(this.value[0])
            : this.noDataValue;
    }

    get popoverInfo() {
        if (!this.popoverDisplayFn) {
            return [];
        }
        return this.popoverDisplayFn(this.value[0]);
    }

    get others() {
        return this.value?.length > 1 ? `+ ${this.value.length - 1} more` : '';
    }

    get othersPopoverInfo() {
        return this.value.slice(1).map(item => this.displayFn(item));
    }

    @HostBinding('class.actionable')
    get isActionable() {
        return !!this.actionFn;
    }

    @HostListener('click')
    private actionHandler() {
        if (!this.actionFn) {
            return;
        }
        this.actionFn(this.value, this.row);
    }

    private loadComponent() {
        if (this.popoverDisplayComponent) {
            const componentRef = this.injectionService.appendComponentHost({
                component: this.popoverDisplayComponent,
                hostLocation: this.injectionHost.viewContainerRef,
                props: {
                    value: this.value[0],
                },
            });
            this.summaryRef = (
                componentRef.instance as IValuePresentationComponent
            ).summaryTpl;
        }
    }
}
