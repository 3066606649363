import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { LoadScheduleRead } from '../../entities/load-schedule.dto';
import { PFLoadEntities } from '@pf/shared-common';

@Injectable()
export class LoadScheduleStore extends AbstractEntityStore<LoadScheduleRead> {
    constructor() {
        super(PFLoadEntities.LoadSchedule);
    }
}
