import { AppState, AuthService as Auth0 } from '@auth0/auth0-angular';
import { Injectable } from '@angular/core';
import { PlatformAuthService, SafeAny } from '@pf/shared-common';
import {
    catchError,
    filter,
    first,
    map,
    Observable,
    of,
    switchMap,
} from 'rxjs';
import { datadogRum } from '@datadog/browser-rum';
import { OneTimeLinkService } from './one-time-link.service';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';

@Injectable()
export class Auth0AuthService extends PlatformAuthService {
    constructor(
        private authService: Auth0,
        private oneTimeLinkService: OneTimeLinkService,
        private router: Router
    ) {
        super();
    }

    isLoading$ = this.oneTimeLinkService.isAuthenticated$.pipe(
        switchMap(isAuthenticated =>
            isAuthenticated ? of(false) : this.authService.isLoading$
        )
    );
    isAuthenticated$ = this.oneTimeLinkService.isAuthenticated$.pipe(
        switchMap(isAuthenticated =>
            isAuthenticated ? of(true) : this.authService.isAuthenticated$
        )
    );
    user$ = this.oneTimeLinkService.isAuthenticated$.pipe(
        switchMap(isAuthenticated =>
            isAuthenticated
                ? of(jwtDecode(this.oneTimeLinkService.token as string))
                : this.authService.user$
        )
    );
    error$ = this.authService.error$;

    login() {
        if (!this.oneTimeLinkService.isAuthenticated) {
            this.authService.isAuthenticated$
                .pipe(
                    first(),
                    filter(authenticated => !authenticated),
                    switchMap(() =>
                        this.authService.loginWithRedirect({
                            appState: {
                                target:
                                    window.location.pathname +
                                    window.location.search,
                            } as AppState,
                        })
                    )
                )
                .subscribe();

            this.getRedirectCallback();
        }
    }

    logout(returnUrl?: string) {
        datadogRum.stopSession();
        this.oneTimeLinkService.clear();
        this.authService.logout({ logoutParams: { returnTo: returnUrl } });
    }

    override getAccessToken(): Observable<string> {
        const oneTimeLinkToken = this.oneTimeLinkService.token;
        return oneTimeLinkToken
            ? of(oneTimeLinkToken)
            : this.authService.getAccessTokenSilently();
    }

    private getRedirectCallback() {
        return this.authService
            .handleRedirectCallback()
            .pipe(
                filter(result => !!result),
                map((state: SafeAny) => state.appState?.target || '/'),
                catchError(err => {
                    console.debug(
                        'Error while handling redirect callback',
                        err
                    );
                    return of('');
                })
            )
            .subscribe(result => {
                if (result) {
                    this.router.navigateByUrl(result.appState?.target || '/');
                }
            });
    }
}
