import { SafeAny } from '@pf/shared-common';
import {
    decodeQueryParams,
    encodeQueryParams,
    JsonParam,
    QueryParamConfigMap,
} from 'serialize-query-params';
import {
    compressURLSafeString,
    decompressURLSafeString,
} from './compression-util';

const MAX_URL_LENGTH = 2048;
const RESERVED_URL_SPACE = 100;
const AVERAGE_COMPRESSION_RATIO = 0.5;
const MAX_INPUT_LENGTH = Math.floor(
    (MAX_URL_LENGTH - RESERVED_URL_SPACE) / AVERAGE_COMPRESSION_RATIO
);

export function setQueryParams(params: Record<string, SafeAny>): void {
    const url = new URL(window.location.href);

    const paramConfigMap: QueryParamConfigMap = {};
    Object.keys(params).forEach(key => {
        paramConfigMap[key] = JsonParam;
    });

    const encodedParams = encodeQueryParams(paramConfigMap, params);
    const urlSearchParams = new URLSearchParams();
    Object.entries(encodedParams).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
            if (Array.isArray(value)) {
                urlSearchParams.append(
                    key,
                    value.filter(v => v !== null).join(',')
                );
            } else {
                urlSearchParams.append(key, value as string);
            }
        }
    });

    const paramString = urlSearchParams.toString();

    if (!isInputLengthValid(paramString)) {
        console.error(
            'Compressed query parameters exceed maximum length:',
            paramString.length
        );
        return;
    }

    const compressedParams = compressURLSafeString(paramString);

    url.search = `data=${compressedParams}`;
    window.history.replaceState({}, '', url.toString());
}

function isInputLengthValid(input: string): boolean {
    return input.length <= MAX_INPUT_LENGTH;
}

export function getQueryParams(): Record<string, SafeAny> | null {
    const url = new URL(window.location.href);
    const compressedParams = url.searchParams.get('data');

    if (!compressedParams) {
        return null;
    }

    try {
        const paramString = decompressURLSafeString(compressedParams);
        const urlSearchParams = new URLSearchParams(paramString);
        const parsedParams: Record<string, string> = {};

        urlSearchParams.forEach((value, key) => {
            parsedParams[key] = value;
        });

        const paramConfigMap: QueryParamConfigMap = {};
        Object.keys(parsedParams).forEach(key => {
            paramConfigMap[key] = JsonParam;
        });

        return decodeQueryParams(
            paramConfigMap,
            parsedParams as Record<string, string>
        );
    } catch (error) {
        console.error('Failed to decompress query parameters:', error);
        return null;
    }
}

export function getQueryParam(key: string): SafeAny | null {
    const params = getQueryParams();
    return params?.[key] ? params[key] : null;
}

export function resetQueryParams(): void {
    const url = new URL(window.location.href);
    url.search = '';
    window.history.replaceState({}, '', url.toString());
}
