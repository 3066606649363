import { IAddressDto, ValueFormatterFn } from '@pf/shared-common';
import { withSuffixSafe } from './utility';

/**
 * Formats an IAddressDto object into a readable string format.
 * @param value - The address object to format.
 * @returns The formatted address string.
 */
export const AddressFormatter: ValueFormatterFn = (value: IAddressDto) => {
    return value
        ? (
              withSuffixSafe(value.addressLine1, ' ') +
              withSuffixSafe(value.addressLine2)
          ).trim() +
              ', ' +
              withSuffixSafe(value.city, ', ') +
              withSuffixSafe(value.geoZone, ' ') +
              withSuffixSafe(value.postalCode)
        : '';
};
