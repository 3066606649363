import {
    AddressDto,
    AssociatedAddressDto,
    ICarrierFragmentDto,
    IContact,
    ICustomerFragmentDto,
    ICustomFieldDto,
    ILocationFragmentDto,
    IVendorFragmentDto,
} from '@pf/shared/util-platform';
import { IEntity, ILocation, IOmniFieldSearchParams } from '@pf/shared-common';
import {
    LocationSearchRequestParams,
    LocationViewModelCreateBody,
    LocationViewModelRead,
    SearchingLocationEnum,
} from '@control-tower/platform-core';
import { asArraySafe, mapToClassSafe } from '@pf/shared-utility';

import { TimezoneDto } from './timezone.dto';
import { ResourceAuth } from '@control-tower/platform-loads';

export type LocationRead = LocationViewModelRead & IEntity;
export type LocationCreate = LocationViewModelCreateBody;
export type LocationSearchParams = LocationSearchRequestParams &
    IOmniFieldSearchParams;
export type LocationOmniSearchFieldTypes = SearchingLocationEnum;

export interface ILocationDto extends ILocation {
    locationName: string;
    locationNumber?: string;
    locationTypeId?: string;
    locationTypeName?: string;
    locationSubType?: string;
    address: AddressDto;
    associatedAddresses?: AssociatedAddressDto[];
    timeZone?: TimezoneDto | null;
    vendors?: IVendorFragmentDto[];
    carriers?: ICarrierFragmentDto[];
    customers?: ICustomerFragmentDto[];
    contacts?: IContact[];
    relatedLocations?: ILocationFragmentDto[];
    shouldReceiveNotifications: boolean;
}

export function GetLocationString(location: LocationDto | LocationRead) {
    let addressString = '';
    if (location.locationName) {
        addressString += location.locationName + ' - ';
    }
    if (location.address?.addressLine1) {
        addressString += location.address.addressLine1;
    }
    if (location.address?.addressLine2) {
        addressString += `, ${location.address.addressLine2}`;
    }
    if (location.address?.city) {
        addressString += `, ${location.address.city}`;
    }
    if (location.address?.geoZone) {
        addressString += `, ${location.address.geoZone}`;
    }
    if (location.address?.postalCode) {
        addressString += `, ${location.address.postalCode}`;
    }
    return addressString;
}

export class LocationDto implements ILocationDto {
    constructor(data: Partial<ILocationDto>) {
        Object.assign(this, data);
        this.associatedAddresses = mapToClassSafe(
            data.associatedAddresses,
            AssociatedAddressDto
        );
        this.vendors = asArraySafe(data.vendors);
        this.carriers = asArraySafe(data.carriers);
        this.customers = asArraySafe(data.customers);
        this.contacts = asArraySafe(data.contacts);
        this.relatedLocations = asArraySafe(data.relatedLocations);
        this.address = new AddressDto(data.address || {});
        this.timeZone = data.timeZone
            ? new TimezoneDto(data.timeZone || {})
            : null;
        this.shouldReceiveNotifications =
            data?.shouldReceiveNotifications !== false;
    }

    locationName!: string;
    locationNumber?: string;
    locationSubType?: string;
    address!: AddressDto;
    associatedAddresses?: AssociatedAddressDto[];
    timeZone?: TimezoneDto | null;
    vendors?: IVendorFragmentDto[];
    carriers?: ICarrierFragmentDto[];
    customers?: ICustomerFragmentDto[];
    contacts?: IContact[];
    relatedLocations?: ILocationFragmentDto[];
    locationTypeId?: string;
    locationTypeName?: string;
    locationTypeCode?: string;
    id!: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
    resourceAuth?: ResourceAuth;
    customFields?: ICustomFieldDto[];
    shouldReceiveNotifications: boolean;
}
