import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFLoadEntities,
} from '@pf/shared-common';
import { createMap, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
} from '@pf/shared-utility';
import {
    WorkflowGlobalParametersCreate,
    WorkflowGlobalParametersDto,
    WorkflowGlobalParametersRead,
    WorkflowGlobalParametersSearchParams,
} from '../../entities/workflow-global-parameters.dto';

const symbols = entitySymbols(PFLoadEntities.WorkflowGlobalParameter);

withDefaultEntityMetadata<WorkflowGlobalParametersDto>(symbols.dto, {
    name: String,
    parameters: [Object],
    description: String,
});

withDefaultEntityMetadata<WorkflowGlobalParametersRead>(symbols.entity, {
    parameters: [Object],
    name: String,
    description: String,
});

withMetadata<WorkflowGlobalParametersCreate>(symbols.createBody, {
    parameters: [Object],
    name: String,
    description: String,
});

withDefaultSearchParamsMetadata<WorkflowGlobalParametersSearchParams>(
    symbols.searchParams,
    {}
);

export const WorkflowGlobalParameterMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<WorkflowGlobalParametersRead, WorkflowGlobalParametersDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<WorkflowGlobalParametersDto, WorkflowGlobalParametersRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<WorkflowGlobalParametersDto, WorkflowGlobalParametersCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
        createMap<
            IQueryParams<WorkflowGlobalParametersDto>,
            WorkflowGlobalParametersSearchParams
        >(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            ...withDefaultSearchParamsMappingConfiguration()
        );
    },
};
