<button
    *ngIf="typeProps"
    nz-button
    nz-tooltip
    [nzTooltipTitle]="tooltip"
    [nzType]="typeProps.type"
    [nzSize]="buttonSize"
    [nzShape]="typeProps.shape"
    [nzLoading]="loading"
    [disabled]="disabled"
    [attr.slim]="typeProps.slim"
    [nzDanger]="buttonType === 'delete' || buttonType === 'action-delete'"
    [nzBlock]="typeProps.width === 'full'"
    (click)="buttonClickHandler()">
    <ng-container *ngIf="!!typeProps.icon">
        <pf-icon
            [size]="buttonSize === 'small' ? 'xs' : 'lg'"
            [iconType]="typeProps.icon"
            (click)="iconClickHandler($event)"></pf-icon>
    </ng-container>
    <ng-content></ng-content>
    <div
        *ngIf="buttonActions?.length"
        class="btn-actions-container"
        nz-dropdown
        nzTrigger="click"
        [(nzVisible)]="showActions"
        [nzDropdownMenu]="submitActions"
        (click)="$event.stopPropagation()">
        <pf-icon class="btn-actions-icon" iconType="expand"></pf-icon>
    </div>
</button>

<nz-dropdown-menu #submitActions="nzDropdownMenu">
    <ul nz-menu>
        <li
            nz-menu-item
            *ngFor="let action of buttonActions || []"
            (click)="action.action(actionsContext)">
            <a>
                <pf-icon
                    *ngIf="action.iconType"
                    [iconType]="action.iconType"></pf-icon>
                {{ action.label }}</a
            >
        </li>
    </ul>
</nz-dropdown-menu>
