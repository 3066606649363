import { IAppEnvironment } from '@pf/shared-common';

export const environment: IAppEnvironment = {
    production: true,
    environment: 'prod',
    mainUrl: 'https://demo.controltower.tech',
    auth0ClientId: 'EOLV0skUfCklTkLJBiSa39ctw3h8MziC',
    auth0AuthParams: {
        redirectUri: 'https://demo.controltower.tech/public/login-callback',
    },
};
