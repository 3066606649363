import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    debounceTime,
    distinctUntilChanged,
    Observable,
    of,
    switchMap,
} from 'rxjs';
import { ContactFacade, IContact } from '@pf/shared/util-platform';
import {
    PFCoreEntities,
    PFEntities,
    PlatformModules,
    TableAction,
} from '@pf/shared-common';
import { AbstractModalComponent } from '@pf/shared-ui';

interface IAssociatedContactSearchComponentData {
    customFieldKey: string | undefined;
    customFieldTitle?: string;
    tableActions: TableAction<IContact>[];
    baseEntityType: PFEntities;
    baseEntityPlatform: PlatformModules;
    addAction: () => void;
}

@UntilDestroy()
@Component({
    selector: 'platform-associated-contact-search',
    templateUrl: './associated-contact-search.component.html',
    styleUrls: ['./associated-contact-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociatedContactSearchComponent
    extends AbstractModalComponent<IAssociatedContactSearchComponentData>
    implements AfterViewInit
{
    private readonly _cdr = inject(ChangeDetectorRef);
    private readonly _contactFacade = inject(ContactFacade);

    searchControl = new FormControl<string>('');
    data$: Observable<IContact[]> = of([]);

    ngAfterViewInit(): void {
        this.searchControl.valueChanges
            .pipe(
                untilDestroyed(this),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap(value => {
                    if (value && value.trim() !== '') {
                        return this._contactFacade.searchByText$(
                            value as string
                        );
                    } else {
                        return of(null);
                    }
                })
            )
            .subscribe(result => {
                if (result) {
                    this.data$ = of(result.data || []);
                    this._cdr.markForCheck();
                }
            });
    }

    protected readonly PlatformModules = PlatformModules;
    protected readonly PFCoreEntities = PFCoreEntities;
}
