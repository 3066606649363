import { Injectable, Provider } from '@angular/core';
import { RoutePathBuilder } from '@ngspot/route-path-builder';
import { RoutesForContact } from '@pf/shared/util-platform';
import {
    RoutesForCarriers,
    RoutesForCustomers,
    RoutesForFiscalPeriod,
    RoutesForLocation,
    RoutesForUserRole,
    RoutesForUsers,
    RoutesForVendors,
} from '@pf/platform-core/domain';
import {
    RoutesForDriver,
    RoutesForEquipment,
    RoutesForTrailer,
    RoutesForVehicle,
} from '@pf/platform-transportation/domain';
import {
    RoutesForCategory,
    RoutesForItem,
    RoutesForItemCatalog,
} from '@pf/platform-item-catalog/domain';
import {
    RoutesForLoads,
    RoutesForLoadSchedule,
    RoutesForLoadWorkflow,
    RoutesForStops,
} from '@pf/platform-loads/domain';
import { RoutesForOneTimeLink } from '@pf/platform-communications/domain';

@Injectable()
export class RoutesForManage extends RoutePathBuilder {
    contacts = this.childRoutes('contacts', RoutesForContact);
    carriers = this.childRoutes('carriers', RoutesForCarriers);
    vendors = this.childRoutes('vendors', RoutesForVendors);
    customers = this.childRoutes('customers', RoutesForCustomers);
    users = this.childRoutes('users', RoutesForUsers);
    locations = this.childRoutes('locations', RoutesForLocation);
    roles = this.childRoutes('roles', RoutesForUserRole);
    itemCatalog = this.childRoutes('item-catalog', RoutesForItemCatalog);
    fiscalPeriods = this.childRoutes('fiscal-periods', RoutesForFiscalPeriod);
    oneTimeLinks = this.childRoutes('one-time-links', RoutesForOneTimeLink);
}

@Injectable()
export class RootRoutesForLoads extends RoutePathBuilder {
    loads = this.childRoutes('loads', RoutesForLoads);
}

@Injectable()
export class RootRoutesForTransportation extends RoutePathBuilder {
    drivers = this.childRoutes('drivers', RoutesForDriver);
    vehicles = this.childRoutes('vehicles', RoutesForVehicle);
    trailers = this.childRoutes('trailers', RoutesForTrailer);
    equipment = this.childRoutes('equipment', RoutesForEquipment);
}

export const defaultRouters = [
    // Feature Routes
    RoutesForCarriers,
    RoutesForContact,
    RoutesForVendors,
    RoutesForCustomers,
    RoutesForUsers,
    RoutesForLocation,
    RoutesForUserRole,
    RoutesForFiscalPeriod,
    RoutesForLoads,
    RoutesForStops,
    RoutesForItem,
    RoutesForCategory,
    RoutesForItemCatalog,
    RoutesForDriver,
    RoutesForEquipment,
    RoutesForTrailer,
    RoutesForVehicle,
    RoutesForOneTimeLink,
    RoutesForLoadSchedule,
    RoutesForLoadWorkflow,

    // Root Routes
    RoutesForManage,
    RootRoutesForLoads,
    RootRoutesForTransportation,
] as Provider[];
