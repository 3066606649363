import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    StopCreate,
    StopRead,
    StopSearchParams,
} from '../../entities/stop.dto';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    ICustomField,
    IPagedResult,
    PFLoadEntities,
} from '@pf/shared-common';
import {
    StopCustomFieldsService,
    StopsService,
} from '@control-tower/platform-loads';
import { StopStore } from './stop.store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Operation } from 'fast-json-patch';

@Injectable()
export class StopDataService extends AbstractDataService<StopRead, StopCreate> {
    protected EntityName = PFLoadEntities.Stop;

    constructor(
        private service: StopsService,
        private customFieldsService: StopCustomFieldsService,
        store: StopStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: StopCreate): Observable<StopRead> {
        return this.service.stopCreate({
            stopViewModelCreateBody: body,
        }) as Observable<StopRead>;
    }

    protected deleteObs$(id: string): Observable<StopRead> {
        return this.service.stopDelete({
            id,
        }) as Observable<StopRead>;
    }

    protected undoDeleteObs$(_: string): Observable<StopRead> {
        // return this.service.stopUndoDelete({
        //     id,
        // }) as Observable<StopRead>;
        throw new Error('Method not implemented.');
    }

    protected getObs$(id: string): Observable<StopRead> {
        return this.service.stopReadById({
            id,
        }) as Observable<StopRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<StopRead> {
        return this.service.stopUpdatePatch({
            id,
            operation: operations,
        }) as Observable<StopRead>;
    }

    protected searchObs$(
        searchParams: StopSearchParams
    ): Observable<IPagedResult<StopRead>> {
        return this.service.stopSearch(searchParams) as Observable<
            IPagedResult<StopRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<StopRead>>> {
        return this.service.stopGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingDto<StopRead>>
        >;
    }

    protected override createCustomFieldObs$(
        entityId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.stopCreateCustomField({
            entityId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override updateCustomFieldObs$(
        entityId: string,
        customFieldId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.stopCustomFieldsUpdatePut({
            entityId,
            id: customFieldId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override deleteCustomFieldObs$(
        entityId: string,
        customFieldId: string
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.stopDeleteCustomField({
            entityId,
            id: customFieldId,
        }) as Observable<ICustomField & { id: string }>;
    }
}
