import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { PFLoadEntities } from '@pf/shared-common';
import { WorkflowGlobalParametersRead } from '../../entities/workflow-global-parameters.dto';

@Injectable()
export class WorkflowGlobalParameterStore extends AbstractEntityStore<WorkflowGlobalParametersRead> {
    constructor() {
        super(PFLoadEntities.WorkflowGlobalParameter);
    }
}
