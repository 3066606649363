import { Injectable } from '@angular/core';
import {
    AppNotification,
    AppNotificationType,
    AppNotificationVm,
    IEntity,
    IManageEntityFacade,
    ISearchFacade,
    NotificationAction,
    NotificationType,
    SafeAny,
} from '@pf/shared-common';
import { Observable, Subject, switchMap, tap } from 'rxjs';
import { AppNotificationService } from '../../notifications';

@Injectable({ providedIn: 'root' })
export class EntityNotificationService {
    constructor(private notifications: AppNotificationService) {}

    notifyEntityUpdate(
        entityName: string,
        recordName: string,
        verb = 'updated'
    ) {
        this.createNotification(entityName, recordName, verb);
    }

    notifyEntityDelete(
        entityName: string,
        recordName: string,
        entityId: string,
        undoDelete$: ((id: string) => Observable<SafeAny>) | null,
        verb = 'deleted'
    ) {
        if (undoDelete$ === null) {
            this.createNotification(entityName, recordName, verb);
            return;
        }
        const undoDeleteSubject = new Subject<AppNotificationVm>();
        const undoDeleteAction = `undo-${entityName.toLowerCase()}-delete`;
        this.notifications.registerAction(undoDeleteAction, undoDeleteSubject);
        undoDeleteSubject
            .pipe(
                switchMap(notification => {
                    const entityId = notification.action?.data
                        ?.entityId as string;
                    return undoDelete$(entityId);
                })
            )
            .subscribe();

        const deleteAction: NotificationAction = {
            onClickAction: undoDeleteAction,
            buttonType: 'submit',
            text: 'Undo?',
            data: { entityId },
        };
        this.createNotification(entityName, recordName, verb, deleteAction);
    }

    notifyEntityAdd(entityName: string, recordName: string, verb = 'added') {
        this.createNotification(entityName, recordName, verb);
    }

    notifyEntityUndoDelete(
        entityName: string,
        recordName: string,
        verb = 'restored'
    ) {
        this.createNotification(entityName, recordName, verb);
    }

    notifyOnEntityUpdated<TEntity extends object & IEntity>(
        entityFacade: IManageEntityFacade<TEntity> & ISearchFacade<TEntity>,
        entityName: string,
        takeUntil: (source: Observable<SafeAny>) => Observable<SafeAny>
    ) {
        entityFacade.updated$
            .pipe(
                takeUntil,
                tap(result => {
                    this.notifyEntityUpdate(
                        entityName,
                        entityFacade.getEntityName(result.entity),
                        result.actionText
                    );
                })
            )
            .subscribe();
    }

    notifyOnEntityDeleted<TEntity extends object & IEntity>(
        entityFacade: IManageEntityFacade<TEntity> & ISearchFacade<TEntity>,
        entityName: string,
        canUndoDelete: boolean,
        takeUntil: (source: Observable<SafeAny>) => Observable<SafeAny>
    ) {
        entityFacade.deleted$
            .pipe(
                takeUntil,
                tap(result => {
                    this.notifyEntityDelete(
                        entityName,
                        entityFacade.getEntityName(result.entity),
                        result.entity.id,
                        canUndoDelete ? entityFacade.undoDelete$ : null,
                        result.actionText
                    );
                })
            )
            .subscribe();
    }

    notifyOnEntityAdded<TEntity extends object & IEntity>(
        entityFacade: IManageEntityFacade<TEntity> & ISearchFacade<TEntity>,
        entityName: string,
        takeUntil: (source: Observable<SafeAny>) => Observable<SafeAny>
    ) {
        entityFacade.added$
            .pipe(
                takeUntil,
                tap(result => {
                    this.notifyEntityAdd(
                        entityName,
                        entityFacade.getEntityName(result.entity),
                        result.actionText
                    );
                })
            )
            .subscribe();
    }

    notifyOnEntityRestored<TEntity extends object & IEntity>(
        entityFacade: IManageEntityFacade<TEntity> & ISearchFacade<TEntity>,
        entityName: string,
        takeUntil: (source: Observable<SafeAny>) => Observable<SafeAny>
    ) {
        entityFacade.restored$
            .pipe(
                takeUntil,
                tap(result => {
                    this.notifyEntityUndoDelete(
                        entityName,
                        entityFacade.getEntityName(result.entity),
                        result.actionText
                    );
                })
            )
            .subscribe();
    }

    private createNotification(
        entityName: string,
        recordName: string,
        actionVerb: string,
        action?: NotificationAction
    ) {
        const message = {
            type: NotificationType.AppNotification,
            appNotificationType: AppNotificationType.success,
            title: `${entityName}`,
            message: `${recordName} has been ${actionVerb}!`,
        } as AppNotification;
        if (action) {
            message.action = action;
        }
        this.notifications.create(message);
    }
}
