import { AbstractDocumentsFacade } from '@pf/shared-services';
import { inject, Injectable } from '@angular/core';
import {
    DocumentTypeConfig,
    DocumentTypeFacade,
    EntityDocumentsFacadeConfig,
    TypeMatch,
} from '@pf/shared/util-platform';
import { Observable, of, shareReplay, switchMap } from 'rxjs';
import {
    IDocument,
    IDocumentCreate,
    IDocumentSearchParams,
    IDocumentTemplate,
    IPagedResult,
    PFLoadEntities,
} from '@pf/shared-common';
import { LoadFilesService } from '@control-tower/platform-loads';

@Injectable()
export abstract class LoadDocumentsFacadeConfig extends EntityDocumentsFacadeConfig {}

@Injectable()
export class LoadDocumentsFacade extends AbstractDocumentsFacade {
    private readonly _documentTypeFacade = inject(DocumentTypeFacade);
    templates$: Observable<IDocumentTemplate[]> = (
        this.config.templates$ || of([] as DocumentTypeConfig[])
    ).pipe(
        shareReplay(1),
        switchMap((documentTypes: DocumentTypeConfig[]) =>
            this._documentTypeFacade.mapToCodes$<
                DocumentTypeConfig & TypeMatch
            >(documentTypes)
        )
    ) as Observable<IDocumentTemplate[]>;

    constructor(
        dataService: LoadFilesService,
        private config: LoadDocumentsFacadeConfig
    ) {
        super(PFLoadEntities.Load, {
            create$: (entityId: string, document: IDocumentCreate) =>
                dataService.loadFilePost({
                    loadId: entityId,
                    ...document,
                }) as Observable<IDocument>,
            delete$: (entityId: string, documentId: string) =>
                dataService.loadFileDelete({
                    id: documentId,
                    loadId: entityId,
                }) as Observable<IDocument>,
            get$: (entityId: string, documentId: string) =>
                dataService.loadFileReadById({
                    id: documentId,
                    loadId: entityId,
                }) as Observable<IDocument>,
            search$: (entityId: string, params: IDocumentSearchParams) =>
                dataService.loadFileSearch({
                    loadId: entityId,
                    ...params,
                }) as Observable<IPagedResult<IDocument>>,
            download$: (entityId: string, documentId: string) =>
                dataService.loadFileGet({ id: documentId, loadId: entityId }),
        });
    }
}
