<pf-text type="description" *ngIf="pfTitle">{{ pfTitle }}</pf-text>
<pf-segmented
    *ngIf="!dropdown"
    class="desktop"
    [segmentedOptions]="segmentedOptions"
    [model]="selectedIndex"
    (selectedModelChange)="handleIndexChange($event)"></pf-segmented>
<pf-select
    [ngClass]="{ laptop: !dropdown }"
    [data]="selectOptions"
    [control]="selectControl"
    (selected)="handleSelectChange($event.value)"></pf-select>
