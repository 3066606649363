import { IChangeTrackingFacade, IEntity, SafeAny } from '@pf/shared-common';
import { Injectable, NgModuleRef } from '@angular/core';

import { EntityHistoryDrawerComponent } from '../..';
import { InjectionService } from '@pf/shared-ui';
import { first } from 'rxjs';

@Injectable()
export class EntityHistoryService {
    constructor(
        private injectionService: InjectionService,
        private moduleRef: NgModuleRef<SafeAny>
    ) {}

    create(entityName: string, facade: IChangeTrackingFacade<IEntity>) {
        const componentRef = this.injectionService.appendComponentHost({
            component: EntityHistoryDrawerComponent,
            hostLocation: 'root',
            ngModuleRef: this.moduleRef,
            props: {
                entityName,
                facade,
            },
        });
        componentRef.instance.closed$
            .pipe(first())
            .subscribe(() => componentRef.destroy());
        return componentRef.instance;
    }
}
