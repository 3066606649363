import { IEntity } from '@pf/shared-common';
import {
    LaneCarrierSearchRequestParams,
    LaneCarrierViewModelCreateBody,
    LaneCarrierViewModelRead,
} from '@control-tower/platform-loads';
import { CustomFieldDto, ICarrierFragmentDto } from '@pf/shared/util-platform';

export type LaneCarrierRead = LaneCarrierViewModelRead & IEntity;
export type LaneCarrierCreate = LaneCarrierViewModelCreateBody;
export type LaneCarrierSearchParams = LaneCarrierSearchRequestParams;

export class LaneCarrierDto implements IEntity {
    constructor(data: Partial<LaneCarrierDto>) {
        Object.assign(this, data);
    }

    id!: string;
    name?: string | null;
    laneId!: string | null;
    carrier?: ICarrierFragmentDto | null;
    carrierId!: string | null;
    priority?: number | null = 1;
    customFields?: CustomFieldDto[] | null;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
}
