<article pfArticle pfTitle="Associated Addresses">
    <pf-drawer-subform
        [entityType]="subFormEntityType"
        tableTitle="Address"
        [formArray]="form.formGroup.controls.entities"
        [columns]="columns"
        [formRef]="formRef"
        [loading]="loading"
    >
        <ng-template #formRef let-currentControl>
            <platform-address-form
                *ngIf="currentControl"
                [associatedAddress]="true"
                [formControl]="currentControl"
            ></platform-address-form>
        </ng-template>
    </pf-drawer-subform>
</article>
