import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFLoadEntities,
} from '@pf/shared-common';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';
import {
    LoadScheduleCreate,
    LoadScheduleDto,
    LoadScheduleRead,
    LoadScheduleSearchParams,
} from '../../entities/load-schedule.dto';
import {
    CustomFieldMetadataMap,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';

const symbols = entitySymbols(PFLoadEntities.LoadSchedule);

withDefaultEntityMetadata<LoadScheduleDto>(symbols.dto, {
    jobName: String,
    disabledReason: String,
    isDisabled: Boolean,
    resourceAuth: ResourceAuthSymbol,
    originLocationId: String,
    destinationLocationId: String,
    previousExecutionDate: String,
    nextExecutionDate: String,
    jobbaRegistrationId: String,
    customFields: [CustomFieldMetadataMap.symbols.dto],
    description: String,
    cron: String,
    timeZoneId: String,
    parameters: Object,
    state: Object,
});

withDefaultEntityMetadata<LoadScheduleRead>(symbols.entity, {
    jobName: String,
    disabledReason: String,
    isDisabled: Boolean,
    resourceAuth: ResourceAuthSymbol,
    originLocationId: String,
    destinationLocationId: String,
    previousExecutionDate: String,
    nextExecutionDate: String,
    jobbaJobRegistrationId: String,
    customFields: [CustomFieldMetadataMap.symbols.entity],
    description: String,
    cron: String,
    parameters: Object,
    state: Object,
    timeZoneId: String,
});

withMetadata<LoadScheduleCreate>(symbols.createBody, {
    jobName: String,
    disabledReason: String,
    isDisabled: Boolean,
    originLocationId: String,
    destinationLocationId: String,
    description: String,
    state: Object,
    cron: String,
    parameters: Object,
    timeZoneId: String,
});

withDefaultSearchParamsMetadata<LoadScheduleSearchParams>(
    symbols.searchParams,
    {
        jobNameStartsWith: String,
        originLocationId: String,
        destinationLocationId: String,
    }
);

export const LoadScheduleMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<LoadScheduleRead, LoadScheduleDto>(
            mapper,
            symbols.entity,
            symbols.dto,
            forMember(
                d => d.disabledReason,
                mapFrom(s => s.disabledReason)
            )
        );
        createMap<LoadScheduleDto, LoadScheduleRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<LoadScheduleDto, LoadScheduleCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                d => d.disabledReason,
                mapFrom(s => s.disabledReason || '--')
            )
        );
        createMap<IQueryParams<LoadScheduleDto>, LoadScheduleSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('jobName'),
            withSearchFilter('originLocationId'),
            ...withDefaultSearchParamsMappingConfiguration()
        );
    },
};
