<pf-text type="description" *ngIf="pfTitle">{{ pfTitle }}</pf-text>
<pf-date-picker
    *ngIf="customFilter"
    datePickerType="dateRange"
    data-pf-handle="loads-date-custom-filter"
    [control]="dateRangeControl"
    (calendarChanged)="calendarChanged.emit($event)"
    [presetRanges]="presetRanges">
</pf-date-picker>
<pf-filter-group
    *ngIf="!customFilter && filterOptions?.length"
    [filterOptions]="filterOptions || []"
    data-pf-handle="loads-date-filter"
    (selectedChange)="handleFilterGroupChange($event)"></pf-filter-group>
