import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, debounceTime, map } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'pf-statistic-group',
    template: `
        <pf-statistic
            *ngIf="includeSum"
            [pfTitle]="sumTitle"
            [pfValue]="groupSum$ | async"></pf-statistic>
        <ng-content></ng-content>
    `,
    styles: [
        `
            :host {
                display: flex;
                min-height: 115px;
                flex-direction: row;
                justify-content: space-evenly;
                flex-wrap: wrap;

                @media screen and (max-width: 1400px) {
                    /* forces three 4 to a line */
                    > * {
                        flex-basis: 25%;
                    }
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticGroupComponent {
    @Input() includeSum = false;
    @Input({ required: true }) groupType!: string;

    groupStats$ = new BehaviorSubject<Record<string, number>>({});

    groupSum$ = this.groupStats$.pipe(
        debounceTime(500),
        map(stats => Object.values(stats).filter(value => value !== undefined)),
        map(statValues =>
            statValues.length
                ? statValues.reduce((sum, value) => sum + +value, 0)
                : undefined
        )
    );

    get sumTitle() {
        return `All ${this.groupType}`;
    }

    setStat(stat: string, value: number) {
        if (!stat || stat === this.sumTitle) {
            return;
        }
        this.groupStats$.next({
            ...this.groupStats$.value,
            [stat]: value,
        });
    }
}
