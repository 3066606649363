import { Injectable } from '@angular/core';
import { Tenant } from '@control-tower/platform-core';
import { createStore, select, withProps } from '@ngneat/elf';
import {
    excludeKeys,
    localStorageStrategy,
    persistState,
} from '@ngneat/elf-persist-state';
import { filter, map } from 'rxjs';

export interface ITenantStore {
    defaultTenantId: string | null;
    selectedTenantId: string | null;
    tenants: Record<string, Tenant>;
}

const tenantStore = createStore(
    { name: 'tenant' },
    withProps<ITenantStore>({
        selectedTenantId: null,
        defaultTenantId: null,
        tenants: {},
    })
);

export const persist = persistState(tenantStore, {
    storage: localStorageStrategy,
    source: () => tenantStore.pipe(excludeKeys(['tenants', 'defaultTenantId'])),
});

@Injectable()
export class TenantStore {
    get tenant() {
        const tenantId =
            tenantStore.value.selectedTenantId ||
            tenantStore.value.defaultTenantId;
        if (!tenantId) return null;
        return tenantStore.value.tenants[tenantId];
    }

    get tenants$() {
        return tenantStore.pipe(select(state => state.tenants));
    }

    set tenants(tenants: Record<string, Tenant>) {
        tenantStore.update(state => ({
            ...state,
            tenants: tenants,
        }));
    }

    get defaultTenantId(): string | null {
        return tenantStore.value.defaultTenantId;
    }

    set defaultTenantId(tenantId: string | null) {
        tenantStore.update(state => {
            return {
                ...state,
                defaultTenantId: tenantId,
                selectedTenantId: state.selectedTenantId || tenantId,
            };
        });
    }

    get selectedTenantId(): string | null {
        return tenantStore.value.selectedTenantId;
    }

    set selectedTenantId(tenantId: string | null) {
        tenantStore.update(state => {
            return {
                ...state,
                selectedTenantId: tenantId,
            };
        });
    }

    getProperty$<TReturn>(
        prop: keyof Tenant,
        mapFn: (tenant: Tenant) => TReturn
    ) {
        return tenantStore.pipe(
            filter(
                p =>
                    p.selectedTenantId != null &&
                    !!p.tenants[p.selectedTenantId]?.[prop]
            ),
            map(state => state.tenants[state.selectedTenantId as string]),
            select(mapFn)
        );
    }
}
