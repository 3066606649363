import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { PresetRanges } from 'ng-zorro-antd/date-picker/standard-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TableService } from '../services/table.service';
import { FilterOption } from '@pf/shared-common';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { DateRange, DateRangeOption, isValue } from '@pf/shared-utility';

@UntilDestroy()
@Component({
    selector: 'pf-date-filter',
    templateUrl: './date-filter.component.html',
    styleUrls: ['./date-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFilterComponent implements OnInit {
    private _cdr = inject(ChangeDetectorRef);
    private _filterOptions: FilterOption[] = [];

    /**
     * @internal
     */
    presetRanges: PresetRanges = {};

    /**
     * @internal
     */
    dateRangeControl = new FormControl<Date[] | null>(null);

    /**
     * @internal
     */
    customFilter: FilterOption | undefined;

    @Input() lazyProcess = false;

    @Input() set filterOptions(options: FilterOption[] | null | undefined) {
        this._filterOptions = options || [];
        this.setPresetRanges();
    }

    get filterOptions() {
        return this._filterOptions;
    }

    @Input() pfTitle?: string = 'Date range filter:';

    @Output() dateRangeChange = new EventEmitter<
        DateRange & { option: DateRangeOption }
    >();
    @Output() dateRangeClear = new EventEmitter<void>();
    @Output() calendarChanged = new EventEmitter<Date[]>();

    constructor(private tableService: TableService) {
        this.listenForTableReset();
    }

    ngOnInit() {
        this.listenForDateRangeChange();
    }

    handleFilterGroupChange(option: FilterOption) {
        this.dateRangeChange.emit({
            option: option.value as DateRangeOption,
            startDate: '',
            endDate: '',
        });
    }

    private listenForTableReset() {
        this.tableService.reset$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.setDefaultOption(true));
    }

    private setPresetRanges() {
        this.customFilter = this._filterOptions.find(
            x => (x.value as DateRangeOption) === 'custom'
        );
        this.presetRanges = this._filterOptions
            .filter(x => (x.value as DateRangeOption) !== 'custom')
            .reduce((acc, curr) => {
                acc[curr.label] = () => {
                    return Object.values(this.processDateFilter(curr))
                        .filter(isValue)
                        .map(x => new Date(x));
                };
                return acc;
            }, {} as PresetRanges);

        this.setDefaultOption(true);
        this._cdr.markForCheck();
    }

    private processDateFilter(
        filterOption: FilterOption,
        startDate?: string,
        endDate?: string,
        reset?: boolean
    ): Record<string, string> {
        const filtersUpdate = (
            filterOption.filterFn
                ? filterOption.filterFn(startDate, endDate)
                : {}
        ) as Record<string, string>;
        if (!this.lazyProcess) {
            this.tableService.updateFilters(filtersUpdate);
        }
        const dates = this.queryFiltersToDates(filtersUpdate);
        if (reset || !this.dateRangeControl.value) {
            this.dateRangeControl.setValue(dates);
        }
        this.dateRangeChange.emit({
            option: filterOption.value as DateRangeOption,
            startDate: dates[0].toISOString(),
            endDate: dates[1].toISOString(),
        });
        return filtersUpdate as Record<string, string>;
    }

    private queryFiltersToDates(queryFilters: Record<string, string>): Date[] {
        return Object.values(queryFilters)
            .filter(isValue)
            .map(x => new Date(x))
            .sort((a, b) => a.getTime() - b.getTime());
    }

    private setDefaultOption(checkInitial?: boolean) {
        const initial = this._filterOptions.find(x => x.initial);
        const defaultSelected =
            checkInitial && initial
                ? initial
                : this._filterOptions.find(x => x.default);
        if (!defaultSelected) {
            return;
        }
        this.processDateFilter(
            defaultSelected,
            defaultSelected.initialValue?.[0] ?? undefined,
            defaultSelected.initialValue?.[1] ?? undefined,
            true
        );
    }

    private listenForDateRangeChange() {
        this.dateRangeControl.valueChanges
            .pipe(untilDestroyed(this), debounceTime(500))
            .subscribe(dates => {
                if (!dates?.length || !this.customFilter) {
                    this.dateRangeClear.emit();
                    return;
                }
                const [startDate, endDate] = dates;

                if (!this.lazyProcess) {
                    this.processDateFilter(
                        this.customFilter,
                        startDate.toISOString(),
                        endDate.toISOString()
                    );
                } else {
                    this.dateRangeChange.emit({
                        option: 'custom',
                        startDate: startDate.toISOString(),
                        endDate: endDate.toISOString(),
                    });
                }
            });
    }
}
