import { AbstractEntityMapper } from '@pf/shared-services';
import { Injectable } from '@angular/core';
import { LaneCarrierMetadataMap } from './lane-carrier.mapping-profile';
import {
    LaneCarrierCreate,
    LaneCarrierDto,
    LaneCarrierRead,
    LaneCarrierSearchParams,
} from '../../entities/lane-carrier.dto';
import { PlatformLoadsMapper } from '../platform-loads.mapper';

@Injectable()
export class LaneCarrierMapper extends AbstractEntityMapper<
    LaneCarrierRead,
    LaneCarrierCreate,
    LaneCarrierDto,
    LaneCarrierSearchParams
> {
    constructor() {
        super(
            LaneCarrierDto,
            PlatformLoadsMapper,
            LaneCarrierMetadataMap.symbols
        );
    }
}
