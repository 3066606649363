import {
    Reference,
    ResourceAuth,
    StopSearchRequestParams,
    StopStatus,
    StopTypeFragment,
    StopViewModelCreateBody,
    StopViewModelRead,
} from '@control-tower/platform-loads';
import { IEntity } from '@pf/shared-common';
import {
    CustomFieldDto,
    IContact,
    ICustomerFragmentDto,
    ICustomFieldDto,
    ILocationFragmentDto,
    IVendorFragmentDto,
} from '@pf/shared/util-platform';
import { EventDto } from './event.dto';
import { asArraySafe, mapToClassSafe } from '@pf/shared-utility';
import { StopChargeDto } from './stop-charge.dto';
import { StopItemDto } from './stop-item.dto';

export type StopRead = StopViewModelRead & IEntity;
export type StopCreate = StopViewModelCreateBody;
export type StopSearchParams = StopSearchRequestParams;

export class StopDto implements IEntity {
    constructor(data: Partial<StopDto>) {
        Object.assign(this, data);
        this.stopItems = asArraySafe(data.stopItems);
        this.contacts = asArraySafe(data.contacts);
        this.customFields = mapToClassSafe(data.customFields, CustomFieldDto);
    }

    id!: string;
    specialInstructions?: string;
    expectedArrival?: string;
    expectedDeparture?: string;
    arrival?: string;
    departure?: string;
    location?: ILocationFragmentDto;
    vendor?: IVendorFragmentDto;
    customer?: ICustomerFragmentDto;
    stopType?: StopTypeFragment;
    stopStatus?: StopStatus | null;
    charges?: StopChargeDto[];
    events?: EventDto[];
    contacts?: IContact[];
    isDeleted?: boolean;
    references?: Reference[];
    customFields?: ICustomFieldDto[];
    stopItems?: StopItemDto[];
    resourceAuth?: ResourceAuth;
    timezone?: string;
}
