import { UntilDestroy } from '@ngneat/until-destroy';
import { EntityBaseEditComponent } from '@pf/shared-ui';

import { Component, Input } from '@angular/core';
import {
    ContactDisplayDto,
    ContactDto,
    ContactFacade,
    IContact,
} from '@pf/shared/util-platform';
import { Observable, of } from 'rxjs';
import { PFCoreEntities } from '@pf/shared-common';

@UntilDestroy()
@Component({
    selector: 'platform-core-contact-edit',
    templateUrl: './contact-edit.component.html',
    styleUrls: ['./contact-edit.component.scss'],
})
export class ContactEditComponent extends EntityBaseEditComponent<ContactDto> {
    entityType = PFCoreEntities.Contact;
    override entityName = 'Contact';

    @Input() afterSaveFn: (entity: ContactDto) => void = () => {
        // do nothing
    };
    @Input() navigateOnSave = true;

    protected override afterEditFn() {
        if (this.navigateOnSave) {
            this.facade.manage();
        }
    }

    constructor(private contactFacade: ContactFacade) {
        super(contactFacade);
        this.checkDuplicatesBeforeCreate = true;
        this.checkDuplicateField = 'email';
    }

    newEntityFn$(): Observable<ContactDto> {
        return of(new ContactDto({}));
    }

    override saveEntity(entity: ContactDto | IContact) {
        return super.saveEntity(entity as ContactDto, this.afterSaveFn);
    }

    override duplicateRecordDisplayFn(entity: ContactDto) {
        return new ContactDisplayDto(entity);
    }
}
