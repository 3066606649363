import {
    LayoutModule,
    PlatformFormsModule,
    ResultModule,
    SharedUiModule,
    TableModule,
} from '@pf/shared-ui';

import { AddressFormComponent } from './forms/address-form.component';
import { AddressesFormComponent } from './forms/addresses-form.component';
import { AssociatedEntitiesFormComponent } from './forms/associated-entities-form.component';
import { CommonModule } from '@angular/common';
import { ContactSummaryComponent } from './templates/contact.component';
import { EntityHistoryModule } from './entity-history/entity-history.module';
import { NgModule } from '@angular/core';
import { CustomFieldsFormComponent } from './forms/custom-fields-form.component';
import { AssociatedContactsFormComponent } from './forms/associated-contacts-form.component';
import { ContactFormComponent } from './forms/contact-form.component';
import { BarcodeComponent } from './components/barcode.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { ContactsPreviewComponent } from './components/contacts-preview.component';
import { EntityContactsCellRendererComponent } from './components/entity-contacts-cell-renderer.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { EntityContactsDrawerComponent } from './components/entity-contacts-drawer.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzResultModule } from 'ng-zorro-antd/result';
import { EntityStatusToggleComponent } from './components/entity-status-toggle.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { BarcodeCellRendererComponent } from './components/barcode-cell-renderer.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { FinancialNumberComponent } from './components/financial-number.component';
import { OneTimeLinksFormComponent } from './forms/one-time-links-form.component';
import { OneTimeLinkFormComponent } from './forms/one-time-link-form.component';
import { OneTimeLinkErrorComponent } from './forms/one-time-link-error.component';
import { OneTimeLinkEditComponent } from './forms/one-time-link-edit.component';
import { OneTimeLinkCompletedComponent } from './forms/one-time-link-completed.component';
import { OneTimeLoginComponent } from './forms/one-time-login.component';
import { Auth0AuthService } from '@pf/shared-services';
import { CustomFieldListComponent } from './components/custom-field-list.component';
import { LocationCellRendererComponent } from './components/location-cell-renderer.component';
import { ContactEditComponent } from './forms/contact-edit.component';
import { AssociatedContactSearchComponent } from './forms/associated-contact-search.component';
import { AssociatedContactTableComponent } from './forms/associated-contact-table.component';
import { SharedUtilPlatformModule } from '@pf/shared/util-platform';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { FinancialFormulaRendererComponent } from './components/financial-formula-renderer.component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

@NgModule({
    imports: [
        CommonModule,
        SharedUiModule,
        LayoutModule,
        TableModule,
        ResultModule,
        PlatformFormsModule,
        EntityHistoryModule,
        NgxBarcode6Module,
        NzDropDownModule,
        NzMenuModule,
        NzDrawerModule,
        NzResultModule,
        NzSpinModule,
        NzSkeletonModule,
        NzPopoverModule,
        SharedUtilPlatformModule,
        NzTooltipDirective,
    ],
    declarations: [
        AddressesFormComponent,
        AddressFormComponent,
        ContactSummaryComponent,
        AssociatedEntitiesFormComponent,
        CustomFieldsFormComponent,
        ContactFormComponent,
        ContactEditComponent,
        AssociatedContactsFormComponent,
        BarcodeComponent,
        ContactsPreviewComponent,
        EntityContactsCellRendererComponent,
        EntityContactsDrawerComponent,
        EntityStatusToggleComponent,
        BarcodeCellRendererComponent,
        FinancialNumberComponent,
        FinancialFormulaRendererComponent,
        OneTimeLinksFormComponent,
        OneTimeLinkFormComponent,
        OneTimeLinkErrorComponent,
        OneTimeLinkEditComponent,
        OneTimeLinkCompletedComponent,
        OneTimeLoginComponent,
        CustomFieldListComponent,
        LocationCellRendererComponent,
        AssociatedContactSearchComponent,
        AssociatedContactTableComponent,
    ],
    exports: [
        AddressesFormComponent,
        AddressFormComponent,
        ContactSummaryComponent,
        AssociatedEntitiesFormComponent,
        CustomFieldsFormComponent,
        ContactFormComponent,
        ContactEditComponent,
        AssociatedContactsFormComponent,
        BarcodeComponent,
        ContactsPreviewComponent,
        EntityContactsCellRendererComponent,
        EntityContactsDrawerComponent,
        EntityStatusToggleComponent,
        BarcodeCellRendererComponent,
        FinancialNumberComponent,
        FinancialFormulaRendererComponent,
        OneTimeLinksFormComponent,
        CustomFieldListComponent,
        LocationCellRendererComponent,
    ],
    providers: [Auth0AuthService],
})
export class SharedUiPlatformModule {}
