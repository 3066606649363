import { LocationDto } from '@pf/platform-core/domain';
import { CustomerFragment } from '@control-tower/platform-core';
import { LoadScheduleDto } from './load-schedule.dto';
import {
    RecurringLoadScheduleSummary,
    V1RecurringLoadSchedulesSummaryGetRequestParams,
} from '@control-tower/platform-loads';
import { IPagedSearchParams } from '@pf/shared-common';

export type LoadScheduleSummaryRead = RecurringLoadScheduleSummary & {
    id: string;
};

export type LoadScheduleSummaryCreate = {
    origin: LocationDto | null;
    schedules?: LoadScheduleDto[];
};

export type LoadScheduleSummarySearchParams = Omit<
    V1RecurringLoadSchedulesSummaryGetRequestParams,
    'pageSize' | 'pageNumber'
> &
    IPagedSearchParams;

export class LoadScheduleSummaryDto {
    id!: string;
    origin: LocationDto | null = null;
    customer: CustomerFragment | null = null;
    numberOfSchedules: number | null = null;
    schedules?: LoadScheduleDto[] = [];

    get name() {
        return this.origin?.locationName || '';
    }

    constructor(data: Partial<LoadScheduleSummaryDto>) {
        Object.assign(this, data);

        if (data.origin && !data.customer) {
            this.customer = data.origin.customers?.[0] || null;
        }
    }
}
