import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
// import { LoadSchedulesService } from '@control-tower/platform-loads';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    IPagedResult,
    PFLoadEntities,
} from '@pf/shared-common';
import { LoadScheduleStore } from './load-schedule.store';
import {
    LoadScheduleCreate,
    LoadScheduleRead,
    LoadScheduleSearchParams,
} from '../../entities/load-schedule.dto';
import { applyPatch, Operation } from 'fast-json-patch';
import { LoadScheduleMapper } from './load-schedule.mapper';
import { RecurringLoadSchedulesService } from '@control-tower/platform-loads';

@Injectable()
export class LoadScheduleDataService extends AbstractDataService<
    LoadScheduleRead,
    LoadScheduleCreate
> {
    protected EntityName = PFLoadEntities.LoadSchedule;

    constructor(
        private service: RecurringLoadSchedulesService,
        store: LoadScheduleStore,
        private mapper: LoadScheduleMapper,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(
        body: LoadScheduleCreate
    ): Observable<LoadScheduleRead> {
        return this.service.recurringLoadSchedulePostRecurringSchedule({
            recurringLoadScheduleViewModelCreate: body,
        }) as Observable<LoadScheduleRead>;
    }

    protected deleteObs$(id: string): Observable<LoadScheduleRead> {
        return this.service.recurringLoadScheduleDelete({
            id,
        }) as Observable<LoadScheduleRead>;
    }

    protected undoDeleteObs$(id: string): Observable<LoadScheduleRead> {
        return this.service.recurringLoadScheduleUndoDelete({
            id,
        }) as Observable<LoadScheduleRead>;
    }

    protected getObs$(id: string): Observable<LoadScheduleRead> {
        return this.service.recurringLoadScheduleReadById({
            id,
        }) as Observable<LoadScheduleRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<LoadScheduleRead> {
        const existing = this.store.get(id);
        const dto = this.mapper.toDTO(existing);
        const createBody = this.mapper.toCreateBody(dto);
        const putBody = applyPatch(
            createBody,
            operations,
            false,
            false
        ).newDocument;
        return this.service.recurringLoadSchedulePutRecurringSchedule({
            id,
            recurringLoadScheduleViewModelCreate: putBody,
        }) as Observable<LoadScheduleRead>;
    }

    protected searchObs$(
        searchParams: LoadScheduleSearchParams
    ): Observable<IPagedResult<LoadScheduleRead>> {
        return this.service.recurringLoadScheduleSearch(
            searchParams
        ) as Observable<IPagedResult<LoadScheduleRead>>;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<LoadScheduleRead>>> {
        return this.service.recurringLoadScheduleGetChanges(
            searchParams
        ) as Observable<
            IPagedResult<EntityChangeTrackingDto<LoadScheduleRead>>
        >;
    }
}
