<ng-container
    *ngFor="let row of tableRows; let index = index; trackBy: dataTrackBy">
    <ng-container
        [ngTemplateOutlet]="recursiveRowTemplate"
        [ngTemplateOutletContext]="{ row, index }"></ng-container>
</ng-container>

<ng-template #recursiveRowTemplate let-row="row" let-index="index">
    <tr
        [ngClass]="row.classes"
        *ngIf="(row.parent && row.parent.expanded) || !row.parent">
        <td
            *ngIf="expandable"
            [(nzExpand)]="row.expanded"
            [nzIndentSize]="row.indentSize"
            [nzShowExpand]="shouldDisplayExpandIcon(row)"
            (nzExpandChange)="expandHandler($event, index)"></td>
        <td
            *ngIf="showSelection"
            [(nzChecked)]="row.checked"
            (nzCheckedChange)="checkedHandler($event, index)"></td>
        <td
            *ngFor="
                let column of columns;
                trackBy: headerTrackBy;
                let first = first
            "
            [attr.data-pf-table-column]="column.headerName"
            [nzIndentSize]="first ? row.indentSize : 0">
            <pf-table-cell
                [row]="row"
                [column]="column"
                [expandToContent]="expandToContent"></pf-table-cell>
        </td>
        <td *ngIf="actions.length > 0" nzRight class="table-actions-cell">
            <pf-table-actions
                [actions]="actions"
                [row]="row"
                [index]="index"></pf-table-actions>
        </td>
    </tr>
    <ng-container *ngIf="row.children && row.children.length && row.expanded">
        <ng-container *ngFor="let child of row.children">
            <ng-container
                [ngTemplateOutlet]="recursiveRowTemplate"
                [ngTemplateOutletContext]="{
                    row: child,
                    index
                }"></ng-container>
        </ng-container>
    </ng-container>
    <tr *ngIf="row.expandable && expandRowRef" [nzExpand]="row.expanded">
        <ng-container
            *ngTemplateOutlet="
                expandRowRef;
                context: {
                    columns,
                    row,
                    actions,
                    index,
                    expanded: row.expanded
                }
            "></ng-container>
    </tr>
</ng-template>
