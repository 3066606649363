import { LayoutService } from '@pf/shared-ui';
import { NavigationEnd, Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
    PlatformAuthService,
    ProfileProvider,
    TenantProvider,
} from '@pf/shared-common';
import { combineLatest, filter, first, firstValueFrom, tap } from 'rxjs';
import { PlatformRoutes } from '../routing/PlatformRoutes';

@Component({
    template: '',
})
export abstract class BaseAppComponent implements OnInit {
    title = 'Control Tower';
    appRouter: PlatformRoutes = {} as PlatformRoutes;
    fullPageLayout = false;
    private loaded = false;

    constructor(
        public layoutService: LayoutService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private authService: PlatformAuthService,
        private tenantProvider: TenantProvider,
        private profileProvider: ProfileProvider
    ) {
        this.listenForRouteChanges();
    }

    ngOnInit(): void {
        this.checkIfLoaded(window.location.pathname);
    }

    private loadAppData() {
        this.addLoader();
        this.fullPageLayout = false;
        this.authService.login();
        this.tenantProvider.load();
        this.profileProvider.load();

        return firstValueFrom(
            combineLatest([
                this.tenantProvider.tenants$,
                this.profileProvider.permissions$,
            ]).pipe(
                filter(tenants => Object.keys(tenants).length > 0),
                tap(() => {
                    this.removeLoader();
                    this.loaded = true;
                })
            )
        );
    }

    private addLoader() {
        document.body.classList.remove('loaded');
    }

    private removeLoader() {
        this.authService.isLoading$
            .pipe(
                filter(isLoading => !isLoading),
                first()
            )
            .subscribe(() => {
                document.body.classList.add('loaded');
                this.cdr.markForCheck();
            });
    }

    private checkIfLoaded(url: string) {
        if (url.startsWith('/public')) {
            if (url.indexOf('login-callback') > -1) {
                this.router.navigateByUrl('/');
                this.loadAppData();
            } else {
                this.fullPageLayout = true;
                this.removeLoader();
            }
        } else {
            if (!this.loaded) {
                this.loadAppData();
            }
            this.fullPageLayout = false;
        }
    }

    private listenForRouteChanges() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.checkIfLoaded(event.url);
            }
        });
    }
}
