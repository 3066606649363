import { Injectable } from '@angular/core';
import { EntityFacadeConfig } from '@pf/shared/util-platform';
import { StopChargeDto } from '../entities/stop-charge.dto';
import { SelectOption } from '@pf/shared-common';

@Injectable()
export abstract class StopChargeFacadeConfig extends EntityFacadeConfig<StopChargeDto> {
    abstract secondaryChargeTypeLabel: string;
    abstract secondaryChargeTypes: SelectOption[];

    getSecondaryChargeTypeValue?(label: string | undefined): string;
    getSecondaryChargeType?(
        value: string | undefined
    ): SelectOption | undefined;
    shouldSecondaryChargeTypeActive?(chargeTypeId: string | undefined): boolean;
}
