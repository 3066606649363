import { Injectable } from '@angular/core';
import {
    IComponentModalContent,
    IModalContent,
    IModalService,
    ModalSize,
    SafeAny,
} from '@pf/shared-common';
import { ModalOptions, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { convertToReadable } from '@pf/shared-utility';
import { isObject } from 'lodash-es';

export enum ModalType {
    Default = 'default',
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
    Success = 'success',
}

@Injectable({
    providedIn: 'root',
})
export class ModalService implements IModalService {
    constructor(private modal: NzModalService) {}

    info(content: IModalContent): void {
        this.modal.info(this.mapToNzModalType(content));
    }

    warning(content: IModalContent): void {
        this.modal.warning({
            ...this.mapToNzModalType(content),
            nzOkText: content.okText ?? 'Confirm',
            nzCancelText: content.cancelText ?? 'Cancel',
        });
    }

    error(content: IModalContent): void {
        this.modal.error(this.mapToNzModalType(content));
    }

    success(content: IModalContent): void {
        this.modal.success(this.mapToNzModalType(content));
    }

    createComponentModal<TComponentProps>(
        content: IComponentModalContent<TComponentProps>,
        modalType: ModalType = ModalType.Default
    ): NzModalRef {
        switch (modalType) {
            case ModalType.Warning:
                return this.modal.warning({
                    ...this.mapToComponentNzModalType(content),
                });
            case ModalType.Error:
                return this.modal.error({
                    ...this.mapToComponentNzModalType(content),
                });
            case ModalType.Info:
                return this.modal.info({
                    ...this.mapToComponentNzModalType(content),
                });
            case ModalType.Success:
                return this.modal.success({
                    ...this.mapToComponentNzModalType(content),
                });
            default:
                return this.modal.create({
                    ...this.mapToComponentNzModalType(content),
                });
        }
    }

    confirmAction(
        entityName: string,
        onOk: () => void,
        action:
            | 'Create'
            | 'Delete'
            | 'Deactivate'
            | 'Cancel'
            | string = 'Delete',
        onCancel?: () => void,
        title?: string,
        additionalText?: string
    ): void {
        this.warning({
            title: title || `Confirm ${action}`,
            content:
                `Are you sure you want to ${action.toLowerCase()} this ${entityName}? ${
                    additionalText || ''
                }`.trim(),
            onOk: () => {
                onOk();
            },
            onCancel: () => {
                onCancel?.();
            },
        });
    }

    /**
     * Confirm action with observable
     * onOk and entityName or content is required
     * @param config
     */
    confirmAction$(config: {
        entityName?: string;
        onOk: () => Observable<SafeAny>;
        action?: 'Create' | 'Delete' | 'Deactivate' | 'Cancel' | string;
        onCancel?: () => Observable<SafeAny>;
        okText?: string;
        title?: string;
        content?: string;
        entityProps?: SafeAny;
    }): Observable<SafeAny> {
        const action = config.action || 'Delete';
        let modalContent =
            config.content ??
            `Are you sure you want to ${action.toLowerCase()} this ${config.entityName}?`.trim();

        modalContent += '<br>';

        if (isObject(config.entityProps)) {
            Object.keys(config.entityProps).forEach(key => {
                if (
                    key === 'id' ||
                    config.entityProps[key] === undefined ||
                    config.entityProps[key] === ''
                )
                    return;
                modalContent += '<br>';

                if (Array.isArray(config.entityProps[key])) {
                    modalContent += `<strong>${convertToReadable(key)}</strong>:<br>`;
                    config.entityProps[key].forEach((element: SafeAny) => {
                        modalContent += `&nbsp;&nbsp;&nbsp;&nbsp;${element}<br>`;
                    });
                    return;
                } else {
                    modalContent += `<strong>${convertToReadable(key)}</strong>: ${config.entityProps[key]}`;
                }
            });
        }

        return new Observable<SafeAny>(observer => {
            this.warning({
                title: config.title || `Confirm ${action}`,
                content: modalContent,
                okText: config.okText || 'Confirm',
                onOk: () => {
                    config.onOk().subscribe(result => {
                        observer.next(result);
                        observer.complete();
                    });
                },
                onCancel: () => {
                    config.onCancel?.().subscribe(result => {
                        observer.next(result);
                        observer.complete();
                    });
                },
            });
        });
    }

    private mapToComponentNzModalType<TComponentProps>(
        content: IComponentModalContent<TComponentProps>
    ): ModalOptions {
        if (content.componentParams && !content.viewContainerRef) {
            throw new Error(
                'To be able to use the component params with the modal, the viewComponentRef needs to be set!'
            );
        }

        const modalContent = this.mapToNzModalType(content);
        modalContent.nzData = content.componentParams;
        modalContent.nzViewContainerRef = content.viewContainerRef;

        return modalContent;
    }

    private mapToNzModalType(content: IModalContent): ModalOptions {
        return {
            nzTitle: content.title,
            nzContent: content.content,
            nzOnOk: content.onOk,
            nzOnCancel: () => {
                this.modal.closeAll();
                content.onCancel?.();
            },
            nzOkDisabled: content.isOkDisabled,
            nzKeyboard: true,
            nzMask: true,
            nzMaskClosable:
                content.maskClosable === undefined
                    ? true
                    : content.maskClosable,
            nzWidth: content.size ?? ModalSize.Medium,
            nzOkText: content.okText ?? 'Ok',
            nzCancelText:
                content.cancelText === undefined
                    ? 'Cancel'
                    : content.cancelText,
        };
    }
}
