<div class="checkbox-group-header">
    <label
        nz-checkbox
        [(ngModel)]="allChecked"
        (ngModelChange)="updateAllChecked()"
        [nzIndeterminate]="indeterminate">
        {{ filterName }}
    </label>
    <ng-container *ngIf="displayValidationMessage">
        <pf-text type="danger">{{ selectionRequiredMessage }}</pf-text>
    </ng-container>
</div>
<ng-container *ngIf="!isGrouped">
    <nz-checkbox-group
        data-pf-style="stacked"
        [(ngModel)]="selectOptions"
        (ngModelChange)="updateSingleChecked()"></nz-checkbox-group>
</ng-container>
<ng-container *ngIf="isGrouped && searchEntityTypes">
    @for (entityType of searchEntityTypes | keyvalue; track entityType.key) {
        <ng-container *ngIf="isEntityOptionsAvailable(entityType.key)">
            <div class="entity-group">
                <nz-tag
                    [nzColor]="entityType.value.groupColor"
                    nzMode="closeable"
                    (nzOnClose)="entityGroupDelete(entityType.key)"
                    >{{ entityType.key }}
                </nz-tag>
                <nz-checkbox-group
                    [(ngModel)]="selectEntityOptions[entityType.key]"
                    (ngModelChange)="updateSingleChecked()"></nz-checkbox-group>
            </div>
        </ng-container>
    }
</ng-container>
<ng-container *ngIf="searchFacade || isGrouped">
    <pf-select
        placeholder="Search.."
        [lazyLoad]="false"
        [control]="searchControl"
        (selected)="selectItem($event)"
        [clearAfterSelect]="true"
        [searchFacade]="searchFacade"
        [defaultQueryParams]="searchFilters"
        [grouped]="isGrouped"></pf-select>
</ng-container>
