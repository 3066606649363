import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { LoadsView } from './load-facade.config';
import { LoadStateProvider } from './load-state.provider';

@Injectable({
    providedIn: 'root',
})
export class LoadViewResolver implements Resolve<LoadsView> {
    constructor(private loadStateProvider: LoadStateProvider) {}

    resolve(
        route: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot
    ): Observable<LoadsView> {
        const view = route.data['view'] as LoadsView;
        this.loadStateProvider.setLoadsView(view);
        return of(view);
    }
}
