import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import { VehiclesService } from '@control-tower/platform-transportation';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    IPagedResult,
    PFTransportationEntities,
} from '@pf/shared-common';
import { VehicleStore } from './vehicle.store';
import {
    VehicleCreate,
    VehicleRead,
    VehicleSearchParams,
} from '../../entities/vehicle.dto';
import { Operation } from 'fast-json-patch';

@Injectable()
export class VehicleDataService extends AbstractDataService<
    VehicleRead,
    VehicleCreate
> {
    protected EntityName = PFTransportationEntities.Vehicle;

    constructor(
        private service: VehiclesService,
        store: VehicleStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: VehicleCreate): Observable<VehicleRead> {
        return this.service.vehicleCreate({
            vehicleViewModelCreateBody: body,
        }) as Observable<VehicleRead>;
    }

    protected deleteObs$(id: string): Observable<VehicleRead> {
        return this.service.vehicleDelete({
            id,
        }) as Observable<VehicleRead>;
    }

    protected undoDeleteObs$(_: string): Observable<VehicleRead> {
        // return this.service.vehicleUndoDelete({
        //     id,
        // }) as Observable<VehicleRead>;
        throw new Error('Method not implemented.');
    }

    protected getObs$(id: string): Observable<VehicleRead> {
        return this.service.vehicleReadById({
            id,
        }) as Observable<VehicleRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<VehicleRead> {
        return this.service.vehicleUpdatePatch({
            id,
            operation: operations,
        }) as Observable<VehicleRead>;
    }

    protected searchObs$(
        searchParams: VehicleSearchParams
    ): Observable<IPagedResult<VehicleRead>> {
        return this.service.vehicleSearch(searchParams) as Observable<
            IPagedResult<VehicleRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<VehicleRead>>> {
        return this.service.vehicleGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingDto<VehicleRead>>
        >;
    }
}
