<div class="text-search-container">
    <pf-input
        [tooltip]="tooltip"
        inputType="search"
        [pfStyle]="displayLabel ? 'stacked' : 'simple'"
        data-pf-handle="search"
        [label]="displayLabel ? filterName : ''"
        [placeholder]="filterName"
        [control]="searchControl"></pf-input>
</div>
