import { IEntity, SafeAny } from '@pf/shared-common';
import { distinctUntilChanged, filter, Observable, of } from 'rxjs';
import { createStore } from '@ngneat/elf';
import { makeUnique } from '@pf/shared-utility';

import { Injectable } from '@angular/core';
import {
    addActiveIds,
    addEntities,
    deleteEntitiesByPredicate,
    getActiveEntities,
    getEntity,
    removeActiveIds,
    selectEntity,
    updateEntities,
    withActiveIds,
    withEntities,
} from '@ngneat/elf-entities';

export type FormAction =
    | 'unknown'
    | 'init'
    | 'error'
    | 'cancel'
    | 'submit'
    | 'cancelled'
    | 'submitted'
    | string;

export type FormSubmitAction = { label: string; action: string };

export interface FormActionState<T extends IEntity> {
    id: string;
    action: FormAction;
    submitAction?: string;
    submitActions?: FormSubmitAction[] | null;
    entityType: string;
    parentFormId?: string;
    entity?: T;
    isDirty?: boolean;
    forceDirty?: boolean;
    drawerRendered?: boolean;
    drawerId?: string;
}

const formStore = createStore(
    { name: 'forms' },
    withEntities<FormActionState<SafeAny>>(),
    withActiveIds()
);

@Injectable({ providedIn: 'root' })
export class FormService {
    registerForm(
        entityType: string,
        formId?: string,
        parentEntityType?: string
    ) {
        const id = formId || makeUnique(entityType);
        const active = this.getActive(parentEntityType || entityType);
        const newForm: FormActionState<SafeAny> = {
            id,
            action: 'init',
            entityType,
            parentFormId: active?.id,
            isDirty: false,
            drawerRendered: false,
        };
        formStore.update(addEntities([newForm]));
        this.setActive(newForm);
        return newForm;
    }

    deRegisterForm(entityType: string) {
        const active = this.getActive(entityType);

        if (!active) {
            console.debug(`Form ${entityType} not registered`);
            return;
        }

        formStore.update(removeActiveIds([active.id]));
        if (active.parentFormId) {
            formStore.update(addActiveIds([active.parentFormId]));
        }
    }

    deRegisterFormId(formId: string) {
        if (!formId) {
            return;
        }
        const form = formStore.query(getEntity(formId));
        if (!form) {
            console.debug(`Form ${formId} not found.`);
            return;
        }
        formStore.update(removeActiveIds([formId]));
    }

    deRegisterAllForms(entityType: string) {
        formStore.update(
            deleteEntitiesByPredicate(state => state.entityType === entityType)
        );
    }

    setActiveForm(entityType: string, formId: string) {
        const form = formStore.query(getEntity(formId));
        if (!form) {
            return this.registerForm(entityType, formId);
        }
        this.setActive(form);
        return form;
    }

    getFormState(id: string) {
        return formStore.query(getEntity(id));
    }

    cancel$ = (entityType: string) =>
        this.getActionStateObs(entityType, 'cancel');
    submit$ = (entityType: string) =>
        this.getActionStateObs(entityType, 'submit');
    cancelled$ = (entityType: string) =>
        this.getActionStateObs(entityType, 'cancelled');
    submitted$ = (entityType: string) =>
        this.getActionStateObs(entityType, 'submitted');
    action$ = (entityType: string, action: string) =>
        this.getActionStateObs(entityType, action);

    activeState$(
        entityType: string
    ): Observable<FormActionState<SafeAny> | null> {
        const active = this.getActive(entityType);

        if (!active) {
            console.debug(`Form ${entityType} not registered`);
            return of(null);
        }

        return formStore.pipe(selectEntity(active.id)) as Observable<
            FormActionState<SafeAny>
        >;
    }

    activeState(entityType: string) {
        const active = this.getActive(entityType);

        if (!active) {
            console.debug(`Form ${entityType} not registered`);
            return null;
        }
        return active;
    }

    resetForm(entityType: string) {
        this.notifyState(entityType, { action: 'init', isDirty: false });
    }

    resetSubmit(entityType: string) {
        this.notifyState(entityType, { action: 'init', isDirty: true });
    }

    cancel = (entityType: string) =>
        this.notifyState(entityType, { action: 'cancel' });
    submit = (entityType: string, submitAction?: string) =>
        this.notifyState(entityType, { action: 'submit', submitAction });
    cancelled = (entityType: string) =>
        this.notifyState(entityType, { action: 'cancelled', isDirty: false });
    submitted = <T extends IEntity>(entityType: string, entity: T) =>
        this.notifyState(entityType, {
            action: 'submitted',
            entity,
            isDirty: false,
        });

    markAsDirty = (entityType: string, forceDirtyState?: boolean) =>
        this.notifyState(entityType, {
            isDirty: true,
            forceDirty: forceDirtyState,
        });

    notifyError(entityType: string) {
        this.notifyState(entityType, { action: 'error' });
    }

    setDrawerRendered(entityType: string, value: boolean, drawerId: string) {
        this.notifyState(entityType, { drawerRendered: value, drawerId });
    }

    isDrawerRendered(entityType: string) {
        return this.getActive(entityType)?.drawerRendered || false;
    }

    notifyState(entityType: string, update: Partial<FormActionState<SafeAny>>) {
        const active = this.getActive(entityType);

        if (!active) {
            console.debug(`Form ${entityType} not registered`);
            return;
        }

        if (active) {
            formStore.update(
                updateEntities(active.id, entity => ({ ...entity, ...update }))
            );
        }
    }

    private getActionStateObs(entityType: string, action: FormAction) {
        return this.activeState$(entityType).pipe(
            distinctUntilChanged((previous, current) => {
                return (
                    previous?.action === current?.action &&
                    previous?.entity?.id === current?.entity?.id
                );
            }),
            filter(state => !!state && state.action === action)
        ) as Observable<FormActionState<SafeAny>>;
    }

    private setActive(formState: FormActionState<SafeAny>) {
        const active = this.getActive(formState.entityType);
        if (active) {
            formStore.update(removeActiveIds([active.id]));
        }
        formStore.update(addActiveIds([formState.id]));
    }

    private getActive(entityType: string) {
        return formStore
            .query(getActiveEntities())
            .find(state => state.entityType === entityType);
    }
}
