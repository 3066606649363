import { Component } from '@angular/core';
import { BaseFilterComponent } from './base-filter.component';
import {
    FilterOption,
    ISegmentedOption,
    SafeAny,
    SelectOption,
} from '@pf/shared-common';
import { DateRange, DateRangeOption, DateRanges } from '@pf/shared-utility';
import { FormControl } from '@angular/forms';
import { combineLatest, skip, take } from 'rxjs';
import { FilterMetadata } from '../stores/filter.store';
import { untilDestroyed } from '@ngneat/until-destroy';

interface TimeFilterProps {
    selectedDateOption: number;
    selectedStartDate: string;
    selectedEndDate: string;
    fiscalPeriodId?: string;
}

@Component({
    selector: 'pf-segmented-date-filter',
    templateUrl: './segmented-date-filter.component.html',
    styleUrl: './segmented-date-filter.component.scss',
})
export class SegmentedDateFilterComponent extends BaseFilterComponent {
    private _selectedDateRange: [string, string] = ['', ''];
    private startFilterKeySuffix = 'StartDate';

    searchControl = new FormControl<string>('');
    segmentedOptions: ISegmentedOption[] = [];
    customDateRangeActivated = false;
    selectedTimeSegmentIndex = 0;

    handleSegmentedChange(option: number) {
        this.resetTimeFilters();
        const selectedSegment = this.segmentedOptions[option];
        if (selectedSegment.value === 'custom') {
            this.customDateRangeActivated = true;
            return;
        } else {
            this.customDateRangeActivated = false;
            this.setDateRange(option);

            // If it is a revisit to the page, we need to update the initial date filter regarding
            // the previous selected date range option.
            if (selectedSegment.default) {
                this.filterService.setInitialFilter({
                    name: this.filterName,
                    key: this.filterKey,
                    value: this._selectedDateRange,
                    props: {
                        selectedDateOption: option,
                        selectedStartDate: this._selectedDateRange[0],
                        selectedEndDate: this._selectedDateRange[1],
                    },
                });
            }
        }
        this.selectedTimeSegmentIndex = option;
        this.filterChangedChecks();
    }

    dateRangeClearHandler() {
        this._selectedDateRange = ['', ''];
        this.filterChangedChecks();
    }

    dateRangeChangeHandler($event: DateRange & { option: DateRangeOption }) {
        this._selectedDateRange = [$event.startDate, $event.endDate];
        this.filterChangedChecks();
    }

    calendarChangedHandler($event: Date[]) {
        if ($event?.[0]) {
            this._selectedDateRange[0] = $event[0].toISOString();
        }
        if ($event?.[1]) {
            this._selectedDateRange[1] = $event[1].toISOString();
        }

        this.filterChangedChecks();
    }

    changeFilterKeyHandler(filterKeyIndex: number) {
        this.resetTimeFilters();
        this.changeFilterKeyByIndex(filterKeyIndex, () => {
            if (this.filterKeyObject?.searchFacade) {
                this._selectedDateRange = ['', ''];
            } else {
                this.setDateRange(this.selectedTimeSegmentIndex);
            }
        });
        this.filterChangedChecks();
    }

    selectFiscalPeriod(item: SelectOption) {
        this.resetTimeFilters();

        this.filterKeyObject?.searchFacade
            ?.getById$(item.value)
            .subscribe((period: SafeAny) => {
                this._selectedDateRange = [period.startDate, period.endDate];
                this.filterChangedChecks({
                    fiscalPeriodId: item.value,
                });
            });
    }

    override setFilterOptions(
        options: FilterOption[] | undefined,
        reset: boolean = false
    ): void {
        if (reset) {
            this.selectedTimeSegmentIndex = -1;
            return;
        }

        if (!options) return;

        this.segmentedOptions = options as ISegmentedOption[];
    }

    override validate(): void {
        this.displayValidationMessage =
            !this._selectedDateRange[0] && !this._selectedDateRange[1];
    }

    override setDefaultFilterKey() {
        const currentStartDateFilterKey = this.filterService
            .getCurrentFilters()
            ?.map(filter => Object.keys(filter!)[0])
            .find(filterKey => filterKey.endsWith(this.startFilterKeySuffix));

        if (currentStartDateFilterKey) {
            this.changeFilterKey(
                currentStartDateFilterKey.replace(this.startFilterKeySuffix, '')
            );
        } else {
            super.setDefaultFilterKey();
        }
    }

    override handleInitialValue(): void {
        combineLatest([
            this.filterService.checkInitialFilterIsDefinedByName$(
                this.filterName
            ),
            this.filterService.getAppliedFilterByName$(this.filterName),
        ])
            .pipe(take(1))
            .subscribe(([initialFiltersDefined, filter]) => {
                if (filter) {
                    const startTime = filter?.value?.[0]?.toString();
                    const endTime = filter?.value?.[1]?.toString();

                    this.setFilterKey(filter);

                    const filterProps = filter.props as TimeFilterProps;

                    if (filter.props?.fiscalPeriodId) {
                        this.searchControl.setValue(
                            filter.props.fiscalPeriodId
                        );
                        this._selectedDateRange = [startTime, endTime];
                    } else if (
                        this.isCustomDateRange(filterProps.selectedDateOption)
                    ) {
                        this.selectCustomDateRange(startTime, endTime);
                    } else {
                        this.handleSegmentedChange(
                            filterProps.selectedDateOption
                        );
                    }
                }

                if (!initialFiltersDefined) {
                    this.setDefaultDateType();
                    this.setDefaultDateRangeFilter();
                }

                super.handleInitialValue();
            });
    }

    override filterChangedChecks(
        timeFilterProps?: Partial<TimeFilterProps>
    ): void {
        this.filterChangeHandler({
            name: this.filterName,
            key: this.filterKey,
            value: this._selectedDateRange,
            props: {
                ...timeFilterProps,
                selectedDateOption: this.selectedTimeSegmentIndex,
                selectedStartDate: this._selectedDateRange[0],
                selectedEndDate: this._selectedDateRange[1],
            },
        });
        this.validate();
    }

    override filterResetFn(): void {
        this.resetFilterKey();
        this.setFilterOptions(this.segmentedOptions, true);
        this.setDefaultDateType();
    }

    override filterClearFn(): void {
        this.resetFilterKey();
        this.setFilterOptions(this.segmentedOptions);
        this.setDefaultDateType();
    }

    private setDateRange(segmentOptionIndex: number): void {
        const segmentedOption = this.segmentedOptions?.[segmentOptionIndex];
        if (!segmentedOption) return;

        const dates = DateRanges[segmentedOption.value as DateRangeOption];
        this._selectedDateRange = [dates().from, dates().to];
        this.selectedTimeSegmentIndex = segmentOptionIndex;
    }

    private setDefaultDateType(): void {
        const defaultOptionIndex =
            this.segmentedOptions?.findIndex(option => option.default) ?? -1;

        if (defaultOptionIndex > -1) {
            this.handleSegmentedChange(defaultOptionIndex);
        }
    }

    private setDefaultDateRangeFilter(): void {
        const defaultOption = this.getDefaultDateOption();
        if (!defaultOption) return;

        const dates = DateRanges[defaultOption.value as DateRangeOption];

        const initialFilterIndex = this.segmentedOptions.findIndex(
            option => option.value === defaultOption.value
        );

        this.updateInitialFilter({
            selectedDateOption: initialFilterIndex,
            selectedStartDate: dates().from,
            selectedEndDate: dates().to,
        });
    }

    override updateInitialFilter(timeFilterProps: TimeFilterProps): void {
        const filter = {
            name: this.filterName,
            key: this.filterKey,
            value: [
                timeFilterProps.selectedStartDate,
                timeFilterProps.selectedEndDate,
            ],
            props: timeFilterProps,
        } as FilterMetadata;
        this.filterService.setInitialFilter(filter);
    }

    private getDefaultDateOption(): ISegmentedOption | undefined {
        const defaultOptionIndex =
            this.segmentedOptions?.findIndex(option => option.default) ?? -1;
        return this.segmentedOptions?.[defaultOptionIndex];
    }

    private resetTimeFilters() {
        this.filtersAreReady$!.pipe(untilDestroyed(this), skip(1)).subscribe(
            () => {
                this.filterService.deleteFilter(this.filterKey);
                this.filterService.removeValidationIssue(this.filterKey);
            }
        );
    }

    private isCustomDateRange(index: number): boolean {
        return this.segmentedOptions[index]?.value === 'custom';
    }

    private selectCustomDateRange(start: string, end: string) {
        this.customDateRangeActivated = true;
        this.selectedTimeSegmentIndex = this.segmentedOptions.findIndex(
            x => x.value === 'custom'
        );
        this._selectedDateRange = [start, end];

        this.segmentedOptions.forEach(option => (option.initial = false));
        this.segmentedOptions[this.selectedTimeSegmentIndex].initial = true;
        this.segmentedOptions[this.selectedTimeSegmentIndex].initialValue = [
            start,
            end,
        ];
    }

    private setFilterKey(filter: FilterMetadata) {
        const filterKey = this.filterKeys?.find(
            key => key.value === filter.key
        );
        if (filterKey) {
            this.changeFilterKey(filterKey?.value);
        }
    }
}
