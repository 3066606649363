import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { FormControl } from '@angular/forms';
import { TableService } from '../../services/table.service';
import { FilterService } from '../../services/filter.service';

@UntilDestroy()
@Component({
    selector: 'pf-table-toolbar',
    templateUrl: './table-toolbar.component.html',
    styleUrls: ['./table-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableToolbarComponent {
    @Input() title = '';
    @Input() showSearch = false;
    @Input() showClearFilters = false;
    @Input() disableClearFilters = false;
    @Input() showSelectionToolbar = false;
    @Input() showAdvancedFilterButton = false;

    @Output() search = new EventEmitter<string>();
    @Output() resetTable = new EventEmitter();
    @Output() refreshTable = new EventEmitter();
    searchControl = new FormControl<string>('');

    constructor(
        public tableService: TableService,
        public filterService: FilterService
    ) {}

    resetClicked() {
        this.tableService.unloadVerifier(() => {
            this.filterService.clearFilters();
            this.searchControl.reset();

            this.filterService.getCanReset().subscribe(canReset => {
                if (canReset) {
                    // value is irrelevant here
                    this.resetTable.next(new Date());
                }
            });
        });
    }
}
