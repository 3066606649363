import { IEntity } from '@pf/shared-common';
import {
    LaneCarrier,
    LaneLookupLookupRequestParams,
    LaneLookupViewModel,
    LaneSearchRequestParams,
    LaneViewModelBase,
    LaneViewModelRead,
} from '@control-tower/platform-loads';
import { CustomFieldViewModel } from '@control-tower/platform-loads/model/customFieldViewModel';
import { LocationDto } from '@pf/platform-core/domain';
import { LaneRateDto } from './lane-rate.dto';
import { asArraySafe } from '@pf/shared-utility';

export type LaneRead = LaneViewModelRead & LaneLookupViewModel & IEntity;
export type LaneCreate = LaneViewModelBase;
export type LaneSearchParams =
    | LaneSearchRequestParams
    | LaneLookupLookupRequestParams;

export class LaneDto implements IEntity {
    constructor(data: Partial<LaneDto>) {
        Object.assign(this, data);
        this.laneCarriers = asArraySafe(data.laneCarriers || this.laneCarriers);
    }

    id!: string;
    laneGroupId?: string | null;
    /**
     * The Lane\'s name
     */
    name?: string | null;
    /**
     * The Lane\'s Code
     */
    code?: string | null;
    /**
     * The Lane\'s Description
     */
    description?: string | null;
    originLocation?: LocationDto | null;
    destinationLocation?: LocationDto | null;
    rates?: Array<LaneRateDto> | null;
    customFields?: Array<CustomFieldViewModel> | null;
    laneCarriers: Array<LaneCarrier> = [];
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
}
