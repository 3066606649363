import {
    BehaviorSubject,
    debounceTime,
    filter,
    map,
    Observable,
    Subject,
} from 'rxjs';
import {
    IEntity,
    QueryFilters,
    SafeAny,
    TableQueryParams,
    TableRow,
    TableState,
} from '@pf/shared-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Injectable } from '@angular/core';
import { createStore, Store } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { ModalService } from '../../../services/modal.service';
import {
    EntitiesRef,
    getAllEntities,
    getEntity,
    setEntities,
    upsertEntities,
    withEntities,
} from '@ngneat/elf-entities';
import { isValue } from '@pf/shared-utility';

export interface EditableTableState {
    entities: Record<IEntity['id'], IEntity>;
    ids: IEntity['id'][];
}

export interface FocusCellState {
    rowIndex: number;
    columnIndex: number;
    direction: 1 | -1;
}

@UntilDestroy()
@Injectable()
export class TableService {
    private readonly _queryParams = new BehaviorSubject<
        Readonly<TableQueryParams>
    >({
        serverRendered: false,
        pageIndex: 0,
        pageSize: 0,
        filters: {},
        checkboxes: [],
    });
    private readonly _tableInitializedSubject = new BehaviorSubject(false);

    private readonly _resetSubject = new Subject<void>();
    private readonly _submittedSubject = new Subject<void>();
    private readonly _editedTableDataSubject = new Subject<SafeAny[]>();
    private readonly _tableRowEditedSubject = new Subject<{
        row: TableRow;
        update: IEntity;
    }>();

    private readonly _focusedCell = new Subject<FocusCellState>();
    private _isTableDataEdited = false;

    private tableEditableStore?: Store<
        {
            name: string;
            state: EntitiesRef<'entities', 'ids', 'idKey'>;
            config: undefined;
        },
        EditableTableState
    >;
    private persistedEditableTableState?: {
        initialized$: Observable<boolean>;
        unsubscribe(): void;
    };
    private editableStateInitialized = false;

    tableState: TableState = {
        rows: [],
        columns: [],
        pageIndex: 1,
        total: 0,
        lastUpdated: new Date(),
    };

    queryParamsChanged$ = this._queryParams.asObservable().pipe(
        untilDestroyed(this),
        filter(
            queryParams =>
                queryParams !== null &&
                queryParams.pageIndex > 0 &&
                !!queryParams.serverRendered
        ),
        debounceTime(300)
    );
    tableInitialized$ = this._tableInitializedSubject.asObservable();

    reset$ = this._resetSubject.asObservable();
    submitted$ = this._submittedSubject.asObservable();

    allRowsChecked$ = new BehaviorSubject<boolean>(false);
    selectedRows$ = new BehaviorSubject<ReadonlyArray<TableRow>>([]);
    editedTableData$ = this._editedTableDataSubject.asObservable();
    focusedCell$ = this._focusedCell.asObservable();
    checked = false;
    indeterminate = false;

    get isTableEditable() {
        return this.editableStateInitialized;
    }

    get editedTableData() {
        return this.tableEditableStore?.query(getAllEntities()) as SafeAny[];
    }

    get tableRowEdited$() {
        return this._tableRowEditedSubject.asObservable();
    }

    get queryParams() {
        return this._queryParams.value;
    }

    get hasCheckedRows() {
        return this.checked || this.indeterminate;
    }

    selectedRowCount$ = this.selectedRows$.pipe(
        map(rows => (this.checked ? this.tableState.total : rows.length))
    );

    get selectedRowCount() {
        return this.checked
            ? this.tableState.total
            : this.selectedRows$.value.length;
    }

    constructor(private modalService: ModalService) {
        this.editedTableData$.pipe(untilDestroyed(this)).subscribe({
            next: data => {
                this._isTableDataEdited = !!data.length;
            },
        });
    }

    initialize() {
        this._tableInitializedSubject.next(true);
    }

    reset(update: Partial<TableQueryParams>, emit = true) {
        this.updateTableQueryParams({
            pageIndex: 1,
            sortField: null,
            sortOrder: null,
            filters: {},
            checkboxes: {},
            searchFields: [],
            searchValue: '',
            ...update,
        });
        this.resetSelection();
        if (emit) {
            this._resetSubject.next();
        }
    }

    updateTableQueryParams(update: Partial<TableQueryParams>) {
        const newQueryParams: Readonly<TableQueryParams> = {
            ...(this._queryParams.value || {
                pageIndex: 0,
                pageSize: 0,
            }),
            ...update,
        };
        this._queryParams.next(newQueryParams);
        this.resetSelection();
    }

    updateFilters<T>(filtersUpdate: QueryFilters<T>, reset = false) {
        if (reset) {
            this.reset({}, false);
        }

        this.updateTableQueryParams({
            pageIndex: 1,
            filters: {
                ...(this.queryParams.filters || {}),
                ...filtersUpdate,
            },
        });
    }

    resetSelection() {
        this.checked = false;
        this.indeterminate = false;
        this.allRowsChecked$.next(false);
        this.selectedRows$.next([]);
    }

    initiateEditableTableState(tableKey: string, persistEditedData: boolean) {
        this.createEditableTableStore(tableKey);
        if (persistEditedData) {
            this.createPersistentState();
            this.persistedEditableTableState?.initialized$.subscribe(
                () => (this.editableStateInitialized = true)
            );
        } else {
            this.editableStateInitialized = true;
        }
    }

    updateEditableTableState(entity: IEntity) {
        this.tableEditableStore?.update(upsertEntities(entity));

        const editedData = this.editedTableData;
        this._editedTableDataSubject.next(editedData.filter(isValue));
        const tableRow = this.tableState.rows.find(row => row.id === entity.id);

        if (tableRow) {
            this._tableRowEditedSubject.next({ row: tableRow, update: entity });
        }
    }

    getEntityFromEditableStateById(id: string): SafeAny {
        return this.tableEditableStore?.query(getEntity(id) as SafeAny);
    }

    submit() {
        this.resetEditableTableState(false);
        this._submittedSubject.next();
    }

    resetEditableTableState(resetEmit = true) {
        this.tableEditableStore?.update(setEntities([]));
        this._editedTableDataSubject.next([]);
        this.reset(this._queryParams.value, resetEmit);
    }

    unloadVerifier(confirmCallback?: () => void, cancelCallback?: () => void) {
        if (this._isTableDataEdited) {
            this.modalService.confirmAction(
                'form',
                () => {
                    this.resetEditableTableState();
                    confirmCallback?.();
                },
                'Refresh Confirmation',
                cancelCallback,
                'Refresh Confirmation',
                'The changes will be lost.'
            );
        } else {
            confirmCallback?.();
        }
    }

    changeFocus(
        rowIndex: number,
        columnIndex: number,
        nextRow = false,
        reverse = false
    ) {
        this._focusedCell.next(
            nextRow
                ? this.findNextRowAvailableEditableCell(rowIndex, columnIndex)
                : this.findNextAvailableEditableCell(
                      rowIndex,
                      columnIndex,
                      reverse
                  )
        );
    }

    private findNextRowAvailableEditableCell(
        rowIndex: number,
        columnIndex: number
    ): FocusCellState {
        const nextRow = this.tableState.rows.findIndex(
            (_row, index) => index > rowIndex
        );
        if (nextRow) {
            return { rowIndex: nextRow, columnIndex, direction: 1 };
        }
        return {
            rowIndex,
            columnIndex,
            direction: 1,
        };
    }

    private findNextAvailableEditableCell(
        rowIndex: number,
        columnIndex: number,
        reverse = false
    ): FocusCellState {
        const columns = this.tableState.columns.sort(
            (a, b) => a.index - b.index
        );
        const nextColumn = columns.find(
            (column, index) =>
                column.editable &&
                ((reverse && index < columnIndex) ||
                    (!reverse && index > columnIndex))
        );
        if (nextColumn) {
            return {
                rowIndex,
                columnIndex: nextColumn.index,
                direction: nextColumn.index > columnIndex ? 1 : -1,
            };
        }
        const nextRow = this.tableState.rows
            .sort((a, b) => a.index - b.index)
            .findIndex((_row, index) =>
                reverse ? index < rowIndex : index > rowIndex
            );
        if (nextRow) {
            const firstAvailableColumn =
                columns.find(column => column.editable)?.index || 0;
            return {
                rowIndex: nextRow,
                columnIndex: firstAvailableColumn,
                direction: reverse ? -1 : 1,
            };
        }
        return {
            rowIndex,
            columnIndex,
            direction: 1,
        };
    }

    private createPersistentState() {
        this.persistedEditableTableState = persistState(
            this.tableEditableStore as SafeAny,
            {
                storage: localStorageStrategy,
            }
        );
    }

    private createEditableTableStore(tableKey: string) {
        this.tableEditableStore = createStore(
            {
                name: `${tableKey}-editable-table`,
            },
            withEntities<IEntity>()
        );
    }
}
