import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum OperationType {
    LoadCustomFields = 'LoadCustomFields',
}

@Injectable({
    providedIn: 'root',
})
export class OperationStatusService {
    private operationStatus = new Map<
        OperationType,
        BehaviorSubject<boolean>
    >();

    constructor() {
        Object.values(OperationType).forEach(operation => {
            this.operationStatus.set(
                operation,
                new BehaviorSubject<boolean>(false)
            );
        });
    }

    startOperation(operation: OperationType) {
        this.operationStatus.get(operation)?.next(true);
    }

    endOperation(operation: OperationType) {
        this.operationStatus.get(operation)?.next(false);
    }

    getOperationStatus(operation: OperationType) {
        return this.operationStatus.get(operation)?.asObservable();
    }
}
