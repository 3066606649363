import {
    Permission,
    UserCarrier,
    UserCustomer,
    UserLocation,
    UserTenant,
    UserVendor,
} from '@control-tower/platform-core';

import { Observable } from 'rxjs';
import { SafeAny } from '../types/SafeTypes';

export interface UserProfile {
    userId: string;
    firstName: string;
    lastName: string;
    fullName: string;
    lastLogin: Date;
    email: string;
    permissions: Permission[];
    vendors: UserVendor[];
    carriers: UserCarrier[];
    locations: UserLocation[];
    customers: UserCustomer[];
    userGroupType: string;
}

export abstract class ProfileProvider {
    abstract readonly profile: UserProfile;
    abstract readonly tenants$: Observable<UserTenant[]>;
    abstract readonly fullName$: Observable<string>;
    abstract readonly lastLogin$: Observable<Date>;
    abstract readonly email$: Observable<string>;
    abstract readonly permissions$: Observable<Permission[]>;
    abstract readonly vendors$: Observable<UserVendor[]>;
    abstract readonly carriers$: Observable<UserCarrier[]>;
    abstract readonly locations$: Observable<UserLocation[]>;
    abstract readonly customers$: Observable<UserCustomer[]>;
    abstract readonly userGroupType$: Observable<string>;

    abstract hasPermission$(permissionName: string): Observable<boolean>;
    abstract load(): void;
    abstract resetPassword(): Observable<SafeAny>;
}
