import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    inject,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    FrameworkColumn,
    getTableRowValue,
    SafeAny,
    TableRow,
} from '@pf/shared-common';
import { InjectionHostDirective } from '../../../directives/injection-host.directive';
import { FormControl } from '@angular/forms';
import { TableService } from '../services/table.service';
import { debounceTime, filter, Subscription } from 'rxjs';
import { BaseControlComponent } from '../../../components/base-control/base-control.component';
import { cloneDeep } from 'lodash-es';
import { isValue } from '@pf/shared-utility';

@UntilDestroy()
@Component({
    selector: 'pf-table-cell-editable',
    templateUrl: './table-cell-editable.component.html',
    styleUrls: ['./table-cell-editable.component.scss'],
})
export class TableCellEditableComponent implements OnInit, AfterViewInit {
    private _cdr = inject(ChangeDetectorRef);
    @ViewChild(InjectionHostDirective, { static: true })
    injectionHost!: InjectionHostDirective;
    @ViewChild('inputElement') inputElementRef!: ElementRef;
    control = new FormControl();

    private _row!: TableRow;
    private _column!: FrameworkColumn;
    private _originalValue!: string | number | boolean | Date | SafeAny;
    private _value: string | number | boolean | Date | SafeAny | undefined;
    private manualEditSubscription?: Subscription;

    /**
     * TODO: we should move the drawer-subform component to its own module
     * and then we can use the dynamic control config here
     * and we can move the `table-cell-editable.component` to the
     * forms module and use the dynamic control config
     */
    // config?: DynamicControlConfig;

    @Input() set value(
        value: string | number | boolean | Date | SafeAny | undefined
    ) {
        this._value = value;
        this.controlValueSetter(this.value);
        this.cellInitialized();
    }

    get value() {
        return this._value;
    }

    get edited() {
        return this.control.value && this.control.value !== this._originalValue;
    }

    @Input() set row(row: TableRow) {
        this._row = row;
        this.cellInitialized();
        this.listenForManualEdits();
    }

    get row() {
        return this._row;
    }

    @Input() set column(column: FrameworkColumn) {
        this._column = column;
        this.cellInitialized();
    }

    get column() {
        return this._column;
    }

    get editedRowData() {
        return (
            this.tableService.getEntityFromEditableStateById(
                this.row.data.id
            ) || cloneDeep(this.row.data)
        );
    }

    constructor(private tableService: TableService) {
        this.tableService.focusedCell$
            .pipe(
                untilDestroyed(this),
                filter(
                    x =>
                        x.columnIndex === this.column.index &&
                        x.rowIndex === this.row.index
                )
            )
            .subscribe(state => {
                if (this.control.disabled) {
                    this.tableService.changeFocus(
                        this.row.index,
                        this.column.index,
                        false,
                        state.direction === -1
                    );
                }
                (
                    this.inputElementRef as unknown as BaseControlComponent
                ).focus();
            });

        this.tableService.reset$.pipe(untilDestroyed(this)).subscribe(() => {
            this.updateControlValue(this._originalValue);
        });

        this.tableService.submitted$
            .pipe(untilDestroyed(this), debounceTime(500))
            .subscribe(() => {
                this.controlValueSetter(this.value);
            });
    }

    ngAfterViewInit(): void {
        this.cellInitialized();
        this.control.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                this.invokeEditValueSetter(value);
            });
    }

    ngOnInit(): void {
        this.controlValueSetter(this.value);
    }

    onTab(event: Event) {
        if ((event as KeyboardEvent).key === 'Tab') {
            event.preventDefault();

            this.tableService.changeFocus(this.row.index, this.column.index);
        }
    }

    onShiftTab(event: Event) {
        // TODO this is not working - Enes
        if (
            (event as KeyboardEvent).shiftKey &&
            (event as KeyboardEvent).key == 'Tab'
        ) {
            event.preventDefault();
            this.tableService.changeFocus(
                this.row.index,
                this.column.index,
                false,
                true
            );
        }
    }

    onEnter(event: Event) {
        if (
            (event as KeyboardEvent).key === 'Enter' &&
            this.column.type !== 'select'
        ) {
            event.preventDefault();
            this.tableService.changeFocus(
                this.row.index,
                this.column.index,
                true
            );
        }
    }

    private cellInitialized() {
        if (this.row && this.column?.editCellInitialized) {
            this.column.editCellInitialized(this.row, this.control);
        }
    }

    private controlValueSetter(
        value: string | number | boolean | Date | SafeAny
    ) {
        if (!this.column) {
            return;
        }

        if (this.edited) {
            return;
        }

        if (this.control.value === value) {
            return;
        }

        const newValue = this.resolveValue(value, this.row.data);

        if (!this._originalValue) {
            this._originalValue = newValue;
        }

        this.updateControlValue(newValue);
    }

    private updateControlValue(newValue: SafeAny) {
        if (this.control.value === newValue) {
            return;
        }

        this.control.setValue(newValue);
        if (this.edited) {
            this.control.markAsDirty();
        }
        this.control.updateValueAndValidity();
        this._cdr.markForCheck();
    }

    private resolveValue(value: SafeAny, rowData: SafeAny): SafeAny {
        if (this.column.type === 'select') {
            const selectedOption =
                this.column.editableDataSelectedOption(rowData);
            return selectedOption?.value || null;
        }
        return value;
    }

    private invokeEditValueSetter(value: SafeAny) {
        if (!this.column.editValueSetter) {
            throw new Error(
                `Column ${this.column.field} does not have an editValueSetter`
            );
        }

        if (
            !isValue(value) ||
            (this.control.pristine && value === this._originalValue)
        ) {
            return;
        }

        this.column
            .editValueSetter(this.editedRowData, value)
            .then((updatedRowData: SafeAny) =>
                this.tableService.updateEditableTableState(updatedRowData)
            );
    }

    private listenForManualEdits() {
        if (!this.row) {
            return;
        }
        this.manualEditSubscription?.unsubscribe();
        this.manualEditSubscription = this.row.manualEdit$
            .pipe(untilDestroyed(this))
            .subscribe(data => {
                const editedRowValue = {
                    ...this.editedRowData,
                    ...data,
                };
                const newEditValue = this.column.valueFormatter(
                    getTableRowValue(this.column, editedRowValue),
                    editedRowValue
                );
                this.updateControlValue(
                    this.resolveValue(newEditValue, editedRowValue)
                );
            });
    }
}
