<form *ngIf="config" [formGroup]="form.formGroup" nz-form>
    <ng-container [ngSwitch]="config.type">
        <pf-input
            *ngSwitchCase="'number'"
            inputType="number"
            [pfStyle]="pfStyle"
            [pfDisabled]="!!config.readonly"
            [label]="config.label || ''"
            [tooltip]="config.tooltip"
            [control]="valueControl"></pf-input>
        <pf-input
            *ngSwitchCase="'textarea'"
            inputType="textarea"
            [pfStyle]="pfStyle"
            [pfDisabled]="!!config.readonly"
            [label]="config.label || ''"
            [tooltip]="config.tooltip"
            [control]="valueControl"></pf-input>
        <pf-checkbox
            *ngSwitchCase="'boolean'"
            [pfStyle]="pfStyle"
            [pfDisabled]="!!config.readonly"
            [label]="config.label || ''"
            [tooltip]="config.tooltip"
            [control]="valueControl">
        </pf-checkbox>
        <pf-select
            *ngSwitchCase="'select'"
            [pfStyle]="pfStyle"
            [data]="selectConfig.options || []"
            [tree]="!!selectConfig.tree"
            [pfDisabled]="!!config.readonly"
            [label]="config.label || ''"
            [tooltip]="config.tooltip"
            [control]="valueControl">
        </pf-select>
        <pf-date-picker
            *ngSwitchCase="'date'"
            [pfStyle]="pfStyle"
            datePickerType="date"
            [pfDisabled]="!!config.readonly"
            [label]="config.label || ''"
            [tooltip]="config.tooltip"
            [control]="valueControl">
        </pf-date-picker>
        <pf-date-picker
            *ngSwitchCase="'datetime'"
            [showTime]="true"
            [pfStyle]="pfStyle"
            datePickerType="date"
            [pfDisabled]="!!config.readonly"
            [label]="config.label || ''"
            [tooltip]="config.tooltip"
            [control]="valueControl">
        </pf-date-picker>
        <pf-input
            *ngSwitchDefault
            inputType="text"
            [pfStyle]="pfStyle"
            [pfDisabled]="!!config.readonly"
            [label]="config.label || ''"
            [tooltip]="config.tooltip"
            [control]="valueControl">
        </pf-input>
    </ng-container>
</form>
