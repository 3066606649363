import { Injectable } from '@angular/core';
import { TableActionsBuilder } from '@pf/shared-ui';
import { CustomerDto } from '../entities/customer.dto';

@Injectable()
export abstract class CustomerManageConfig {
    abstract configureAdditionalTableActions?(
        builder: TableActionsBuilder<CustomerDto>
    ): TableActionsBuilder<CustomerDto>;
}
