import { IFilterConfig, SafeAny, TableColumn } from '@pf/shared-common';

export abstract class FilterFacadeConfig {
    abstract headerNames: string[];
    abstract filters: Record<string, IFilterConfig>;

    abstract setFilters(
        columns: TableColumn<SafeAny>[],
        actor?: string
    ): TableColumn<SafeAny>[];

    getFilter(filterName: string) {
        if (
            !this.headerNames.includes(filterName) ||
            !this.filters[filterName]
        ) {
            throw new Error(`Filter ${filterName} not found.`);
        }
        return this.filters[filterName];
    }
}
