import { inject, Injectable } from '@angular/core';
import { filter, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { LoadStatusTypeFacade } from '../type-entities/LoadStatusTypeFacade';
import { LoadFacadeConfig, LoadStatusTypesConfig } from './load-facade.config';
import { LoadStateProvider } from './load-state.provider';
import { isValue } from '@pf/shared-utility';
import { LoadSystemStatusType } from '@control-tower/platform-loads';
import { SelectOption } from '@pf/shared-common';

@Injectable({
    providedIn: 'root',
})
export class LoadStatusTypeProvider {
    private readonly _loadStatusTypeFacade = inject(LoadStatusTypeFacade);

    private readonly _loadRecentStatusTypesConfig$: Observable<LoadStatusTypesConfig> =
        this.config.recentStatusTypes$.pipe(
            switchMap((config: LoadStatusTypesConfig) =>
                this._loadStatusTypeFacade.mapToCodes$(config)
            )
        );
    private readonly _loadHistoryStatusTypesConfig$: Observable<LoadStatusTypesConfig> =
        this.config.historyStatusTypes$.pipe(
            switchMap((config: LoadStatusTypesConfig) =>
                this._loadStatusTypeFacade.mapToCodes$(config)
            )
        );

    constructor(
        private loadStateProvider: LoadStateProvider,
        private readonly config: LoadFacadeConfig
    ) {}

    get loadStatusTypes$(): Observable<LoadStatusTypesConfig> {
        return this.loadStateProvider.loadsView$.pipe(
            filter(isValue),
            switchMap(view =>
                view === 'recent'
                    ? this._loadRecentStatusTypesConfig$
                    : this._loadHistoryStatusTypesConfig$
            ),
            map((typeConfigs: LoadStatusTypesConfig) =>
                typeConfigs.filter(type => !!type.typeFragment)
            ),
            tap(types => {
                this.validateLoadStatusTypes(types);
            }),
            shareReplay(1)
        );
    }

    loadStatusTypeOptions$: Observable<SelectOption[]> =
        this._loadHistoryStatusTypesConfig$.pipe(
            map(types => {
                if (!types) {
                    return [];
                }
                return types
                    .filter(type => !!type.typeFragment)
                    .map(type => {
                        return {
                            label: type.typeFragment?.name,
                            value: type.typeFragment?.id,
                        } as SelectOption;
                    });
            })
        );

    private validateLoadStatusTypes(types: LoadStatusTypesConfig) {
        if (!types.length) {
            return;
        }
        const newStatuses = types.filter(
            t => t.typeFragment?.loadSystemStatus === LoadSystemStatusType.New
        );
        const inProgressStatuses = types.filter(
            t =>
                t.typeFragment?.loadSystemStatus ===
                LoadSystemStatusType.InProgress
        );

        if (newStatuses.length !== 1) {
            console.debug('New statuses', newStatuses);
        }
        if (inProgressStatuses.length === 0) {
            console.debug('In progress statuses', inProgressStatuses);
        }
    }
}
