import {
    CustomerCreate,
    CustomerDto,
    CustomerRead,
    CustomerSearchParams,
} from '../entities/customer.dto';

import { AbstractManageEntityFacade } from '@pf/shared-services';
import {
    BuildChangeTrackingFacadeFactory,
    CustomFieldConfig,
    IContact,
    IEntityWithContactsFacade,
} from '@pf/shared/util-platform';
import { CustomerDataService } from '../infrastructure/customers/customer.data.service';
import { CustomerMapper } from '../infrastructure/customers/customer.mapper';
import { CustomerStore } from '../infrastructure/customers/customer.store';
import { Injectable, Optional } from '@angular/core';
import { RoutesForCustomers } from './customer.routes';
import { map, Observable, of, switchMap } from 'rxjs';
import {
    ColumnSearchType,
    EntitySaveOptions,
    IColumnSearchFacade,
} from '@pf/shared-common';
import { ResourceAuth } from '@control-tower/platform-loads';
import { CustomerFacadeConfig } from './customer-facade.config';

@Injectable()
export class CustomerFacade
    extends AbstractManageEntityFacade<
        CustomerDto,
        CustomerRead,
        CustomerCreate,
        CustomerSearchParams
    >
    implements
        IEntityWithContactsFacade<CustomerDto>,
        IColumnSearchFacade<CustomerDto>
{
    nameField: keyof CustomerDto = 'customerName';

    public customFieldsConfig$: Observable<CustomFieldConfig[]>;

    constructor(
        protected customerDataService: CustomerDataService,
        routes: RoutesForCustomers,
        customerStore: CustomerStore,
        customerMapper: CustomerMapper,
        @Optional() public config?: CustomerFacadeConfig
    ) {
        super(customerDataService, routes, customerStore, customerMapper);

        this.customFieldsConfig$ = this.config?.customFields$ ?? of([]);
    }

    override applyResourceAuth(body: CustomerCreate, dto: CustomerDto) {
        body.resourceAuth = {
            customers: dto.id ? [dto.id] : [],
        } as ResourceAuth;
    }

    override save$(
        dto: CustomerDto,
        options?: EntitySaveOptions<CustomerDto>
    ): Observable<CustomerDto> {
        return super.save$(dto, options).pipe(
            switchMap(result => {
                if (
                    !result.resourceAuth?.customers?.find(x => x === result.id)
                ) {
                    // save again to update resourceAuth with new location id
                    return super.save$(result, {
                        emitEvent: false,
                        saveCustomFields: false,
                    });
                }
                return of(result);
            })
        );
    }

    get columnListFilterName(): string {
        return 'customer';
    }

    getColumnListFilters$(): Observable<ColumnSearchType[]> {
        const queryParams = {
            pageNumber: 1,
            pageSize: 50,
        };

        return this.dataService.searchAll$(queryParams).pipe(
            map(results =>
                results.sort((a, b) =>
                    a.customerName.localeCompare(b.customerName)
                )
            ),
            map(result =>
                result.map(customer => ({
                    value: customer.id,
                    label: customer.customerName,
                }))
            )
        );
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        this.nameField
    );

    getContacts$(entity: CustomerDto): Observable<IContact[]> {
        return this.customerDataService.getContacts$(entity.id);
    }

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof CustomerDto, string>> {
        return { customerName: searchText };
    }
}
