import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { BaseFilterComponent } from './base-filter.component';
import { FilterOption, SafeAny } from '@pf/shared-common';
import {
    debounceTime,
    distinctUntilChanged,
    Observable,
    of,
    skipUntil,
} from 'rxjs';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'pf-text-search-filter',
    templateUrl: './text-search-filter.component.html',
    styleUrl: './text-search-filter.component.scss',
})
export class TextSearchFilterComponent
    extends BaseFilterComponent
    implements AfterViewInit
{
    private _isSyncing = false;

    searchControl = new FormControl<string>('');

    @Input() instantSearch = false;
    @Input() displayLabel = true;
    @Output() search = new EventEmitter<string>();

    ngAfterViewInit(): void {
        this.setupControlValueChanges();
        this.filterService
            .getAppliedFilterByName$(this.filterName)
            .pipe(untilDestroyed(this), debounceTime(300))
            .subscribe(filter => {
                if (filter) {
                    if (Array.isArray(filter.value)) {
                        this.setSearchControlValue(filter.value.join());
                    } else {
                        this.setSearchControlValue(filter.value || '');
                    }
                }
            });
    }

    override setFilterOptions(
        _options: FilterOption[] | Observable<FilterOption[]> | undefined
    ): void {}

    override validate(): void {}

    override filterChangedChecks(): void {
        this.filterChangeHandler(this.getFilterMetadata());
    }

    override filterResetFn(): void {
        this.searchControl.reset();
    }

    override filterClearFn(): void {
        this.searchControl.reset();
    }

    override updateInitialFilter(_: SafeAny) {}

    private setSearchControlValue(value: string) {
        this._isSyncing = true;
        this.searchControl.setValue(value, { emitEvent: false });
        this._isSyncing = false;
    }

    private setupControlValueChanges() {
        const valueChanges$ = this.searchControl.valueChanges.pipe(
            skipUntil(this.filtersAreReady$ ?? of(true)),
            untilDestroyed(this),
            debounceTime(10),
            distinctUntilChanged()
        );

        valueChanges$.subscribe(search => this.handleSearchChange(search));
    }

    private handleSearchChange(search: string | null): void {
        if (this._isSyncing || search === null) {
            return;
        }

        const trimmedSearch = search ? search.trim() : '';

        if (this.instantSearch) {
            this.triggerInstantSearch(trimmedSearch);
        }

        if (trimmedSearch === '') {
            this.handleEmptySearch();
        } else {
            this.filterChangedChecks();
        }
    }

    private triggerInstantSearch(search: string): void {
        const filterMetadata = this.getFilterMetadata();
        this.filterService.updateFilters(filterMetadata);
        this.filterService.updateLastAppliedFilters(filterMetadata);
        this.search.next(search);
    }

    private handleEmptySearch(): void {
        this.filterService.deleteFilter(this.filterName);
    }

    private getFilterMetadata() {
        return {
            name: this.filterName,
            key: this.filterKey,
            value: this.searchControl.value,
        };
    }
}
