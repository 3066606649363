<div class="banner">
    <ng-container
        *ngIf="isTestEnvironment && environmentBannerType === IconType.hide">
        <nz-alert
            nzType="info"
            nzBanner
            [nzMessage]="
            'Test/QA Environment: ' + (profileProvider.email$ | async)
        "></nz-alert>
    </ng-container>
</div>
<div class="app-header-content">
    <div class="app-header-left">
        <div class="burger-menu">
            <pf-button buttonType="action" (click)="displayMenuFn()">
                <pf-icon iconType="menu"></pf-icon>
            </pf-button>
        </div>
        <div class="logo">
            <img
                [src]="brandingService.branding.logoLocation"
                [attr.alt]="brandingService.branding.name" />
        </div>
    </div>
    <div id="app-header-actions">
        <ng-content select="[header-actions]"></ng-content>
        <pf-button
            id="app-header-menu-btn"
            buttonType="actions-more"
            icon-right
            nz-dropdown
            [nzDropdownMenu]="menu">
            <p>
                <pf-icon iconType="user"></pf-icon>
                <span id="app-header-username">{{
                        profileProvider.fullName$ | async
                    }}</span>
            </p>
        </pf-button>
        <nz-dropdown-menu #menu nzPlacement="bottomLeft">
            <ul nz-menu>
                <li class="user-menu-email">
                    {{ profileProvider.email$ | async }}
                </li>
                <!--            <li nz-menu-item>-->
                <!--                <a>Edit Profile</a>-->
                <!--            </li>-->
                <li nz-menu-item (click)="resetPassword()">
                    <a>Reset Password</a>
                </li>
                <li nz-menu-item (click)="logout()">
                    <a>Logout</a>
                </li>
            </ul>
        </nz-dropdown-menu>
        <ng-container *ngIf="isTestEnvironment">
            <pf-button
                id="app-header-env-btn"
                (click)="switchEnvironmentBanner()"
                buttonType="action"
                tooltip="Hide/View Environment Banner">
                <pf-icon [iconType]="environmentBannerType"></pf-icon>
            </pf-button>
        </ng-container>
    </div>
</div>
