import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    ContactDto,
    IContact,
    IEntityWithContactsFacade,
} from '@pf/shared/util-platform';
import {
    IEntity,
    IManageEntityFacade,
    PFEntities,
    PlatformModules,
    SafeAny,
    TableColumn,
} from '@pf/shared-common';
import { catchError, map, of, tap } from 'rxjs';
import { TableColumnBuilder } from '@pf/shared-ui';
import { FormControl } from '@angular/forms';
import { ContactSummaryComponent } from '../templates/contact.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { contactCustomFieldColumn } from '@pf/platform-core/domain';

interface IEntityWithContacts extends IEntity {
    contacts?: IContact[];
}

@UntilDestroy()
@Component({
    selector: 'platform-entity-contacts-drawer',
    templateUrl: './entity-contacts-drawer.component.html',
    styleUrls: ['./entity-contacts-drawer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityContactsDrawerComponent implements OnInit {
    private cdr = inject(ChangeDetectorRef);
    private _entity?: IEntityWithContacts;
    columns: TableColumn<IContact>[] = [];

    @Input() set entity(entity: IEntityWithContacts | undefined) {
        if (entity?.id) {
            this.loadEntity(entity.id);
        }
        if (this._entity?.id === entity?.id) return;
        this._entity = entity;
        this.setupDrawer();
    }

    get entity() {
        return this._entity;
    }

    @Input()
    customFieldKey: string | undefined;
    @Input() customFieldTitle?: string;
    @Input() entityFacade?: IManageEntityFacade<SafeAny>;
    @Input() entityContactsFacade?: IEntityWithContactsFacade<IEntity>;
    @Input() openDrawer = false;
    @Input() control = new FormControl();
    @Input() baseEntityPlatform!: PlatformModules;
    @Input() baseEntityType!: PFEntities;
    drawerTitle?: string;
    loading = true;
    entityName?: string;

    @Output() drawerStatusChangeEmitter = new EventEmitter<boolean>();

    open(): void {
        this.openDrawer = true;
        this.drawerStatusChangeEmitter.emit(this.openDrawer);
        this.fetchContacts();
        this.cdr.markForCheck();
    }

    close(): void {
        this.openDrawer = false;
        this.drawerStatusChangeEmitter.emit(this.openDrawer);
        this.cdr.markForCheck();
    }

    deleteContact = () => {
        if (!this.entity || !this.entityFacade) {
            return;
        }

        this.entity.contacts = this.control.value;
        this.entityFacade.save$(this.entity as IEntity).subscribe(_ => {
            this.cdr.markForCheck();
        });
    };

    postSaveContactFn = () => {
        if (this.entity && this.entityFacade) {
            this.entity.contacts = this.control.value.filter(
                (contact: IContact) => contact.isDeleted !== true
            );
            this.entityFacade.save$(this.entity as IEntity).subscribe();
        }
    };

    ngOnInit(): void {
        this.setupColumns();
        this.setupDrawer();
    }

    private setupDrawer() {
        if (!this.entity || !this.entityContactsFacade || !this.openDrawer) {
            return;
        }

        this.entityName = this.entityContactsFacade.getEntityName(this.entity);
        this.drawerTitle = `${this.entityName} - Contacts`;

        this.loadEntity(this.entity.id);
        this.fetchContacts();
    }

    private fetchContacts() {
        if (!this.entity || !this.entityContactsFacade) {
            return;
        }
        this.loading = true;
        this.entityContactsFacade
            .getContacts$(this.entity)
            .pipe(
                map(
                    contacts =>
                        contacts?.map(contact => new ContactDto(contact)) ?? []
                ),
                catchError(_ => of([])),
                tap(() => {
                    this.loading = false;
                    this.cdr.markForCheck();
                })
            )
            .subscribe(contacts => {
                this.control.setValue(contacts);
            });

        this.cdr.markForCheck();
    }

    private loadEntity(id: string) {
        this.entityContactsFacade
            ?.getById$(id)
            .subscribe((entity: IEntity | null) => {
                if (entity) this._entity = entity;
            });
    }

    private setupColumns() {
        const contactColumns = new TableColumnBuilder<IContact>()
            .withNameColumn('fullName')
            .withColumn({
                headerName: 'Email',
                field: 'email',
            })
            .withColumn({
                headerName: 'Type',
                field: ['contactType', 'name'],
            });

        if (this.customFieldKey) {
            contactColumns.with(
                contactCustomFieldColumn,
                this.customFieldKey,
                this.customFieldTitle,
                ContactSummaryComponent
            );
        }

        this.columns = contactColumns.columns;
    }
}
