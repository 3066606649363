import { IEntity } from '@pf/shared-common';
import {
    RateSearchRequestParams,
    RateViewModelCreateBody,
    RateViewModelRead,
} from '@control-tower/platform-loads';
import { Currency } from '@control-tower/platform-loads/model/currency';
import { ItemChargeDto } from './item-charge.dto';
import { ICustomFieldDto } from '@pf/shared/util-platform';

export type LaneRateRead = RateViewModelRead & IEntity;
export type LaneRateCreate = RateViewModelCreateBody;
export type LaneRateSearchParams = RateSearchRequestParams;

export class LaneRateDto implements IEntity {
    constructor(data: Partial<LaneRateDto>) {
        Object.assign(this, data);
        if (!this.name) {
            this.name = 'Lane Rate';
        }
    }

    id!: string;
    laneId?: string;
    name!: string;
    code?: string | null;
    description?: string | null;
    /**
     * The Rate\'s Classification
     */
    classification?: string | null;
    minRate?: Currency;
    maxRate?: Currency;
    /**
     * The data the Rate should be affective.
     */
    startDate?: string | null;
    /**
     * The date the Rate should not be affective.
     */
    endDate?: string | null;
    itemCharges?: Array<ItemChargeDto> | null;
    customFields?: ICustomFieldDto[] | null;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
}
