import {
    Auth0ClientFactory,
    Auth0ClientService,
    AuthClientConfig,
    AuthConfig,
    AuthConfigService,
    AuthGuard,
    AuthModule as Auth0AuthModule,
    AuthService,
} from '@auth0/auth0-angular';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
    IAppEnvironment,
    PlatformAuthService,
    ProfileProvider,
    sharedEnv,
    TenantProvider,
} from '@pf/shared-common';
import { Auth0AuthService } from './auth0-auth.service';
import { DefaultProfileProvider } from '../providers/default-profile.provider';
import { DefaultTenantProvider } from '../providers/default-tenant.provider';
import { UserPermissionCheckService } from './user-permission-check.service';
import { UserStore } from '../providers/user.store';
import { TenantStore } from '../providers/tenant.store';

@NgModule({
    imports: [Auth0AuthModule],
})
export class AuthModule {
    static forRoot(
        auth0ClientId: string,
        auth0AuthParams: IAppEnvironment['auth0AuthParams']
    ): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                AuthService,
                AuthGuard,
                {
                    provide: AuthConfigService,
                    useValue: {
                        ...sharedEnv.auth0,
                        clientId: auth0ClientId,
                        authorizationParams: {
                            ...sharedEnv.auth0.authorizationParams,
                            ...auth0AuthParams,
                        },
                    } as AuthConfig,
                },
                {
                    provide: Auth0ClientService,
                    useFactory: Auth0ClientFactory.createClient,
                    deps: [AuthClientConfig],
                },
                UserStore,
                TenantStore,
                {
                    provide: PlatformAuthService,
                    useClass: Auth0AuthService,
                },
                {
                    provide: TenantProvider,
                    useClass: DefaultTenantProvider,
                },
                {
                    provide: ProfileProvider,
                    useClass: DefaultProfileProvider,
                },
                {
                    provide: UserPermissionCheckService,
                    useClass: UserPermissionCheckService,
                },
            ],
        };
    }
}
