import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import { ContactsService } from '@control-tower/platform-core';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    ICustomField,
    IPagedResult,
    PFCoreEntities,
} from '@pf/shared-common';
import { ContactStore } from './contact.store';
import { ContactCreate, ContactRead, ContactSearchParams } from './contact.dto';
import { Operation } from 'fast-json-patch';

@Injectable()
export class ContactDataService extends AbstractDataService<
    ContactRead,
    ContactCreate
> {
    protected EntityName = PFCoreEntities.Contact;

    constructor(
        private contactsService: ContactsService,
        store: ContactStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: ContactCreate): Observable<ContactRead> {
        return this.contactsService.contactCreate({
            contactViewModelCreateBody: body,
        }) as Observable<ContactRead>;
    }

    protected deleteObs$(id: string): Observable<ContactRead> {
        return this.contactsService.contactDelete({
            id,
        }) as Observable<ContactRead>;
    }

    protected undoDeleteObs$(id: string): Observable<ContactRead> {
        return this.contactsService.contactUndoDelete({
            id,
        }) as Observable<ContactRead>;
    }

    protected getObs$(id: string): Observable<ContactRead> {
        return this.contactsService.contactReadById({
            id,
        }) as Observable<ContactRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<ContactRead> {
        return this.contactsService.contactUpdatePatch({
            id,
            operation: operations,
        }) as Observable<ContactRead>;
    }

    protected searchObs$(
        searchParams: ContactSearchParams
    ): Observable<IPagedResult<ContactRead>> {
        return this.contactsService.contactSearch(searchParams) as Observable<
            IPagedResult<ContactRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<ContactRead>>> {
        return this.contactsService.contactGetChanges(
            searchParams
        ) as Observable<IPagedResult<EntityChangeTrackingDto<ContactRead>>>;
    }

    protected override createCustomFieldObs$(
        entityId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.contactsService.contactCreateCustomField({
            entityId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override updateCustomFieldObs$(
        entityId: string,
        customFieldId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.contactsService.contactCustomFieldsUpdatePut({
            entityId,
            id: customFieldId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override deleteCustomFieldObs$(
        entityId: string,
        customFieldId: string
    ): Observable<ICustomField & { id: string }> {
        return this.contactsService.contactDeleteCustomField({
            entityId,
            id: customFieldId,
        }) as Observable<ICustomField & { id: string }>;
    }
}
