<button
    id="filter-button"
    nz-button
    nzType="primary"
    nzShape="round"
    nz-popover
    [nzPopoverContent]="appliedFilterNames$ | async | safeString"
    (click)="toggleFilter()">
    Filter(s)
    <ng-container *ngIf="(appliedFiltersCount$ | async | safeNumber) > 0">
        <nz-tag [nzColor]="'#f50'"
            >{{ appliedFiltersCount$ | async }} applied</nz-tag
        >
    </ng-container>
    <pf-icon iconType="filter"></pf-icon>
</button>
