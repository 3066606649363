import { StopDto } from '../../entities/stop.dto';
import { Injectable, WritableSignal } from '@angular/core';
import {
    CustomFieldConfig,
    EntityFacadeConfig,
    IFormFacadeConfig,
    TypeMatch,
} from '@pf/shared/util-platform';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoadDto } from '../../entities/load.dto';
import { StopFacade } from './stop.facade';
import { TypedFormGroup } from 'ngx-sub-form';
import { SafeAny } from '@pf/shared-common';
import { IStopUnifiedFragmentDto } from '../../entities/stop.unified.dto';
import { StopItemDto } from '../../entities/stop-item.dto';

export type StopTypeConfig = TypeMatch & {
    stopType: 'pickup' | 'delivery' | 'other';
    disabled?: (stop: IStopUnifiedFragmentDto | undefined) => boolean;
};

export type LoadStopTypesConfig = StopTypeConfig[];

export function getStopTypeConfig(
    stopType: StopTypeConfig['stopType'],
    stopTypes: StopTypeConfig[]
): StopTypeConfig | undefined {
    return stopTypes.find(config => config.stopType === stopType);
}

export interface StopView {}

@Injectable()
export abstract class StopFacadeConfig
    extends EntityFacadeConfig<StopDto>
    implements IFormFacadeConfig<StopDto>
{
    facade?: StopFacade;
    associatedLoad$ = new BehaviorSubject<LoadDto | null>(null);
    abstract stopTypes$: Observable<LoadStopTypesConfig>;
    abstract view?: WritableSignal<StopView>;

    abstract stopCustomFields$?: Observable<CustomFieldConfig[]>;

    abstract canAddStopItem$(
        stop?: StopDto | IStopUnifiedFragmentDto | null
    ): Observable<boolean>;

    abstract isStopItemReadonly$(
        stop?: StopDto | IStopUnifiedFragmentDto | null,
        stopItem?: StopItemDto | null
    ): Observable<Record<keyof StopItemDto, boolean>>;

    onFormChange?(
        currentValue: StopDto,
        formGroup: TypedFormGroup<SafeAny>
    ): void;

    loadStopsMap?(stops: IStopUnifiedFragmentDto[]): IStopUnifiedFragmentDto[];
}
