import {
    EntitySaveOptions,
    ICustomFieldsEntity,
    IEntity,
    IEntityDataService,
    IEntityMapper,
    IEntityStore,
    IManageEntityFacade,
    IManageEntityRoutes,
    IPagedSearchParams,
    PlatformAuthService,
    SafeAny,
    sharedEnv,
} from '@pf/shared-common';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    debounceTime,
    first,
    lastValueFrom,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    tap,
} from 'rxjs';
import {
    asArraySafe,
    fixEntityOperations,
    RemoveNulls,
} from '@pf/shared-utility';

import { compare } from 'fast-json-patch';
import { AbstractSearchFacade } from './AbstractSearchFacade';
import { inject, OnDestroy } from '@angular/core';
import {
    IRealTimeConnection,
    RealTimeEvent,
    realTimeEventFactory,
} from '@firebend/control-tower-web-socket-client';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep, isObjectLike, mergeWith } from 'lodash-es';

export const eventListeners: Set<string> = new Set<string>();

function mergeCustomizer(objValue: SafeAny, srcValue: SafeAny) {
    if (
        Array.isArray(objValue) &&
        Array.isArray(srcValue) &&
        ((objValue.length > 0 && !isObjectLike(objValue[0])) ||
            (srcValue.length > 0 && !isObjectLike(srcValue[0])))
    ) {
        return srcValue;
    }
    return undefined;
}

@UntilDestroy()
export abstract class AbstractManageEntityFacade<
        TDto extends IEntity | ICustomFieldsEntity,
        TEntity extends IEntity | ICustomFieldsEntity,
        TCreateBody extends object,
        TSearchParams extends IPagedSearchParams,
    >
    extends AbstractSearchFacade<TDto, TEntity, TCreateBody, TSearchParams>
    implements IManageEntityFacade<TDto>, OnDestroy
{
    private _eventQueue = new BehaviorSubject<RealTimeEvent<TEntity>[]>([]);
    private _authService = inject(PlatformAuthService);
    added$ = new Subject<{ entity: TDto; actionText?: string }>();
    updated$ = new Subject<{ entity: TDto; actionText?: string }>();
    deleted$ = new Subject<{ entity: TDto; actionText?: string }>();
    restored$ = new Subject<{ entity: TDto; actionText?: string }>();
    private eventEntityMappingObs$:
        | ((entity: TEntity) => Observable<TEntity>)
        | undefined;
    private _eventListener?: string;
    private _eventsConnection?: IRealTimeConnection;
    protected mergeOnUpdate = true;

    protected constructor(
        protected override dataService: IEntityDataService<
            TEntity,
            TCreateBody
        >,
        public routes: IManageEntityRoutes,
        store: IEntityStore<TEntity>,
        mapper: IEntityMapper<TEntity, TCreateBody, TDto, TSearchParams>
    ) {
        super(dataService, store, mapper);
    }

    ngOnDestroy(): void {
        this.removeEventListener();
    }

    get active$(): Observable<TDto | null> {
        //TODO: maybe figure out how to avoid mapping every time this is called?
        return this.store
            .active$()
            .pipe(map(entity => (entity ? this.mapper.toDTO(entity) : null)));
    }

    get active(): TDto | null {
        return this.store.active ? this.mapper.toDTO(this.store.active) : null;
    }

    applyResourceAuth?(body: TCreateBody, dto: TDto): void;

    manage() {
        if (!this.routes) {
            return;
        }
        this.routes.main().navigate();
    }

    // added parameter savecustomfields because trying to update the custom fields after cancelling a load is returning 403 -terry
    save$(dto: TDto, options?: EntitySaveOptions<TDto>): Observable<TDto> {
        const resolvedOptions = {
            saveCustomFields: true,
            emitEvent: true,
            ...(options || {}),
        };
        if (!dto.id) {
            return this.addEntity$(dto, resolvedOptions);
        } else {
            return this.updateEntity$(dto, resolvedOptions);
        }
    }

    private updateEntity$(dto: TDto, resolvedOptions: EntitySaveOptions<TDto>) {
        const existingEntity = this.store.get(dto.id);
        if (!existingEntity) {
            throw new Error(
                `Cannot update entity ${dto.id} because it does not exist in the store`
            );
        }

        if (dto.isDeleted && !existingEntity.isDeleted) {
            return this.delete$(dto.id);
        }

        const existingEntityCreateBody = this.mapper.toCreateBody(
            this.mapper.toDTO(existingEntity)
        );
        const updatingEntityCreateBody = this.mapper.toCreateBody(dto);

        const mergedEntity = this.mergeOnUpdate
            ? mergeWith(
                  cloneDeep(existingEntityCreateBody),
                  updatingEntityCreateBody,
                  mergeCustomizer
              )
            : updatingEntityCreateBody;

        this.applyResourceAuth?.(mergedEntity, dto);
        const operations = fixEntityOperations(
            dto,
            compare(existingEntityCreateBody, mergedEntity)
        );

        return this.dataService.update$(dto.id, operations, mergedEntity).pipe(
            switchMap(entity =>
                resolvedOptions.saveCustomFields
                    ? this.saveCustomFields$(entity, dto)
                    : of(entity)
            ),
            map(entity => this.mapper.toDTO(entity)),
            tap(savedDto => {
                if (resolvedOptions.postSave) {
                    resolvedOptions.postSave(savedDto);
                }
            }),
            tap(entity => {
                if (resolvedOptions.emitEvent) {
                    if (entity.id !== dto.id) {
                        this.added$.next({
                            entity,
                            actionText: resolvedOptions.actionText,
                        });
                    } else {
                        this.updated$.next({
                            entity,
                            actionText: resolvedOptions.actionText,
                        });
                    }
                }
            })
        );
    }

    private addEntity$(dto: TDto, resolvedOptions: EntitySaveOptions<TDto>) {
        const body = RemoveNulls(this.mapper.toCreateBody(dto));
        this.applyResourceAuth?.(body, dto);
        return this.dataService.create$(body).pipe(
            switchMap(entity =>
                resolvedOptions.saveCustomFields
                    ? this.saveCustomFields$(entity, dto)
                    : of(entity)
            ),
            map(entity => this.mapper.toDTO(entity)),
            tap(savedDto => {
                if (resolvedOptions.postSave) {
                    resolvedOptions.postSave(savedDto);
                }
            }),
            tap(savedDto => {
                if (resolvedOptions.emitEvent) {
                    this.added$.next({
                        entity: savedDto,
                        actionText: resolvedOptions.actionText,
                    });
                }
            })
        );
    }

    saveCustomFields$(entity: TEntity, dto: TDto): Observable<TEntity> {
        const existingCustomFields = asArraySafe(
            (entity as ICustomFieldsEntity).customFields
        );
        let customFields = asArraySafe(
            (dto as ICustomFieldsEntity).customFields
        );

        const updates = [];
        if (customFields.length > 0) {
            const addingCustomFields = customFields.filter(
                customField => !customField.id
            );
            if (addingCustomFields.length > 0) {
                updates.push(
                    this.dataService
                        .createCustomFields$(entity.id, addingCustomFields)
                        .pipe(
                            tap(addedCustomFields => {
                                // Add the new custom fields to the existing custom fields array
                                customFields.push(...addedCustomFields);
                            })
                        )
                );

                // Remove the fields to be added from the customFields array. They will be re-added upon successful creation
                customFields = customFields.filter(cf => !!cf.id);
            }

            const updatingCustomFields = customFields.filter(
                customField =>
                    customField.id &&
                    existingCustomFields.find(e => e.id === customField.id)
                        ?.value !== customField.value
            );
            if (updatingCustomFields.length > 0) {
                updates.push(
                    this.dataService.updateCustomFields$(
                        entity.id,
                        updatingCustomFields
                    )
                );
            }
        }

        if (existingCustomFields.length > 0) {
            const customFieldIdsToDelete = existingCustomFields
                .filter(
                    existingCustomField =>
                        !customFields.find(
                            customField =>
                                customField.id === existingCustomField.id
                        )
                )
                .map(customField => customField.id as string);
            if (customFieldIdsToDelete.length > 0) {
                updates.push(
                    this.dataService.deleteCustomFields$(
                        entity.id,
                        customFieldIdsToDelete
                    )
                );
                customFields = customFields.filter(
                    cf => customFieldIdsToDelete.indexOf(cf.id as string) === -1
                );
            }
        }
        return updates.length
            ? combineLatest(updates).pipe(
                  map(() => {
                      (entity as ICustomFieldsEntity).customFields =
                          customFields;
                      return entity;
                  })
              )
            : of(entity);
    }

    resetActive(): void {
        this.store.resetActiveEntity();
    }

    setActive(entityId: string): void {
        this.store.setActiveEntity(entityId);
    }

    add() {
        this.resetActive();
        if (!this.routes) {
            return;
        }
        this.routes.add().navigate();
    }

    edit(entityId: string, hash?: string, newWindow = false): void {
        this.store.setActiveEntity(entityId);

        if (!this.routes) {
            return;
        }
        if (newWindow) {
            window.open(this.routes.edit(entityId).url, '_blank');
        } else {
            this.routes.edit(entityId).navigate({
                state: { hash },
            });
        }
    }

    view(entityId: string): void {
        if (!this.routes) {
            return;
        }
        this.routes.view(entityId).navigate();
    }

    delete$(
        entityId: string,
        actionText?: string,
        notify = true
    ): Observable<TDto> {
        return this.dataService.delete$(entityId).pipe(
            map(entity => this.mapper.toDTO(entity)),
            tap(entity => {
                if (notify) {
                    this.deleted$.next({ entity, actionText });
                }
            })
        );
    }

    undoDelete$ = (
        entityId: string,
        actionText?: string,
        notify = true
    ): Observable<TDto> => {
        return this.undoDelete(entityId, actionText, notify);
    };

    protected undoDelete(entityId: string, actionText?: string, notify = true) {
        return this.dataService.undoDelete$(entityId).pipe(
            map(entity => this.mapper.toDTO(entity)),
            tap(entity => {
                if (notify) {
                    this.restored$.next({ entity, actionText });
                }
            })
        );
    }

    load(entityId: string) {
        this.dataService
            .get$(entityId)
            .pipe(
                first(),
                tap(() => this.store.setActiveEntity(entityId))
            )
            .subscribe();
    }

    listenForRealTimeEvents(
        eventName: string,
        opts?: {
            customEntityMapping: (entity: TEntity) => Observable<TEntity>;
        }
    ) {
        this.eventEntityMappingObs$ = opts?.customEntityMapping;
        this.addEventListener(eventName);
    }

    private addEventListener(eventName: string) {
        if (eventListeners.has(eventName)) {
            return;
        }

        console.log('Adding event listener for', eventName);
        this.listenOnEventsQueue();
        this.connectToRealTimeEvents(eventName);
        this._eventListener = eventName;
        eventListeners.add(eventName);
    }

    private async removeEventListener() {
        if (!this._eventListener) {
            return;
        }

        console.log('Removing event listener for', this._eventListener);
        eventListeners.delete(this._eventListener);
        if (this._eventsConnection) {
            await this._eventsConnection.stopAsync();
        }
    }

    private connectToRealTimeEvents(eventName: string) {
        realTimeEventFactory(`${sharedEnv.baseRealTimeUrl}/events/signalr`)
            .withAccessToken(lastValueFrom(this._authService.getAccessToken()))
            .startAsync()
            .then(builder => {
                builder.onAll<TEntity>(eventName, event => {
                    this._eventQueue.next([event, ...this._eventQueue.value]);
                });
                this._eventsConnection = builder.connection;
            });
    }

    private listenOnEventsQueue() {
        this._eventQueue
            .asObservable()
            .pipe(
                untilDestroyed(this),
                debounceTime(2000),
                catchError(err => {
                    console.error('Error processing real time events', err);
                    return of([] as RealTimeEvent<TEntity>[]);
                })
            )
            .subscribe(events => {
                if (events.length === 0) {
                    return;
                }
                // Get distinct events by id
                this.processEvents(events);
                this._eventQueue.next([]);
            });
    }

    private processEvents(events: RealTimeEvent<TEntity>[]) {
        events
            .reduce((acc, event) => {
                if (!acc.find(e => e.entity.id === event.entity.id)) {
                    acc.push(event);
                }
                return acc;
            }, [] as RealTimeEvent<TEntity>[])
            .forEach(event => {
                this.processEvent(event);
            });
    }

    private processEvent(event: RealTimeEvent<TEntity>) {
        if (!event.entity) {
            return;
        }

        console.log('Processing event for', event.entity.id);
        if (!this.store.exists(event.entity.id)) {
            console.log(event.entity.id, 'not in store');
            return;
        }

        const existingEntity = this.store.get(event.entity.id);
        if (
            existingEntity &&
            event.entity.modifiedDate === existingEntity.modifiedDate
        ) {
            console.log(event.entity.id, 'already up to date');
            return;
        }
        const customEntityMapping = this.eventEntityMappingObs$ || of;
        customEntityMapping(event.entity as TEntity)
            .pipe(
                first(),
                tap(entity => {
                    console.log('Updating entity', entity.id);
                    this.store.update(entity);
                })
            )
            .subscribe();
    }
}
