import {
    PlatformAuthService,
    SafeAny,
    TenantProvider,
} from '@pf/shared-common';
import {
    Tenant,
    TenantBranding,
    TenantLocalization,
    TenantsService,
} from '@control-tower/platform-core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, filter, map, of, Subject, switchMap } from 'rxjs';

import { Injectable } from '@angular/core';
import { ITenantStore, TenantStore } from './tenant.store';

@UntilDestroy()
@Injectable()
export class DefaultTenantProvider extends TenantProvider {
    private _loaded = false;

    constructor(
        private tenantService: TenantsService,
        private authService: PlatformAuthService,
        private tenantStore: TenantStore
    ) {
        super();
    }

    get tenant(): Tenant | null {
        return this.tenantStore.tenant;
    }

    id$ = this.tenantStore.getProperty$('id', tenant => tenant.id as string);
    name$ = this.tenantStore.getProperty$(
        'name',
        tenant => tenant.name as string
    );
    branding$ = this.tenantStore.getProperty$(
        'branding',
        tenant => (tenant.branding || {}) as TenantBranding
    );
    localization$ = this.tenantStore.getProperty$(
        'localization',
        tenant => (tenant.localization || {}) as TenantLocalization
    );
    tenants$ = this.tenantStore.tenants$;

    failure$: Subject<SafeAny> = new Subject();

    load(): void {
        if (this._loaded) {
            return;
        }
        this._loaded = true;
        this.authService.isAuthenticated$
            .pipe(
                untilDestroyed(this),
                filter(isAuthenticated => isAuthenticated),
                switchMap(() => this.tenantService.v1TenantsGet()),
                map(tenants =>
                    (tenants || []).reduce(
                        (pv, cv) => {
                            pv[cv.id as string] = cv;
                            return pv;
                        },
                        {} as ITenantStore['tenants']
                    )
                )
            )
            .pipe(
                catchError(error => {
                    this.failure$.next(error);
                    return of({} as Record<string, Tenant>);
                })
            )
            .subscribe(tenants => (this.tenantStore.tenants = tenants));
    }

    setDefaultTenant(tenantId: string): void {
        this.tenantStore.defaultTenantId = tenantId;
    }

    selectTenant(tenantId: string): void {
        if (this.tenantStore.selectedTenantId === tenantId) {
            return;
        }
        this.tenantStore.selectedTenantId = tenantId;
        // brute force refresh. We could potentially do this without a page refresh;
        // however, we would have to make sure every part of the app uses consistent refresh logic
        (window as SafeAny).location.reload();
    }
}
