import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    CustomerCreate,
    CustomerRead,
    CustomerSearchParams,
} from '../../entities/customer.dto';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    ICustomField,
    IPagedResult,
    IPagedSearchParams,
    PFCoreEntities,
} from '@pf/shared-common';

import {
    CustomerCustomFieldsService,
    CustomersService,
} from '@control-tower/platform-core';
import { CustomerStore } from './customer.store';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { Operation } from 'fast-json-patch';
import { ContactStore, IContact } from '@pf/shared/util-platform';

@Injectable()
export class CustomerDataService extends AbstractDataService<
    CustomerRead,
    CustomerCreate
> {
    protected EntityName = PFCoreEntities.Customer;

    constructor(
        private customerService: CustomersService,
        private customFieldsService: CustomerCustomFieldsService,
        store: CustomerStore,
        private contactStore: ContactStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: CustomerCreate): Observable<CustomerRead> {
        return this.customerService.customerCreate({
            customerViewModelCreateBody: body,
        }) as Observable<CustomerRead>;
    }

    protected deleteObs$(id: string): Observable<CustomerRead> {
        return this.customerService.customerDelete({
            id,
        }) as Observable<CustomerRead>;
    }

    protected undoDeleteObs$(id: string): Observable<CustomerRead> {
        return this.customerService.customerUndoDelete({
            id,
        }) as Observable<CustomerRead>;
    }

    protected getObs$(id: string): Observable<CustomerRead> {
        return this.customerService.customerReadById({
            id,
        }) as Observable<CustomerRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<CustomerRead> {
        return (
            this.customerService.customerUpdatePatch({
                id,
                operation: operations,
            }) as Observable<CustomerRead>
        ).pipe(
            map(x => {
                // TODO: figure out a better way to fix this
                // maybe always return booleans from api
                x.shouldReceiveNotifications ??= false;
                return x;
            })
        );
    }

    protected searchObs$(
        searchParams: CustomerSearchParams
    ): Observable<IPagedResult<CustomerRead>> {
        return this.customerService.customerSearch(searchParams) as Observable<
            IPagedResult<CustomerRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<CustomerRead>>> {
        return this.customerService.customerGetChanges(
            searchParams
        ) as Observable<IPagedResult<EntityChangeTrackingDto<CustomerRead>>>;
    }

    getContacts$(id: string): Observable<IContact[]> {
        return this.customerService
            .v1CustomersIdContactsGet({
                id,
            })
            .pipe(
                map(response => response.contacts as IContact[]),
                tap(contacts => {
                    contacts.forEach(contact =>
                        this.contactStore.add(contact, false)
                    );
                })
            ) as Observable<IContact[]>;
    }

    protected override createCustomFieldObs$(
        entityId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.customerCreateCustomField({
            entityId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override updateCustomFieldObs$(
        entityId: string,
        customFieldId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.customerCustomFieldsUpdatePut({
            entityId,
            id: customFieldId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override searchCustomFieldsObs$(
        searchParams: IPagedSearchParams & { key?: string; value?: string }
    ): Observable<IPagedResult<ICustomField>> {
        return this.customFieldsService.customerSearchCustomFields(
            searchParams
        ) as Observable<IPagedResult<ICustomField>>;
    }

    protected override deleteCustomFieldObs$(
        entityId: string,
        customFieldId: string
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.customerDeleteCustomField({
            entityId,
            id: customFieldId,
        }) as Observable<ICustomField & { id: string }>;
    }
}
