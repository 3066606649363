import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Host,
    Input,
    OnInit,
    Optional,
} from '@angular/core';
import {
    EntityChangeTrackingDto,
    IChangeTrackingFacade,
    IEntity,
} from '@pf/shared-common';

import { IEntityHistoryComponent } from './IEntityHistoryComponent';
import { Observable } from 'rxjs';
import { EntityHistoryDrawerComponent } from './entity-history-drawer.component';
import { calcDrawerHeight } from '@pf/shared-ui';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DetailedHistoryViewComponent } from './detailed-history-view.component';

@Component({
    selector: 'platform-entity-history',
    templateUrl: './entity-history.component.html',
    styleUrls: ['./entity-history.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityHistoryComponent
    implements IEntityHistoryComponent<IEntity>, OnInit
{
    @Input() facade!: IChangeTrackingFacade<IEntity>;
    @Input() entityName!: string;
    recentChanges$!: Observable<EntityChangeTrackingDto<IEntity>[]>;
    drawerHeight: string;

    get detailedHistoryTitle() {
        return `${this.entityName} Detailed History`;
    }

    constructor(
        private cdr: ChangeDetectorRef,
        private drawerService: NzDrawerService,
        @Optional() @Host() historyDrawer: EntityHistoryDrawerComponent
    ) {
        this.drawerHeight = calcDrawerHeight(historyDrawer ? 1 : 0);
    }

    ngOnInit(): void {
        if (!this.facade) {
            throw new Error('Facade must be set on entity history component');
        }
        this.recentChanges$ = this.facade.getRecentChanges$();
    }

    trackByFn(_: number, value: EntityChangeTrackingDto<IEntity>) {
        return value.id;
    }

    openDetailedHistory() {
        const drawerRef = this.drawerService.create({
            nzTitle: this.detailedHistoryTitle,
            nzContent: DetailedHistoryViewComponent,
            nzHeight: this.drawerHeight,
            nzPlacement: 'bottom',
            nzData: {
                changeTrackingFacade: this.facade,
                entityName: this.entityName,
            },
        });

        const detectChanges = () => {
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 1000);
        };

        detectChanges();

        drawerRef.afterClose.subscribe(_ => {
            detectChanges();
        });
    }
}
