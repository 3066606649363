import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';
import {
    CustomFieldMetadataMap,
    ITypeEntityFragmentSymbol,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';
import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFLoadEntities,
} from '@pf/shared-common';
import { createMap, forMember, mapFrom, Mapper } from '@automapper/core';
import {
    ItemChargeCreate,
    ItemChargeDto,
    ItemChargeRead,
    ItemChargeSearchParams,
} from '../../entities/item-charge.dto';
import { ItemMetadataMap } from '@pf/platform-item-catalog/domain';

const symbols = entitySymbols(PFLoadEntities.ItemCharge);

withDefaultEntityMetadata<ItemChargeDto>(symbols.dto, {
    name: String,
    rateId: String,
    accountType: String,
    chargeType: ITypeEntityFragmentSymbol,
    customFields: [CustomFieldMetadataMap.symbols.dto],
    perQuantity: Number,
    rateValue: Object,
    resourceAuth: ResourceAuthSymbol,
    unitOfMeasure: String,
    item: ItemMetadataMap.symbols.dto,
});

withDefaultEntityMetadata<ItemChargeRead>(symbols.entity, {
    accountType: String,
    chargeType: ITypeEntityFragmentSymbol,
    customFields: [CustomFieldMetadataMap.symbols.entity],
    itemId: String,
    item: ItemMetadataMap.symbols.entity,
    perQuantity: Number,
    rateValue: Object,
    resourceAuth: ResourceAuthSymbol,
    unitOfMeasure: String,
});

withMetadata<ItemChargeCreate>(symbols.createBody, {
    accountType: String,
    chargeTypeId: String,
    rateId: String,
    itemId: String,
    perQuantity: Number,
    rateValue: Object,
    resourceAuth: ResourceAuthSymbol,
    unitOfMeasure: String,
});

withDefaultSearchParamsMetadata<ItemChargeSearchParams>(symbols.searchParams, {
    accountType: String,
    rateUnitOfMeasure: String,
    chargeTypeId: String,
});

export const ItemChargeMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<ItemChargeRead, ItemChargeDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<ItemChargeDto, ItemChargeRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<ItemChargeDto, ItemChargeCreate>(
            mapper,
            symbols.dto,
            symbols.createBody,
            forMember(
                x => x.itemId,
                mapFrom(source => source.item?.id || null)
            ),
            forMember(
                x => x.chargeTypeId,
                mapFrom(source => source.chargeType?.id || null)
            )
        );
        createMap<IQueryParams<ItemChargeDto>, ItemChargeSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('chargeType', 'chargeTypeId'),
            withSearchFilter('unitOfMeasure', 'rateUnitOfMeasure'),
            withSearchFilter('accountType'),
            ...withDefaultSearchParamsMappingConfiguration()
        );
    },
};
