import { IEntity, IPagedSearchParams } from '@pf/shared-common';
import {
    RecurringLoadScheduleSearchRequestParams,
    RecurringLoadScheduleViewModelCreate,
    RecurringLoadScheduleViewModelRead,
    ResourceAuth,
} from '@control-tower/platform-loads';
import { CustomFieldRead } from '@pf/shared/util-platform';

export type LoadSchedulePayload = {
    originLocationId: string;
    destinationLocationId?: string;
    destinationLocationName?: string;
    customerId: string;
    vendorId?: string;
    vendorName?: string;
    numberOfLoads: number;
    commodity: string;
    commodityId: string;
};

export type LoadScheduleRead = RecurringLoadScheduleViewModelRead &
    IEntity & {
        parameters?: {
            webHookPayload?: LoadSchedulePayload;
        };
    };
export type LoadScheduleCreate = RecurringLoadScheduleViewModelCreate & {
    parameters?: {
        webHookPayload?: LoadSchedulePayload;
    };
};
export type LoadScheduleSearchParams =
    RecurringLoadScheduleSearchRequestParams & IPagedSearchParams;

export function GetPayload(
    loadScheduleDto?: LoadScheduleDto
): LoadSchedulePayload {
    return loadScheduleDto
        ? loadScheduleDto.parameters?.webHookPayload ||
              ({} as LoadSchedulePayload)
        : ({} as LoadSchedulePayload);
}

export class LoadScheduleDto implements IEntity {
    constructor(data: Partial<LoadScheduleDto>) {
        Object.assign(this, data);
        const payload = GetPayload(this);
        this.jobName = `${payload.customerId}-${payload.originLocationId}-${payload.commodityId}`;
    }

    id!: string;
    isDisabled?: boolean;
    disabledReason?: string;
    originLocationId?: string;
    destinationLocationId?: string;
    previousExecutionDate?: string;
    nextExecutionDate?: string;
    jobbaRegistrationId?: string;
    createdDate?: string;
    modifiedDate?: string;
    customFields?: CustomFieldRead[];
    jobName?: string;
    description?: string;
    cron?: string;
    timeZoneId?: string;
    parameters?: {
        webHookUrl: string;
        webHookPayload: LoadSchedulePayload;
    };
    state?: object;
    resourceAuth?: ResourceAuth;
}
