import {
    FilterOption,
    IEntity,
    IEntityMapper,
    IPagedResult,
    SafeAny,
    SelectOption,
    TreeSelectOption,
} from '@pf/shared-common';
import {
    catchError,
    map,
    Observable,
    of,
    OperatorFunction,
    scan,
    startWith,
    switchMap,
} from 'rxjs';
import {
    toSelectOptions,
    toSelectOptionsFlattened,
    toTreeSelectOptions,
} from './arrays-util';

export interface LoadingState<T = unknown> {
    loading: boolean;
    error?: Error | null;
    data?: T;
}

export function switchMapWithLoading<T>(
    observableFunction: (value: SafeAny) => Observable<T>
): OperatorFunction<SafeAny, LoadingState<T>> {
    return (source: Observable<SafeAny>) =>
        source.pipe(
            switchMap(value =>
                observableFunction(value).pipe(
                    map(data => ({ data, loading: false })),
                    catchError(error => of({ error, loading: false })),
                    startWith({ error: null, loading: true })
                )
            ),
            scan((state: LoadingState<T>, change: LoadingState<T>) => ({
                ...state,
                ...change,
            }))
        );
}

export type OptionalMapOperator<T> =
    | ((source: Observable<T>) => Observable<T>)
    | undefined;

export const optionalMap =
    <T>(mapOperator: OptionalMapOperator<T>) =>
    (source: Observable<T>): Observable<T> => {
        return mapOperator ? mapOperator(source) : source;
    };

export const mapToSelectOptions =
    <TDto extends IEntity>(
        label: keyof TDto | ((record: TDto) => string),
        value: keyof TDto = 'id',
        groupBy: (record: TDto) => string | null | undefined = () => undefined
    ) =>
    (source: Observable<IPagedResult<TDto>>): Observable<SelectOption[]> => {
        return source.pipe(
            map(result => toSelectOptions(result.data, label, value, groupBy))
        );
    };

export const convertSelectOptionsToFilterOptions =
    <T = SafeAny>(): ((
        source: Observable<SelectOption[]>
    ) => Observable<FilterOption<T>[]>) =>
    (source: Observable<SelectOption[]>) =>
        source.pipe(
            map((selectOptions: SelectOption[]) =>
                selectOptions.map((selectOption: SelectOption) => ({
                    label: selectOption.label
                        ? selectOption.label.toString()
                        : '',
                    value:
                        selectOption.value !== null &&
                        selectOption.value !== undefined
                            ? selectOption.value
                            : '',
                    default: false,
                    initial: false,
                    filterFn: undefined,
                }))
            )
        );

export const mapDtoListToSelectOptions =
    <TDto extends IEntity>(
        label: keyof TDto | ((record: TDto) => string),
        value: keyof TDto = 'id',
        groupBy: (record: TDto) => string | null | undefined = () => undefined
    ) =>
    (source: Observable<TDto[]>): Observable<SelectOption[]> => {
        return source.pipe(
            map(data => toSelectOptions(data, label, value, groupBy))
        );
    };

export const flattenAndMapToSelectOptions =
    <TDto extends IEntity>(
        label: keyof TDto | ((record: TDto) => string),
        value: keyof TDto = 'id',
        groupBy: (record: TDto) => string[] | null | undefined = () => undefined
    ) =>
    (source: Observable<IPagedResult<TDto>>): Observable<SelectOption[]> => {
        return source.pipe(
            map(result =>
                toSelectOptionsFlattened(result.data, label, value, groupBy)
            )
        );
    };

export const mapToTreeSelectOptions =
    <TDto extends IEntity>(
        title: keyof TDto,
        value: keyof TDto = 'id',
        recursive = false
    ) =>
    (
        source: Observable<IPagedResult<TDto>>
    ): Observable<TreeSelectOption[]> => {
        return source.pipe(
            map(result =>
                toTreeSelectOptions(result.data, title, value, recursive)
            )
        );
    };

export const mapToDtoPageResult =
    <TEntity, TCreateBody, TDto, TSearchParams>(
        mapper: IEntityMapper<TEntity, TCreateBody, TDto, TSearchParams>
    ) =>
    (
        source: Observable<IPagedResult<TEntity>>
    ): Observable<IPagedResult<TDto>> => {
        return source.pipe(
            map(result => ({
                ...result,
                data: mapper.toDTOList(result.data),
            }))
        );
    };
