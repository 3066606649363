export enum LoadAppConstants {
    Load = 'Load',
    Stop = 'Stop',
    Charge = 'Charge',
    StopItemQuantity = 'ItemQuantity',
    UOM = 'UOM',
    ChargeAmount = 'ChargeAmount',
    AccountType = 'AccountType',
}

export const LoadAppConstantDefaults = {
    [LoadAppConstants.Load]: 'Load',
    [LoadAppConstants.Stop]: 'Stop',
    [LoadAppConstants.Charge]: 'Charge',
    [LoadAppConstants.StopItemQuantity]: 'Item Quantity',
    [LoadAppConstants.UOM]: 'UOM',
    [LoadAppConstants.ChargeAmount]: 'Charge Amount',
    [LoadAppConstants.AccountType]: 'Account Type',
};
