<!-- <pf-dynamic-control [config]="config"></pf-dynamic-control> -->
<ng-container [ngSwitch]="column.type">
    <ng-container *ngSwitchCase="'string'">
        <pf-input
            #inputElement
            (keydown.shift_tab)="onShiftTab($event)"
            (keydown.Tab)="onTab($event)"
            (keydown.Enter)="onEnter($event)"
            pfStyle="compact"
            [pfReadonly]="!row.editable"
            [control]="control"></pf-input>
    </ng-container>
    <ng-container *ngSwitchCase="'number'">
        <pf-input
            #inputElement
            (keydown.shift_tab)="onShiftTab($event)"
            (keydown.Tab)="onTab($event)"
            (keydown.Enter)="onEnter($event)"
            inputType="number"
            pfStyle="compact"
            [pfReadonly]="!row.editable"
            [control]="control"></pf-input>
    </ng-container>
    <ng-container *ngSwitchCase="'date'">
        <pf-date-picker
            #inputElement
            (keydown.shift_tab)="onShiftTab($event)"
            (keydown.Tab)="onTab($event)"
            (keydown.Enter)="onEnter($event)"
            pfStyle="compact"
            [showTime]="false"
            datePickerType="date"
            [pfReadonly]="!row.editable"
            [control]="control"></pf-date-picker>
    </ng-container>
    <ng-container *ngSwitchCase="'select'">
        <pf-select
            #inputElement
            (keydown.shift_tab)="onShiftTab($event)"
            (keydown.Tab)="onTab($event)"
            (keydown.Enter)="onEnter($event)"
            pfStyle="compact"
            [control]="control"
            [grouped]="column.isEditableDataGrouped"
            [pfReadonly]="!row.editable"
            [data]="column.editableDataSource$ | async | safeArray"></pf-select>
    </ng-container>
</ng-container>
