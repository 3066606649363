import {
    PermissionTypes,
    PFCommunicationsEntities,
    PFCoreEntities,
    PFItemCatalogEntities,
    PFLoadEntities,
    PFTransportationEntities,
    PlatformModules,
} from '@pf/shared-common';

/**
 * Builds a permission path for the platform core module
 * @param entity
 * @param permissionType
 * @returns {module}:{entity}:{action}
 */
export function buildCorePermissionPath(
    permissionType: PermissionTypes,
    entity?: PFCoreEntities
) {
    return buildPermissionPath(
        PlatformModules.PlatformCore,
        permissionType,
        entity
    );
}

/**
 * Builds a permission path for the platform core module
 * @param entity
 * @param permissionType
 * @returns {module}:{entity}:{action}
 */
export function buildCommunicationsPermissionPath(
    permissionType: PermissionTypes,
    entity?: PFCommunicationsEntities
) {
    return buildPermissionPath(
        PlatformModules.PlatformCommunications,
        permissionType,
        entity
    );
}

/**
 * Builds a permission path for the platform core module
 * @param entity
 * @param permissionType
 * @returns {module}:{entity}:{action}
 */
export function buildLoadPermissionPath(
    permissionType: PermissionTypes,
    entity?: PFLoadEntities
) {
    return buildPermissionPath(
        PlatformModules.PlatformLoads,
        permissionType,
        entity
    );
}

/**
 * Builds a permission path for the platform core module
 * @param entity
 * @param permissionType
 * @returns {module}:{entity}:{action}
 */
export function buildItemCatalogPermissionPath(
    permissionType: PermissionTypes,
    entity?: PFItemCatalogEntities
) {
    return buildPermissionPath(
        PlatformModules.PlatformLoads,
        permissionType,
        entity
    );
}

/**
 * Builds a permission path for the platform core module
 * @param entity
 * @param permissionType
 * @returns {module}:{entity}:{action}
 */
export function buildTransportationPermissionPath(
    permissionType: PermissionTypes,
    entity?: PFTransportationEntities
) {
    return buildPermissionPath(
        PlatformModules.PlatformTransportation,
        permissionType,
        entity
    );
}

export function buildPermissionPath(
    module: PlatformModules,
    permissionType: PermissionTypes,
    entity?: string
) {
    if (entity === undefined) {
        return `${module}:${permissionType}`;
    }
    return `${module}:${entity}:${permissionType}`;
}
