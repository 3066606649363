import { inject, Injectable } from '@angular/core';
import { IWorkflowDefinitionModelProvider } from '@pf/shared/ui-platform';
import { ITypeEntity, PFLoadEntities } from '@pf/shared-common';
import { combineLatest, map, Observable } from 'rxjs';
import {
    createAnyVariablesValueModel,
    createChoiceValueModel,
    createDefinitionModel,
    createNullableAnyVariableValueModel,
    createRootModel,
    createStepModel,
    createStringValueModel,
    createVariableDefinitionsValueModel,
    DefinitionModel,
} from 'sequential-workflow-editor-model';
import { StopTypeFacade } from '../application/type-entities/StopTypeFacade';
import { ChargeTypeFacade } from '../application/type-entities/ChargeTypeFacade';
import { ComponentTypesMap, WorkflowType } from '../types/load-workflow.types';
import { StepType } from '@control-tower/platform-loads';

@Injectable()
export class LoadWorkflowGlobalParametersDefinitionModelProvider
    implements IWorkflowDefinitionModelProvider
{
    private readonly _stopTypeFacade = inject(StopTypeFacade);
    private readonly _chargeTypeFacade = inject(ChargeTypeFacade);

    name = PFLoadEntities.WorkflowGlobalParameter;

    getDefinitionModel$(): Observable<DefinitionModel> {
        return combineLatest([
            this._stopTypeFacade.getAll$(),
            this._chargeTypeFacade.getAll$(),
        ]).pipe(
            map(([stopTypes, chargeTypes]) => {
                return this.mapDefinitionModel(stopTypes, chargeTypes);
            })
        );
    }

    private mapDefinitionModel(
        stopTypes: ITypeEntity[],
        chargeTypes: ITypeEntity[]
    ) {
        const rootModel = createRootModel(root => {
            root.property('name').value(createStringValueModel({}));
            root.property('description').value(
                createStringValueModel({ multiline: true })
            );
            root.property('globals')
                .value(createVariableDefinitionsValueModel({}))
                .label('Parameters');
        });

        const stopTypeChoices = stopTypes.map(stopType => stopType.name);
        const chargeTypeChoices = chargeTypes.map(
            chargeType => chargeType.name
        );

        const firstStopWithTypeCodeModel = createStepModel(
            WorkflowType.FirstStopWithTypeCode,
            ComponentTypesMap[StepType.Action],
            step => {
                step.category('Load Functions');
                step.property('stopTypeId')
                    .value(createChoiceValueModel({ choices: stopTypeChoices }))
                    .label('Stop Type');
            }
        );

        const getCustomerModel = createStepModel(
            WorkflowType.GetCustomer,
            ComponentTypesMap[StepType.Action],
            step => {
                step.category('Load Functions');
                step.property('stopTypeId')
                    .value(createChoiceValueModel({ choices: stopTypeChoices }))
                    .label('Stop Type');
            }
        );

        const getLocationModel = createStepModel(
            WorkflowType.GetLocation,
            ComponentTypesMap[StepType.Action],
            step => {
                step.category('Load Functions');
                step.property('stopTypeId')
                    .value(createChoiceValueModel({ choices: stopTypeChoices }))
                    .label('Stop Type');
            }
        );

        const stopItemModel = createStepModel(
            WorkflowType.StopItem,
            ComponentTypesMap[StepType.Action],
            step => {
                step.category('Load Functions');
                step.property('stopTypeId')
                    .value(createChoiceValueModel({ choices: stopTypeChoices }))
                    .label('Stop Type');
            }
        );

        const sumChargesModel = createStepModel(
            WorkflowType.SumCharges,
            ComponentTypesMap[StepType.Action],
            step => {
                step.category('Load Functions');
                step.property('chargeTypeId')
                    .value(
                        createChoiceValueModel({ choices: chargeTypeChoices })
                    )
                    .label('Charge Type');
                step.property('stopTypeId')
                    .value(
                        createChoiceValueModel({
                            choices: ['', ...stopTypeChoices],
                            defaultValue: '',
                        })
                    )
                    .label('Stop Type');
                step.property('accountType')
                    .value(
                        createChoiceValueModel({
                            choices: ['', 'Payable', 'Receivable'],
                            defaultValue: '',
                        })
                    )
                    .label('Account Type');
            }
        );

        const firstWithValueModel = createStepModel(
            WorkflowType.FirstWithValue,
            ComponentTypesMap[StepType.Action],
            step => {
                step.category('Functions');
                step.property('params')
                    .value(
                        createAnyVariablesValueModel({
                            valueTypes: ['number', 'string'],
                        })
                    )
                    .label('Params');
                step.property('defaultValue')
                    .value(createStringValueModel({}))
                    .label('Default Value');
            }
        );

        const propertyAccessorModel = createStepModel(
            WorkflowType.PropertyAccessor,
            ComponentTypesMap[StepType.Action],
            step => {
                step.category('Functions');
                step.property('path')
                    .value(createStringValueModel({}))
                    .label('Path');
                step.property('paramReference')
                    .value(
                        createNullableAnyVariableValueModel({
                            valueTypes: ['object'],
                            isRequired: true,
                        })
                    )
                    .label('Param Reference');
                step.property('outputType')
                    .value(
                        createChoiceValueModel({
                            choices: ['any', 'string', 'number', 'boolean'],
                        })
                    )
                    .label('Output Type');
            }
        );

        const staticValueModel = createStepModel(
            WorkflowType.StaticValue,
            ComponentTypesMap[StepType.Action],
            step => {
                step.category('Functions');
                step.property('staticValue')
                    .value(createStringValueModel({}))
                    .label('Value');
                step.property('type')
                    .value(
                        createChoiceValueModel({
                            choices: ['string', 'number', 'boolean'],
                        })
                    )
                    .label('Type');
            }
        );

        return createDefinitionModel(model => {
            model.valueTypes([
                'string',
                'boolean',
                'number',
                'any',
                'object',
                'date',
            ]);
            model.root(rootModel);
            model.steps([
                firstStopWithTypeCodeModel,
                propertyAccessorModel,
                getCustomerModel,
                getLocationModel,
                stopItemModel,
                sumChargesModel,
                firstWithValueModel,
                staticValueModel,
            ]);
        });
    }
}
