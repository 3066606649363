import {
    ButtonAction,
    ButtonSize,
    ButtonType,
    ButtonWidth,
    IconType,
    SafeAny,
} from '@pf/shared-common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';
import { NzButtonShape, NzButtonType } from 'ng-zorro-antd/button';

@Component({
    selector: 'pf-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
    constructor() {
        this._buttonType = 'submit';
    }

    @Input() tooltip?: string | TemplateRef<void>;
    @Input() buttonActions?: ButtonAction[] | null;
    @Input() actionsOnly = false;
    @Input() buttonSize: ButtonSize = 'default';
    @Input() loading = false;
    @Input() disabled = false;
    /**
     * The `icon` input will be used to create a button with an icon.
     * Only valid for button type of `icon`
     */
    @Input() pfIcon?: keyof typeof IconType;
    @Output() iconClick = new EventEmitter<MouseEvent>();

    private _buttonType: ButtonType;
    get buttonType() {
        return this._buttonType;
    }

    /**
     * The `buttonType` setter will be used to create different setups
     * for the button that will be created.
     */
    @Input()
    set buttonType(value: ButtonType) {
        this._buttonType = value;
        if (!ButtonConfiguration[value]) {
            throw new Error(`Invalid button type: ${value}`);
        }
        const props = { ...(ButtonConfiguration[value] || {}) };
        if (props && value === 'icon') {
            props.icon = this.pfIcon || '';
        }
        this.typeProps = props as ButtonComponent['typeProps'];
    }

    @Input() actionsContext?: SafeAny;

    @HostBinding('class.pf-full-width')
    get isFullWidth(): boolean {
        return this.typeProps?.width === 'full';
    }

    typeProps?: {
        type: NzButtonType;
        shape: NzButtonShape;
        icon: keyof typeof IconType | '';
        slim: true | null;
        width?: ButtonWidth;
    };
    showActions = false;

    buttonClickHandler() {
        if (this.actionsOnly && this.buttonActions?.length) {
            this.showActions = true;
        }
    }

    iconClickHandler(event: MouseEvent) {
        this.iconClick.emit(event);
    }
}

const ButtonConfiguration: Record<ButtonType, ButtonComponent['typeProps']> = {
    action: {
        type: 'link',
        icon: '',
        shape: null,
        slim: true,
    },
    'action-delete': {
        type: 'link',
        icon: '',
        shape: null,
        slim: true,
    },
    'actions-more': {
        type: 'link',
        icon: 'expand',
        shape: null,
        slim: true,
    },
    add: {
        type: 'primary',
        icon: 'plus',
        shape: null,
        slim: null,
    },
    'add-item': {
        type: 'default',
        icon: 'plus',
        shape: null,
        slim: true,
        width: 'full',
    },
    'add-with-menu': {
        type: 'primary',
        icon: 'expand',
        shape: null,
        slim: null,
    },
    apply: {
        type: 'primary',
        icon: '',
        shape: null,
        slim: null,
    },
    cancel: {
        type: 'default',
        icon: '',
        shape: null,
        slim: null,
    },
    clear: {
        type: 'primary',
        shape: 'round',
        icon: 'close',
        slim: null,
    },
    'clear-square': {
        type: 'default',
        icon: '',
        shape: null,
        slim: null,
    },
    copy: {
        type: 'link',
        icon: 'copy',
        shape: null,
        slim: null,
    },
    delete: {
        type: 'default',
        icon: '',
        shape: null,
        slim: null,
        width: 'full',
    },
    'full-width': {
        type: 'default',
        icon: '',
        shape: null,
        slim: null,
        width: 'full',
    },
    export: {
        type: 'primary',
        icon: 'export',
        shape: null,
        slim: null,
    },
    icon: {
        type: 'primary',
        icon: '',
        shape: 'circle',
        slim: null,
    },
    refresh: {
        type: 'primary',
        icon: 'refresh',
        shape: 'round',
        slim: null,
    },
    route: {
        type: 'primary',
        icon: 'route',
        shape: null,
        slim: null,
    },
    search: {
        type: 'default',
        icon: 'search',
        shape: null,
        slim: true,
        width: 'full',
    },
    submit: {
        type: 'primary',
        icon: '',
        shape: null,
        slim: null,
    },
    undo: {
        type: 'default',
        icon: 'undo',
        shape: 'round',
        slim: null,
    },
    upload: {
        type: 'primary',
        icon: 'upload',
        shape: null,
        slim: null,
    },
    view: {
        type: 'primary',
        icon: 'view',
        shape: null,
        slim: null,
    },
    play: {
        type: 'primary',
        icon: 'play',
        shape: null,
        slim: null,
    },
};
