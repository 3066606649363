<form [formGroup]="form.formGroup" nz-form>
    <section pfSection pfTitle="Contact Information" [disableAnchor]="true">
        <div class="pf-form-group">
            <pf-input
                label="First Name"
                [controlName]="form.formControlNames.firstName"
                placeholder="First Name"></pf-input>
            <pf-select
                label="Contact Type"
                [searchFacade]="contactTypeFacade"
                [controlName]="form.formControlNames.contactTypeId"
                (selected)="this.setContactTypeName($event)"></pf-select>
            <pf-input
                label="Last Name"
                [controlName]="form.formControlNames.lastName"
                placeholder="Last Name"></pf-input>
            <pf-input
                label="Email"
                [controlName]="form.formControlNames.email"
                inputType="email"
                placeholder="Email"></pf-input>
            <pf-input
                label="Phone"
                [controlName]="form.formControlNames.phone"
                inputType="phone"
                placeholder="Phone"></pf-input>
            <pf-input
                label="Description"
                [controlName]="form.formControlNames.description"
                inputType="textarea"
                placeholder="Title"
                [max]="250"
                pfErrorTip="Description cannot exceed 250 characters."></pf-input>
        </div>
    </section>
    <pf-lazy-content [operation]="OperationType.LoadCustomFields">
        <nz-skeleton
            [nzActive]="true"
            [nzTitle]="false"
            [nzParagraph]="{
                rows: 5,
                width: [500, 500, 500, 500, 500]
            }"></nz-skeleton>
        <div content>
            <ng-container
                *ngIf="
                    contactFacade.customFieldsConfig$
                        | async as customFieldsConfig
                ">
                <section
                    pfSection
                    pfTitle="Additional Contact Information"
                    *ngIf="customFieldsConfig.length > 0">
                    <article pfArticle pfTitle="Custom Fields">
                        <platform-custom-fields-form
                            [pfStyle]="'default'"
                            [pfColumn]="false"
                            [customFieldConfigs]="
                                customFieldsConfig | safeArray
                            "
                            [formControlName]="
                                form.formControlNames.customFields
                            "></platform-custom-fields-form>
                    </article>
                </section>
            </ng-container>
        </div>
    </pf-lazy-content>
</form>
