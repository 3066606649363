import { getMobileOS } from './device-util';
import { phone } from 'phone';
import { IAddressDto } from '@pf/shared-common';

/**
 * Formats a given phone number according to US standards.
 * @param {string} phoneNumber - The phone number to format.
 * @returns {string} - The formatted phone number.
 */
function formatNumber(phoneNumber: string) {
    return phone(phoneNumber, { country: 'US' }).phoneNumber;
}

/**
 * Launches a hyperlink or sets the window location based on the action.
 * @param {string} action - The action to perform (e.g., 'https', 'tel').
 * @param {string} link - The link to launch.
 */
export function launchLink(action: string, link: string) {
    console.log(`launchLink called with action: ${action}, link: ${link}`);

    if (action === 'https') {
        const aRef = document.createElement('a');
        aRef.href = action + ':' + link;
        aRef.setAttribute('target', '_blank');
        aRef.click();
    } else {
        window.location.href = action + ':' + encodeURIComponent(link);
    }
}

/**
 * Formats the phone number and launches a call link.
 * @param {string} phoneNumber - The phone number to call.
 */
export function launchCall(phoneNumber: string) {
    const formattedNumber = formatNumber(phoneNumber);
    if (!formattedNumber) {
        console.warn(
            `Call link not launched due to invalid phone number ${phoneNumber}`
        );
        return;
    }
    launchLink('tel', formattedNumber);
}

/**
 * Formats the phone number and launches a text message link with an optional message.
 * @param {string} phoneNumber - The phone number to text.
 * @param {string} [message] - The message body.
 */
export function launchText(phoneNumber: string, message?: string) {
    let formattedNumber = formatNumber(phoneNumber);
    if (!formattedNumber) {
        console.warn(
            `Text link not launched due to invalid phone number ${phoneNumber}`
        );
        return;
    }
    if (message) {
        formattedNumber += `${
            getMobileOS() === 'iOS' ? '&' : '?'
        }body=${message}`;
    }
    launchLink('sms', formattedNumber);
}

/**
 *    Constructs an email link with optional subject and body parameters and launches it.
 *    @param {string} emailAddress - The email address.
 *    @param {string} [subject] - The email subject.
 *    @param {string} [body] - The email body.
 */
export function launchEmail(
    emailAddress: string,
    subject?: string,
    body?: string
) {
    let link = emailAddress;
    const params = [];
    if (subject) {
        params.push('subject=' + subject);
    }
    if (body) {
        params.push('body=' + body);
    }
    if (params.length > 0) {
        link += `${getMobileOS() === 'iOS' ? '&' : '?'}` + params.join('&');
    }
    launchLink('mailto', link);
}

/**
 *    Constructs a map link using either Apple Maps or Google Maps based on the mobile OS and launches it.
 *    @param {IAddressDto} address - The address to open in the map.
 */
export function launchMap(address: IAddressDto) {
    launchLink(
        'https',
        `//maps.${getMobileOS() === 'iOS' ? 'apple' : 'google'}.com/?q=${
            address.addressLine1
        }${address.addressLine2 || ''}, ${address.city} ${
            address.geoZone ? ', ' + address.geoZone : ''
        } ${address.country ? ', ' + address.country : ''}`
    );
}
