import { Injectable } from '@angular/core';
import { TableActionsBuilder } from '@pf/shared-ui';
import { VendorDto } from '../entities/vendor.dto';

@Injectable()
export abstract class VendorManageConfig {
    abstract configureAdditionalTableActions?(
        builder: TableActionsBuilder<VendorDto>
    ): TableActionsBuilder<VendorDto>;
}
