import { Injectable } from '@angular/core';
import { AbstractEntityStore } from '@pf/shared-services';
import { LaneRateRead } from '../../entities/lane-rate.dto';
import { PFLoadEntities } from '@pf/shared-common';

@Injectable()
export class LaneRateStore extends AbstractEntityStore<LaneRateRead> {
    constructor() {
        super(PFLoadEntities.LaneRate);
    }
}
