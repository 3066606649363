import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { IWorkflowDefinitionModelProvider } from './IWorkflowDefinitionProvider';

export const WorkflowDefinitionModelProviderToken = new InjectionToken(
    'WorkflowDefinitionProvider'
);

export const DefaultStepEditorsMap = {};

@Injectable({ providedIn: 'root' })
export class WorkflowDefinitionsService {
    constructor(
        @Optional()
        @Inject(WorkflowDefinitionModelProviderToken)
        private providers?: IWorkflowDefinitionModelProvider[]
    ) {
        if (!providers?.length) {
            throw new Error(
                'No workflow definition model providers found. Please provide at least one provider.'
            );
        }
    }

    getDefinitionModelProvider(modelName: string) {
        if (!this.providers) {
            throw new Error('No workflow definition model providers');
        }
        return this.providers.find(provider => provider.name === modelName);
    }
}
