import { QueryFilters, SafeAny } from '@pf/shared-common';
import { FilterMetadata } from '../stores/filter.store';

/**
 * Counts the number of filters that have been applied (i.e., filters that are not part of the initial/default filters).
 *
 * @param appliedFilters - The array of current filters.
 * @param initialFilters - The array of initial/default filters.
 * @returns The number of applied filters that are different from the initial filters.
 */
export function countAppliedFilters(
    appliedFilters: FilterMetadata[],
    initialFilters: FilterMetadata[]
): number {
    let difference = 0;

    appliedFilters.forEach(appliedFilter => {
        if (
            !initialFilters.some(initialFilter =>
                isSameFilter(appliedFilter, initialFilter)
            )
        ) {
            difference++;
        }
    });

    return difference;
}

/**
 * Checks if two filters (by their key) are the same.
 *
 * @param currentFilter - The current filter object to compare.
 * @param initialFilter - The initial filter object to compare against.
 * @returns True if the filters have the same key, false otherwise.
 */
export function isSameFilter(
    currentFilter: FilterMetadata,
    initialFilter: FilterMetadata
): boolean {
    if (!initialFilter || !currentFilter) {
        return false;
    }
    const currentKey = currentFilter.key;
    const currentValue = currentFilter.value;
    const initialKey = initialFilter.key;
    const initialValue = initialFilter.value;

    return (
        currentKey === initialKey && areValuesEqual(currentValue, initialValue)
    );
}

/**
 * Finds a filter in the array based on the filter's key.
 * @param filters
 * @param name
 */
export function findFilterByName(
    filters: FilterMetadata[],
    name: string
): FilterMetadata | undefined {
    return filters.find(filter => filter.name === name);
}

/**
 * Compares two arrays of QueryFilters<SafeAny> and returns true if they have the same keys and values.
 * @param filters1 - The first array of filters to compare.
 * @param filters2 - The second array of filters to compare.
 * @returns True if the filters are equal, false otherwise.
 */
export function areFiltersEqual(
    filters1: (FilterMetadata | undefined)[],
    filters2: (FilterMetadata | undefined)[]
): boolean {
    const cleanFilters1 = filters1.filter(
        filter => filter !== undefined
    ) as FilterMetadata[];
    const cleanFilters2 = filters2.filter(
        filter => filter !== undefined
    ) as FilterMetadata[];

    const map1 = new Map<string, FilterMetadata>();
    const map2 = new Map<string, FilterMetadata>();

    cleanFilters1.forEach(filter => {
        map1.set(filter.key, filter);
    });

    cleanFilters2.forEach(filter => {
        map2.set(filter.key, filter);
    });

    if (map1.size !== map2.size) {
        return false;
    }

    for (const [key, filter1] of map1.entries()) {
        const filter2 = map2.get(key);
        if (!filter2 || !areObjectsEqual(filter1, filter2)) {
            return false;
        }
    }

    return true;
}

/**
 * Compares two objects for equality. Returns true if both objects have the same keys and values.
 * Handles nested objects and arrays recursively.
 * @param obj1 - The first object to compare.
 * @param obj2 - The second object to compare.
 * @returns True if the objects are equal, false otherwise.
 */
function areObjectsEqual(
    obj1: Record<string, SafeAny>,
    obj2: Record<string, SafeAny>
): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
            return false;
        }
        if (!areValuesEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

/**
 * Casts FilterMetadata to QueryFilters.
 * @param filters - Array of FilterMetadata
 * @returns QueryFilters
 */
export function castFilterMetadataToQueryFilters(
    filters: FilterMetadata[]
): QueryFilters<SafeAny> {
    return filters.reduce((acc, filter) => {
        acc = acc || {};

        if (Array.isArray(filter.props)) {
            filter.props.forEach(prop => {
                acc![prop.filterKey] = prop.value;
            });
        } else {
            acc[filter.key] = filter.value;
        }
        return acc;
    }, {} as QueryFilters<SafeAny>);
}

/**
 * Compares two values for equality. Handles primitives, arrays, and objects recursively.
 * @param value1 - The first value to compare.
 * @param value2 - The second value to compare.
 * @returns True if the values are equal, false otherwise.
 */
function areValuesEqual(value1: SafeAny, value2: SafeAny): boolean {
    if (value1 === value2) {
        return true;
    }

    if (value1 == null || value2 == null) {
        return value1 === value2;
    }

    if (Array.isArray(value1) && Array.isArray(value2)) {
        if (value1.length !== value2.length) {
            return false;
        }
        for (let i = 0; i < value1.length; i++) {
            if (!areValuesEqual(value1[i], value2[i])) {
                return false;
            }
        }
        return true;
    }

    if (typeof value1 === 'object' && typeof value2 === 'object') {
        return areObjectsEqual(value1, value2);
    }

    return false;
}
