import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FilterGroupComponent } from './components/filter-group/filter-group.component';
import { NgModule } from '@angular/core';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { SharedUiModule } from '../../shared-ui.module';
import { TableActionsComponent } from './components/table-actions/table-actions.component';
import { TableCellComponent } from './components/table-cell/table-cell.component';
import { TableComponent } from './components/table.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { TableHeaderSearchComponent } from './components/table-header-search/table-header-search.component';
import { TableToolbarComponent } from './components/table-toolbar/table-toolbar.component';
import { DateFilterComponent } from './components/date-filter.component';
import { TableRowsComponent } from './components/table-rows.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { TableCellEditableComponent } from './components/table-cell-editable.component';
import { EditableTableActionsComponent } from './components/editable-table-actions.component';
import { FilterButtonComponent } from './components/filter-button.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { AdvancedFiltersComponent } from './components/advanced-filters.component';
import { LayoutModule } from '../layout';
import { CheckboxGroupFilterComponent } from './components/checkbox-group-filter.component';
import { SegmentedDateFilterComponent } from './components/segmented-date-filter.component';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { TextSearchFilterComponent } from './components/text-search-filter.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedUiModule,
        NzButtonModule,
        NzFormModule,
        NzTableModule,
        NzDropDownModule,
        NzTagModule,
        NzDividerModule,
        NzPopoverModule,
        NzSegmentedModule,
        NzCheckboxModule,
        LayoutModule,
        NzTooltipDirective,
    ],
    declarations: [
        AdvancedFiltersComponent,
        TableComponent,
        TableToolbarComponent,
        TableCellComponent,
        TableHeaderComponent,
        TableHeaderSearchComponent,
        TableActionsComponent,
        FilterGroupComponent,
        DateFilterComponent,
        TableRowsComponent,
        TableCellEditableComponent,
        EditableTableActionsComponent,
        FilterButtonComponent,
        CheckboxGroupFilterComponent,
        SegmentedDateFilterComponent,
        TextSearchFilterComponent,
    ],
    exports: [
        TableComponent,
        FilterGroupComponent,
        DateFilterComponent,
        TableRowsComponent,
    ],
})
export class TableModule {}
