import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
} from '@angular/core';
import { ISegmentedOption } from '@pf/shared-common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'pf-segmented',
    templateUrl: './segmented.component.html',
    styleUrls: ['./segmented.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SegmentedComponent),
            multi: true,
        },
    ],
})
export class SegmentedComponent implements ControlValueAccessor {
    private _model: number | null = null;

    @Input()
    set model(value: number | null) {
        if (this._model !== value) {
            this._model = value;
            this.onChange(value);
            this.selectedModelChange.emit(value as number);
        }
    }

    get model(): number | null {
        return this._model;
    }

    @Input()
    segmentedOptions: ISegmentedOption[] = [];
    @Input() pfTitle?: string;

    @Output()
    selectedModelChange = new EventEmitter<number>();

    handleModelChange(index: number): void {
        this.selectedModelChange.emit(index);
        this.onTouched();
    }

    writeValue(value: number | null): void {
        this.model = value;
    }

    registerOnChange(fn: (value: number | null) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    private onChange: (value: number | null) => void = () => {};
    private onTouched: () => void = () => {};
}
