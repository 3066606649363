import {
    AfterViewInit,
    Directive,
    ElementRef,
    Input,
    Renderer2,
} from '@angular/core';
import {
    PermissionTypes,
    PFEntities,
    PlatformModules,
} from '@pf/shared-common';
import { UserPermissionCheckService } from '@pf/shared-services';
import { Observable } from 'rxjs';
import { buildPermissionPath } from '../utility/permission-path-builder.util';

type PermissionCheckMethod = 'All' | 'One';

@Directive({
    selector: '[platformRequiredPermissions]',
})
export class RequiredPermissionsDirective implements AfterViewInit {
    @Input() customPermission?: string;
    @Input() permissionPlatform?: PlatformModules;
    @Input() permissionEntity?: PFEntities;
    @Input() permissionType?: PermissionTypes;
    @Input() hideElementIfNoPermission = false;
    @Input() permissions?: string[];
    @Input() permissionCheckMethod?: PermissionCheckMethod;

    constructor(
        private permissionCheckService: UserPermissionCheckService,
        private elRef: ElementRef,
        private renderer: Renderer2
    ) {}

    ngAfterViewInit(): void {
        this.checkPermission();
    }

    private checkPermission() {
        let permissionObservable: Observable<boolean>;

        if (this.permissions && this.permissions.length > 0) {
            if (this.permissionCheckMethod === 'All') {
                permissionObservable =
                    this.permissionCheckService.hasAllPermissions$(
                        this.permissions
                    );
            } else {
                permissionObservable =
                    this.permissionCheckService.hasOneOfPermissions$(
                        this.permissions
                    );
            }
        } else {
            const requiredPermission =
                this.customPermission || this.combinePermissionPath();
            if (!requiredPermission) {
                return;
            }

            permissionObservable =
                this.permissionCheckService.checkPermission$(
                    requiredPermission
                );
        }

        permissionObservable.subscribe(
            hasPermission => {
                this.handlePermission(hasPermission);
            },
            error => {
                console.error('Permission check error:', error);
            }
        );
    }

    private handlePermission(hasPermission: boolean) {
        if (hasPermission) {
            return;
        }
        this.renderer.setProperty(
            this.elRef.nativeElement.children[0],
            this.hideElementIfNoPermission ? 'hidden' : 'disabled',
            'true'
        );
        if (!this.hideElementIfNoPermission) {
            this.renderer.setProperty(
                this.elRef.nativeElement.children[0],
                'title',
                'You do not have permission to perform this action'
            );
        }
    }

    private combinePermissionPath() {
        if (!this.permissionPlatform || !this.permissionType) {
            return null;
        }
        return buildPermissionPath(
            this.permissionPlatform,
            this.permissionType,
            this.permissionEntity
        );
    }
}
