import { withMetadata } from '@pf/shared-utility';
import { ConversionRate, Currency } from '@control-tower/platform-loads';

export const CurrencySymbol = 'Currency';
export const ConversionRateSymbol = 'Conversion';

withMetadata<ConversionRate>(ConversionRateSymbol, {
    currencyTypeCode: String,
    amount: Number,
    direction: String,
    rate: Number,
});

withMetadata<Currency>(CurrencySymbol, {
    currencyTypeCode: String,
    amount: Number,
    date: String,
    conversions: [ConversionRateSymbol],
});
