<ng-content select="[toolbar-title]"></ng-content>
<div id="toolbar-wrapper">
    <div id="toolbar-title">
        <pf-text
            table-name
            type="primary"
            bold
            *ngIf="title || !showSelectionToolbar">
            {{ title }}
        </pf-text>
        <ng-container *ngIf="showAdvancedFilterButton">
            <pf-filter-button></pf-filter-button>
        </ng-container>
        <pf-button
            buttonType="clear"
            data-pf-handle="clear-filters"
            (click)="resetClicked()"
            [disabled]="disableClearFilters"
            *ngIf="showClearFilters">
            Clear Filter(s)
        </pf-button>
        <div
            table-name
            id="table-reset-toolbar"
            *ngIf="tableService.queryParams?.serverRendered"
            [attr.title]="
                'Last updated on ' +
                (tableService.tableState.lastUpdated | date: 'medium')
            ">
            <pf-button buttonType="refresh" (click)="refreshTable.emit()"
                >Refresh
            </pf-button>
        </div>
        <div table-name id="table-row-stats">
            <ng-container
                *ngIf="(tableService.selectedRowCount$ | async) === 0">
                <span>{{ tableService.tableState.total }}&nbsp;total</span>
            </ng-container>
            <div
                table-name
                id="table-selection-toolbar"
                *ngIf="showSelectionToolbar">
                <ng-container
                    *ngIf="
                        tableService.selectedRowCount$
                            | async as selectedRowCount
                    ">
                    <span *ngIf="selectedRowCount > 0"
                        >{{ selectedRowCount }} selected of
                        {{ tableService.tableState.total }}</span
                    >
                </ng-container>
                <ng-content select="[selection-toolbar]"></ng-content>
            </div>
        </div>
    </div>
    <div toolbar-actions>
        <div id="table-toolbar-left">
            <ng-content select="[toolbar-left]"></ng-content>
        </div>
        <ng-content select="[toolbar-right]"></ng-content>
    </div>
</div>
<ng-container *ngIf="showSearch">
    <pf-text-search-filter
        [ngStyle]="{ width: '340px' }"
        [filter]="{
            name: 'Search',
            filterKey: 'Search',
            filterType: 'text'
        }"
        [displayLabel]="false"
        [instantSearch]="true"
        (search)="search.emit($event)"></pf-text-search-filter>
</ng-container>
