import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    Output,
} from '@angular/core';
import { InputType, InputTypePropMap, InputTypeProps } from './input.types';
import { BaseControlComponent } from '../base-control/base-control.component';
import { FormControl } from '@angular/forms';
import { SafeAny } from '@pf/shared-common';
import { debounceTime, Subject } from 'rxjs';

/**
 * The input component
 *
 * @example
 * <pf-input
 *      label="Name"
 *      placeholder="Name"
 *      inputType="text">
 * </pf-input>
 */
@Component({
    selector: 'pf-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent extends BaseControlComponent {
    private _inputType!: InputType;
    private _dataList: string[] = [];

    /**
     * The `placeholder` input is used as a placeholder on the input
     * component.
     */
    @Input() placeholder = '';
    /**
     * The `min` input will be used for assigning a maximum value if it is
     * a `number` type component.
     */
    @Input() min!: number;
    /**
     * The `max` input will be used for assigning a maximum value if it is
     * a `number` type component.
     */
    @Input() max!: number;

    /**
     * The `datalist` input is allowing us to display a list of options
     * for the input component. It is used for the `text` type input
     * component. The `datalist` input is an array of strings. It will still
     * allow us to type in the input component but it will also show the
     * options below the input component. The `datalist` input is not
     * case-sensitive.
     * @example
     * <pf-input
     *      label="Name"
     *      placeholder="Name"
     *      inputType="text"
     *      [datalist]="['John', 'Doe']">
     * </pf-input>
     * @example
     */
    @Input() set datalist(list: string[]) {
        this._dataList = list;
        this.filteredDataList = [...list];
    }

    get datalist(): string[] {
        return this._dataList;
    }

    filteredDataList: string[] = [];

    /**
     * The `inputType` input is allowing us to specify the `pf-input` type.
     * [InputType]{@link InputType}
     */
    @Input()
    set inputType(value: keyof typeof InputType) {
        this._inputType = InputType[value];
        this.setProps();
    }

    @Input() textAreaRows = 3;

    @Input() pfAutocomplete?: string;

    @Output() pfInput = new EventEmitter<SafeAny>();

    /**
     * This property is used by form to show below validation messages regarding the
     * input type.
     */
    autoTips = {
        en: {
            phone: 'Invalid phone number',
            email: 'Invalid email address',
            required: 'Required',
        },
    };

    /**
     * This property will be used for different input components' property values
     */
    typeProps?: InputTypeProps;

    private searchTerms = new Subject<string>();

    constructor(
        injector: Injector,
        elementRef: ElementRef,
        private cdr: ChangeDetectorRef
    ) {
        super(injector, elementRef);

        this.searchTerms
            .pipe(debounceTime(500))
            .subscribe(term => this.onChange(term));
    }

    protected override formControlConfig(control: FormControl): void {
        if (this.typeProps?.validators) {
            control.addValidators(this.typeProps.validators);
            control.updateValueAndValidity();
        }
    }

    /**
     * The `parseNumber` function replace the `letters` and the `non-alphanumeric`
     * characters (-except the (-) and (.) characters) on the given `value`. It checks the
     * min & max limits and alter the value if any of them exceed.
     *
     * example outputs:
     *
     * 1.0 -> 1
     *
     * 1.5 -> 1.5
     *
     * @param {string} value  The input value to be processed
     * @returns float formatted string response
     */
    parseNumber = (value: string): string => {
        value = value
            .replace(/[^0-9.-]/g, '') // remove chars except number, hyphen, point.
            .replace(/(\..*)\./g, '$1') // remove multiple points.
            .replace(/(?!^)-/g, '') // remove middle hyphen.
            .replace(/^0+(\d)/gm, '$1'); // remove multiple leading zeros.

        if (value.length === 1 && value === '-') {
            return value;
        }

        if (value.endsWith('.') || value.endsWith('.0')) {
            return value;
        }

        const number = parseFloat(value);

        if (Number.isNaN(number)) {
            return '';
        }

        if (this.typeProps?.max && this.typeProps.max < number) {
            return this.typeProps?.max.toString();
        } else if (this.typeProps?.min && this.typeProps.min > number) {
            return this.typeProps?.min.toString();
        }
        return number.toString();
    };

    onChange(value: string) {
        this.pfInput.emit(value);
        if (value) {
            this.filteredDataList = this.datalist.filter(option =>
                option.toLowerCase().includes(value.toLowerCase())
            );
        } else {
            this.filteredDataList = [...this.datalist];
        }
        this.cdr.markForCheck();
    }

    search(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        const value = inputElement?.value || '';

        this.searchTerms.next(value);
        this.pfInput.emit(value);
    }

    /**
     * This function sets the [`typeProps`]{@link InputComponent#typeProps} fields regarding
     * the given [`inputType`]{@link inputType}.
     */
    private async setProps() {
        await new Promise(r => setTimeout(r));
        this.typeProps = InputTypePropMap[this._inputType](this.min, this.max);
        this.cdr.markForCheck();
        if (this.formControl) {
            this.formControlConfig(this.formControl);
        }

        if (this.typeProps.type === 'number') {
            this.focusElementSelector = '.pf-input input';
        }
    }
}
