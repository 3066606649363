import {
    asArraySafe,
    isArrayEmpty,
    isValue,
    normalizeString,
} from '@pf/shared-utility';
import { ICustomFieldDto } from '../models/custom-fields.dto';

export const findCustomField = <T extends ICustomFieldDto>(
    customFields: T[] | null | undefined,
    key: string
): T | undefined => {
    if (!isValue(key) || isArrayEmpty(customFields)) {
        return undefined;
    }
    const normalizedKey = normalizeString(key);
    return (customFields as T[]).find(
        (customField: ICustomFieldDto) =>
            customField.key?.toLowerCase() === normalizedKey
    );
};

export const mergeCustomFields = <T extends ICustomFieldDto>(
    list1?: T[],
    list2?: T[]
): T[] => {
    const mergedList = [...asArraySafe(list1)];
    asArraySafe(list2).forEach((item: T) => {
        const existingItem = findCustomField(mergedList, item.key);
        if (existingItem) {
            existingItem.value = item.value;
        } else {
            mergedList.push(item);
        }
    });
    return mergedList;
};
