import {
    IMappingProfile,
    IQueryParams,
    IQueryParamsSymbol,
    PFLoadEntities,
    SafeAny,
} from '@pf/shared-common';
import { createMap, Mapper } from '@automapper/core';
import {
    entitySymbols,
    withDefaultEntityMetadata,
    withDefaultSearchParamsMappingConfiguration,
    withDefaultSearchParamsMetadata,
    withMetadata,
    withSearchFilter,
} from '@pf/shared-utility';
import {
    LaneCarrierCreate,
    LaneCarrierDto,
    LaneCarrierRead,
    LaneCarrierSearchParams,
} from '../../entities/lane-carrier.dto';
import {
    CustomFieldMetadataMap,
    DefaultCarrierFragmentMetadataMap,
    ResourceAuthSymbol,
} from '@pf/shared/util-platform';

const symbols = entitySymbols(PFLoadEntities.LaneCarrier);

withDefaultEntityMetadata<LaneCarrierDto>(symbols.dto, {
    name: String,
    laneId: String,
    carrier: DefaultCarrierFragmentMetadataMap.symbols.dto,
    carrierId: String,
    priority: Number,
    customFields: CustomFieldMetadataMap.symbols.dto,
});

withDefaultEntityMetadata<LaneCarrierRead>(symbols.entity, {
    laneId: String,
    carrierId: String,
    priority: Number,
    name: String,
    resourceAuth: ResourceAuthSymbol,
    carrier: DefaultCarrierFragmentMetadataMap.symbols.entity,
    customFields: CustomFieldMetadataMap.symbols.entity,
});

withMetadata<LaneCarrierCreate>(symbols.createBody, {
    laneId: String,
    carrierId: String,
    priority: Number,
    name: String,
    resourceAuth: ResourceAuthSymbol,
});

withDefaultSearchParamsMetadata<LaneCarrierSearchParams | SafeAny>(
    symbols.searchParams,
    {
        name: String,
        laneId: String,
        carrierId: String,
    }
);

export const LaneCarrierMetadataMap: IMappingProfile = {
    symbols,
    profile: (mapper: Mapper) => {
        createMap<LaneCarrierRead, LaneCarrierDto>(
            mapper,
            symbols.entity,
            symbols.dto
        );
        createMap<LaneCarrierDto, LaneCarrierRead>(
            mapper,
            symbols.dto,
            symbols.entity
        );
        createMap<LaneCarrierDto, LaneCarrierCreate>(
            mapper,
            symbols.dto,
            symbols.createBody
        );
        createMap<IQueryParams<LaneCarrierDto>, LaneCarrierSearchParams>(
            mapper,
            IQueryParamsSymbol,
            symbols.searchParams,
            withSearchFilter('laneId'),
            withSearchFilter('carrierId'),
            withSearchFilter('name'),
            ...withDefaultSearchParamsMappingConfiguration()
        );
    },
};
