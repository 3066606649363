import { Component, Input } from '@angular/core';
import {
    AnimationType,
    FrameworkColumn,
    ITableCellValueRenderer,
    SafeAny,
    StatusStyle,
} from '@pf/shared-common';

export type StatusOption = {
    label: string;
    status?: StatusStyle;
    animation?: AnimationType;
};
export type StatusOptions = Record<string | number | symbol, StatusOption> & {
    defaultOption: StatusOption;
};

@Component({
    selector: 'pf-status-renderer',
    template: `
        <span
            [attr.status-color]="option?.status"
            [attr.animation]="option?.animation"></span>
        <span>{{ option?.label }}</span>
    `,
    styleUrls: ['status-renderer.component.scss'],
})
export class StatusRendererComponent implements ITableCellValueRenderer {
    option?: StatusOption;
    @Input() column!: FrameworkColumn;
    @Input() row!: SafeAny;

    private _value?: SafeAny;
    private _options?: StatusOptions;

    @Input() set value(value: SafeAny) {
        this._value = value;
        this.setOption();
    }

    @Input() set options(options: StatusOptions) {
        this._options = options;
        this.setOption();
    }

    private setOption() {
        this.option =
            this._options?.[this._value] || this._options?.defaultOption;
    }
}
