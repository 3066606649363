import { SafeAny, SelectOption } from '@pf/shared-common';
import {
    UserCreate,
    UserDto,
    UserRead,
    UserSearchParams,
} from '../entities/user.dto';

import {
    AbstractManageEntityFacade,
    UserAccountType,
} from '@pf/shared-services';
import { BuildChangeTrackingFacadeFactory } from '@pf/shared/util-platform';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoutesForUsers } from './user.routes';
import { UserDataService } from '../infrastructure/users/user.data.service';
import { UserMapper } from '../infrastructure/users/user.mapper';
import { UserStore } from '../infrastructure/users/user.store';
import { ResolveAppConstant } from '@pf/shared-ui';

@Injectable()
export class UserFacade extends AbstractManageEntityFacade<
    UserDto,
    UserRead,
    UserCreate,
    UserSearchParams
> {
    private readonly _accountTypesOptions = Object.keys(UserAccountType).map(
        x => ({
            label: x,
            alias: ResolveAppConstant(x),
            value: (UserAccountType as SafeAny)[x],
        })
    ) as SelectOption[];

    nameField: keyof UserDto = 'email';

    constructor(
        private userDataService: UserDataService,
        routes: RoutesForUsers,
        userStore: UserStore,
        userMapper: UserMapper
    ) {
        super(userDataService, routes, userStore, userMapper);
    }

    override applyResourceAuth(body: UserCreate) {
        const tenantUser =
            body.userGroupType === UserAccountType.Tenant ||
            body.userGroupType === UserAccountType.Administrator;
        body.allCarriers = tenantUser;
        body.allVendors = tenantUser;
        body.allLocations = tenantUser;
        body.allCustomers = tenantUser;
    }

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        this.nameField
    );

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof UserDto, string>> {
        return { email: searchText };
    }

    get accountTypeOptions() {
        return this._accountTypesOptions;
    }

    resetPassword$(id: string): Observable<SafeAny> {
        return this.userDataService.resetPassword$(id);
    }

    override delete$(entityId: string): Observable<UserDto> {
        return super.delete$(entityId, 'inactivated');
    }

    override undoDelete$ = (entityId: string): Observable<UserDto> => {
        return super.undoDelete(entityId, 'activated');
    };
}
