import { AbstractEntityMapper } from '@pf/shared-services';
import {
    WorkflowGlobalParametersCreate,
    WorkflowGlobalParametersDto,
    WorkflowGlobalParametersRead,
    WorkflowGlobalParametersSearchParams,
} from '../../entities/workflow-global-parameters.dto';
import { Injectable } from '@angular/core';
import { PlatformLoadsMapper } from '../platform-loads.mapper';
import { WorkflowGlobalParameterMetadataMap } from './workflow-global-parameter.mapping-profile';

@Injectable()
export class WorkflowGlobalParameterMapper extends AbstractEntityMapper<
    WorkflowGlobalParametersRead,
    WorkflowGlobalParametersCreate,
    WorkflowGlobalParametersDto,
    WorkflowGlobalParametersSearchParams
> {
    constructor() {
        super(
            WorkflowGlobalParametersDto,
            PlatformLoadsMapper,
            WorkflowGlobalParameterMetadataMap.symbols
        );
    }
}
