import { NgModule } from '@angular/core';
import { SequentialWorkflowDesignerModule } from 'sequential-workflow-designer-angular';
import { WorkflowDesignerComponent } from './workflow-designer.component';
import { LayoutModule, SharedUiModule } from '@pf/shared-ui';

@NgModule({
    imports: [SequentialWorkflowDesignerModule, SharedUiModule, LayoutModule],
    declarations: [WorkflowDesignerComponent],
    exports: [WorkflowDesignerComponent],
})
export class WorkflowModule {}
