import { SafeAny } from '@pf/shared-common';
import { StepType } from '@control-tower/platform-loads';
import { asArraySafe } from '@pf/shared-utility';

export const ComponentTypesMap = {
    [StepType.Action]: 'task',
    [StepType.Switch]: 'switch',
    [StepType.Sequence]: 'container',
    [StepType.If]: 'switch',
    task: StepType.Action,
    switch: StepType.Switch,
    container: StepType.Sequence,
};

export enum WorkflowType {
    FirstStopWithTypeCode = 'FirstStopWithTypeCode',
    GetCustomer = 'GetCustomer',
    GetLocation = 'GetLocation',
    StopItem = 'StopItem',
    SumCharges = 'SumCharges',
    FirstWithValue = 'FirstWithValue',
    PropertyAccessor = 'PropertyAccessor',
    EvaluateFormula = 'EvaluateFormula',
    StaticValue = 'StaticValue',
    MapUom = 'map-uom',
    If = 'If',
}

export type StepPropertiesMapper = {
    toProperties: (properties: SafeAny) => SafeAny;
    fromProperties: (properties: SafeAny) => SafeAny;
};

export const TypePropertiesMapper: Record<WorkflowType, StepPropertiesMapper> =
    {
        [WorkflowType.MapUom]: {
            toProperties: properties => ({
                paramRef: {
                    name: properties.paramRef || '',
                    type: 'string',
                },
            }),
            fromProperties: properties => ({
                paramRef: properties.paramRef?.name || '',
            }),
        },
        [WorkflowType.If]: {
            toProperties: properties => ({
                paramRef: {
                    name: properties.paramRef || '',
                    type: 'string',
                },
                operator: properties.operator || '',
                value: properties.value || '',
            }),
            fromProperties: properties => ({
                paramRef: properties.paramRef?.name || '',
                operator: properties.operator || '',
                value: properties.value || '',
            }),
        },
        [WorkflowType.FirstStopWithTypeCode]: {
            toProperties: properties => ({
                stopTypeId: properties.stopTypeId || '',
            }),
            fromProperties: properties => ({
                stopTypeId: properties.stopTypeId || '',
            }),
        },
        [WorkflowType.GetCustomer]: {
            toProperties: properties => ({
                stopTypeId: properties.stopTypeId || '',
            }),
            fromProperties: properties => ({
                stopTypeId: properties.stopTypeId || '',
            }),
        },
        [WorkflowType.GetLocation]: {
            toProperties: properties => ({
                stopTypeId: properties.stopTypeId || '',
            }),
            fromProperties: properties => ({
                stopTypeId: properties.stopTypeId || '',
            }),
        },
        [WorkflowType.StopItem]: {
            toProperties: properties => ({
                stopTypeId: properties.stopTypeId || '',
            }),
            fromProperties: properties => ({
                stopTypeId: properties.stopTypeId || '',
            }),
        },
        [WorkflowType.SumCharges]: {
            toProperties: properties => ({
                chargeTypeId: properties.chargeTypeId || '',
                stopTypeId: properties.stopTypeId || '',
                accountType: properties.accountType || '',
            }),
            fromProperties: properties => ({
                chargeTypeId: properties.chargeTypeId || '',
                stopTypeId: properties.stopTypeId || '',
                accountType: properties.accountType || '',
            }),
        },
        [WorkflowType.FirstWithValue]: {
            toProperties: properties => ({
                params: {
                    variables: properties.params || [],
                },
                defaultValue: properties.defaultValue || '',
            }),
            fromProperties: properties => ({
                params: asArraySafe(properties.params?.variables),
                defaultValue: properties.defaultValue || '',
            }),
        },
        [WorkflowType.PropertyAccessor]: {
            toProperties: properties => ({
                paramReference: {
                    name: properties.paramReference?.name || '',
                    type: properties.paramReference?.type?.toLowerCase() || '',
                },
                path: properties.path || '',
                outputType: properties.outputType || 'any',
            }),
            fromProperties: properties => ({
                paramReference: properties.paramReference,
                path: properties.path,
                outputType: properties.outputType,
            }),
        },
        [WorkflowType.EvaluateFormula]: {
            toProperties: properties => {
                const variables = properties.variables || {};
                return {
                    name: properties.name || '',
                    expression: properties.expression || '',
                    description: properties.description || '',
                    variables: {
                        items: Object.keys(variables).map(key => ({
                            key: key,
                            value: variables[key] || '',
                        })),
                    },
                };
            },
            fromProperties: properties => ({
                name: properties.name || '',
                expression: properties.expression || '',
                description: properties.description || '',
                variables: asArraySafe(
                    properties.variables?.items as {
                        key: string;
                        value: string;
                    }[]
                ).reduce(
                    (pv, cv) => {
                        pv[cv.key] = cv.value;
                        return pv;
                    },
                    {} as Record<string, string>
                ),
            }),
        },
        [WorkflowType.StaticValue]: {
            toProperties: properties => ({
                staticValue: properties.staticValue || '',
                type: properties.type || 'string',
            }),
            fromProperties: properties => ({
                staticValue: properties.staticValue || '',
                type: properties.type || 'string',
            }),
        },
    };
