import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { DirtyCheckService } from './services/dirty-check.service';
import { DrawerFormComponent } from './components/drawer-form.component';
import { DrawerSubformComponent } from './components/drawer-subform.component';
import { FormButtonsComponent } from './components/form-buttons.component';
import { NgModule } from '@angular/core';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { SharedUiModule } from '../../shared-ui.module';
import { TableModule } from '../table';
import { NzFormModule } from 'ng-zorro-antd/form';
import { DynamicControlComponent } from './components/dynamic-control.component';
import { FormRendererComponent } from './components/form-renderer.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedUiModule,
        NzDrawerModule,
        NzModalModule,
        TableModule,
        NzDropDownModule,
    ],
    declarations: [
        DrawerFormComponent,
        FormButtonsComponent,
        DrawerSubformComponent,
        DynamicControlComponent,
        FormRendererComponent,
    ],
    exports: [
        FormButtonsComponent,
        DrawerFormComponent,
        DrawerSubformComponent,
        FormsModule,
        ReactiveFormsModule,
        NzFormModule,
        DynamicControlComponent,
        FormRendererComponent,
    ],
    providers: [DirtyCheckService],
})
export class PlatformFormsModule {}
