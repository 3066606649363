import * as hash from 'object-hash';

import {
    ICustomField,
    IEntity,
    IEntityReadDataService,
    IEntityStore,
    IOmniFieldSearchParams,
    IPagedResult,
    IPagedSearchParams,
    SafeAny,
} from '@pf/shared-common';
import {
    bufferCount,
    combineLatest,
    concatMap,
    forkJoin,
    map,
    Observable,
    of,
    reduce,
    switchMap,
    tap,
} from 'rxjs';

import { LoggerService } from '../logging/Logger.service';
import { isEntityStale } from '@pf/shared-utility';

export abstract class AbstractReadDataService<TRead extends IEntity>
    implements IEntityReadDataService<TRead>
{
    protected constructor(
        protected store: IEntityStore<TRead>,
        protected _logger: LoggerService
    ) {}

    protected abstract EntityName: string;

    protected abstract getObs$(id: string): Observable<TRead>;

    protected exportObs$(
        _: (IPagedSearchParams | IOmniFieldSearchParams) & {
            exportType: string;
            filename: string;
        }
    ): Observable<SafeAny> {
        throw new Error('Method not implemented.');
    }

    protected abstract searchObs$(
        searchParams: IPagedSearchParams | IOmniFieldSearchParams,
        isOneTimeSearch?: boolean
    ): Observable<IPagedResult<TRead>>;

    protected searchCustomFieldsObs$(
        _searchParams: IPagedSearchParams & { key?: string; value?: string }
    ): Observable<IPagedResult<ICustomField>> {
        throw new Error('searchCustomFieldsObs$ not implemented.');
    }

    oneTimeSearchObs$(
        searchParams: IPagedSearchParams | IOmniFieldSearchParams
    ) {
        return this.searchObs$(searchParams, true).pipe(
            tap(result => {
                result.data.forEach(entity => this.store.add(entity, false));
            })
        );
    }

    get$(id: string, noCache = false): Observable<TRead> {
        const existing = this.store.get(id);
        if (!noCache && existing && !isEntityStale(existing)) {
            return of(this.store.get(id));
        }
        this._logger.debug(`Fetching ${this.EntityName}`);
        return this.getObs$(id).pipe(
            tap(entity => this.store.add(entity, false))
        );
    }

    getMany$(ids: string[]): Observable<TRead[]> {
        return combineLatest(ids.map(id => this.get$(id)));
    }

    search$(
        queryParams: IPagedSearchParams | IOmniFieldSearchParams
    ): Observable<IPagedResult<TRead>> {
        this._logger.debug(`Searching ${this.EntityName}`);
        const pageHash = hash(queryParams);

        return this.searchObs$(queryParams).pipe(
            tap(result => this.store.setPage(result, pageHash)),
            this.store.skipWhilePageExists(
                queryParams.pageNumber as number,
                pageHash
            )
        );
    }

    oneTimeSearch$(
        queryParams: IPagedSearchParams | IOmniFieldSearchParams
    ): Observable<IPagedResult<TRead>> {
        this._logger.debug(`One time search of ${this.EntityName}`);
        return this.oneTimeSearchObs$(queryParams);
    }

    searchAll$(queryParams: IPagedSearchParams): Observable<TRead[]> {
        this._logger.debug(`Searching all ${this.EntityName}`);
        queryParams.pageNumber = 1;
        queryParams.isDeleted = queryParams.isDeleted || false;
        const pageSize = (queryParams.pageSize = 50);

        return this.searchObs$(queryParams, true).pipe(
            switchMap(result => {
                const pages = Math.ceil(result.totalRecords / pageSize);

                if (pages <= 1) {
                    return of([result]);
                }

                const requests = new Array(pages - 1).fill(0).map((_, i) => {
                    const nextQueryParams = {
                        ...queryParams,
                        pageNumber: i + 2,
                    };
                    return this.searchObs$(nextQueryParams);
                });
                return [of(result), ...requests];
            }),
            bufferCount(10),
            concatMap(requests => forkJoin(requests)),
            reduce(
                (acc, batchResults) => [...acc, ...batchResults],
                [] as IPagedResult<TRead>[]
            ),
            map(results => results.flatMap(result => result.data))
        );
    }

    exportRaw$(
        queryParams: IPagedSearchParams,
        filename: string
    ): Observable<Blob> {
        this._logger.debug(`Raw export of ${this.EntityName}`);
        return this.exportObs$({
            ...queryParams,
            exportType: 'csv',
            filename,
            pageNumber: 1,
            pageSize: 1000,
        });
    }

    searchCustomFields$(
        queryParams: IPagedSearchParams & {
            key?: string;
            value?: string;
        }
    ): Observable<ICustomField[]> {
        this._logger.debug(`Searching custom fields for ${this.EntityName}`);
        queryParams.pageNumber = queryParams.pageNumber || 1;
        const pageSize = queryParams.pageSize || 50;

        return this.searchCustomFieldsObs$({
            ...queryParams,
            pageSize,
        }).pipe(
            switchMap(result => {
                if (queryParams.pageSize) {
                    return of(result);
                }

                const pages = Math.ceil(result.totalRecords / pageSize);

                if (pages <= 1) {
                    return of([result]);
                }

                const requests = new Array(pages - 1).fill(0).map((_, i) => {
                    const nextQueryParams = {
                        ...queryParams,
                        pageNumber: i + 2,
                        pageSize,
                    };
                    return this.searchCustomFieldsObs$(nextQueryParams);
                });
                return forkJoin([of(result), ...requests]);
            }),
            map(results => {
                if (Array.isArray(results)) {
                    return (results as IPagedResult<ICustomField>[]).reduce(
                        (acc, result) => {
                            acc.push(...result.data);
                            return acc;
                        },
                        [] as ICustomField[]
                    );
                }
                return (results as IPagedResult<ICustomField>).data;
            })
        );
    }
}
