import { withMetadata } from '@pf/shared-utility';
import { PlatformSharedEntities } from './shared-entities.enum';
import { Formula } from '@control-tower/platform-loads';

export const FormulaSymbol = PlatformSharedEntities.Formula;

export const withFormulaMetadata = () => {
    withMetadata<Formula>(FormulaSymbol, {
        name: String,
        description: String,
        value: Number,
        formulaExpression: String,
        calculation: String,
    });
};
