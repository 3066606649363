import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    FilterInitialSelection,
    FilterOption,
    ICheckboxFilterConfig,
    IFilterConfig,
    IFilterKeyConfig,
    IOptionsSearchFacade,
    QueryFilters,
    SafeAny,
} from '@pf/shared-common';
import { FilterService } from '../services/filter.service';
import { Observable } from 'rxjs';
import {
    CreateMultiEntitySearchFacade,
    MultiEntitySearchConfig,
} from '@pf/shared/util-platform';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterMetadata } from '../stores/filter.store';

@UntilDestroy()
@Component({
    selector: 'pf-base-filter',
    template: ``,
    styles: [],
})
export abstract class BaseFilterComponent implements OnInit {
    private _filterOptions: FilterOption[] | undefined = [];
    private _displayValidationMessage: boolean = false;
    private _filterOptions$: Observable<FilterOption[]> | undefined;
    private _filterConfig: IFilterConfig = {} as IFilterConfig;

    isGrouped = false;
    searchFacade?: IOptionsSearchFacade;
    searchFacadeLabel?: string;
    searchEntityTypes?: Record<string, ICheckboxFilterConfig>;
    searchFilters?: QueryFilters<SafeAny>;
    filterName = '';
    filterKey = '';
    filterKeyObject?: IFilterKeyConfig;
    filterKeyIndex = 0;
    filterKeys?: IFilterKeyConfig[];
    selectionRequired: boolean = false;
    selectionRequiredMessage: string = '';
    initialSelection: FilterInitialSelection = 'any';
    tooltip?: string;

    @Input() filtersAreReady$?: Observable<[Set<string>, IFilterConfig[]]>;

    @Input() set filter(filterConfig: IFilterConfig) {
        this._filterConfig = filterConfig;
        this._filterOptions$ = filterConfig.filterOptions$;

        this.initializeFilterConfig(filterConfig);
        this.processMultiFacadeConfiguration(filterConfig.searchFacades);
    }

    @Output() filterComponentReady: EventEmitter<void> =
        new EventEmitter<void>();

    get filterOptions() {
        return this._filterOptions;
    }

    get displayValidationMessage() {
        return this._displayValidationMessage;
    }

    set displayValidationMessage(value: boolean) {
        if (value) {
            this.filterService.addValidationIssue(
                this.filterKey,
                this.selectionRequiredMessage
            );
        } else {
            this.filterService.removeValidationIssue(this.filterKey);
        }
        this._displayValidationMessage = value;
    }

    @Output() filterChangeEmitter: EventEmitter<FilterMetadata> =
        new EventEmitter<FilterMetadata>();

    setDefaultFilterKey() {
        if (this._filterConfig.filterKeys?.length) {
            const defaultFilterKey = this._filterConfig.filterKeys.find(
                fk => fk.default
            )?.value;
            this.changeFilterKey(
                defaultFilterKey ?? this._filterConfig.filterKeys[0].value
            );
        } else {
            this.changeFilterKey(this._filterConfig.filterKey);
        }
    }

    /**
     * Set the default key to the filterKeyIndex
     */
    resetFilterKey() {
        this.filterKeyIndex =
            this._filterConfig?.filterKeys?.findIndex(fk => fk.default) ?? 0;
    }

    filterChangeHandler(filter: FilterMetadata) {
        this.filterChangeEmitter.emit(filter);
    }

    changeFilterKey(filterKey: string) {
        if (!this.filterKeys) {
            this.filterKey = filterKey;
            return;
        }

        const filterKeyIndex = this.filterKeys.findIndex(
            fk => fk.value === filterKey
        );
        this.changeFilterKeyByIndex(filterKeyIndex);
    }

    changeFilterKeyByIndex(
        filterKeyIndex: number,
        filterKeyUpdateCallback?: () => void
    ) {
        if (!this.filterKeys) {
            return;
        }
        const filterKey = this.filterKeys[filterKeyIndex];

        if (filterKey.value === this.filterKey) {
            return;
        }

        this.filterKeyIndex = filterKeyIndex;
        this.filterKey = filterKey.value;
        this.filterKeyObject = filterKey;

        filterKeyUpdateCallback?.();
    }

    constructor(protected filterService: FilterService) {}

    ngOnInit() {
        this.setupFilterReset();
        this.setupFilterClear();
        if (this._filterOptions$) {
            this._filterOptions$
                ?.pipe(untilDestroyed(this))
                .subscribe(options => {
                    this.setFilterOptions(options);
                    this.handleInitialValue();
                });
        } else {
            this.handleInitialValue();
        }
    }

    abstract setFilterOptions(
        options: FilterOption[] | Observable<FilterOption[]> | undefined
    ): void;

    abstract validate(): void;

    /**
     * override this method to handle initial selection
     */
    handleInitialValue() {
        setTimeout(() => {
            this.filterComponentReady.emit();
        }, 300);
    }

    abstract filterChangedChecks(): void;

    abstract filterResetFn(): void;

    abstract filterClearFn(): void;

    abstract updateInitialFilter(filter: SafeAny): void;

    private processMultiFacadeConfiguration(
        searchFacades?: ICheckboxFilterConfig[]
    ) {
        if (!searchFacades?.length) {
            return;
        }

        this.isGrouped = true;

        this.searchEntityTypes = searchFacades.reduce(
            (acc, searchFacade) => {
                acc[searchFacade.entityType] = searchFacade;
                return acc;
            },
            {} as Record<string, ICheckboxFilterConfig>
        );

        this.searchFacade = CreateMultiEntitySearchFacade(
            searchFacades.reduce(
                (acc, config) => {
                    acc[config.entityType] = {
                        name: config.entityType,
                        facade: config.searchFacade,
                    };
                    return acc;
                },
                {} as Record<string, MultiEntitySearchConfig>
            )
        );
    }

    private initializeFilterConfig(filterConfig: IFilterConfig) {
        this.filterKeys = filterConfig.filterKeys;
        this.setDefaultFilterKey();
        this.filterName = filterConfig.name;
        this.searchFacade = filterConfig.searchFacade;
        this.searchFacadeLabel = filterConfig.checkboxFilterConfigs;
        this.searchFilters = filterConfig.searchFilters;
        this.selectionRequired = filterConfig.selectionRequired ?? false;
        this.selectionRequiredMessage =
            filterConfig.selectionRequiredMessage ?? '';
        this.initialSelection = filterConfig.initialSelection ?? 'any';
        this.tooltip = filterConfig.tooltip;
    }

    private setupFilterReset() {
        this.filterService.filterReset$
            .pipe(untilDestroyed(this))
            .subscribe(status => {
                if (status) {
                    this.filterResetFn();
                }
            });
    }

    private setupFilterClear() {
        this.filterService.filterClear$
            .pipe(untilDestroyed(this))
            .subscribe(status => {
                if (status) {
                    this.filterClearFn();
                }
            });
    }
}
