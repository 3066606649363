import { IEntity, SafeAny, SelectOption, TreeSelectOption } from '@pf/shared-common';

/**
 * Converts a list of interface objects into instances of a class.
 * @param list The list of interface objects.
 * @param ctor The class constructor.
 * @returns A list of class instances.
 */
export const mapToClassSafe = <TClass, TInterface = TClass>(
    list: TInterface[] | undefined | null,
    ctor: new (data: TInterface) => TClass
): TClass[] => {
    return list ? list.map(item => new ctor(item)) : [];
};

/**
 * Ensures a list is returned as an array, defaulting to an empty array if the input is null or undefined.
 * @param list The input list.
 * @returns A safe array.
 */
export const asArraySafe = <TInterface>(
    list: TInterface[] | undefined | null
): TInterface[] => {
    return list ? [...list] : [];
};

/**
 * Creates a shallow copy of an array.
 * @param arr The input array.
 * @returns A shallow copy of the array.
 */
export const shallowCopyArray = (arr: SafeAny[]) => {
    return [...arr];
};

/**
 * Checks if an array is empty or null/undefined.
 * @param arr The input array.
 * @returns True if the array is empty or null/undefined, false otherwise.
 */
export const isArrayEmpty = (arr?: SafeAny[] | null) => {
    return !arr || arr.length === 0;
};

/**
 * Converts an array of data into an array of select options.
 * @param data The input data array.
 * @param label The label property or function.
 * @param value The value property.
 * @param groupBy The grouping function.
 * @returns An array of select options.
 */
export const toSelectOptions = <TDto>(
    data: TDto[],
    label: keyof TDto | ((record: TDto) => string),
    value: keyof (TDto & IEntity) = 'id',
    groupBy: (record: TDto) => string | null | undefined = () => undefined
) => {
    return data.map(record => {
        return {
            label:
                typeof label === 'string'
                    ? ((record as SafeAny)[label] as string)
                    : (label as (record: TDto) => string)(record),
            value: (record as SafeAny)[value] as SafeAny,
            group: groupBy(record) as SafeAny,
        } as SelectOption;
    });
};

/**
 * Converts an array of data into a flattened array of select options, supporting grouping.
 * @param data The input data array.
 * @param label The label property or function.
 * @param value The value property.
 * @param groupBy The grouping function.
 * @returns A flattened array of select options.
 */
export const toSelectOptionsFlattened = <TDto>(
    data: TDto[],
    label: keyof TDto | ((record: TDto) => string),
    value: keyof (TDto & IEntity) = 'id',
    groupBy: (record: TDto) => string[] | null | undefined = () => undefined
) => {
    return data.flatMap(record => {
        const groups = groupBy(record);
        return (groups || [null]).map(
            group =>
                ({
                    label:
                        typeof label === 'string'
                            ? ((record as SafeAny)[label] as string)
                            : (label as (record: TDto) => string)(record),
                    value: (record as SafeAny)[value] as SafeAny,
                    group: group as SafeAny,
                }) as SelectOption
        );
    });
};

/**
 * Converts an array of data into a tree structure for select options.
 * @param data The input data array.
 * @param title The title property.
 * @param value The value property.
 * @param recursive Whether to recursively process children.
 * @returns An array of tree select options.
 */
export const toTreeSelectOptions = <TDto>(
    data: TDto[],
    title: keyof TDto,
    value: keyof (TDto & IEntity) = 'id',
    recursive = false
): TreeSelectOption[] => {
    return data.map(record => {
        const children = (record as SafeAny).children as TDto[];
        return {
            title: record[title] as SafeAny,
            key: (record as SafeAny)[value],
            value: (record as SafeAny)[value],
            children: recursive
                ? toTreeSelectOptions(
                      asArraySafe(children),
                      title,
                      value,
                      recursive
                  )
                : null,
            isLeaf: recursive && !children?.length,
        } as TreeSelectOption;
    });
};

/**
 * Flattens a tree structure into an array.
 * @param data The input tree data.
 * @returns A flattened array.
 */
export function flattenTree<T extends { children?: T[] }>(data: T): T[] {
    if (!data.children?.length) {
        return [] as T[];
    }
    return data.children.concat(
        data.children.reduce((pv, cv) => {
            return pv.concat(flattenTree(cv));
        }, [] as T[])
    );
}

/**
 * Changes the index of an element in an array.
 * @param arr The input array.
 * @param oldIndex The old index of the element.
 * @param newIndex The new index of the element.
 * @returns The array with the element moved to the new index.
 */
export function changeArrayIndex<T>(
    arr: T[],
    oldIndex: number,
    newIndex: number
): T[] {
    if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined as unknown as T);
        }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
}

/**
 * Ensures that the input is returned as an array.
 * - If the input is already an array, it returns the array itself.
 * - If the input is an object or any primitive type, it wraps the input in an array.
 * - If the input is null or undefined, it returns null.
 *
 * @template T - The type of the input elements.
 * @param {T | T[] | null | undefined} input - The value to be ensured as an array.
 * @returns {T[] | null} - The original array, a new array containing the input, or null if input is null/undefined.
 *
 * @example
 *
 * ensureArray([1, 2, 3]); // Returns: [1, 2, 3]
 * ensureArray('hello');   // Returns: ['hello']
 * ensureArray(null);      // Returns: null
 * ensureArray(undefined); // Returns: null
 * ensureArray({ key: 'value' }); // Returns: [{ key: 'value' }]
 */
export function ensureArray<T>(input: T | T[] | null | undefined): T[] {
    if (input === null || input === undefined) {
        return [];
    }
    if (Array.isArray(input)) {
        return input;
    }
    return [input];
}
