import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    ICustomField,
    IPagedResult,
    IPagedSearchParams,
    PFCoreEntities,
} from '@pf/shared-common';
import {
    LocationCreate,
    LocationRead,
    LocationSearchParams,
} from '../../entities/location.dto';

import { Injectable } from '@angular/core';
import {
    LocationCustomFieldsService,
    LocationsService,
} from '@control-tower/platform-core';
import { LocationStore } from './location.store';
import { map, Observable, tap } from 'rxjs';
import { Operation } from 'fast-json-patch';
import { ContactStore, IContact } from '@pf/shared/util-platform';

@Injectable()
export class LocationDataService extends AbstractDataService<
    LocationRead,
    LocationCreate
> {
    protected EntityName = PFCoreEntities.Location;

    constructor(
        private service: LocationsService,
        private customFieldsService: LocationCustomFieldsService,
        store: LocationStore,
        private contactStore: ContactStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(body: LocationCreate): Observable<LocationRead> {
        return this.service.locationCreate({
            locationViewModelCreateBody: body,
        }) as Observable<LocationRead>;
    }

    protected deleteObs$(id: string): Observable<LocationRead> {
        return this.service.locationDelete({
            id,
        }) as Observable<LocationRead>;
    }

    protected undoDeleteObs$(id: string): Observable<LocationRead> {
        return this.service.locationUndoDelete({
            id,
        }) as Observable<LocationRead>;
    }

    protected getObs$(id: string): Observable<LocationRead> {
        return this.service.locationReadById({
            id,
        }) as Observable<LocationRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<LocationRead> {
        return (
            this.service.locationUpdatePatch({
                id,
                operation: operations,
            }) as Observable<LocationRead>
        ).pipe(
            map(x => {
                // TODO: figure out a better way to fix this
                // maybe always return booleans from api
                x.shouldReceiveNotifications ??= false;
                return x;
            })
        );
    }

    protected searchObs$(
        searchParams: LocationSearchParams
    ): Observable<IPagedResult<LocationRead>> {
        return this.service.locationSearch(searchParams) as Observable<
            IPagedResult<LocationRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<LocationRead>>> {
        return this.service.locationGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingDto<LocationRead>>
        >;
    }

    protected override createCustomFieldObs$(
        entityId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.locationCreateCustomField({
            entityId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override updateCustomFieldObs$(
        entityId: string,
        customFieldId: string,
        customField: ICustomField
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.locationCustomFieldsUpdatePut({
            entityId,
            id: customFieldId,
            customFieldViewModelCreate: {
                key: customField.key,
                value: customField.value,
            },
        }) as Observable<ICustomField & { id: string }>;
    }

    protected override deleteCustomFieldObs$(
        entityId: string,
        customFieldId: string
    ): Observable<ICustomField & { id: string }> {
        return this.customFieldsService.locationDeleteCustomField({
            entityId,
            id: customFieldId,
        }) as Observable<ICustomField & { id: string }>;
    }

    getContacts$(id: string): Observable<IContact[]> {
        return this.service
            .v1LocationsIdContactsGet({
                id,
            })
            .pipe(
                map(response => response.contacts as IContact[]),
                tap(contacts => {
                    contacts.forEach(contact =>
                        this.contactStore.add(contact, false)
                    );
                })
            ) as Observable<IContact[]>;
    }

    protected override searchCustomFieldsObs$(
        searchParams: IPagedSearchParams & { key?: string; value?: string }
    ): Observable<IPagedResult<ICustomField>> {
        return this.customFieldsService.locationSearchCustomFields(
            searchParams
        ) as Observable<IPagedResult<ICustomField>>;
    }
}
