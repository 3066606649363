import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import {
    FiscalPeriodsService,
    FiscalPeriodStatus,
} from '@control-tower/platform-core';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    IPagedResult,
    PFCoreEntities,
} from '@pf/shared-common';
import { FiscalPeriodStore } from './fiscal-period.store';
import {
    FiscalPeriodCreate,
    FiscalPeriodRead,
    FiscalPeriodSearchParams,
} from '../../entities/fiscal-period.dto';
import { Operation } from 'fast-json-patch';
import { asArraySafe } from '@pf/shared-utility';

@Injectable()
export class FiscalPeriodDataService extends AbstractDataService<
    FiscalPeriodRead,
    FiscalPeriodCreate
> {
    protected EntityName = PFCoreEntities.FiscalPeriod;

    constructor(
        private service: FiscalPeriodsService,
        store: FiscalPeriodStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(
        body: FiscalPeriodCreate
    ): Observable<FiscalPeriodRead> {
        return this.service.fiscalPeriodCreate({
            fiscalPeriodViewModelCreateBody: body,
        }) as Observable<FiscalPeriodRead>;
    }

    protected deleteObs$(id: string): Observable<FiscalPeriodRead> {
        return this.service.fiscalPeriodDelete({
            id,
        }) as Observable<FiscalPeriodRead>;
    }

    protected undoDeleteObs$(id: string): Observable<FiscalPeriodRead> {
        return this.service.fiscalPeriodUndoDelete({
            id,
        }) as Observable<FiscalPeriodRead>;
    }

    protected getObs$(id: string): Observable<FiscalPeriodRead> {
        return this.service.fiscalPeriodReadById({
            id,
        }) as Observable<FiscalPeriodRead>;
    }

    protected updateObs$(
        id: string,
        operations: Operation[]
    ): Observable<FiscalPeriodRead> {
        return this.service.fiscalPeriodUpdatePatch({
            id,
            operation: operations,
        }) as Observable<FiscalPeriodRead>;
    }

    protected searchObs$(
        searchParams: FiscalPeriodSearchParams
    ): Observable<IPagedResult<FiscalPeriodRead>> {
        return this.service.fiscalPeriodSearch(searchParams) as Observable<
            IPagedResult<FiscalPeriodRead>
        >;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<IPagedResult<EntityChangeTrackingDto<FiscalPeriodRead>>> {
        return this.service.fiscalPeriodGetChanges(searchParams) as Observable<
            IPagedResult<EntityChangeTrackingDto<FiscalPeriodRead>>
        >;
    }

    public getOverlappingFiscalPeriods$(
        startDate: string,
        endDate: string
    ): Observable<FiscalPeriodRead[]> {
        return this.service
            .fiscalPeriodSearch({
                dateRangeStart: startDate,
                dateRangeEnd: endDate,
                status: FiscalPeriodStatus.Open,
                pageNumber: 1,
                pageSize: 10,
                isDeleted: false,
            })
            .pipe(
                map(result => {
                    return asArraySafe(result?.data);
                })
            ) as Observable<FiscalPeriodRead[]>;
    }
}
