import {
    inject,
    Injectable,
    Optional,
    signal,
    WritableSignal,
} from '@angular/core';
import { AbstractManageEntityFacade } from '@pf/shared-services';
import {
    StopCreate,
    StopDto,
    StopRead,
    StopSearchParams,
} from '../../entities/stop.dto';
import { StopDataService } from '../../infrastructure/stops/stop.data.service';
import { StopMapper } from '../../infrastructure/stops/stop.mapper';
import { StopStore } from '../../infrastructure/stops/stop.store';
import { RoutesForStops } from './stop.routes';
import { StopItemDataService } from '../../infrastructure/stop-items/stop-item.data.service';
import {
    combineLatest,
    distinctUntilChanged,
    first,
    map,
    Observable,
    of,
    shareReplay,
    switchMap,
    tap,
} from 'rxjs';
import { StopItemDto } from '../../entities/stop-item.dto';
import { StopItemFacade } from '../stop-item.facade';
import {
    LoadStopTypesConfig,
    StopFacadeConfig,
    StopView,
} from './stop-facade.config';
import { asArraySafe, optionalMap } from '@pf/shared-utility';
import { StopChargeDto } from '../../entities/stop-charge.dto';
import { StopTypeFacade } from '../type-entities/StopTypeFacade';
import { LoadDto } from '../../entities/load.dto';
import { CustomFieldConfig } from '@pf/shared/util-platform';
import { EntitySaveOptions, SafeAny } from '@pf/shared-common';
import { ChargeTypeFacade } from '../type-entities/ChargeTypeFacade';
import { IStopUnifiedFragmentDto } from '../../entities/stop.unified.dto';
import { TypedFormGroup } from 'ngx-sub-form';

@Injectable()
export class StopFacade extends AbstractManageEntityFacade<
    StopDto,
    StopRead,
    StopCreate,
    StopSearchParams
> {
    private readonly _chargeTypeFacade = inject(ChargeTypeFacade);
    private readonly _stopTypeFacade = inject(StopTypeFacade);
    readonly _loadStopTypes$ = this.config
        ? this.config.stopTypes$.pipe(
              switchMap(stopTypesConfig =>
                  this._stopTypeFacade.mapToCodes$(stopTypesConfig)
              )
          )
        : of([]);

    stopItemFacade = inject(StopItemFacade);

    nameField: keyof StopDto = 'id';

    stopTypes$: Observable<LoadStopTypesConfig> = this._loadStopTypes$.pipe(
        map(typeConfigs => typeConfigs.filter(type => !!type.typeFragment)),
        tap(types => {
            this.validateLoadStopTypes(types);
        }),
        shareReplay(1)
    );
    stopView!: WritableSignal<StopView>;

    canAddStopItem$ = (stop?: StopDto | IStopUnifiedFragmentDto | null) => {
        return (
            this.config?.canAddStopItem$(stop).pipe(shareReplay(1)) || of(true)
        );
    };

    isStopItemReadonly$ = (
        stop?: StopDto | IStopUnifiedFragmentDto | null,
        stopItem?: StopItemDto | null
    ) => {
        return (
            this.config
                ?.isStopItemReadonly$(stop, stopItem)
                .pipe(distinctUntilChanged(), shareReplay(1)) ||
            of({} as Record<keyof StopItemDto, boolean>)
        );
    };

    textSearchFilter(): Partial<Record<keyof StopDto, string>> {
        throw new Error('Method not implemented.');
    }

    get customFieldsConfig$(): Observable<CustomFieldConfig[]> {
        return this.config?.stopCustomFields$ || of([]);
    }

    constructor(
        dataService: StopDataService,
        private stopItemDataService: StopItemDataService,
        routes: RoutesForStops,
        store: StopStore,
        mapper: StopMapper,
        @Optional() public config?: StopFacadeConfig
    ) {
        super(dataService, routes, store, mapper);
        this._chargeTypeFacade.getAll$().subscribe();
        if (config) {
            config.facade = this;
        }
        this.setViewSignal();
    }

    override add(): void {
        this.resetActive();
    }

    override edit(entityId: string): void {
        this.store.setActiveEntity(entityId);
    }

    override save$(
        dto: StopDto,
        options?: EntitySaveOptions<StopDto> & { saveItems?: boolean }
    ): Observable<StopDto> {
        console.log('saving stop', dto);
        const resolvedOptions = {
            saveItems: true,
            ...options,
        };
        const stopItems = [...asArraySafe(dto.stopItems)];
        dto.stopItems = [];
        const newItemCharges = asArraySafe(dto.charges).filter(
            c => c.stopItem?.id && c.stopItem.id.length < 3
        );
        dto.charges = asArraySafe(dto.charges).filter(
            c => !c.stopItem?.id || c.stopItem.id.length > 3
        );
        return of(dto).pipe(
            optionalMap(this.config?.preSaveMap),
            // Call base save
            switchMap(stop => super.save$(stop, resolvedOptions)),
            switchMap(stop => {
                // Save can be called twice, once for the stop and again after adding the stop items to the charges.
                // This prevents the stop items from being saved twice.
                if (!resolvedOptions.saveItems) {
                    return of(stop);
                }
                // Save stop items if any
                const observable = stopItems.length
                    ? combineLatest(
                          stopItems.map(item => {
                              item.stopId = stop.id;
                              return item.isDeleted
                                  ? this.stopItemFacade.delete$(item.id)
                                  : this.stopItemFacade.save$(item);
                          })
                      ).pipe(
                          // Filter out deleted items
                          map(items => items.filter(item => !item.isDeleted))
                      )
                    : of([]);
                return observable.pipe(
                    switchMap(items =>
                        // Save new charges with stop items
                        this.saveNewCharges$(stop, newItemCharges, items).pipe(
                            // Add updated stop items to stop
                            map(stop => {
                                stop.stopItems = items;
                                return stop;
                            })
                        )
                    )
                );
            }),
            optionalMap(this.config?.postSaveMap)
        );
    }

    getStopItems(stopId: string): StopItemDto[] {
        return this.stopItemFacade.getManyByStopId(stopId);
    }

    getStopItems$(stopId?: string): Observable<StopItemDto[]> {
        return (
            stopId
                ? of(stopId)
                : this.store.active$().pipe(
                      first(),
                      map(stop => stop?.id)
                  )
        ).pipe(
            switchMap(stopId => {
                if (!stopId) {
                    return of([]);
                }

                return this.stopItemDataService
                    .oneTimeSearch$({
                        pageSize: 50,
                        pageNumber: 1,
                        isDeleted: false,
                        stopId: stopId,
                    } as StopSearchParams)
                    .pipe(
                        map(result => {
                            return result.data.map(
                                item => new StopItemDto(item as StopItemDto)
                            );
                        })
                    );
            })
        );
    }

    private saveNewCharges$(
        stop: StopDto,
        newCharges: StopChargeDto[],
        stopItems: StopItemDto[]
    ) {
        if (!newCharges.length) {
            return of(stop);
        }

        const charges = newCharges.map(charge => {
            const stopItemIndex = +(charge.stopItem?.id as string);
            const stopItem = stopItems[stopItemIndex];
            if (!stopItem) {
                throw new Error(
                    `Stop item at index ${stopItemIndex} not found`
                );
            }
            return new StopChargeDto({
                ...charge,
                stopItem: {
                    id: stopItem.id,
                },
            });
        });
        stop.charges = [...asArraySafe(stop.charges), ...charges];
        return this.save$(stop, { saveItems: false });
    }

    private validateLoadStopTypes(types: LoadStopTypesConfig) {
        if (!types.length) {
            return;
        }
        const pickupTypes = types.filter(t => t.stopType === 'pickup');
        const deliveryTypes = types.filter(t => t.stopType === 'delivery');
        if (pickupTypes.length === 0) {
            throw new Error('There must be at least one pickup type.');
        }
        if (deliveryTypes.length === 0) {
            throw new Error('There must be at least one delivery type.');
        }
    }

    setAssociatedLoad(active: LoadDto | null) {
        this.config?.associatedLoad$?.next(active);
    }

    notifyFormChange(stop: StopDto, control: TypedFormGroup<SafeAny>) {
        if (this.config?.onFormChange) {
            this.config.onFormChange(stop, control);
        }
    }

    mapStops(stops: IStopUnifiedFragmentDto[]): IStopUnifiedFragmentDto[] {
        return this.config?.loadStopsMap
            ? this.config.loadStopsMap(stops)
            : stops;
    }

    private setViewSignal() {
        const defaultView: StopView = {
            canUpdateItems: true,
        };
        if (!this.config?.view) {
            this.stopView = signal<StopView>(defaultView);
        } else {
            this.config.view.update(view => {
                return {
                    ...defaultView,
                    ...view,
                };
            });
            this.stopView = this.config.view;
        }
    }
}
