import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TypeEntityStore } from './stores/TypeEntityStore';
import { RequiredPermissionsDirective } from './directives/required-permissions.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [RequiredPermissionsDirective],
    exports: [RequiredPermissionsDirective],
    providers: [TypeEntityStore],
})
export class SharedUtilPlatformModule {}
