import { CategoryDataService } from '../infrastructure/categories/category.data.service';
import {
    CategoryCreate,
    CategoryDto,
    CategoryRead,
    CategorySearchParams,
} from '../entities/category.dto';
import { CategoryStore } from '../infrastructure/categories/category.store';
import { Injectable, InjectionToken } from '@angular/core';
import { AbstractManageEntityFacade } from '@pf/shared-services';
import { CategoryMapper } from '../infrastructure/categories/category.mapper';
import {
    BuildChangeTrackingFacadeFactory,
    mapToIPagedResult,
} from '@pf/shared/util-platform';
import { RoutesForCategory } from './item.routes';
import {
    IPagedResult,
    IQueryParams,
    ITreeOptionsSearchFacade,
    SafeAny,
    TreeSelectOption,
} from '@pf/shared-common';
import {
    combineLatest,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    tap,
} from 'rxjs';
import * as hash from 'object-hash';
import { ItemDto } from '../entities/item.dto';
import { ModalService } from '@pf/shared-ui';
import { mapToTreeSelectOptions, normalizeString } from '@pf/shared-utility';
import { ResourceAuth } from '@control-tower/platform-item-catalog';

export const READONLY_CATEGORY_NAMES = new InjectionToken<string[]>(
    'readonly-category-names'
);

@Injectable()
export class CategoryFacade
    extends AbstractManageEntityFacade<
        CategoryDto,
        CategoryRead,
        CategoryCreate,
        CategorySearchParams
    >
    implements ITreeOptionsSearchFacade<CategoryDto>
{
    nameField: keyof CategoryDto = 'name';

    parentIdField: keyof CategoryDto = 'parentCategoryId';

    constructor(
        dataService: CategoryDataService,
        routes: RoutesForCategory,
        protected override store: CategoryStore,
        mapper: CategoryMapper,
        private modalService: ModalService
    ) {
        super(dataService, routes, store, mapper);
    }

    override applyResourceAuth(body: CategoryCreate) {
        body.resourceAuth = {
            allAuthorized: true,
        } as ResourceAuth;
    }

    suggestedCategories$ = this.store.getUIEntities$().pipe(
        map(categories => {
            return [...categories]
                .sort((a, b) => {
                    const aItemsCount = a.itemsWithCategory?.length || 0;
                    const bItemsCount = b.itemsWithCategory?.length || 0;
                    return bItemsCount - aItemsCount;
                })
                .slice(0, 10);
        }),
        switchMap(categories => {
            return combineLatest(
                categories.map(category => {
                    return this.getById$(category.id);
                })
            );
        }),
        map(categories => {
            return categories.filter(category => !category.isDeleted);
        })
    );

    changeTrackingFactory = BuildChangeTrackingFacadeFactory(
        this.dataService,
        this.nameField as keyof CategoryRead
    );

    textSearchFilter(
        searchText: string
    ): Partial<Record<keyof CategoryDto, string>> {
        return { name: searchText };
    }

    loadChildren$(parentId: string): Observable<IPagedResult<CategoryDto>> {
        return this.store.getChildren$(parentId).pipe(
            map(categories => categories.filter(c => !c.isDeleted)),
            mapToIPagedResult
        );
    }

    loadTopLevel$(): Observable<IPagedResult<CategoryDto>> {
        return super.oneTimeSearch$({ pageSize: 100, pageIndex: 1 }).pipe(
            switchMap(() => this.store.getTopLevelCategories$()),
            map(categories => categories.filter(c => !c.isDeleted)),
            mapToIPagedResult
        );
    }

    override search$(
        params: IQueryParams<CategoryDto>
    ): Observable<IPagedResult<CategoryDto>> {
        this.lastQueryParams = params;
        return this.getCategoryTree$(params);
    }

    getCategoryTree$(
        params: IQueryParams<CategoryDto>
    ): Observable<IPagedResult<CategoryDto>> {
        function filterFn(category: CategoryDto) {
            const searchFilter = normalizeString(params.searchValue || '');
            const hasChildren = !!category.children?.length;
            const showDeleted =
                !!(params as SafeAny).checkboxes?.isDeleted ||
                !category.isDeleted;
            const matchesCode =
                !params.filters?.code ||
                normalizeString(category.code) ===
                    normalizeString(params.filters.code as string);
            const matchesSearch =
                matchesCode &&
                (!searchFilter ||
                    normalizeString(category.name).indexOf(searchFilter) > -1 ||
                    normalizeString(category.code).indexOf(searchFilter) > -1);
            return hasChildren || (showDeleted && matchesSearch);
        }

        return super.oneTimeSearch$(params).pipe(
            map(result => {
                const topLevelCategories = this.categorySorter(
                    result.data.filter(category => category.isTopLevelCategory)
                );

                const getChildren = (category: CategoryDto): CategoryDto[] => {
                    const children = result.data.filter(
                        child =>
                            child.parentCategoryId &&
                            child.parentCategoryId === category.id
                    );
                    if (!children.length) {
                        return [];
                    }

                    return this.categorySorter(children).map(child => {
                        return {
                            ...child,
                            children: getChildren(child).filter(filterFn),
                        };
                    });
                };

                topLevelCategories.forEach(category => {
                    category.children = getChildren(category).filter(filterFn);
                });

                return topLevelCategories.filter(filterFn);
            }),
            mapToIPagedResult,
            tap(result => this.store.setPage(result, hash(params)))
        );
    }

    getCategoryTreeWithItems$(
        parentCode: string
    ): Observable<TreeSelectOption[]> {
        const getItemOptionsForCategory$ = (
            categoryId: string
        ): Observable<TreeSelectOption[]> => {
            // Get items for category
            return this.getRelatedItems$(categoryId).pipe(
                // TODO: we should do this on the back end -terry
                map(x => ({
                    ...x,
                    data: x.data.filter(y => !y.isDeleted),
                })),
                mapToTreeSelectOptions('name'),
                map(options => {
                    // Return item options with icon and isLeaf set to true
                    return options.map(option => {
                        return {
                            ...option,
                            isLeaf: true,
                            icon: 'item',
                        } as TreeSelectOption;
                    });
                })
            );
        };

        const getItemOptionsForCategories$ = (
            categories: CategoryDto[]
        ): Observable<TreeSelectOption[]> => {
            if (!categories?.length) {
                return of([]);
            }
            return combineLatest(
                categories.map(category => {
                    const categoryOption = {
                        title: category.name,
                        value: category.id,
                        key: category.id,
                        selectable: false,
                    } as TreeSelectOption;
                    // Build item tree for category
                    return getItemOptionsForCategory$(category.id).pipe(
                        switchMap(options => {
                            if (category.children) {
                                // Build item tree for children
                                return getItemOptionsForCategories$(
                                    category.children
                                ).pipe(
                                    map(children => {
                                        // Concat children and item options
                                        return {
                                            ...categoryOption,
                                            children: children.concat(options),
                                        } as TreeSelectOption;
                                    })
                                );
                            }
                            // No children, just return category option
                            categoryOption.isLeaf = true;
                            return of(categoryOption);
                        })
                    );
                })
            );
        };

        return this.getCategoryTree$({ pageSize: 100, pageIndex: 1 }).pipe(
            switchMap(categories => {
                const parentCategory = categories.data.find(
                    c => c.code === parentCode
                );
                if (!parentCategory) {
                    console.warn(`No category found for code ${parentCode}`);
                    return of([]);
                }
                return getItemOptionsForCategories$([parentCategory]);
            })
        );
    }

    private categorySorter(categories: CategoryDto[]) {
        return categories.sort((a, b) => {
            if (a.readOnly) {
                return -1;
            } else if (b.readOnly) {
                return 1;
            }
            return a.name.localeCompare(b.name);
        });
    }

    getRelatedItems$(categoryId: string): Observable<IPagedResult<ItemDto>> {
        return (this.dataService as CategoryDataService).getCategoryItems$(
            categoryId
        ) as Observable<IPagedResult<ItemDto>>;
    }

    getSubCategoryCount$(categoryId: string) {
        return this.getById$(categoryId).pipe(
            map(category => {
                return this.getSubCategoryCount(category);
            })
        );
    }

    override delete$(
        entityId: string,
        actionText?: string
    ): Observable<CategoryDto> {
        return this.getById$(entityId).pipe(
            switchMap(entity => {
                const deleteSubject = new Subject<boolean>();
                if (!entity.children || entity.children?.length === 0) {
                    this.modalService.confirmAction(
                        'Category',
                        () => deleteSubject.next(true),
                        'Delete',
                        () => deleteSubject.next(false)
                    );
                } else {
                    this.modalService.warning({
                        title: 'Delete Category',
                        content: `Are you sure you want to delete this category? There are ${this.getSubCategoryCount(
                            entity
                        )} sub-categories that will be deleted as well.`,
                        onOk: () => deleteSubject.next(true),
                        onCancel: () => deleteSubject.next(false),
                    });
                }
                return deleteSubject;
            }),
            switchMap(confirmed =>
                confirmed
                    ? this.deleteRecursively$(entityId, actionText)
                    : this.getById$(entityId)
            )
        );
    }

    deleteRecursively$(categoryId: string, actionText?: string) {
        return this.store.getAllChildren$(categoryId).pipe(
            switchMap(children => {
                return combineLatest([
                    super.delete$(categoryId, actionText),
                    ...children.map(child =>
                        super.delete$(child.id, actionText, false)
                    ),
                ]);
            }),
            map(results => results[0])
        );
    }

    override undoDelete$ = (
        entityId: string,
        actionText?: string
    ): Observable<CategoryDto> => {
        return this.store.getAllChildren$(entityId).pipe(
            switchMap(children => {
                return combineLatest([
                    super.undoDelete(entityId, actionText),
                    ...children.map(child =>
                        super.undoDelete(child.id, actionText, false)
                    ),
                ]);
            }),
            map(results => results[0])
        );
    };

    private getSubCategoryCount(category?: CategoryDto) {
        if (!category) {
            return 0;
        }
        let subCategoryCount = category.children?.length || 0;
        category.children?.forEach(subCategory => {
            subCategoryCount += this.getSubCategoryCount(subCategory);
        });
        return subCategoryCount;
    }
}
