import { IEntity } from '@pf/shared-common';
import {
    VehicleSearchRequestParams,
    VehicleViewModelCreateBody,
    VehicleViewModelRead,
} from '@control-tower/platform-transportation';
import {
    ICarrierFragmentDto,
    ILocationFragmentDto,
} from '@pf/shared/util-platform';
import { TypeEntityFragment } from '@control-tower/platform-transportation/model/typeEntityFragment';

export type VehicleRead = VehicleViewModelRead & IEntity;
export type VehicleCreate = VehicleViewModelCreateBody;
export type VehicleSearchParams = VehicleSearchRequestParams;

export class VehicleDto implements IEntity {
    constructor(data: Partial<VehicleDto>) {
        Object.assign(this, data);
    }

    id!: string;
    isDeleted?: boolean;
    createdDate?: string;
    modifiedDate?: string;
    vehicleNumber!: string;
    carrier!: ICarrierFragmentDto;
    location?: ILocationFragmentDto;
    vehicleType?: TypeEntityFragment;
}
