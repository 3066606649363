import { AbstractEntityMapper } from '@pf/shared-services';
import { Injectable } from '@angular/core';
import {
    ItemChargeCreate,
    ItemChargeDto,
    ItemChargeRead,
    ItemChargeSearchParams,
} from '../../entities/item-charge.dto';
import { ItemChargeMetadataMap } from './item-charge.mapping-profile';
import { PlatformLoadsMapper } from '../platform-loads.mapper';

@Injectable()
export class ItemChargeMapper extends AbstractEntityMapper<
    ItemChargeRead,
    ItemChargeCreate,
    ItemChargeDto,
    ItemChargeSearchParams
> {
    constructor() {
        super(
            ItemChargeDto,
            PlatformLoadsMapper,
            ItemChargeMetadataMap.symbols
        );
    }
}
