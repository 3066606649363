/**
 * Determines the mobile operating system based on the user agent string.
 * @returns 'Android' | 'iOS' | 'Other'
 */
export const getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
        return 'Android';
    } else if (
        /iPad|iPhone|iPod/.test(ua) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    ) {
        return 'iOS';
    }
    return 'Other';
};

/**
 * Copies a given text to the clipboard.
 * @param text - The text to copy.
 * @returns A promise that resolves to true if the text is successfully copied, and false otherwise.
 */
export const copyToClipboard = (text: string) => {
    return navigator.clipboard.writeText(text).then(
        () => {
            return true;
        },
        () => {
            return false;
        }
    );
};
