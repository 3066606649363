import { FilterOption } from '@pf/shared-common';
import {
    endOfDay,
    endOfMonth,
    endOfToday,
    endOfYesterday,
    format,
    isDate,
    parse,
    startOfDay,
    startOfMonth,
    startOfToday,
    startOfWeek,
    startOfYesterday,
    subMonths,
    subWeeks,
} from 'date-fns';
// import { lookup as zipToTzLookup } from 'zipcode-to-timezone';
import { formatInTimeZone } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';

let zipToTzLookup: (zip: string | number) => string | null = () => '';
import('zipcode-to-timezone').then(m => (zipToTzLookup = m.lookup));

/**
 * Converts a zip code to a timezone.
 */
export function zipToTimezone(zip?: string) {
    if (!zip) {
        return '';
    }
    return zipToTzLookup(zip) || '';
}

export type DateRangeOption =
    | 'custom'
    | 'today'
    | 'yesterday'
    | 'thisWeek'
    | 'lastWeek'
    | 'thisMonth'
    | 'lastMonth'
    | 'last3Months'
    | 'last6Months'
    | 'last12Months'
    | 'calendarMonth';

export type DateRange = {
    startDate: string;
    endDate: string;
};

/**
 * Defines various date ranges as functions that return the start and end dates in ISO string format.
 */
export const DateRanges: Record<
    DateRangeOption,
    (
        startDate?: string,
        endDate?: string
    ) => { from: string; to: string; label: string }
> = {
    custom: (startDate, endDate) => ({
        from: startDate as string,
        to: endDate as string,
        label: 'Custom',
    }),
    today: () => ({
        from: startOfToday().toISOString(),
        to: endOfToday().toISOString(),
        label: 'Today',
    }),
    yesterday: () => ({
        from: startOfYesterday().toISOString(),
        to: endOfYesterday().toISOString(),
        label: 'Yesterday',
    }),
    thisWeek: () => ({
        from: startOfWeek(new Date()).toISOString(),
        to: endOfDay(new Date()).toISOString(),
        label: 'Current Week',
    }),
    lastWeek: () => ({
        from: startOfDay(subWeeks(new Date(), 1)).toISOString(),
        to: endOfDay(new Date()).toISOString(),
        label: 'Last Week',
    }),
    thisMonth: () => ({
        from: startOfMonth(new Date()).toISOString(),
        to: endOfDay(new Date()).toISOString(),
        label: 'Current Month',
    }),
    lastMonth: () => ({
        from: startOfDay(subMonths(new Date(), 1)).toISOString(),
        to: endOfDay(new Date()).toISOString(),
        label: 'Last Month',
    }),
    last3Months: () => ({
        from: startOfDay(subMonths(new Date(), 3)).toISOString(),
        to: endOfDay(new Date()).toISOString(),
        label: 'Last 3 Months',
    }),
    last6Months: () => ({
        from: startOfDay(subMonths(new Date(), 6)).toISOString(),
        to: endOfDay(new Date()).toISOString(),
        label: 'Last 6 Months',
    }),
    last12Months: () => ({
        from: startOfDay(subMonths(new Date(), 12)).toISOString(),
        to: endOfDay(new Date()).toISOString(),
        label: 'Last 12 Months',
    }),
    calendarMonth: () => ({
        from: subMonths(new Date(), 1).toISOString(),
        to: endOfDay(new Date()).toISOString(),
        label: 'Calendar Month',
    }),
};

/**
 * Generates filter options based on provided date range options.
 */
export const DateRangeFilterOptions = (
    options: Partial<Record<DateRangeOption, true>> & {
        initialDateRage?: DateRange;
        initialOption?: DateRangeOption;
        defaultOption: DateRangeOption;
    },
    fromField = 'createdStartDate',
    toField = 'createdEndDate',
    additionalFilters: Record<string, string | undefined> = {}
): FilterOption[] => {
    const {
        defaultOption,
        initialOption,
        initialDateRage,
        ...selectedOptions
    } = options;
    return Object.keys(selectedOptions).map(option => {
        const dateRangeOption = option as DateRangeOption;
        const rangeFn = DateRanges[option as DateRangeOption];
        return {
            label: rangeFn().label,
            value: dateRangeOption,
            default: dateRangeOption === defaultOption,
            initial:
                dateRangeOption === initialOption ||
                (initialDateRage?.startDate && dateRangeOption === 'custom'),
            filterFn: (startDate: string, endDate: string) => {
                const range = rangeFn(
                    startDate || initialDateRage?.startDate,
                    endDate || initialDateRage?.endDate
                );
                return {
                    ...additionalFilters,
                    [fromField]: range.from,
                    [toField]: range.to,
                };
            },
        } as FilterOption;
    });
};

/**
 * Checks if two date ranges overlap.
 */
export function dateRangeOverlaps(
    a_start: Date | number,
    a_end: Date | number,
    b_start: Date | number,
    b_end: Date | number
) {
    if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
    if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
    if (b_start <= a_start && a_end <= b_end) return true; // a in b
    return false;
}

/**
 * Safely formats a date string or Date object into a specified format.
 */
export function formatDateSafe(
    date: Date | string | undefined | null,
    format: string,
    locale: Locale = enUS,
    timeZone: string = 'UTC'
) {
    try {
        if (!date) {
            return '';
        }

        let parsedDate: Date;
        if (typeof date === 'string') {
            parsedDate = new Date(Date.parse(date));
        } else {
            parsedDate = date;
        }

        if (!isDate(parsedDate)) {
            return '';
        }

        return formatInTimeZone(parsedDate, timeZone, format, { locale });
    } catch (e) {
        console.error('Failed to format date', date, e);
        return '';
    }
}

export function dateNoTz(date: string) {
    if (!date) {
        return null;
    }
    const timeZoneIdentifierIndex = date.indexOf('T');
    if (timeZoneIdentifierIndex > -1) {
        date = date.substring(0, timeZoneIdentifierIndex);
    }

    return parseDate(date, 'yyyy-MM-dd');
}

export function getStartOfMonth(date?: string | null): string {
    if (!date) {
        return '';
    }

    const parsedDate = dateNoTz(date);
    if (!parsedDate) {
        return '';
    }

    return format(startOfMonth(parsedDate), 'yyyy-MM-dd');
}

export function getEndOfMonth(date?: string | null): string {
    if (!date) {
        return '';
    }

    const parsedDate = dateNoTz(date);
    if (!parsedDate) {
        return '';
    }

    return format(endOfMonth(parsedDate), 'yyyy-MM-dd');
}

export function parseDate(
    dateString: string,
    dateFormat: string = 'yyyy-MM-dd'
): Date | null {
    try {
        const parsed = parse(dateString, dateFormat, new Date());
        return isNaN(+parsed) ? null : parsed;
    } catch (e) {
        return null;
    }
}
