import {
    addProfile,
    CamelCaseNamingConvention,
    createMapper,
} from '@automapper/core';
import { IQueryParamsMetadata, IQueryParamsSymbol } from '@pf/shared-common';
import {
    withResourceAuthMetadata,
    withTypeEntityMetadata,
} from '@pf/shared/util-platform';

import { LoadMetadataMap } from './loads/load.mapping-profile';
import { pojos } from '@automapper/pojos';
import { withMetadata } from '@pf/shared-utility';
import { StopMetadataMap } from './stops/stop.mapping-profile';
import { StopItemMetadataMap } from './stop-items/stop-item.mapping-profile';
import { LoadScheduleMetadataMap } from './load-schedules/load-schedule.mapping-profile';
import { LoadScheduleSummaryMetadataMap } from './load-schedules/load-schedule-summary.mapping-profile';
import { LaneMetadataMap } from './lanes/lane.mapping-profile';
import { LaneRateMetadataMap } from './lane-rates/lane-rate.mapping-profile';
import { LaneCarrierMetadataMap } from './lane-carriers/lane-carrier.mapping-profile';
import { ItemChargeMetadataMap } from './item-charges/item-charge.mapping-profile';
import { LoadWorkflowMetadataMap } from './load-workflows/load-workflow.mapping-profile';
import { WorkflowGlobalParameterMetadataMap } from './workflow-global-parameters/workflow-global-parameter.mapping-profile';
import { withFormulaMetadata } from '@pf/shared/util-platform';

/** Global Metadata */
withMetadata(IQueryParamsSymbol, IQueryParamsMetadata);
withTypeEntityMetadata();
withResourceAuthMetadata();
withFormulaMetadata();

/** Create mapper */
export const PlatformLoadsMapper = createMapper({
    strategyInitializer: pojos(),
    namingConventions: new CamelCaseNamingConvention(),
});

/** Add mapping profiles */
addProfile(PlatformLoadsMapper, LoadMetadataMap.profile);
addProfile(PlatformLoadsMapper, StopMetadataMap.profile);
addProfile(PlatformLoadsMapper, StopItemMetadataMap.profile);
addProfile(PlatformLoadsMapper, LoadScheduleSummaryMetadataMap.profile);
addProfile(PlatformLoadsMapper, LoadScheduleMetadataMap.profile);
addProfile(PlatformLoadsMapper, LaneMetadataMap.profile);
addProfile(PlatformLoadsMapper, LaneRateMetadataMap.profile);
addProfile(PlatformLoadsMapper, LaneCarrierMetadataMap.profile);
addProfile(PlatformLoadsMapper, ItemChargeMetadataMap.profile);
addProfile(PlatformLoadsMapper, LoadWorkflowMetadataMap.profile);
addProfile(PlatformLoadsMapper, WorkflowGlobalParameterMetadataMap.profile);
