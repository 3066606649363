import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityFacadeConfig } from '../abstractions/entity-facade.config';
import { ContactDto } from './contact.dto';
import { CustomFieldConfig } from '../configuration/custom-field.config';

@Injectable()
export abstract class ContactFacadeConfig extends EntityFacadeConfig<ContactDto> {
    abstract customFields$: Observable<CustomFieldConfig[]>;
}
