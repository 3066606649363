import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractDataService, LoggerService } from '@pf/shared-services';
import { WorkflowGlobalParametersService } from '@control-tower/platform-loads';
import {
    EntityChangeTrackingDto,
    IChangesSearchParams,
    IPagedResult,
    PFLoadEntities,
} from '@pf/shared-common';
import { WorkflowGlobalParameterStore } from './workflow-global-parameter.store';
import {
    WorkflowGlobalParametersCreate,
    WorkflowGlobalParametersRead,
    WorkflowGlobalParametersSearchParams,
} from '../../entities/workflow-global-parameters.dto';
import { Operation } from 'fast-json-patch';

@Injectable()
export class WorkflowGlobalParametersDataService extends AbstractDataService<
    WorkflowGlobalParametersRead,
    WorkflowGlobalParametersCreate
> {
    protected EntityName = PFLoadEntities.WorkflowGlobalParameter;

    constructor(
        private service: WorkflowGlobalParametersService,
        store: WorkflowGlobalParameterStore,
        logger: LoggerService
    ) {
        super(store, logger);
    }

    protected createObs$(
        body: WorkflowGlobalParametersCreate
    ): Observable<WorkflowGlobalParametersRead> {
        return this.service.workflowGlobalParametersEntityCreate({
            workflowGlobalParametersCreateBody: body,
        }) as Observable<WorkflowGlobalParametersRead>;
    }

    protected deleteObs$(id: string): Observable<WorkflowGlobalParametersRead> {
        return this.service.workflowGlobalParametersEntityDelete({
            id,
        }) as Observable<WorkflowGlobalParametersRead>;
    }

    protected undoDeleteObs$(
        id: string
    ): Observable<WorkflowGlobalParametersRead> {
        return this.service.workflowGlobalParametersEntityUndoDelete({
            id,
        }) as Observable<WorkflowGlobalParametersRead>;
    }

    protected getObs$(id: string): Observable<WorkflowGlobalParametersRead> {
        return this.service.workflowGlobalParametersEntityReadById({
            id,
        }) as Observable<WorkflowGlobalParametersRead>;
    }

    protected updateObs$(
        id: string,
        _: Operation[],
        createBody: WorkflowGlobalParametersCreate
    ): Observable<WorkflowGlobalParametersRead> {
        return this.service.workflowGlobalParametersEntityUpdatePut({
            id,
            workflowGlobalParametersCreateBody: createBody,
        }) as Observable<WorkflowGlobalParametersRead>;
    }

    protected searchObs$(
        searchParams: WorkflowGlobalParametersSearchParams
    ): Observable<IPagedResult<WorkflowGlobalParametersRead>> {
        return this.service.workflowGlobalParametersEntitySearch(
            searchParams
        ) as Observable<IPagedResult<WorkflowGlobalParametersRead>>;
    }

    protected searchChangesObs$(
        searchParams: IChangesSearchParams
    ): Observable<
        IPagedResult<EntityChangeTrackingDto<WorkflowGlobalParametersRead>>
    > {
        return this.service.workflowGlobalParametersEntityGetChanges(
            searchParams
        ) as Observable<
            IPagedResult<EntityChangeTrackingDto<WorkflowGlobalParametersRead>>
        >;
    }
}
